import { CustomChain } from "@ethereumjs/common";
import EthNetworkIcon from "assets/EthIcon.svg";
import PolygonIcon from "assets/PolygonRoundIcon.svg";

import PolygonRoundIcon from "assets/PolygonRound.svg";

import { topTokensPolygon } from "./topTokensConf";
import { NATIVE_ADDRESS } from "constants";

export const ETHERSCAN_API_KEY = "M193VGUECIDFKWVWAJA9ZPQNKBANY9613B";
export const BSC_API_KEY = "E4EU36PNSJEURVVB9FZEZ5VEW6VMAM83WS";
export const POLYGON_API_KEY = "9RQBN2EHMAFFZUGTH9IKZK4IHJV7U4MIPU";
export const ARBITRUM_API_KEY = "AGTAE34B2ZN88QQ1ZMTS2G7ZG4Y8Q1VGDM";
export const OPTIMISM_API_KEY = "6UYU737J6QDAHA5C9I984UFS6THCPDUHC7";

export enum SupportedChainId {
  // ETHEREUM_MAINNET = 1,
  // ETHEREUM_GOERLI = 5,
  // BSC = 56,
  // BSCT = 97,
  POLYGON = 137,
  // MUMBAI = 80001,
  // ARBITRUM = 42161,
  // OPTIMISM = 10,
  // MANTLE_TESTNET = 5001,
  BASE = 8453,
  // SEPOLIA = 11155111,
}

export const supportedMainnetChainIds = [137];

export const SUPPORTED_NETWORKS = {
  // [SupportedChainId.ETHEREUM_MAINNET]: {
  //   CHAIN_TX: Chain.Mainnet,
  //   name: "Ethereum",
  //   chainId: SupportedChainId.ETHEREUM_MAINNET,
  //   rpc: `https://rpc.ankr.com/eth/`,
  //   rpcUrl: `https://rpc.ankr.com/eth`,
  //   wss: `wss://eth-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
  //   api: "https://api.etherscan.io/api",
  //   gasFeesUrl: "https://api.etherscan.io/api",
  //   ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
  //   alchemy_url: `https://eth-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
  //   swap1InchApiurl: "https://api.1inch.dev/swap/v5.2/1",
  //   oneInchContract: "",
  //   block_explorer_url: "https://etherscan.io/",
  //   api_key: ETHERSCAN_API_KEY,
  //   symbol: "ETH",
  //   coingeckoId: "ethereum",
  //   icon: EthNetworkIcon,
  //   ankrSymbol: "eth",
  //   chainName: "Ethereum",
  //   networkName: "Ethereum Network",
  //   nativeTokenName: "Ether",
  //   defaultSwapToToken: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  //   scanName: "Etherscan",
  //   owlracle_gas_url: "https://api.owlracle.info/v4/eth/gas",
  //   jiffyScanName: "mainnet",
  //   paymasterAddress: "",
  //   dummyToken: "",
  //   topTokens: topTokensEthereum,
  //   accrossAddress: "",
  //   usdcAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  //   usdcDecimals: 6,
  //   swapRouter02: "",
  //   blockaid: "ethereum",
  // },

  [SupportedChainId.POLYGON]: {
    name: "Polygon Mainnet",
    CHAIN_TX: CustomChain.PolygonMainnet,
    chainId: SupportedChainId.POLYGON,
    rpc: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}/`,
    rpcUrl: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    wss: "wss://polygon-mainnet.blastapi.io/566f5dab-20f2-4ce9-9225-374a70da3722",
    api: "https://api.polygonscan.com/api",
    gasFeesUrl: "https://api.polygonscan.com/api",
    ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
    alchemy_url: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    swap1InchApiurl: "https://api.1inch.dev/swap/v5.2/137",
    oneInchContract: "0x1111111254eeb25477b68fb85ed929f73a960582",
    block_explorer_url: "https://polygonscan.com",
    api_key: POLYGON_API_KEY,
    symbol: "MATIC",
    coingeckoId: "matic-network",
    icon: PolygonIcon,
    ankrSymbol: "polygon",
    chainName: "Polygon Network",
    networkName: "Polygon Network",
    nativeTokenName: "Matic",
    defaultSwapToToken: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    scanName: "Polygonscan",
    owlracle_gas_url: "https://api.owlracle.info/v4/poly/gas",
    jiffyScanName: "matic",
    onRamperNetwork: "polygon",
    dummyToken: "",
    topTokens: topTokensPolygon,
    accrossAddress: "0x9295ee1d8C5b022Be115A2AD3c30C72E34e7F096",
    usdcAddress: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
    usdcDecimals: 6,
    swapRouter02: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
    blockaid: "polygon",
    wethAddress: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    bundlerRPC: "https://public.stackup.sh/api/v1/node/polygon-mainnet",
    stackupUrl:
      "https://api.stackup.sh/v1/node/221b5cfa6d4f5cff2e221d693b2e953d49d9797d0f18f2e6d119482223a92a37",
    factoryAddress: "0xe177629abf2E4c024cCBEDD688732D71eEb34715",
    paymasterAddress: "0x1c537AC092FA1eF101a638dc651effC866361A23",
    implementationAddress: "0xF11CD1EE9B2662356afd0f5b520191fffb0a854e",
    accrossUsdcSupportedAddress: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    squidUsdcSupportedAddress: "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
  },
};

export const CASH_SUPPORTED_NETWORK = {
  [SupportedChainId.BASE]: {
    name: "base",
    CHAIN_TX: {
      name: "base",
      networkId: 8453,
      chainId: 8453,
    },
    chainId: SupportedChainId.BASE,
    rpc: `https://base-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    rpcUrl: `https://base-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    wss: `wss://base-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}/`,
    api: "https://api.basescan.org/api",
    gasFeesUrl: "https://api.basescan.org/api",
    ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
    alchemy_url: `https://base-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    block_explorer_url: "https://basescan.org",
    swap1InchApiurl: "",
    oneInchContract: "",
    api_key: ETHERSCAN_API_KEY,
    symbol: "ETH",
    coingeckoId: "ethereum",
    icon: EthNetworkIcon,
    ankrSymbol: "",
    chainName: "BASE",
    networkName: "BASE Network",
    nativeTokenName: "Ether",
    defaultSwapToToken: "",
    scanName: "BaseScan",
    owlracle_gas_url: "",
    jiffyScanName: "base",
    paymasterAddress: "0x145472EFF928600F3DE999ad0ba796A876C912Ec",
    dummyToken: "",
    topTokens: {},
    usdcDecimals: 6,
    usdcAddress: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    accrossAddress: "0x09aea4b2242abc8bb4bb78d537a67a245a7bec64",
    executorAddress: "0xAfe270984A30d0f689Cc8fCBd5512ac5d4196B0b",
    implementationAddress: "0xF11CD1EE9B2662356afd0f5b520191fffb0a854e",
    factoryAddress: "0xe177629abf2E4c024cCBEDD688732D71eEb34715",
    blockaid: "base",
    stackupUrl: "https://public.stackup.sh/api/v1/node/base-mainnet",
    bundlerRPC: "https://public.stackup.sh/api/v1/node/base-mainnet",
    squidUsdcSupportedAddress: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    swapRouter02: "0x2626664c2603336E57B271c5C0b26F421741e481",
    wethAddress: NATIVE_ADDRESS,
  },
};

export enum SupportedChainNames {
  "Ethereum" = SupportedChainId.ETHEREUM_MAINNET,
  "BSC" = SupportedChainId.POLYGON,
  "BSCT" = SupportedChainId.POLYGON,
  "Polygon" = SupportedChainId.POLYGON,
  "ARBITRUM" = SupportedChainId.POLYGON,
  "OPTIMISM" = SupportedChainId.POLYGON,
  "MANTLE_TESTNET" = SupportedChainId.POLYGON,
}

export const TESTNET_NETWORKS = [
  // SupportedChainId.ETHEREUM_GOERLI,
  SupportedChainId.MUMBAI,
  // SupportedChainId.BSCT,
  // SupportedChainId.MANTLE_TESTNET,
];
export const MAINNET_NETWORKS = [
  // SupportedChainId.ETHEREUM_MAINNET,
  // SupportedChainId.POLYGON,
  // SupportedChainId.ARBITRUM,
  // SupportedChainId.OPTIMISM,
  // SupportedChainId.BSC,
];

export const MAX_SWAP = [
  SupportedChainId.ETHEREUM_MAINNET,
  SupportedChainId.BSC,
];

export const filterData = [
  // {
  //   id: 1,
  //   iconUrl: EthIcon,
  //   name: "Ethereum Network",
  //   slug: "ethereum",
  //   chainId: 1,
  // },
  {
    id: 2,
    iconUrl: PolygonRoundIcon,
    name: "Polygon Network",
    slug: "polygon",
    chainId: 137,
  },
  // {
  //   id: 3,
  //   iconUrl: BMBRound,
  //   name: "BNB Smart Chain",
  //   slug: "bnb",
  //   chainId: 56,
  // },
  // {
  //   id: 4,
  //   iconUrl: MentalIcon,
  //   name: "Mantle Network",
  //   slug: "mantle",
  //   chainId: 10,
  // },
];
