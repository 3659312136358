import { Box, Grid, useTheme } from "@mui/material";

import DepositIcon from "assets/deposit.svg";
import SendIcon from "assets/send-icon.svg";
import CashIcon from "assets/cash-out.svg";
import BuyIcon from "assets/buyIcon.svg";

import ChipButton from "components/ChipButton";
import ExpandableChart from "components/ExpandableChart";
import InfoCard from "components/InfoCard";
import TokensListTable from "./TokensListTable";

import { FC, useEffect, useState } from "react";

import InfoCardAmount from "../../../components/InfoCardAmount";

import { LIGHT_BLUE_COLOR } from "constants/";
import { DARK_BLUE_COLOR } from "constants/";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import CryptoCard from "assets/cryptoCard.svg";
import { useAppSelector } from "store/store";
import { setCryptoHistory } from "@slices/appSlice";

export interface CryptoViewProps {
  accountName: string;
  totalWalletBalance: number;
  chartData: number[];
  chartTimestamps: number[];
}

const CryptoView: FC<CryptoViewProps> = ({
  accountName,
  totalWalletBalance,
  chartData,
  chartTimestamps,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const walletBalance = parseFloat(totalWalletBalance + "").toFixed(2);
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { gas, activeNetwork } = useAppSelector((state) => state.app);

  useEffect(() => {
    dispatch(
      setCryptoHistory({
        interval: 2,
      })
    );
  }, []);

  return (
    <Box width={"100%"}>
      <Box
        className="flex-row-center"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          // flexDirection: 'row',
          gap: "26px",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        {/* User Info & Balance Area */}
        <Box sx={{ paddingTop: "0px !important", flex: 1, width: "100%" }}>
          {/* <Card title="@igors" ens="" balance="50.67" /> */}
          <InfoCard
            backgroundImage={`url(${CryptoCard})`}
            backgroundPosition="0px"
            backgroundSize="cover"
            title={`@${accountName?.toLowerCase()}`}
            amount={<InfoCardAmount amount={walletBalance} />}
            // amount={walletBalance}
            gas={gas.totalBalance.toFixed(4).toString()}
            isCashTab={false}
            openModal
          />
        </Box>

        {/* Receive Cash Area */}

        <Box
          sx={{
            paddingTop: "0px !important",
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          <ExpandableChart
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            chartData={
              chartData.length === 1 ? [chartData[0], chartData[0]] : chartData
            }
            chartTimestamps={
              chartTimestamps.length === 1
                ? [chartTimestamps[0], Date.now()]
                : chartTimestamps
            }
            title={accountName}
            amount={walletBalance + ""}
            percentageChange="5"
            chartColor={LIGHT_BLUE_COLOR}
            chartStrokeColor={DARK_BLUE_COLOR}
            isCryptoScreen={true}
          />
        </Box>
      </Box>

      {!isExpanded && (
        <Box mt={2.5}>
          <Box
            className="flex-row-center"
            style={{
              display: "flex",
              gap: "4px",
              justifyContent: "flex-start",
              flexFlow: "wrap",
            }}
          >
            <ChipButton
              title="Receive"
              onClick={() => {
                navigate("/crypto/receive");
              }}
              icon={DepositIcon}
              fontWeight={400}
              fontSize={14}
              fontFamily="Helvetica Neue"
            />
            <ChipButton
              title="Send"
              onClick={() => {
                navigate("/crypto/send");
              }}
              icon={SendIcon}
              fontWeight={400}
              fontSize={14}
              fontFamily="Helvetica Neue"
            />

            <ChipButton
              title="Buy"
              onClick={() => {
                navigate("/crypto/buy");
              }}
              icon={BuyIcon}
              fontWeight={400}
              fontSize={14}
              fontFamily="Helvetica Neue"
            />

            <ChipButton
              title="Sell"
              onClick={() => {
                navigate("/crypto/sell");
              }}
              icon={CashIcon}
              fontWeight={400}
              fontSize={14}
              fontFamily="Helvetica Neue"
            />
          </Box>

          <Grid pt={5}>
            <TokensListTable
              chainId={137}
              isApplyFilter={false}
              isShowTokenAmountUnderName
            />
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default CryptoView;
