import { createSlice } from "@reduxjs/toolkit";
import { SupportedChainId } from "constants/chains";
import { Interval } from "../../../enums/index";
import { AppReduxState } from "interfaces";

export const initialPendingTxValues = {
  value: "",
  valueIn$: "",
  transferAmount: "",
  transactionMethod: "",
  scanLink: "",
  eoaEns: "",
  addressEns: "",
  toAddressEns: "",
  toAddress: "",
  assetName: "",
  networkFeesIn$: "",
  iconURL: "",
  action: "",
  appName: "",
};
const initialSwapTokenDetails = {
  amount: 0,
  amountInUSD: 0,
  id: "0",
  image: "",
  tokenAddress: "",
  tokenBalance: 0,
  tokenDecimal: 18,
  tokenName: "",
  tokenPrice: 0,
  tokenSymbol: "",
};
const initialState: AppReduxState = {
  gas: {
    [SupportedChainId.POLYGON]: { balance: 0 },
    [SupportedChainId.BASE]: { balance: 0 },
    totalBalance: 0,
  },
  cashGasgas: {
    8453: { balance: 0 },
  },

  cashExchangeTxs: [],
  rootAccountInfo: {
    smartAccountAddress: "",
    address: "",
    name: "",
    secret: "",
    usdTokenBalance: 0,
  },
  referredTo: [],
  ownReferralCode: "",
  externalReferralCode: "",
  encryptedSeedPhraseWithPassword: "",
  pendingTx: "",
  cashPendingTx: "",
  pendingTxDetails: initialPendingTxValues,
  accessToken: "",
  blockNumbers: {},
  isAccountCreated: false,
  currentDappRoute: "",
  expirationTime: 0,
  user: {
    mode: "google",
    email: "",
    name: "",
    profileImage: "",
  },
  mnemonic: "",
  holdings: {},
  activity: {},
  localActivity: {},
  accounts: {},
  activeAccount: {
    address: "",
    secret: "",
    smartAccountAddress: "",
    accountName: "",
  },
  lastCreatedAccount: {
    address: "",
    secret: "",
    smartAccountAddress: "",
    accountName: "",
  },
  activeNetwork: SupportedChainId.POLYGON,
  nfts: {},
  newNFTs: {},
  transactions: {},
  cashTransactions: [],
  settingsOpenOption: null,
  connectedDapps: [],
  dappsList: [],

  portfolio: {
    total_wallet_balance: 0.0,
    wallet: "0x",
    assets: [],
  },
  contacts: {},
  cryptoHistory: {
    wallet: ["0x"],
    balance_usd: 0,
    balance_history: [],

    interval: Interval.DAY,
    [Interval.HOUR]: {
      chart_data: [],
      chart_timestamps: [],
    },
    [Interval.DAY]: {
      chart_data: [],
      chart_timestamps: [],
    },
    [Interval.WEEK]: {
      chart_data: [],
      chart_timestamps: [],
    },
    [Interval.MONTH]: {
      chart_data: [],
      chart_timestamps: [],
    },
  },
  cashHistory: {
    wallet: ["0x"],
    balance_usd: 0,
    balance_history: [],

    interval: Interval.DAY,
    [Interval.HOUR]: {
      chart_data: [],
      chart_timestamps: [],
    },
    [Interval.DAY]: {
      chart_data: [],
      chart_timestamps: [],
    },
    [Interval.WEEK]: {
      chart_data: [],
      chart_timestamps: [],
    },
    [Interval.MONTH]: {
      chart_data: [],
      chart_timestamps: [],
    },
  },
  mainnetNetworks: [
    SupportedChainId.ETHEREUM_MAINNET,
    // SupportedChainId.BSC,
    SupportedChainId.POLYGON,
  ],
  testnetNetworks: [
    // SupportedChainId.ETHEREUM_GOERLI,
    // SupportedChainId.BSCT,
    SupportedChainId.MUMBAI,
    // SupportedChainId.MANTLE_TESTNET,
  ],
  homeScreenMainTabByDefault: 0,
  userEns: "",
  isDappRoutes: false,
  fetchedKeys: {
    key1: "",
    key2: "",
    key3: "",
    key4: "",
    rootAddress: "",
  },
  balances: {
    accumulatedAllAccounts: 0,
    smartWalletBalance: {},
  },
  swapDetails: {
    chainId: 1,
    tokenA: initialSwapTokenDetails,
    tokenB: initialSwapTokenDetails,
    paymentToken: "",
  },
  connectedDapp2: [],
  userSpendingDetails: {
    path: [],
    isPatternSet: false,
    isSpendingLimitSaved: false,
    isFirstTx: true,
    isDailyLimitExceed: false,
    spendinglimit: 0,
    isFirstTxInApp: true,
  },
  isInitialized: false,
  gasInfo: {
    totalGas: 0,
    depositedTokens: [],
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setGas: (state, action) => {
      state.gas = action.payload;
    },
    setCashGas: (state, action) => {
      state.cashGas = action.payload;
    },
    setGasInfo: (state, action) => {
      state.gasInfo = action.payload;
    },
    setRootAccountInfo: (state, action) => {
      state.rootAccountInfo = action.payload;
    },
    setRootAccountUsdBalance: (state, action) => {
      state.rootAccountInfo = {
        ...state.rootAccountInfo,
        usdTokenBalance: action.payload,
      };
    },
    setReferredTo: (state, action) => {
      state.referredTo = action.payload;
    },
    setOwnReferralCode: (state, action) => {
      state.ownReferralCode = action.payload;
    },
    setExternalReferralCode: (state, action) => {
      state.externalReferralCode = action.payload;
    },
    setFetchedKeys: (state, action) => {
      state.fetchedKeys = action.payload;
    },
    setEncryptedSeedPhraseWithPassword: (state, action) => {
      state.encryptedSeedPhraseWithPassword = action.payload;
    },

    setPendingTx: (state, action) => {
      state.pendingTx = action.payload;
    },
    setCashPendingTx: (state, action) => {
      state.cashPendingTx = action.payload;
    },
    setPendingTxDetails: (state, action) => {
      state.pendingTxDetails = action.payload;
    },
    setAccesToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setBlockNumbers: (state, action) => {
      state.blockNumbers = action.payload;
    },
    setExpirationTime: (state, action) => {
      state.expirationTime = action.payload;
    },
    setCurrentDappRoute: (state, action) => {
      state.currentDappRoute = action.payload;
    },
    setIsAccountCreated: (state, action) => {
      state.isAccountCreated = action.payload;
    },
    clearState: (state) => {
      state.accounts = {};
      state.activeAccount = {
        address: "",
        secret: "",
        smartAccountAddress: "",
        accountName: "",
      };
      state.portfolio = {
        total_wallet_balance: 0.0,
        wallet: "0x",
        assets: [],
      };
      state.cryptoHistory = {
        wallet: ["0x"],
        balance_usd: 0.0,
        balance_history: [],
        interval: Interval.DAY,
        [Interval.DAY]: {
          chart_data: [],
          chart_timestamps: [],
        },
        [Interval.HOUR]: {
          chart_data: [],
          chart_timestamps: [],
        },
        [Interval.MONTH]: {
          chart_data: [],
          chart_timestamps: [],
        },
        [Interval.WEEK]: {
          chart_data: [],
          chart_timestamps: [],
        },
      };
      state.cashHistory = {
        wallet: ["0x"],
        balance_usd: 0.0,
        balance_history: [],
        interval: Interval.DAY,
        [Interval.DAY]: {
          chart_data: [],
          chart_timestamps: [],
        },
        [Interval.HOUR]: {
          chart_data: [],
          chart_timestamps: [],
        },
        [Interval.MONTH]: {
          chart_data: [],
          chart_timestamps: [],
        },
        [Interval.WEEK]: {
          chart_data: [],
          chart_timestamps: [],
        },
      };
      state.nfts = {};
      state.newNFTs = {};
      state.contacts = {};
      // state.connectedDapps = [];

      state.accounts = {};
      state.holdings = {};
      state.activity = {};
      state.localActivity = {};

      state.mnemonic = "";

      state.user = {
        mode: "",
        email: "",
        name: "",
        profileImage: "",
      };
    },
    setIsDappRoutes: (state, action) => {
      state.isDappRoutes = action.payload;
    },
    setConnectedDapps: (state, action) => {
      console.log("🚀 ~ file: index.tsx:213 ~ action:", action.payload);
      state.connectedDapps.push(action.payload);
    },
    setDappsList: (state, action) => {
      console.log("🚀 ~ file: index.tsx:213 ~ action:", action.payload);
      state.dappsList = [...state.dappsList, action.payload];
    },
    setFilteredDappsList: (state, action) => {
      state.dappsList = action.payload;
    },
    setConnectedDapps2: (state, action) => {
      console.log("🚀 ~ file: index.tsx:219 ~ action:", action.payload);
      state.connectedDapp2 = action.payload;
    },
    setMode: (state, action) => {
      state.user.mode = action.payload;
    },
    setUserInfo: (state, action) => {
      const { name, email, profileImage, phoneNumber } = action.payload;
      state.user.name = name;
      state.user.email = email;
      state.user.profileImage = profileImage;
    },

    saveMnemonic: (state, action) => {
      state.mnemonic = action.payload;
    },
    setHomeScreenMainTabByDefault: (state, action) => {
      state.homeScreenMainTabByDefault = action.payload;
    },

    setAccounts: (state, action) => {
      state.accounts = Object.assign({}, state.accounts, action.payload);
    },
    removeAccounts: (state, action) => {
      state.accounts = {};
    },
    setUserName: (state, action) => {
      state.user.name = action.payload;
    },

    setHoldings: (state, action) => {
      state.holdings = Object.assign({}, state.holdings, action.payload);
    },
    setActivity: (state, action) => {
      state.activity = Object.assign({}, state.activity, action.payload);
    },
    setLocalActivity: (state, action) => {
      state.localActivity = Object.assign(
        {},
        state.localActivity,
        action.payload
      );
    },
    setNFTs: (state, action) => {
      state.nfts = Object.assign({}, state.nfts, action.payload);
    },

    setNewNfts: (state, action) => {
      state.newNFTs = Object.assign({}, state.newNFTs, action.payload);
    },
    setTransactions: (state, action) => {
      state.transactions = Object.assign(
        {},
        state.transactions,
        action.payload
      );
    },
    setCashTransactions: (state, action) => {
      state.cashTransactions = action.payload;
    },
    setSettingsOpenOption: (state, action) => {
      state.settingsOpenOption = action.payload;
    },
    setContacts: (state, action) => {
      state.contacts = action.payload;
    },
    setActiveAccount: (state, action) => {
      state.activeAccount = { ...action.payload };
    },
    setLastCreatedAccount: (state, action) => {
      state.lastCreatedAccount = { ...action.payload };
    },
    setActiveNetwork: (state, action) => {
      state.activeNetwork = action.payload;
    },
    setPortfolio: (state, action) => {
      state.portfolio = Object.assign({}, state.portfolio, action.payload);
    },
    setCryptoHistory: (state, action) => {
      state.cryptoHistory = Object.assign(
        {},
        state.cryptoHistory,
        action.payload
      );
    },
    setCashHistory: (state, action) => {
      state.cashHistory = Object.assign({}, state.cashHistory, action.payload);
    },
    setMainnetNetworks: (state, action) => {
      state.mainnetNetworks = [...state.mainnetNetworks, action.payload];
    },
    setTestnetNetworks: (state, action) => {
      state.testnetNetworks = [...state.testnetNetworks, action.payload];
    },

    setUserEns: (state, action) => {
      state.userEns = action.payload;
    },
    setBalances: (state, action) => {
      state.balances = action.payload;
    },
    setSwapDetails: (state, action) => {
      state.swapDetails = {
        ...state.swapDetails,
        ...action.payload,
      };
    },
    setUserSpendingDetails: (state, action) => {
      state.userSpendingDetails = {
        ...state.userSpendingDetails,
        ...action.payload,
      };
    },
    toggleInitialized: (state) => {
      state.isInitialized = !state.isInitialized;
    },
    setCashExchangeTxs: (state, action) => {
      state.cashExchangeTxs = [...state.cashExchangeTxs, action.payload];
    },
  },
});

export const {
  setCashGas,
  setGas,
  setGasInfo,
  setCashExchangeTxs,
  setRootAccountUsdBalance,
  setRootAccountInfo,
  setReferredTo,
  setOwnReferralCode,
  setExternalReferralCode,
  setFetchedKeys,
  setEncryptedSeedPhraseWithPassword,
  setCashPendingTx,
  setPendingTx,
  setPendingTxDetails,
  setAccesToken,
  setBlockNumbers,
  removeAccounts,
  setCurrentDappRoute,
  setIsAccountCreated,
  setExpirationTime,
  setIsDappRoutes,
  setConnectedDapps,
  setMode,
  setUserInfo,
  setAccounts,
  setUserName,
  saveMnemonic,
  setHoldings,
  setActivity,
  setLocalActivity,
  setActiveAccount,
  setActiveNetwork,
  setNFTs,
  setTransactions,
  setCashTransactions,
  setSettingsOpenOption,
  setNewNfts,
  setPortfolio,
  setCryptoHistory,
  setCashHistory,
  setMainnetNetworks,
  setTestnetNetworks,
  clearState,
  setHomeScreenMainTabByDefault,
  setUserEns,
  setContacts,
  setBalances,
  setSwapDetails,
  setLastCreatedAccount,
  setConnectedDapps2,
  setUserSpendingDetails,
  toggleInitialized,
  setDappsList,
  setFilteredDappsList,
} = appSlice.actions;

export default appSlice.reducer;
