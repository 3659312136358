import * as React from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StashedTagIcon from "assets/stashedWhite.svg";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import { Box } from "@mui/material";

const Connector = styled(StepConnector)(({ theme }) => ({
  left: "calc(-50% + 8px)",
  right: "calc(50% + 8px)",
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#1A1C20",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#1A1C20",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: "#EBECEE",
    borderRadius: 1,
  },
}));

const StepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: "#EBECEE",
  zIndex: 1,
  color: "#fff",
  display: "flex",
  borderRadius: 3,
  justifyContent: "center",
  alignItems: "center",
  width: 13.224,
  height: 13.224,
  marginTop: 16.5,
  transform: "rotate(45deg)",
  ...(ownerState.active && {
    backgroundColor: "#1A1C20",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#1A1C20",
  }),
}));

function stepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <VideoLabelIcon sx={{ height: 6 }} />,
    2: <VideoLabelIcon sx={{ height: 6 }} />,
    3: <VideoLabelIcon sx={{ height: 6 }} />,
    4: <VideoLabelIcon sx={{ height: 6 }} />,
    5: <VideoLabelIcon sx={{ height: 6 }} />,
  };

  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {/* <Box sx={{ height: '24px', width: '6px', marginTop: '-4px' }}> */}
      <img
        src={StashedTagIcon}
        style={{
          height: "11px",
          width: "11px",
          transform: "rotateZ(45deg)",
          // marginLeft: '-1px',
          marginTop: "0px",
        }}
      />
      {/* </Box> */}
    </StepIconRoot>
  );
}

const CustomizedSteppers: React.FC<{
  step: number;
  steps: string[];
  changeStep: (selectedStep: number) => void;
}> = ({ step = 0, steps, changeStep }) => {
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={step}
        connector={<Connector />}
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: steps.length === 2 ? "100%" : "80%",
          },
          alignSelf: "center",
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={stepIcon}
              sx={{
                "& .MuiStepLabel-label": {
                  fontFamily: "Space Grotesk",
                  fontWeight: 500,
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "15px",
                  },
                },
                cursor: "pointer",
              }}
              onClick={() => {
                changeStep(steps.indexOf(label));
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default CustomizedSteppers;
