import { SessionTypes, SignClientTypes } from "@walletconnect/types";
import { proxy } from "valtio";

/**
 * Types
 */
interface ModalData {
  proposal?: SignClientTypes.EventArguments["session_proposal"];
  requestEvent?: SignClientTypes.EventArguments["session_request"];
  requestSession?: SessionTypes.Struct;
  payload?: {
    callData?: any;
    value?: string;
    to?: string;
  };
}

interface pendingTxns {
  reqId: number;
  open: boolean;
  data: ModalData;
  fromSdk: boolean;
}

interface State {
  viewST?:
    | "SessionProposalModal"
    | "SessionSignModal"
    | "SessionSignTypedDataModal"
    | "SessionSendTransactionModal"
    | "SessionUnsuportedMethodModal"
    | "SessionSignCosmosModal"
    | "SessionSignSolanaModal"
    | "SessionSignPolkadotModal"
    | "SessionSignNearModal"
    | "SessionSignMultiversxModal"
    | "SessionSignTronModal"
    | "SessionSignTezosModal"
    | "SessionSignKadenaModal"
    | "SdkSendTransactionModal"
    | "SessionDisconnectModal";

  txnQueue: pendingTxns[];
}

/**
 * State
 */
const state = proxy<State>({
  txnQueue: [],
});

/**
 * Store / Actions
 */
const SendTransactionModalStore = {
  state,

  open(view: State["viewST"], data: any) {
    state.viewST = view;
    // state.data = [...state.data, data];
    // console.log("state.data", state.data);
    // state.open = true;
    if (data.requestEvent) {
      if (
        state.txnQueue.filter((tx) => tx.reqId === data.requestEvent.id)
          .length > 0
      ) {
        state.txnQueue = state.txnQueue;
      } else {
        state.txnQueue.push({
          open: true,
          data: data,
          reqId: data.requestEvent.id,
          fromSdk: false,
        });
      }
    } else {
      console.log("ye milrha hai", data);
      state.txnQueue.push({
        open: true,
        data: data.payload.payload,
        reqId: data.payload.dappId,
        fromSdk: true,
      });
    }
  },

  close(reqId: number) {
    const updatedTxns = state.txnQueue.filter((txn) => txn.reqId !== reqId);
    if (updatedTxns.length > 0) {
      state.txnQueue = updatedTxns;
    } else {
      state.txnQueue = updatedTxns;
      delete state.viewST;
    }
  },

  closeAll() {
    state.txnQueue = [];
    delete state.viewST;
  },
};

export default SendTransactionModalStore;
