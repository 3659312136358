import {
  CASH_SUPPORTED_NETWORK,
  SUPPORTED_NETWORKS,
  SupportedChainId,
} from "constants/chains";
import { EMPTY_CALLDATA } from "../contract-integration";
import { ethers } from "ethers";

export const isCryptoAccountDeployed = async (
  activeNetwork: SupportedChainId,
  smartAccountAddress: string
) => {
  const rpc =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS].rpc;

  const provider = new ethers.providers.JsonRpcProvider(rpc);

  const accountByteCode = await provider.getCode(smartAccountAddress);

  const isDeployed = accountByteCode != EMPTY_CALLDATA;

  return isDeployed;
};

export const isCashAccountDeployed = async (
  rootSmartAccountAddress: string
) => {
  const rpc = CASH_SUPPORTED_NETWORK[SupportedChainId.BASE].rpc;

  const provider = new ethers.providers.JsonRpcProvider(rpc);

  const accountByteCode = await provider.getCode(rootSmartAccountAddress);

  const isDeployed = accountByteCode != EMPTY_CALLDATA;

  return isDeployed;
};
