import React, { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import pbkdf2 from "pbkdf2";
import { useNavigate } from "react-router-dom";
import Logo from "components/Logo";
import { useAppDispatch, useAppSelector } from "store/store";
import { decryptMessage, fetchEncryptedKeysByName } from "utils/utils";

import { setHashedPassword } from "@slices/walletSlice";
import TextInput from "components/TextInput";
import Button from "components/NewButton";
import { setIsAccountCreated, setReferredTo } from "@slices/appSlice";

import { Web3AuthNoModal } from "@web3auth/no-modal";
import { init } from "utils/google";

const Login = () => {
  const passwordRef = useRef(null);
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [password, setPassword] = useState("");
  const [securePassword, setSecurePassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [web3auth, setWeb3auth] = useState<Web3AuthNoModal | null>(null);

  const { encryptedSeedPhraseWithPassword, rootAccountInfo } = useAppSelector(
    (state) => state.app
  );

  const { hashedPassword } = useAppSelector((state) => state.wallet);

  useEffect(() => {
    if (hashedPassword) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    (async () => {
      const web3auth = await init("/login");
      if (web3auth) {
        web3auth.logout();
      }
    })();
  }, []);

  const dispatchAndNavigate = (hashedEnteredPassword: string) => {
    dispatch(setHashedPassword(hashedEnteredPassword));

    setLoading(false);
    navigate("/");
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      setError(false);
      const hashedEnteredPassword = pbkdf2
        .pbkdf2Sync(password, "salt", 1, 32, "sha512")
        .toString("hex");
      const decrypt = decryptMessage(
        encryptedSeedPhraseWithPassword,
        hashedEnteredPassword
      );

      if (decrypt) {
        const data = await fetchEncryptedKeysByName(rootAccountInfo.name);

        if (data.referedTo) {
          dispatch(setReferredTo(data.referedTo));
        }
        dispatchAndNavigate(hashedEnteredPassword);
      } else {
        setError(true);
        setLoading(false);
      }
    } catch (error) {
      console.log("err", error);

      setError(true);
      setLoading(false);
    }
  };

  const handleKeyPress = (e: any) => {
    const { key, keyCode } = e || {};
    if (key === "Enter" || keyCode === 13) {
      e.preventDefault(); // Prevent the default behavior of the Tab key
      handleLogin();
    }

    if (key === "Tab" || keyCode === 9) {
      e.preventDefault();
      if (buttonRef.current) {
        buttonRef.current.focus();
      }
    }
  };

  const navigateToStart = async () => {
    dispatch(setIsAccountCreated(false));
    navigate("/");
  };

  return (
    <div className="login-container">
      <div className="center-content">
        <Logo />
        <div className="welcome-box-container">
          <TextInput
            title="Password"
            value={password}
            onChange={setPassword}
            showSecure={() => setSecurePassword(!securePassword)}
            showEndIcon
            secure={securePassword}
            type={securePassword ? "password" : "text"}
            style={{
              width: "350px",
              marginTop: "8px",
            }}
            onKeyPress={(e) => handleKeyPress(e)}
            useRef={passwordRef}
          />

          {error && (
            <Typography
              style={{
                margin: "-20px 0px 20px 0px",
                color: "red",
                fontSize: 14,
                textAlign: "left",
              }}
            >
              Password is not correct
            </Typography>
          )}

          <Button
            title="Log In"
            onClick={handleLogin}
            id="handle_login"
            loading={loading}
            disabled={password.length === 0}
            style={{
              width: "380px",
              padding: "15px 0px",
              margin: 0,
              opacity: password.length === 0 ? 0.5 : 1,
            }}
            useRef={buttonRef}
          />

          <div onClick={navigateToStart}>
            <Typography
              style={{
                fontSize: 14,
                textAlign: "left",
                cursor: "pointer",
              }}
            >
              Forgot your password?
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
