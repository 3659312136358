const iconsBasePath = "325/";
const iconSize = "large";
const iconExt = "png";

export enum topTokensNamesPolygon {
  ETH = "ETH",
  TUSD = "TUSD",
  USDT = "USDT",
  BNB = "BNB",
  AAVE = "AAVE",
  WAVAX = "WAVAX",
  USDC = "USDC",
  SOL = "SOL",
  ADA = "ADA",
  DOGE = "DOGE",
  TRX = "TRX",
  TON = "TON",
  DAI = "DAI",
  MATIC = "MATIC",
  WBTC = "WBTC",
  DOT = "DOT",
  LTC = "LTC",
  BCH = "BCH",
  LINK = "LINK",
  SHIB = "SHIB",
  LEO = "LEO",
  WETH = "WETH",
  UNI = "UNI",
}

export const WRAPPED_TOKEN_ADDRESS: { [key: number]: string } = {
  1: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
  137: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
};

export enum topTokensNamesEthereum {
  TUSD = "TUSD",
  ETH = "ETH",
  USDT = "USDT",
  BNB = "BNB",
  XRP = "XRP",
  USDC = "USDC",
  SOL = "SOL",
  ADA = "ADA",
  DOGE = "DOGE",
  TRX = "TRX",
  TON = "TON",
  DAI = "DAI",
  WMATIC = "WMATIC",
  WBTC = "WBTC",
  DOT = "DOT",
  LTC = "LTC",
  BCH = "BCH",
  LINK = "LINK",
  SHIB = "SHIB",
  LEO = "LEO",
  WETH = "WETH",
  WAVAX = "WAVAX",
  UNI = "UNI",
}

export const topTokensPolygon = {
  [topTokensNamesPolygon.ETH]: {
    address: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    chainId: 137,
    name: "Polygon",
    symbol: "Matic",
    logoUrl:
      // `${iconsBasePath}4713/thumb/polygon.${iconExt}?1698233745`,
      `${iconsBasePath}4713/${iconSize}/matic-token-icon.${iconExt}?1624446912`,
    decimals: 18,
    isNative: true,
  },
  [topTokensNamesPolygon.TUSD]: {
    address: "0x2e1ad108ff1d8c782fcbbb89aad783ac49586756",
    chainId: 137,
    name: "Bridged TrueUSD",
    symbol: topTokensNamesPolygon.TUSD,
    logoUrl: `${iconsBasePath}30837/thumb/tusd.jpeg?1696529695`,
    decimals: 18,
    isNative: false,
  },
  [topTokensNamesPolygon.WETH]: {
    address: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    chainId: 137,
    name: "WETH",
    symbol: topTokensNamesPolygon.WETH,
    logoUrl: `${iconsBasePath}2518/thumb/weth.${iconExt}?1696503332`,
    decimals: 18,
    isNative: false,
  },
  [topTokensNamesPolygon.USDT]: {
    address: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    chainId: 137,
    name: "Tether",
    symbol: topTokensNamesPolygon.USDT,
    logoUrl: `${iconsBasePath}325/${iconSize}/Tether.${iconExt}?1668148663`,
    decimals: 6,
    isNative: false,
  },
  [topTokensNamesPolygon.BNB]: {
    address: "0xecdcb5b88f8e3c15f95c720c51c71c9e2080525d",
    chainId: 137,
    name: "Binance Coin  Wormhole",
    symbol: topTokensNamesPolygon.BNB,
    logoUrl: `${iconsBasePath}22884/thumb/BNB_wh_small.${iconExt}?1696522182`,
    decimals: 18,
    isNative: false,
  },
  [topTokensNamesPolygon.WAVAX]: {
    address: "0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b",
    chainId: 137,
    name: "Wrapped AVAX",
    symbol: topTokensNamesPolygon.WAVAX,
    logoUrl: `${iconsBasePath}15075/thumb/wrapped-avax.${iconExt}?1696514734`,
    decimals: 18,
    isNative: false,
  },
  [topTokensNamesPolygon.USDC]: {
    address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    chainId: 137,
    name: "USD Coin",
    symbol: topTokensNamesPolygon.USDC,
    logoUrl: `${iconsBasePath}6319/thumb/usdc.${iconExt}?1696506694`,
    decimals: 6,
    isNative: false,
  },
  [topTokensNamesPolygon.SOL]: {
    address: "0xd93f7e271cb87c23aaa73edc008a79646d1f9912",
    chainId: 137,
    name: "SOL  Wormhole ",
    symbol: topTokensNamesPolygon.SOL,
    logoUrl: `${iconsBasePath}22876/thumb/SOL_wh_small.${iconExt}?1696522175`,
    decimals: 9,
    isNative: false,
  },
  // [topTokensNamesPolygon.ADA]: { //not available on polygon
  //   address: "",
  //   chainId: 137,
  //   name: "",
  //   symbol: topTokensNamesPolygon.ADA,
  //   logoUrl: "",
  //   decimals: 18,
  //   isNative: false,
  // },
  // [topTokensNamesPolygon.DOGE]: { //not available on polygon
  //   address: "",
  //   chainId: 137,
  //   name: "",
  //   symbol: topTokensNamesPolygon.DOGE,
  //   logoUrl: "",
  //   decimals: 18,
  //   isNative: false,
  // },
  // [topTokensNamesPolygon.TRX]: { //not available on polygon
  //   address: "",
  //   chainId: 137,
  //   name: "",
  //   symbol: topTokensNamesPolygon.TRX,
  //   logoUrl: "",
  //   decimals: 18,
  //   isNative: false,
  // },
  // [topTokensNamesPolygon.TON]: { //not available on polygon
  //   address: "",
  //   chainId: 137,
  //   name: "",
  //   symbol: topTokensNamesPolygon.TON,
  //   logoUrl: "",
  //   decimals: 18,
  //   isNative: false,
  // },
  [topTokensNamesPolygon.DAI]: {
    address: "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
    chainId: 137,
    name: "Dai",
    symbol: topTokensNamesPolygon.DAI,
    logoUrl: `${iconsBasePath}9956/${iconSize}/Badge_Dai.${iconExt}?1687143508`,
    decimals: 18,
    isNative: false,
  },
  // [topTokensNamesPolygon.MATIC]: {
  //   address: "",
  //   chainId: 137,
  //   name: "",
  //   symbol: topTokensNamesPolygon.MATIC,
  //   logoUrl: "",
  //   decimals: 18,
  //   isNative: false,
  // },
  [topTokensNamesPolygon.WBTC]: {
    address: "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    chainId: 137,
    name: "Wrapped Bitcoin",
    symbol: topTokensNamesPolygon.WBTC,
    logoUrl: `${iconsBasePath}7598/${iconSize}/wrapped_bitcoin_wbtc.${iconExt}?1548822744`,
    decimals: 8,
    isNative: false,
  },
  // [topTokensNamesPolygon.DOT]: { //not available on polygon
  //   address: "",
  //   chainId: 137,
  //   name: "",
  //   symbol: topTokensNamesPolygon.DOT,
  //   logoUrl: "",
  //   decimals: 18,
  //   isNative: false,
  // },
  // [topTokensNamesPolygon.LTC]: { //not available on polygon
  //   address: "",
  //   chainId: 137,
  //   name: "",
  //   symbol: topTokensNamesPolygon.LTC,
  //   logoUrl: "",
  //   decimals: 18,
  //   isNative: false,
  // },
  // [topTokensNamesPolygon.BCH]: { //not available on polygon
  //   address: "",
  //   chainId: 137,
  //   name: "",
  //   symbol: topTokensNamesPolygon.BCH,
  //   logoUrl: "",
  //   decimals: 18,
  //   isNative: false,
  // },
  [topTokensNamesPolygon.LINK]: {
    address: "0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39",
    chainId: 137,
    name: "Chainlink",
    symbol: topTokensNamesPolygon.LINK,
    logoUrl: `${iconsBasePath}877/${iconSize}/chainlink-new-logo.${iconExt}?1547034700`,
    decimals: 18,
    isNative: false,
  },
  // [topTokensNamesPolygon.SHIB]: {
  //   address: "",
  //   chainId: 137,
  //   name: "",
  //   symbol: topTokensNamesPolygon.SHIB,
  //   logoUrl: "",
  //   decimals: 18,
  //   isNative: false,
  // },
  // [topTokensNamesPolygon.LEO]: { //not available on polygon
  //   address: "",
  //   chainId: 137,
  //   name: "",
  //   symbol: topTokensNamesPolygon.LEO,
  //   logoUrl: "",
  //   decimals: 18,
  //   isNative: false,
  // },

  [topTokensNamesPolygon.UNI]: {
    address: "0xb33eaad8d922b1083446dc23f610c2567fb5180f",
    chainId: 137,
    name: "Uniswap",
    symbol: topTokensNamesPolygon.UNI,
    logoUrl: `${iconsBasePath}12504/${iconSize}/uni.jpg?1687143398`,
    decimals: 18,
    isNative: false,
  },
};

export const topTokensEthereum = {
  [topTokensNamesEthereum.ETH]: {
    address: "0x0000000000000000000000000000000000000000",
    chainId: 1,
    name: "ETH",
    symbol: topTokensNamesEthereum.ETH,
    logoUrl: `${iconsBasePath}22990/thumb/ETH_wh_small.${iconExt}?1696522286`,
    decimals: 18,
    isNative: true,
  },
  [topTokensNamesEthereum.TUSD]: {
    address: "0x0000000000085d4780b73119b644ae5ecd22b376",
    chainId: 1,
    name: "TrueUSD",
    symbol: topTokensNamesEthereum.TUSD,
    logoUrl:
      // `${iconsBasePath}3449/thumb/tusd.${iconExt}?1696504140`,
      `${iconsBasePath}3449/${iconSize}/tusd.${iconExt}?1618395665`,
    decimals: 18,
    isNative: false,
  },

  [topTokensNamesEthereum.USDT]: {
    address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    chainId: 1,
    name: "Tether",
    symbol: topTokensNamesEthereum.USDT,
    logoUrl:
      // `${iconsBasePath}325/thumb/Tether.${iconExt}?1696501661`,
      `${iconsBasePath}325/${iconSize}/Tether.${iconExt}?1668148663`,
    decimals: 6,
    isNative: false,
  },
  [topTokensNamesEthereum.BNB]: {
    address: "0x418d75f65a02b3d53b2418fb8e1fe493759c7605",
    chainId: 1,
    name: "Binance Coin  Wormhole ",
    symbol: topTokensNamesEthereum.BNB,
    logoUrl: `${iconsBasePath}22884/thumb/BNB_wh_small.${iconExt}?1696522182`,
    decimals: 18,
    isNative: false,
  },
  [topTokensNamesEthereum.XRP]: {
    address: "0x39fbbabf11738317a448031930706cd3e612e1b9",
    chainId: 1,
    name: "Wrapped XRP",
    symbol: topTokensNamesEthereum.XRP,
    logoUrl: `${iconsBasePath}19952/thumb/WXRP_revision-256px.${iconExt}?1696519369`,
    decimals: 18,
    isNative: false,
  },
  [topTokensNamesEthereum.USDC]: {
    address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    chainId: 1,
    name: "USDC",
    symbol: topTokensNamesEthereum.USDC,
    logoUrl: `${iconsBasePath}6319/thumb/usdc.${iconExt}?1696506694`,
    decimals: 6,
    isNative: false,
  },
  [topTokensNamesEthereum.SOL]: {
    address: "0xd31a59c85ae9d8edefec411d448f90841571b89c",
    chainId: 1,
    name: "SOL  Wormhole ",
    symbol: topTokensNamesEthereum.SOL,
    logoUrl: `${iconsBasePath}22876/thumb/SOL_wh_small.${iconExt}?1696522175`,
    decimals: 9,
    isNative: false,
  },
  // [topTokensNamesEthereum.ADA]: { //not on eth
  //   address: "",
  //   chainId: 137,
  //   name: "",
  //   symbol: topTokensNamesEthereum.ADA,
  //   logoUrl: "",
  //   decimals: 18,
  //   isNative: false,
  // },
  [topTokensNamesEthereum.DOGE]: {
    address: "0xd721706581d97ecd202bbab5c71b5a85f0f78e69",
    chainId: 1,
    name: "DOGE 1",
    symbol: topTokensNamesEthereum.DOGE,
    logoUrl: `${iconsBasePath}32298/thumb/200.${iconExt}?1697185878`,
    decimals: 9,
    isNative: false,
  },
  [topTokensNamesEthereum.TRX]: {
    address: "0x50327c6c5a14dcade707abad2e27eb517df87ab5",
    chainId: 1,
    name: "Wrapped Tron",
    symbol: topTokensNamesEthereum.TRX,
    logoUrl: `${iconsBasePath}22471/thumb/xOesRfpN_400x400.jpg?1696521795`,
    decimals: 6,
    isNative: false,
  },
  [topTokensNamesEthereum.TON]: {
    address: "0x582d872a1b094fc48f5de31d3b73f2d9be47def1",
    chainId: 1,
    name: "Toncoin",
    symbol: topTokensNamesEthereum.TON,
    logoUrl: `${iconsBasePath}17980/thumb/ton_symbol.${iconExt}?1696517498`,
    decimals: 9,
    isNative: false,
  },
  [topTokensNamesEthereum.DAI]: {
    address: "0x6b175474e89094c44da98b954eedeac495271d0f",
    chainId: 1,
    name: "Dai",
    symbol: topTokensNamesEthereum.DAI,
    logoUrl:
      // `${iconsBasePath}9956/thumb/Badge_Dai.${iconExt}?1696509996`,
      `${iconsBasePath}9956/${iconSize}/Badge_Dai.${iconExt}?1687143508`,
    decimals: 18,
    isNative: false,
  },
  [topTokensNamesEthereum.WMATIC]: {
    address: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
    chainId: 1,
    name: "Polygon",
    symbol: topTokensNamesEthereum.WMATIC,
    logoUrl:
      // `${iconsBasePath}4713/thumb/polygon.${iconExt}?1698233745`,
      `${iconsBasePath}4713/${iconSize}/matic-token-icon.${iconExt}?1624446912`,
    decimals: 18,
    isNative: false,
  },
  [topTokensNamesEthereum.WBTC]: {
    address: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
    chainId: 1,
    name: "Wrapped Bitcoin",
    symbol: topTokensNamesEthereum.WBTC,
    logoUrl: `${iconsBasePath}7598/${iconSize}/wrapped_bitcoin_wbtc.${iconExt}?1548822744`,
    decimals: 8,
    isNative: false,
  },
  // [topTokensNamesEthereum.DOT]: { //not on eth
  //   address: "",
  //   chainId: 137,
  //   name: "",
  //   symbol: topTokensNamesEthereum.DOT,
  //   logoUrl: "",
  //   decimals: 18,
  //   isNative: false,
  // },
  // [topTokensNamesEthereum.LTC]: { //not on eth
  //   address: "",
  //   chainId: 137,
  //   name: "",
  //   symbol: topTokensNamesEthereum.LTC,
  //   logoUrl: "",
  //   decimals: 18,
  //   isNative: false,
  // },
  // [topTokensNamesEthereum.BCH]: {
  //   address: "",
  //   chainId: 137,
  //   name: "",
  //   symbol: topTokensNamesEthereum.BCH,
  //   logoUrl: "",
  //   decimals: 18,
  //   isNative: false,
  // },
  [topTokensNamesEthereum.LINK]: {
    address: "0x514910771af9ca656af840dff83e8264ecf986ca",
    chainId: 1,
    name: "Chainlink",
    symbol: topTokensNamesEthereum.LINK,
    logoUrl: `${iconsBasePath}877/${iconSize}/chainlink-new-logo.${iconExt}?1547034700`,
    decimals: 18,
    isNative: false,
  },
  [topTokensNamesEthereum.SHIB]: {
    address: "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
    chainId: 1,
    name: "Shiba Inu",
    symbol: topTokensNamesEthereum.SHIB,
    logoUrl: `${iconsBasePath}11939/${iconSize}/shiba.${iconExt}?1622619446`,
    decimals: 18,
    isNative: false,
  },
  [topTokensNamesEthereum.LEO]: {
    address: "0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3",
    chainId: 1,
    name: "LEO Token",
    symbol: topTokensNamesEthereum.LEO,
    logoUrl: `${iconsBasePath}8418/${iconSize}/leo-token.${iconExt}?1558326215`,
    decimals: 18,
    isNative: false,
  },
  [topTokensNamesPolygon.UNI]: {
    address: "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
    chainId: 1,
    name: "Uniswap",
    symbol: topTokensNamesPolygon.UNI,
    logoUrl: `${iconsBasePath}12504/${iconSize}/uni.jpg?1687143398`,
    decimals: 18,
    isNative: false,
  },
};

export const tokensForGas = [
  // {
  //   token: 'USDC',
  //   chain: 'ethereum',
  //   tokenAddress: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  //   ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
  //   rpc: `https://eth-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
  // },
  // {
  //   token: 'USDT',
  //   chain: 'ethereum',
  //   tokenAddress: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  //   ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
  //   rpc: `https://eth-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
  // },
  // {
  //   token: 'MATIC',
  //   chain: 'ethereum',
  //   tokenAddress: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0',
  //   ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
  //   rpc: `https://eth-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
  // },
  {
    token: "USDC",
    chain: "Polygon",
    decimal: 6,
    logoUrl:
      // `${iconsBasePath}6319/${iconSize}/USD_Coin_icon.${iconExt}?1547042389`,
      `${iconsBasePath}6319/${iconSize}/USD_Coin_icon.${iconExt}?1547042389`,
    tokenAddress: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    tokenAddressForMobula: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
    rpc: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
  },
  {
    token: "USDT",
    chain: "Polygon",
    decimal: 6,
    logoUrl: `${iconsBasePath}325/${iconSize}/Tether-logo.${iconExt}?1598003707`,
    tokenAddress: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    tokenAddressForMobula: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
    rpc: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
  },
  {
    token: "MATIC",
    chain: "Polygon",
    decimal: 18,
    logoUrl: `${iconsBasePath}4713/${iconSize}/matic-token-icon.${iconExt}?1624446912`,
    tokenAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    tokenAddressForMobula: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
    rpc: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
  },
];

export const tokensForGasObject: { [key: number]: any } = {
  1: [
    {
      token: "USDC",
      chain: "ethereum",
      symbol: "USDC",
      isNative: false,
      decimal: 6,

      logoUrl: `${iconsBasePath}6319/thumb/usdc.${iconExt}?1696506694`,
      tokenAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
      ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
      rpc: `https://eth-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    },
    {
      token: "USDT",
      chain: "ethereum",
      symbol: "USDT",
      decimal: 6,

      isNative: false,
      logoUrl:
        // `${iconsBasePath}325/thumb/Tether.${iconExt}?1696501661`,
        `${iconsBasePath}325/${iconSize}/Tether.${iconExt}?1668148663`,
      tokenAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
      ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
      rpc: `https://eth-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    },
    {
      token: "Ethereum",
      chain: "ethereum",
      symbol: "ETH",
      decimal: 18,

      logoUrl:
        // `${iconsBasePath}4713/thumb/polygon.${iconExt}?1698233745",
        `${iconsBasePath}4713/${iconSize}/matic-token-icon.${iconExt}?1624446912`,
      tokenAddress: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
      isNative: true,
      ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
      rpc: `https://eth-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    },
  ],
  137: [
    {
      token: "USDC",
      chain: "polygon",
      symbol: "USDC",
      isNative: false,
      decimal: 6,
      logoUrl:
        // `${iconsBasePath}6319/${iconSize}/USD_Coin_icon.${iconExt}?1547042389`,
        `${iconsBasePath}6319/${iconSize}/USD_Coin_icon.${iconExt}?1547042389`,
      tokenAddress: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
      rpc: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    },
    {
      token: "USDT",
      chain: "polygon",
      symbol: "USDT",
      isNative: false,
      decimal: 6,

      logoUrl: `${iconsBasePath}325/${iconSize}/Tether-logo.${iconExt}?1598003707`,
      tokenAddress: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
      rpc: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    },
    {
      token: "Matic",
      chain: "polygon",
      symbol: "Matic",
      isNative: true,
      decimal: 18,
      logoUrl:
        // `${iconsBasePath}4713/${iconSize}/matic-token-icon.${iconExt}?1624446912`,
        `${iconsBasePath}4713/${iconSize}/matic-token-icon.${iconExt}?1624446912`,
      tokenAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
      ALCHEMY_API_KEY: process.env.ALCHEMY_MAINNET_API_KEY,
      rpc: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
    },
  ],
};
