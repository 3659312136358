import React, { useEffect, useState } from "react";

import "./index.css";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";

import { useAppDispatch, useAppSelector } from "store/store";

import { useNavigate } from "react-router-dom";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";

import axios from "axios";
import Button from "components/NewButton";

import CashIcon from "assets/CashIcon.svg";
import GBPIcon from "assets/GBPIcon.svg";
import MoonpayIcon from "assets/MoonpayIcon.svg";
import depositRight from "assets/depositRight.svg";

import MidArrow from "assets/midArrow.svg";
import InviteInput from "pages/app/Referral/ReferredFriends/InviteInput";
import CashTokenInput from "components/CashTokenInputForm";
import { topFiat } from "constants/";

const CashWithdraw = () => {
  const theme = useTheme();
  const [fiatList, setFiatList] = useState([{}]);
  const [paymentMethods, setPaymentMethods] = useState([{}]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [provider, setProvider] = useState("");
  const [quoteId, setQuoteId] = useState("");
  const [transactionUrl, setTransactionUrl] = useState("");

  const [searchedFiat, setSearchedFiat] = useState("");

  const [loading, setLoading] = useState(false);
  const [tokensLoading, setTokensLoading] = useState(false);

  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [activeCurrency, setActiveCurrency] = useState("GBP");
  const [activeCurrencyIcon, setActiveCurrencyIcon] = useState(GBPIcon);
  const [rate, setRate] = useState(0);

  const [step, setStep] = useState(0);

  const navigate = useNavigate();
  const { activeAccount, rootAccountInfo } = useAppSelector(
    (state) => state.app
  );
  console.log(
    "file: index.tsx:104  Send  activeAccount:",
    activeAccount,
    rootAccountInfo
  );

  const handleAmountChange = (value) => {
    const inputValue = value;
    console.log(inputValue);

    if (/[^0-9.]/.test(inputValue)) {
      setError("Special characters are not allowed");
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setError("Only one decimal point is allowed");
    } else if (!/^\d{0,10}(\.\d{0,15})?$/.test(inputValue)) {
      setError(
        "Maximum of 10 digits before decimals and 15 digits after decimals are allowed"
      );
    } else if (Number(value) < 30) {
      setValue(inputValue);
      setError("Amount should be greater than 30");
    } else {
      setError("");

      setValue(inputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      setValue(newValue);
      setError("");
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (Number(newValue) > 0) {
        setValue(newValue);
      }
      setError("");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setTokensLoading(true);
        const { data } = await axios.get("https://api.onramper.com/supported", {
          headers: {
            Authorization: process.env.ONRAMP_API_KEY,
          },
        });
        const { message } = data;

        const { crypto, fiat } = message;
        const filteredFiat = fiat.filter(
          (ft) => ft.id !== "eur" && ft.id !== "gbp" && ft.id !== "usd"
        );

        setFiatList([...topFiat, ...filteredFiat]);

        setTokensLoading(false);
      } catch (error) {
        console.log("E>>>>>>>>>>>", error);
        setTokensLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (step === 2 && activeCurrency) {
        try {
          setTokensLoading(true);
          const { data } = await axios.get(
            `https://api.onramper.com/supported/payment-types/${activeCurrency}`,
            {
              headers: {
                Authorization: process.env.ONRAMP_API_KEY,
              },
            }
          );
          const { message } = data;

          setPaymentMethods(message);
          setTokensLoading(false);
        } catch (error) {
          console.log("E>>>>>>>>>>>", error);
          setTokensLoading(false);
        }
      }
      if (step === 3 && activeCurrency && value) {
        try {
          setTokensLoading(true);
          const { data } = await axios.get(
            `https://api.onramper.com/quotes/${activeCurrency}/usdc_base?amount=${value}`,
            {
              headers: {
                Authorization: process.env.ONRAMP_API_KEY,
              },
            }
          );

          setRate(data.length > 0 && data[0].payout);
          setProvider(data.length > 0 && data[0].ramp);
          setQuoteId(data.length > 0 && data[0].quoteId);

          setTokensLoading(false);
        } catch (error) {
          console.log("E>>>>>>>>>>>", error);
          setTokensLoading(false);
        }
      }
    })();
  }, [step, activeCurrency]);

  const createIntent = async () => {
    try {
      setLoading(true);
      const { data } = await axios.post(
        "https://api.onramper.com/checkout/intent",
        {
          onramp: provider,
          source: activeCurrency,
          destination: "usdc_base",
          amount: value,
          type: "buy",
          paymentMethod: selectedPaymentMethod.paymentTypeId,
          network: "base",

          originatingHost: "buy.onramper.com",
          metaData: {
            quoteId: quoteId,
          },
          wallet: {
            address: rootAccountInfo.smartAccountAddress,
          },
        },
        {
          headers: {
            Authorization: process.env.ONRAMP_API_KEY,
          },
        }
      );
      console.log("DATAAAAAAAA", data);
      setTransactionUrl(data.message.transactionInformation.url);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      {/* <Button title="Send" onClick={sendUsdc} loading={loading} /> */}

      <Box mt={6}>
        <NavigatorHeading
          title="Withdraw Cash"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/cash");
              }}
            />
          }
        />
      </Box>
      <Box mt={6}>
        <CustomizedSteppers
          step={step}
          steps={["Currency", "Amount", "Method", "Confirmation"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />
      </Box>

      <Box mt={5}>
        {tokensLoading ? (
          <div
            style={{ width: "100%", textAlign: "center", margin: "25% 0px" }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <Box mt={5}>
            {step == 0 && (
              <Box display={"flex"} flexDirection={"column"}>
                <Grid container display="flex" justifyContent="center">
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    style={{
                      flexBasis: "100%",
                      maxWidth: "68%",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Helvetica Neue",
                        fontSize: 17,
                        fontWeight: 500,
                        marginBottom: 5,
                        marginTop: 5,
                        color: "#1A1C2080",
                      }}
                    >
                      Select the currency you want to buy
                    </Typography>

                    <Grid
                      style={{
                        width: "100%",
                        margin: "10px 0px",
                      }}
                      item
                      // xs={12}
                      // sm={12}
                      // md={10}
                      // lg={10}
                      alignItems={"center"}
                    >
                      <InviteInput
                        handleOnChange={(e) => setSearchedFiat(e.target.value)}
                        background={"#EFF3F4"}
                        value={searchedFiat}
                        placeholder={"Search currency"}
                        width="100%"
                      />
                    </Grid>

                    {fiatList
                      .filter((list) =>
                        list?.id
                          ?.toLowerCase()
                          ?.includes(searchedFiat?.toLowerCase())
                      )
                      .map((currency) => (
                        <Box
                          key={currency.id}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: 10,
                            borderRadius: 10,
                            marginBottom: 5,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setStep(1);
                            setActiveCurrency(currency.id);
                            setActiveCurrencyIcon(currency.icon);
                          }}
                        >
                          <img
                            src={currency.icon}
                            alt={`Icon for ${currency.code}`}
                            height={33}
                          />
                          <Typography style={{ fontSize: 12 }} ml={1}>
                            {currency.code}
                          </Typography>
                        </Box>
                      ))}
                  </Grid>
                </Grid>
              </Box>
            )}
            {step == 1 && (
              <Grid container display="flex" justifyContent="center">
                <Grid
                  item
                  lg={6}
                  sm={12}
                  display="flex"
                  justifyContent="center"
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          color: "rgba(26, 28, 32, 0.50)",
                          fontFamily: "Helvetica Neue",
                          fontsize: 17,
                          fontWeight: 500,
                        }}
                      >
                        Amount to withdraw from Stashed
                      </Typography>
                      <Box mt={2.5}>
                        <CashTokenInput
                          placeHolder="0.00"
                          onChange={handleAmountChange}
                          onKeydown={handleKeyDown}
                          value={String(value)}
                          tokenName={"Cash"}
                          tokenIcon={CashIcon}
                        />
                      </Box>
                      {error && (
                        <div style={{ color: "red" }}>{error}</div> // Style as needed
                      )}

                      <Box mt={5}>
                        <Button
                          title="Continue"
                          onClick={() => setStep(2)}
                          style={{
                            borderRadius: "10px",
                            padding: "15px 0px",
                          }}
                          disabled={
                            !value ||
                            error.length > 0 ||
                            Number(value) > rootAccountInfo.usdTokenBalance
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
            {step == 2 && (
              <Grid container display="flex" justifyContent="center">
                <Grid item lg={6} sm={12}>
                  <div>
                    <Box
                      mt={4}
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      {paymentMethods.map((method) => (
                        <Grid
                          sx={{
                            padding: "10px",
                            display: "flex",
                            width: "100%",
                            height: "80px",
                            cursor: "pointer",
                            borderRadius: "10px",
                            marginBottom: 2.5,

                            background: "#f7f7f7",
                            "&:hover": {
                              backgroundColor: "lightgray",
                            },
                            position: "relative",
                          }}
                          onClick={() => {
                            setStep(3);
                            setSelectedPaymentMethod(method);
                          }}
                        >
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={method.icon}
                              alt={method.name}
                              height={44}
                            />
                            <Grid
                              sx={{
                                color: "#1A1C20",
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Space Grotesk",
                                  fontSize: "18px",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  lineHeight: "normal",
                                  color: "#1A1C20",
                                }}
                              >
                                {method.name}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                    <Typography
                      sx={{
                        color: "#1A1C2080",
                        textAlign: "center",
                        fontFamily: "Helvetica Neue",
                        fontsize: 18,
                        fontWeight: 500,
                        marginBottom: 10,
                      }}
                    >
                      Select a method to add Cash to your account
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            )}
            {step == 3 && (
              <Grid container display="flex" justifyContent="center" py={4}>
                {transactionUrl && (
                  <iframe
                    title="MyIFrame"
                    width="600"
                    height="400"
                    src={transactionUrl}
                    style={{ display: "block" }}
                  ></iframe>
                )}
                <Grid item lg={6} sm={12}>
                  <Box mt={2.5} position={"relative"}>
                    <div
                      className="input-container"
                      style={{ marginBottom: 10 }}
                    >
                      <FormControl sx={{ flex: 1 }}>
                        <Box
                          sx={{
                            backgroundColor: "#EDEEF2",
                            color: "#1A1C20",
                            width: "100%",
                            borderRadius: "10px",
                            fontSize: "25px",
                            fontFamily: "Space Grotesk",
                            fontWeight: "700",
                            border: "0.5px solid rgba(26, 28, 32, 0.50)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "15px 0px 10px 0px",
                              }}
                            >
                              <Box ml={1}>
                                <img src={activeCurrencyIcon} height={38} />
                              </Box>
                              <Box
                                sx={{
                                  color: "#1A1C20",
                                  fontFamily: "Space Grotesk",
                                  fontSize: "17px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  lineHeight: "normal",
                                  marginTop: -1,
                                  marginLeft: 1,
                                }}
                              >
                                {activeCurrency.toUpperCase()}
                              </Box>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginRight: 10,
                              }}
                            >
                              <Box
                                mr={1.25}
                                sx={{
                                  color: "#1A1C20",
                                  fontFamily: "Space Grotesk",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  lineHeight: "normal",
                                }}
                              >
                                {value}
                              </Box>
                            </div>
                          </Box>
                        </Box>
                      </FormControl>
                    </div>

                    <div
                      style={{
                        position: "absolute",
                        top: "60px",
                        left: "50%",
                        zIndex: 2,
                      }}
                    >
                      <img src={MidArrow} />
                    </div>

                    <div
                      className="input-container"
                      style={{ marginBottom: 10 }}
                    >
                      <FormControl sx={{ flex: 1 }}>
                        <Box
                          sx={{
                            backgroundColor: "#EDEEF2",
                            color: "#1A1C20",
                            width: "100%",
                            borderRadius: "10px",
                            fontSize: "25px",
                            fontFamily: "Space Grotesk",
                            fontWeight: "700",
                            border: "0.5px solid rgba(26, 28, 32, 0.50)",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                paddingTop: 15,
                              }}
                            >
                              <Box mr={1.25}>
                                <img src={CashIcon} />
                              </Box>
                              <Box
                                sx={{
                                  color: "#1A1C20",
                                  fontFamily: "Space Grotesk",
                                  fontSize: "17px",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  lineHeight: "normal",
                                  marginTop: -2,
                                  marginLeft: -1.5,
                                }}
                              >
                                Cash
                              </Box>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginRight: 10,
                              }}
                            >
                              <Box
                                mr={1.25}
                                sx={{
                                  color: "#1A1C20",
                                  fontFamily: "Space Grotesk",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  lineHeight: "normal",
                                }}
                              >
                                {rate}
                              </Box>
                              <Box
                                sx={{
                                  borderRadius: "6.02px",
                                  backgroundColor: "#00E599",
                                  padding: "5px",
                                }}
                              >
                                <Typography
                                  style={{
                                    color: "#1A1C20",
                                    fontFamily: "Space Grotesk",
                                    fontSize: "7.23px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "normal",
                                  }}
                                >
                                  ESTIMATED
                                </Typography>
                              </Box>
                            </div>
                          </Box>
                        </Box>
                      </FormControl>
                    </div>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "20px 0px 20px 0px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Helvetica Neue",
                        fontSize: 18,
                        fontWeight: 500,
                        color: "#1A1C2080",
                      }}
                    >
                      Payment method
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <img src={selectedPaymentMethod.icon} height={50} />
                      {/* <Typography
                    style={{
                      fontFamily: "Helvetica Neue",
                      fontSize: 18,
                      fontWeight: 500,
                      color: "#1A1C2080",
                    }}
                  >
                    {selectedPaymentMethod.name}
                  </Typography> */}
                    </div>
                  </Box>
                  <Typography
                    style={{
                      fontFamily: "Space Grotesk",
                      fontSize: 18,
                      fontWeight: 600,
                      color: "#1A1C20",
                    }}
                  >
                    Provider
                  </Typography>
                  <div
                    className="input-container"
                    style={{ marginBottom: 10, marginTop: 20 }}
                  >
                    <FormControl sx={{ flex: 1 }}>
                      <Box
                        sx={{
                          backgroundColor: "#EDEEF2",
                          color: "#1A1C20",
                          width: "100%",
                          borderRadius: "10px",
                          fontSize: "25px",
                          fontFamily: "Space Grotesk",
                          fontWeight: "700",
                          border: "0.5px solid rgba(26, 28, 32, 0.50)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              paddingTop: 15,
                            }}
                          >
                            {/* <Box mr={1.25} ml={1}>
                          <img src={MoonpayIcon} />
                        </Box> */}
                            <Box
                              sx={{
                                color: "#1A1C20",
                                marginTop: -2,
                                height: 20,
                                padding: 3,
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Space Grotesk",
                                  fontSize: "18px",
                                  fontStyle: "normal",
                                  fontWeight: "600",
                                  lineHeight: "normal",
                                }}
                              >
                                {provider}
                              </Typography>
                            </Box>
                          </div>

                          {/* <Box mr={1.25}>
                        <img src={depositRight} />
                      </Box> */}
                        </Box>
                      </Box>
                    </FormControl>
                  </div>
                  <Button
                    title="Deposit"
                    disabled={!rate || !quoteId || !provider}
                    onClick={createIntent}
                    style={{
                      borderRadius: "10px",
                      padding: "15px 0px",
                    }}
                    loading={loading}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default CashWithdraw;
