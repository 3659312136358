import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store/store";
import { numFormatter } from "../../../../../../utils/utils";
import { Grid } from "@mui/material";
import TokenImage from "../../../../../../components/TokenImage";

const ReviewAssetListComponent: FC<{
  //   asset: Asset;
  nextStep?: () => void;
  value: number;
  constantValue?: string;

  transactionForm?: boolean;
}> = ({
  nextStep = () => {},
  transactionForm = false,
  value,
  constantValue = "",
}) => {
  //   const navigate = useNavigate();
  const { nfts, activeAccount, activeNetwork } = useAppSelector(
    (state) => state.app
  );
  const { selectedToken } = useAppSelector((state) => state.wallet);
  console.log("file: index.tsx:89  Send  selectedToken:", selectedToken);
  console.log("file: index.tsx:17  activeNetwork:", activeNetwork);
  const { amount: tokenBalance, symbol: numberSymbol } = numFormatter(
    value ?? 1,
    4
  );
  const dispatch = useAppDispatch();
  const { amount: usdBalance, symbol: usdSymbol } = numFormatter(
    value * selectedToken[0]?.tokenPrice ?? 1,
    4
  );
  return (
    <Grid
      sx={{
        padding: "10px",
        display: "flex",
        width: "100%",
        alignSelf: "center",
        justifyContent: "space-between",
        cursor: "pointer",
        borderRadius: "10px",
        backgroundColor: "#F7F7F7",
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <TokenImage logo={selectedToken[0]?.image} />
        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
          }}
        >
          <div>{selectedToken[0]?.tokenName}</div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
            textAlign: "right",
          }}
        >
          <div>{<>${usdBalance + usdSymbol}</>}</div>
          <div
            style={{
              // color: "rgba(26, 28, 32, 0.50)",
              fontFamily: "Helvetica Neue",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            <span
              style={{
                color: "rgba(26, 28, 32, 0.50)",
              }}
            >
              {constantValue
                ? constantValue
                : tokenBalance +
                  numberSymbol +
                  " " +
                  selectedToken[0]?.tokenSymbol}{" "}
            </span>
          </div>
          {/* <div
            style={{
              // color: "rgba(26, 28, 32, 0.50)",
              fontFamily: "Helvetica Neue",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            Min Price
          </div> */}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ReviewAssetListComponent;
