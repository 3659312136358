// import { EIP155_CHAINS, EIP155_SIGNING_METHODS, TEIP155Chain } from '@/data/EIP155Data'
import {
  EIP155_CHAINS,
  EIP155_SIGNING_METHODS,
  TEIP155Chain,
} from "../data/EIP155Data";

// import { eip155Addresses, eip155Wallets } from '@/utils/EIP155WalletUtil'
import { eip155Addresses, eip155Wallets } from "./EIP155WalletUtil";

// import {
//   getSignParamsMessage,
//   getSignTypedDataParamsData,
//   getWalletAddressFromParams
// } from '@/utils/HelperUtil'
import {
  getSignParamsMessage,
  getSignTypedDataParamsData,
  getWalletAddressFromParams,
} from "./HelperUtil";

import { formatJsonRpcError, formatJsonRpcResult } from "@json-rpc-tools/utils";
import { SignClientTypes } from "@walletconnect/types";
import { getSdkError } from "@walletconnect/utils";
import { ethers, providers } from "ethers";
type RequestEventArgs = Omit<
  SignClientTypes.EventArguments["session_request"],
  "verifyContext"
>;
export async function approveEIP155Request(
  requestEvent: RequestEventArgs,
  activeAccountAddress: string
) {
  const { params, id } = requestEvent;
  const { chainId, request } = params;
  console.log("{ eip155Addresses, eip155Wallets }", {
    eip155Addresses,
    eip155Wallets,
  });
  // const wallet =
  //   eip155Wallets[getWalletAddressFromParams(eip155Addresses, params)];
  const wallet = eip155Wallets[activeAccountAddress];
  console.log("wallet and other things", {
    wallet,
    chainId,
    request,
    params,
    id,
  });
  switch (request.method) {
    case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
    case EIP155_SIGNING_METHODS.ETH_SIGN:
      try {
        const message = getSignParamsMessage(request.params);
        const signedMessage = await wallet.signMessage(message);
        return formatJsonRpcResult(id, signedMessage);
      } catch (error: any) {
        console.log("approveEIP155Request", error);
        // alert(error.message);
        return formatJsonRpcError(id, error.message);
      }

    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
    case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4:
      try {
        console.log("men bhi tw chala");
        const {
          domain,
          types,
          message: data,
        } = getSignTypedDataParamsData(request.params);
        // https://github.com/ethers-io/ethers.js/issues/687#issuecomment-714069471
        delete types.EIP712Domain;
        // delete types.PermitSingle;
        console.log("domain, types, data", domain, types, data);
        const signedData = await wallet._signTypedData(domain, types, data);
        return formatJsonRpcResult(id, signedData);
      } catch (error: any) {
        console.log("approveEIP155Request", error);
        // alert(error.message);
        return formatJsonRpcError(id, error.message);
      }

    case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
      try {
        const provider = new providers.JsonRpcProvider(
          EIP155_CHAINS[chainId as TEIP155Chain].rpc
        );
        console.log("provider", provider);
        const sendTransaction = request.params[0];
        console.log("sendTransaction", sendTransaction);
        const connectedWallet = wallet.connect(provider);
        console.log("connectedWallet", connectedWallet);
        const reqs = ethers.providers.JsonRpcProvider.hexlifyTransaction(
          request.params[0],
          { from: true, gas: true }
        );
        console.log("new things", reqs);
        const { hash } = await connectedWallet.sendTransaction(reqs);
        console.log("hash", hash);
        return formatJsonRpcResult(id, hash);
      } catch (error: any) {
        console.log("approveEIP155Request", error);
        // alert(error.message);
        return formatJsonRpcError(id, error.message);
      }

    case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
      try {
        const signTransaction = request.params[0];
        const signature = await wallet.signTransaction(signTransaction);
        return formatJsonRpcResult(id, signature);
      } catch (error: any) {
        console.log("approveEIP155Request", error);
        // alert(error.message);
        return formatJsonRpcError(id, error.message);
      }

    default:
      throw new Error(getSdkError("INVALID_METHOD").message);
  }
}

export function rejectEIP155Request(request: RequestEventArgs) {
  const { id } = request;

  return formatJsonRpcError(id, getSdkError("USER_REJECTED").message);
}
