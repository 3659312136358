import {
  Box,
  CircularProgress,
  Grid,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import CardLayout from "components/CardLayout";

import DepositIcon from "assets/deposit.svg";
import WithdrawIcon from "assets/withdraw.svg";
import SendIcon from "assets/send-icon.svg";
import FillupIcon from "assets/fillup-icon.svg";

import Button from "components/NewButton";
import ChipButton from "components/ChipButton";
import ExpandableChart from "components/ExpandableChart";
import InfoCard from "components/InfoCard";

import CashBackground from "assets/backgrounds/cash.png";
import NoDataUI from "../../../components/NoDataCard";
import GasIcon from "assets/gasIcon.svg";
import GasCard from "assets/gasCard.svg";

import BlankGas from "assets/blankGas.svg";
import InfoCardAmount from "components/InfoCardAmount";
import CenterItem from "components/CenterItem";
import SearchInput from "components/SearchInput";

import SearchOptionsIcon from "assets/searchOptionsIcon.svg";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";

import { numFormatter } from "../../../utils/utils";
import TokenImage from "../../../components/TokenImage";

import FilterModal from "components/Modal/FilterModal";

import { filterData } from "constants/chains";
import { USDT_URL } from "constants/";
import { getUsdcBalance } from "utils/balance";

const WithdrawListComponent: FC<{
  asset: any;
  nextStep?: () => void;
  transactionForm?: boolean;
}> = ({ asset, nextStep = () => {}, transactionForm = false }) => {
  console.log(
    "file: WithdrawTokensList.tsx:19  asset: selectedTokenaaaaa",
    asset
  );

  const { amount: tokenBalance, symbol: tokenSymbol } = numFormatter(
    asset.balance,
    4
  );
  const { amount: tokenUsdBalance, symbol: tokenUsdSymbol } = numFormatter(
    asset.balanceInUSD,
    4
  );

  return (
    <Grid
      sx={{
        padding: "10px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        cursor: "pointer",
        borderRadius: "10px",
        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
      onClick={() => {}}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <TokenImage logo={asset.logoUrl} />
        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
          }}
        >
          <div>{asset.token}</div>
          <div
            style={{
              // color: "rgba(26, 28, 32, 0.50)",
              fontFamily: "Helvetica Neue",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            <span
              style={{
                color: "rgba(26, 28, 32, 0.50)",
              }}
            >
              {tokenBalance + tokenSymbol + " "} {asset.token}
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
          }}
        >
          <div>${tokenUsdBalance + tokenUsdSymbol}</div>
          {/* <div
              style={{
                // color: "rgba(26, 28, 32, 0.50)",
                fontFamily: "Helvetica Neue",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Min Price
            </div> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

const GasView = () => {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [usdBalance, setUsdBalance] = useState(0);
  const navigate = useNavigate();

  const { gas, activeNetwork, activeAccount } = useAppSelector(
    (state) => state.app
  );

  useEffect(() => {
    (async () => {
      const usdcBalance = await getUsdcBalance(
        activeAccount.smartAccountAddress,
        activeNetwork
      );

      setUsdBalance(usdcBalance);
    })();
  }, []);

  return (
    <Box width={"100%"}>
      <Box
        className="flex-row-center"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          gap: "26px",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        {/* User Info & Balance Area */}
        <Box
          sx={{
            paddingTop: "0px !important",
            flex: 1,
            width: "100%",
          }}
        >
          {/* <Card title="@igors" ens="" balance="50.67" /> */}
          <InfoCard
            backgroundImage={`url(${GasCard})`}
            backgroundPosition="0px"
            backgroundSize="cover"
            amount={
              loading ? (
                "loading..."
              ) : (
                <InfoCardAmount amount={gas.totalBalance.toString()} />
              )
            }
            title={`Gas`}
          />
        </Box>

        {/* Receive Cash Area */}
        {/* <Grid item xs={12} md={6} sx={{ paddingTop: "0 !important" }}>
          <>
          
          </>
        </Grid>
      </Grid> */}
        <Box sx={{ paddingTop: "0px !important", flex: 1, width: "100%" }}>
          {/* <ExpandableChart
            data={[45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]}
            title='igor'
            amount='5392.49'
            percentageChange='5'
          /> */}
          <CardLayout
            backgroundColor="white"
            style={{
              // height: '100%',
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              height: "205px",
            }}
          >
            <NoDataUI
              title={`Your Gas Account lets your pay for transactions across all of your accounts on any chain!`}
              description=""
              gasCard={true}
              icon={GasIcon}
            />
          </CardLayout>
        </Box>
      </Box>

      <Box mt={2.5}>
        <Box
          className="flex-row-center"
          style={{
            display: "flex",
            gap: "4px",
            justifyContent: "flex-start",
            flexFlow: "wrap",
            paddingBottom: "20px",
          }}
        >
          <ChipButton
            title={gas.totalBalance > 0 ? "Top Up" : "Fill Up"}
            onClick={() => {
              navigate("/gas/fillup");
            }}
            icon={FillupIcon}
            fontWeight={400}
            fontSize={14}
            iconHeight={12}
            iconWidth={13}
            fontFamily="Helvetica Neue"
          />
          <ChipButton
            title="Withdraw"
            onClick={() => {
              navigate("/gas/withdraw");
            }}
            icon={SendIcon}
            fontWeight={400}
            fontSize={14}
            iconHeight={11}
            iconWidth={14}
            fontFamily="Helvetica Neue"
          />
        </Box>

        <Box
          className="flex-row-center"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
            paddingBottom: "40px",
            flexDirection: "column",
            gap: "26px",
            [theme.breakpoints.down("md")]: {
              flexDirection: "column",
            },
          }}
        >
          {loading ? (
            <div
              style={{ width: "100%", textAlign: "center", margin: "5% 0px" }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <WithdrawListComponent
                asset={{
                  balance: usdBalance,
                  balanceInUSD: usdBalance,
                  token: "USDC",
                  logoUrl: USDT_URL,
                }}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default GasView;
