import axios from "axios";
import { MICRO_SERVICES_URL } from "constants/";
import {
  CASH_SUPPORTED_NETWORK,
  SUPPORTED_NETWORKS,
  SupportedChainId,
} from "constants/chains";
import { initalizeWeb3 } from "./utils";
import abi from "abis/erc20abi.json";

export const getAggregateBalance = async (address: string, type: string) => {
  try {
    const { data } = await axios.get(
      MICRO_SERVICES_URL +
        `/signature/getAggregateBalance?address=${address}&chainType=${type}`
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const getProtocolFee = async (chainId: SupportedChainId) => {
  try {
    const { data } = await axios.get(
      MICRO_SERVICES_URL + `/signature/protocolFees/${chainId}`
    );

    return data.protocolFees;
  } catch (error) {
    throw error;
  }
};

export const getUsdcBalance = async (
  address: string,
  chainId: SupportedChainId
) => {
  try {
    if (chainId === SupportedChainId.BASE) {
      const web3 = initalizeWeb3(chainId);
      const { usdcAddress, usdcDecimals } = CASH_SUPPORTED_NETWORK[chainId];
      //@ts-ignore
      const contract = new web3.eth.Contract(abi.abi, usdcAddress);

      const balance = await contract.methods.balanceOf(address).call();

      return balance / 10 ** usdcDecimals;
    } else {
      const web3 = initalizeWeb3(chainId);
      const { usdcAddress, usdcDecimals } =
        SUPPORTED_NETWORKS[chainId as keyof typeof SUPPORTED_NETWORKS];
      //@ts-ignore
      const contract = new web3.eth.Contract(abi.abi, usdcAddress);

      const balance = await contract.methods.balanceOf(address).call();

      return balance / 10 ** usdcDecimals;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getMinimumTopupLimit = async (chainId: SupportedChainId) => {
  try {
    const { data } = await axios.get(
      MICRO_SERVICES_URL + `/signature/minimumTopUpLimit/${chainId}`
    );

    return data.minimumTopUpLimit;
  } catch (error) {
    throw error;
  }
};
