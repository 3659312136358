import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import PeferencesIcon from "assets/peferencesIcon.png";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  CASH_SUPPORTED_NETWORK,
  SUPPORTED_NETWORKS,
  SupportedChainId,
} from "constants/chains";
import { checkGasAllowance } from "utils/swap";
import { getChain } from "utils/chains";
import {
  decryptMessage,
  fetchUsdPriceFromMobula,
  initalizeWeb3,
  showAlert,
} from "utils/utils";
import { ethers, BigNumber as ethBigNumber } from "ethers";
import { ExecuteCall, sendUserOp } from "../../contract-integration";
import {
  getMinimumTopupLimit,
  getProtocolFee,
  getUsdcBalance,
} from "utils/balance";
import { getApproveTokenCallData, upgradeWalletImpl } from "stash-fe-script";
import { APPROVE_AMOUNT } from "constants/";
import BigNumber from "bignumber.js";
import { txSubmissionOrderPrepaid } from "../../contract-integration/prepaidGas";
import {
  setCashPendingTx,
  setPendingTx,
  setPendingTxDetails,
} from "@slices/appSlice";
import { defaultAbiCoder } from "@ethersproject/abi";
import ExtraTopupAmountPopup from "components/ExtraTopupAmountPopup";
import { isCashAccountDeployed, isCryptoAccountDeployed } from "utils/deployed";
import { createSignatureAndCalculateFees } from "utils/signature";

const UpgradeAccount = () => {
  const [cryptoLoading, setCryptoLoading] = useState(false);
  const [cashLoading, setCashLoading] = useState(false);
  const [cryptoAccountUpgraded, setCryptoAccountUpgraded] = useState(true);
  const [cashAccountUpgraded, setCashAccountUpgraded] = useState(true);
  const [cryptoAllowance, setCryptoAllowance] = useState(0);
  const [cashAllowance, setCashAllowance] = useState(0);
  const [extraTopupAmount, setExtraTopupAmount] = useState("0");
  const [openCryptoExtraTopupModal, setOpenCryptoExtraTopupModal] =
    useState(false);
  const [openCashExtraTopupModal, setOpenCashExtraTopupModal] = useState(false);
  const [cryptoUsdcBalance, setCryptoUsdcBalance] = useState(0);
  const [cashUsdcBalance, setCashUsdcBalance] = useState(0);
  const [finalOpState, setFinalOpState] = useState<any>(null);
  const [gasFeeInUSD, setGasFeeInUSD] = useState("0");

  const { activeAccount, activeNetwork, gas, rootAccountInfo } = useAppSelector(
    (state) => state.app
  );
  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const {
    usdcAddress,
    usdcDecimals,
    implementationAddress,
    stackupUrl,
    alchemy_url,
    bundlerRPC,
  } = SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const dispatch = useAppDispatch();

  // Crypto Account
  useEffect(() => {
    (async () => {
      const web3 = initalizeWeb3(activeNetwork);

      const slot =
        "0x360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc";
      let slotValue = await web3.eth.getStorageAt(
        activeAccount.smartAccountAddress,
        slot
      );

      console.log(
        "SLOT VALUE",
        slotValue,
        defaultAbiCoder.decode(["address"], slotValue)[0].toLowerCase() ===
          implementationAddress.toLowerCase()
      );

      if (
        defaultAbiCoder.decode(["address"], slotValue)[0].toLowerCase() ===
        implementationAddress.toLowerCase()
      ) {
        setCryptoAccountUpgraded(true);
      } else {
        setCryptoAccountUpgraded(false);
        const allowance = await checkGasAllowance(
          activeAccount.smartAccountAddress,
          usdcAddress,
          activeNetwork
        );

        const usdcBalance = await getUsdcBalance(
          activeAccount.smartAccountAddress,
          activeNetwork
        );
        setCryptoUsdcBalance(usdcBalance);
        setCryptoAllowance(Number(allowance));
      }
    })();
  }, [cryptoLoading]);

  // Cash Account
  useEffect(() => {
    (async () => {
      const { usdcAddress } = CASH_SUPPORTED_NETWORK[SupportedChainId.BASE];
      const web3 = initalizeWeb3(SupportedChainId.BASE);

      const slot =
        "0x360894a13ba1a3210667c828492db98dca3e2076cc3735a920a3ca505d382bbc";
      let slotValue = await web3.eth.getStorageAt(
        rootAccountInfo.smartAccountAddress,
        slot
      );

      if (
        defaultAbiCoder.decode(["address"], slotValue)[0].toLowerCase() ===
        implementationAddress.toLowerCase()
      ) {
        setCashAccountUpgraded(true);
      } else {
        setCashAccountUpgraded(false);
        const allowance = await checkGasAllowance(
          rootAccountInfo.smartAccountAddress,
          usdcAddress,
          SupportedChainId.BASE
        );

        const usdcBalance = await getUsdcBalance(
          rootAccountInfo.smartAccountAddress,
          SupportedChainId.BASE
        );
        setCashUsdcBalance(usdcBalance);
        setCashAllowance(Number(allowance));
      }
    })();
  }, [cashLoading]);

  const onCryptoAccountUpgrade = async () => {
    try {
      setCryptoLoading(true);
      setFinalOpState(null);

      const chain = getChain(activeNetwork);

      const rpcEndpoint =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
          .alchemy_url;

      const pkey = decryptMessage(activeAccount.secret, hashedPassword);

      const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);
      const stackupProvider = new ethers.providers.JsonRpcProvider(bundlerRPC);

      const wallet = new ethers.Wallet(pkey, provider);

      if (!wallet) return;

      let userCallDataArray: ExecuteCall[] = [];

      const usdcBalance = await getUsdcBalance(
        activeAccount.smartAccountAddress,
        activeNetwork
      );
      console.log("usdcBalance", usdcBalance);

      if (!usdcBalance && !gas.totalBalance) {
        showAlert("You do not have USDC balance to top up gas tank.");
        setCryptoLoading(false);
        return;
      }

      const approveCallData = getApproveTokenCallData(
        APPROVE_AMOUNT,

        chain,
        usdcAddress
      );

      if (!cryptoAllowance) {
        userCallDataArray.push(approveCallData);
      } else {
        const upgradeCallData = await upgradeWalletImpl(
          implementationAddress,
          activeAccount.smartAccountAddress
        );
        if (upgradeCallData.calldata) {
          userCallDataArray.push({
            to: upgradeCallData.to,
            value: upgradeCallData.value,
            calldata: upgradeCallData.calldata,
          });
        }
      }

      console.log(activeAccount.address);

      const cryptoAccountDeployed = await isCryptoAccountDeployed(
        activeNetwork,
        activeAccount.smartAccountAddress
      );

      const { finalOp, usdcFee } = await txSubmissionOrderPrepaid({
        userCallDataArray,

        wallet,

        isAccountDeployed: cryptoAccountDeployed,
        chain,

        smartAccountAddress: activeAccount.smartAccountAddress,
        provider: stackupProvider,
        extraTopupAmount,
        approval: cryptoAllowance ? true : false,
        gasBalance: gas.totalBalance,
        rootAddress: rootAccountInfo.address.toLowerCase(),
      });

      setFinalOpState(finalOp);
      setGasFeeInUSD(Number(usdcFee).toFixed(4));

      if (Number(usdcFee) > gas.totalBalance) {
        setOpenCryptoExtraTopupModal(true);
      } else {
        await executeCryptoAccountUpgrade();
      }
    } catch (error) {
      console.log(error);
      setCryptoLoading(false);
      showAlert(error || "Something went wrong");
    }
  };

  const executeCryptoAccountUpgrade = async () => {
    try {
      setCryptoLoading(true);
      //replace via sendStackup or sendAlchemy  UserOp
      const response = await sendUserOp(finalOpState, stackupUrl, alchemy_url);
      const userOPResponse: any = await response.wait();
      console.log("userOp Hash :", response.userOpHash);
      console.log("Tx Hash :", userOPResponse?.transactionHash);
      console.log("success status :", userOPResponse?.args.success);

      if (!cryptoAllowance) {
        dispatch(setPendingTx(userOPResponse?.transactionHash));
      }

      showAlert(
        "Soon you can see your transaction in the transactions tab",
        cryptoAllowance
          ? "Transaction Submitted"
          : "Approval Transaction Submitted",
        `<a href="https://polygonscan.com/tx/${userOPResponse?.transactionHash}" target="_blank">View on Polygonscan</a>`
      );
      setCryptoLoading(false);
    } catch (error) {
      console.log(error);
      setCryptoLoading(false);
    }
  };

  const onCashAccountUpgrade = async () => {
    try {
      const { usdcAddress, bundlerRPC } =
        CASH_SUPPORTED_NETWORK[SupportedChainId.BASE];
      setCashLoading(true);
      setFinalOpState(null);

      const chain = getChain(SupportedChainId.BASE);

      const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);

      const provider = new ethers.providers.JsonRpcProvider(bundlerRPC);
      const wallet = new ethers.Wallet(pkey, provider);

      if (!wallet) return;

      let userCallDataArray: ExecuteCall[] = [];

      const usdcBalance = await getUsdcBalance(
        rootAccountInfo.smartAccountAddress,
        SupportedChainId.BASE
      );
      console.log("usdcBalance", usdcBalance);

      if (!usdcBalance && !gas.totalBalance) {
        showAlert("You do not have USDC balance to top up gas tank.");
        setCashLoading(false);
        return;
      }

      const approveCallData = getApproveTokenCallData(
        APPROVE_AMOUNT,

        chain,
        usdcAddress
      );

      if (!cashAllowance) {
        userCallDataArray.push(approveCallData);
      } else {
        const upgradeCallData = await upgradeWalletImpl(
          implementationAddress,
          rootAccountInfo.smartAccountAddress
        );
        if (upgradeCallData.calldata) {
          userCallDataArray.push({
            to: upgradeCallData.to,
            value: upgradeCallData.value,
            calldata: upgradeCallData.calldata,
          });
        }
      }

      const cashAccountDeployed = await isCashAccountDeployed(
        rootAccountInfo.smartAccountAddress
      );

      const { finalOp, usdcFee } = await txSubmissionOrderPrepaid({
        userCallDataArray,

        wallet,

        isAccountDeployed: cashAccountDeployed,
        chain,

        smartAccountAddress: rootAccountInfo.smartAccountAddress,
        provider,
        extraTopupAmount,
        approval: cashAllowance ? true : false,
        gasBalance: gas.totalBalance,
        rootAddress: rootAccountInfo.address.toLowerCase(),
      });

      setFinalOpState(finalOp);
      setGasFeeInUSD(Number(usdcFee).toFixed(4));

      if (Number(usdcFee) > gas.totalBalance) {
        setOpenCashExtraTopupModal(true);
      } else {
        await executeCashAccountUpgrade();
      }
    } catch (error) {
      console.log(error);
      setCashLoading(false);
      showAlert(error || "Something went wrong");
    }
  };

  const executeCashAccountUpgrade = async () => {
    try {
      const { stackupUrl, rpcUrl } =
        CASH_SUPPORTED_NETWORK[SupportedChainId.BASE];
      setCashLoading(true);
      //replace via sendStackup or sendAlchemy  UserOp
      const response = await sendUserOp(finalOpState, stackupUrl, rpcUrl);
      const userOPResponse: any = await response.wait();
      console.log("userOp Hash :", response.userOpHash);
      console.log("Tx Hash :", userOPResponse?.transactionHash);
      console.log("success status :", userOPResponse?.args.success);

      if (!cashAllowance) {
        dispatch(setCashPendingTx(userOPResponse?.transactionHash));
      }

      showAlert(
        "Soon you can see your transaction in the transactions tab",
        cashAllowance
          ? "Transaction Submitted"
          : "Approval Transaction Submitted",
        `<a href="https://basescan.org/tx/${userOPResponse?.transactionHash}" target="_blank">View on Basescan</a>`
      );
      setCashLoading(false);
    } catch (error) {
      console.log(error);
      setCashLoading(false);
    }
  };

  const handleTopupAmountChange = (value) => {
    const inputValue = value;
    console.log(inputValue);

    if (/[^0-9.]/.test(inputValue)) {
    } else {
      setExtraTopupAmount(inputValue);
    }
  };

  const createCryptoSignature = async () => {
    try {
      const chain = getChain(activeNetwork);
      const { feesInUsdc, preFinalOp } = await createSignatureAndCalculateFees(
        finalOpState,
        rootAccountInfo.address,
        extraTopupAmount,
        activeAccount.secret,
        hashedPassword,
        alchemy_url,
        chain
      );
      setFinalOpState(preFinalOp);
      setGasFeeInUSD(Number(feesInUsdc).toFixed(4));
    } catch (error) {
      setCryptoLoading(false);
      throw error;
    }
  };

  const createCashSignature = async () => {
    try {
      const { bundlerRPC } = CASH_SUPPORTED_NETWORK[SupportedChainId.BASE];
      const chain = getChain(SupportedChainId.BASE);
      const { feesInUsdc, preFinalOp } = await createSignatureAndCalculateFees(
        finalOpState,
        rootAccountInfo.address,
        extraTopupAmount,
        rootAccountInfo.secret,
        hashedPassword,
        bundlerRPC,
        chain
      );
      setFinalOpState(preFinalOp);
      setGasFeeInUSD(Number(feesInUsdc).toFixed(4));
    } catch (error) {
      setCryptoLoading(false);
      throw error;
    }
  };

  const addCryptoExtraTopupAmount = async () => {
    try {
      if (!extraTopupAmount || Number(extraTopupAmount) <= 0) {
        setOpenCryptoExtraTopupModal(false);
        return;
      }
      setOpenCryptoExtraTopupModal(false);
      setCryptoLoading(true);

      await createCryptoSignature();

      await executeCryptoAccountUpgrade();
    } catch (error) {
      setCryptoLoading(false);
      showAlert(error);
    }
  };

  const addCashExtraTopupAmount = async () => {
    try {
      if (!extraTopupAmount || Number(extraTopupAmount) <= 0) {
        setOpenCryptoExtraTopupModal(false);
        return;
      }
      setOpenCryptoExtraTopupModal(false);
      setCashLoading(true);

      await createCashSignature();

      await executeCashAccountUpgrade();
    } catch (error) {
      setCashLoading(false);
      showAlert(error);
    }
  };

  return (
    <Box>
      <ExtraTopupAmountPopup
        openExtraTopupModal={openCryptoExtraTopupModal}
        addExtraTopupAmount={addCryptoExtraTopupAmount}
        extraTopupAmount={extraTopupAmount}
        gasBalance={gas.totalBalance}
        usdcBalance={cryptoUsdcBalance}
        handleTopupAmountChange={handleTopupAmountChange}
        minimumTopupAmount={cryptoUsdcBalance - Number(gasFeeInUSD)}
        closeExtraTopupModal={async () => {
          setOpenCryptoExtraTopupModal(false);
          await executeCryptoAccountUpgrade();
        }}
      />
      <ExtraTopupAmountPopup
        openExtraTopupModal={openCashExtraTopupModal}
        addExtraTopupAmount={addCashExtraTopupAmount}
        extraTopupAmount={extraTopupAmount}
        gasBalance={gas.totalBalance}
        usdcBalance={cashUsdcBalance}
        handleTopupAmountChange={handleTopupAmountChange}
        minimumTopupAmount={cashUsdcBalance - Number(gasFeeInUSD)}
        closeExtraTopupModal={async () => {
          setOpenCashExtraTopupModal(false);
          await executeCashAccountUpgrade();
        }}
      />

      {!cryptoAccountUpgraded && (
        <Paper
          elevation={3}
          sx={{
            borderRadius: "10px",
            boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
            background: "#f7f7f7",
          }}
        >
          <Box
            onClick={onCryptoAccountUpgrade}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "25px",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <img src={PeferencesIcon} height={20} width={20} />

              <Typography variant="body2">Upgrade Crypto Account</Typography>
            </Box>

            {cryptoLoading && (
              <div>
                <CircularProgress color="inherit" size="20px" />
              </div>
            )}
          </Box>
        </Paper>
      )}
      <div style={{ marginTop: 20 }}></div>
      {!cashAccountUpgraded && (
        <Paper
          elevation={3}
          sx={{
            borderRadius: "10px",
            boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
            background: "#f7f7f7",
          }}
        >
          <Box
            onClick={onCashAccountUpgrade}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "25px",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <img src={PeferencesIcon} height={20} width={20} />

              <Typography variant="body2">Upgrade Cash Account</Typography>
            </Box>

            {cashLoading && (
              <div>
                <CircularProgress color="inherit" size="20px" />
              </div>
            )}
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default UpgradeAccount;
