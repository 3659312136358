import { Box } from "@mui/system";
import React, { useState } from "react";
import CollapeWrapper from "./Collapse/CollapeWrapper";
import SettingsCollapseBody from "./Collapse/SettingsCollapseBody";
import { CircularProgress, Paper, Typography } from "@mui/material";
import AccountIcon from "assets/profileIcon.png";
import LogoutIcon from "assets/logoutIcon.svg";
import PeferencesIcon from "assets/peferencesIcon.png";

import DarkAccountIcon from "assets/darkProfileIcon.png";
import DarkPeferencesIcon from "assets/darkPeferencesIcon.png";
import AccountMenu from "./Collapse/SubMenu/AccountMenu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setHashedPassword } from "../../../store/slices/walletSlice";
import { useAppDispatch } from "../../../store/store";
import PreferencesMenu from "./Collapse/SubMenu/PreferencesMenu";
import ContactMenu from "./Collapse/SubMenu/ContactMenu";
import UpgradeAccount from "components/UpgradeAccount";

const SettingsView = () => {
  const dispatch = useAppDispatch();

  return (
    <Box pt={3}>
      <Box pt={3} pb={"30px"}>
        <Typography variant="h4">Settings</Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        minHeight={"70vh"}
        gap={2}
      >
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <CollapeWrapper
            title="Account"
            icon={AccountIcon}
            darkIcon={DarkAccountIcon}
            BodyComponent={
              <>
                <AccountMenu />
              </>
            }
          />
          <CollapeWrapper
            title="Peferences"
            icon={PeferencesIcon}
            darkIcon={DarkPeferencesIcon}
            BodyComponent={
              <>
                <PreferencesMenu />
              </>
            }
          />
          <CollapeWrapper
            title="Contacts"
            icon={AccountIcon}
            darkIcon={DarkAccountIcon}
            BodyComponent={
              <>
                <ContactMenu shouldAddContact />
              </>
            }
          />
        </Box>
        <UpgradeAccount />
        <Box>
          <Paper
            elevation={3}
            sx={{
              borderRadius: "10px",
              boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
              background: "#f7f7f7",
            }}
          >
            <Box
              onClick={() => dispatch(setHashedPassword(""))}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "25px",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <img src={LogoutIcon} height={20} width={20} />

                <Typography variant="body2">Logout</Typography>
              </Box>

              <ExpandMoreIcon
                style={{
                  transform: "rotate(270deg)",
                  color: "#8C8D8F",
                }}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsView;
