import React, { FC, useMemo } from "react";
import {
  getStringBeforeDot,
  isValidAddress,
  trimTokenAddress,
} from "../../../../../../utils/utils";
import { Grid } from "@mui/material";
import TokenImage from "../../../../../../components/TokenImage";
import { initials } from "@dicebear/collection";
import { createAvatar } from "@dicebear/core";
import NoImg from "../../../../../../assets/noImg.svg";

const ReviewAssetListComponent: FC<{
  isNFTComponent?: boolean;
  selectedNFT?: any;
  recipient?: any;
}> = ({ isNFTComponent = false, selectedNFT = null, recipient = null }) => {
  const accAvatar = useMemo(() => {
    if (recipient === null) return;
    return createAvatar(initials, {
      size: 37,
      seed: recipient || "",
    }).toDataUriSync();
  }, [recipient]);
  console.log("selectedNFT yerha", { selectedNFT, recipient });
  return (
    <Grid
      sx={{
        padding: "10px",
        display: "flex",
        width: "100%",
        alignSelf: "center",
        justifyContent: "space-between",
        borderRadius: "10px",
        backgroundColor: "#F7F7F7",
        height: "76px",
        mt: !isNFTComponent ? "-10px" : "0px",
        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        {!isNFTComponent ? (
          <img
            src={accAvatar}
            alt="avatar"
            style={{
              borderRadius: "50%",
            }}
          />
        ) : (
          <TokenImage
            logo={selectedNFT?.metadata?.image || NoImg}
            borderWidth={0}
          />
        )}

        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            textAlign: "left",
            alignItems: "center",
          }}
        >
          <div>
            {!isNFTComponent
              ? recipient
                ? isValidAddress(recipient)
                  ? trimTokenAddress(recipient || "")
                  : getStringBeforeDot(recipient || "")
                : trimTokenAddress(selectedNFT?.token_address || "")
              : selectedNFT?.name}
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: isNFTComponent ? "flex" : "none",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            textAlign: "right",
          }}
        >
          <div>#{trimTokenAddress(selectedNFT?.token_id || "")}</div>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ReviewAssetListComponent;
