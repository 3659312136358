import { Wallet, ethers, BigNumber } from "ethers";

import { ExecuteCall } from ".";

import { StashUserOpExt } from "stash-fe-script";
import { Chain } from "viem/chains";

export async function txSubmissionOrderPrepaid({
  wallet,
  provider,
  smartAccountAddress,
  userCallDataArray,
  isAccountDeployed,
  chain,
  gasBalance,
  approval,
  rootAddress,
}: {
  wallet: Wallet;

  userCallDataArray: ExecuteCall[];
  isAccountDeployed: boolean;
  chain: Chain;
  provider: ethers.providers.JsonRpcProvider;
  smartAccountAddress: string;
  extraTopupAmount: string;
  gasBalance: number;
  approval: boolean;
  rootAddress: string;
}) {
  try {
    let { userOp: finalOp, gasFeesInUsdc } = await StashUserOpExt.createUserOp(
      userCallDataArray,

      wallet,
      chain,
      isAccountDeployed,
      smartAccountAddress,
      provider,
      gasBalance,
      approval,
      rootAddress
    );

    return {
      finalOp,
      usdcFee: gasFeesInUsdc,
    };
  } catch (err) {
    console.log(err);
    throw err;
  }
}
