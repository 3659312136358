import { Client, UserOperationBuilder, IUserOperation, Presets } from "userop";
import { ethers, Wallet, BigNumberish, BytesLike, BigNumber } from "ethers";
import { arrayify, defaultAbiCoder, hexConcat } from "ethers/lib/utils";

import {
  Factory_Address as factory,
  EntryPoint_Address as entryPoint,
  Paymaster_Token_Address as paymasterToken,
  Paymaster_Verifier_Address as paymasterVerifier,
  CallDataType,
  FactoryData,
  ExecuteCall,
  PaymasterType,
  SALT,
  EMPTY_CALLDATA,
  Paymaster_Owner_Address,
  TransferData,
} from "./constants";
// import { assert } from "console";
import {
  EntryPoint__factory,
  Factory__factory,
  Stash__factory,
  Token__factory,
  VerifyingPaymaster__factory,
} from "./types/ethers-contracts";
import { TokenPaymaster__factory } from "./types/ethers-contracts/factories";

export async function sendUserOp(
  userOp: IUserOperation,
  bundlerRPC: string,
  rpcEndpoint: string
) {
  const builder = new UserOperationBuilder().useDefaults(userOp);

  const client = await Client.init(rpcEndpoint, {
    overrideBundlerRpc: bundlerRPC,
    entryPoint: entryPoint,
  });

  const res = await client.sendUserOperation(builder);
  return res;
}
//replace with getDummyUserOp
//transferdata and calldata isnt even used here
//same as getPartialUserOpForDepositPaymaster, idk why there are two copies
export async function getPartialUserOpForVerifyingPaymaster(
  signerAddress: string,
  factoryData: FactoryData,
  mainCallData: CallDataType,
  executeCallData: ExecuteCall[],
  transferData: TransferData,
  bundlerRPC: string,
  rpcEndpoint: string,
  counterfactual: string,
  nonce: any
): Promise<Partial<IUserOperation>> {
  // 2. Initialize provider with the chainID & bundler client.
  const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);

  // 3. Get `initCode` code for userOp, if any.
  let initCode: BytesLike;
  if (
    factoryData === FactoryData.FactoryGetSender ||
    factoryData === FactoryData.FactoryCreateSender
  ) {
    initCode = getInitCodeForBundler(factoryData, signerAddress, SALT);
  } else {
    // The calldata is empty, this can be the case where only factory gets created.
    initCode = EMPTY_CALLDATA;
  }

  // Todo: asdasd
  // 4. Get `sender/counterfactual` against the signer for userOp.
  // const counterfactual = await getCounterFactualAddress(
  //   initCode,
  //   signerAddress,
  //   SALT,
  //   provider
  // );

  // 5. Get `calldata` field for userOP, if any.
  // let callDataUserOp: BytesLike =
  //   mainCallData == CallDataType.Batch
  //     ? getBatchCalldataForBundlerWithToken(CallDataType.Batch, executeCallData, transferData)
  //     : EMPTY_CALLDATA;

  // 6. Get `nonce` field for userOp.
  // const nonce = await getNonceForBuilder(counterfactual, SALT, provider);

  // 7. Set & initialize bundler fields.
  const builder = new UserOperationBuilder().useDefaults({
    sender: counterfactual,
  });

  // const gasPrice = await provider.getGasPrice(); // NOTE: Might need to remove it.
  builder.setNonce(nonce);
  builder.setInitCode(initCode);
  // builder.setCallData(callDataUserOp);
  builder.setMaxFeePerGas(180000000000); // Set current gas
  builder.setMaxPriorityFeePerGas(180000000000); // Set current gas
  builder.setCallGasLimit(4000000);
  builder.setVerificationGasLimit(4000000);
  builder.setPreVerificationGas(80000);

  // await builder.useMiddleware(Presets.Middleware.estimateUserOperationGas(
  //   new ethers.providers.JsonRpcProvider(bundlerRPC)
  // ))

  let op = builder.getOp();
  delete (op as any).paymasterAndData;
  delete (op as any).signature;

  return op;
}

export async function getPartialUserOpForDepositPaymaster(
  signerAddress: string,
  factoryData: FactoryData,
  mainCallData: CallDataType,
  executeCallData: ExecuteCall[],
  rpcEndpoint: string,
  counterfactual: string,
  nonce: any
) {
  // 2. Initialize provider with the chainID & bundler client.
  const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);

  // 3. Get `initCode` code for userOp, if any.
  let initCode: BytesLike;
  if (
    factoryData === FactoryData.FactoryGetSender ||
    factoryData === FactoryData.FactoryCreateSender
  ) {
    initCode = getInitCodeForBundler(factoryData, signerAddress, SALT);
  } else {
    // The calldata is empty, this can be the case where only factory gets created.
    initCode = EMPTY_CALLDATA;
  }

  // 4. Get `sender/counterfactual` against the signer for userOp.
  // const counterfactual = await getCounterFactualAddress(
  //   initCode,
  //   signerAddress,
  //   SALT,
  //   provider
  // );

  // 5. Get `calldata` field for userOP, if any.
  // let callDataUserOp: BytesLike;
  // if (executeCallData.length == 1) {
  //   callDataUserOp = getExecuteCallDataForBundler(CallDataType.Execute, executeCallData[0]);
  // } else {
  //   callDataUserOp = getBatchDataForBundler(mainCallData, executeCallData);
  // }

  // 6. Get `nonce` field for userOp.
  // const nonce = await getNonceForBuilder(counterfactual, SALT, provider);

  // 7. Set & initialize bundler fields.
  const builder = new UserOperationBuilder().useDefaults({
    sender: counterfactual,
  });

  builder.setNonce(nonce);
  builder.setInitCode(initCode);
  // builder.setCallData(callDataUserOp);
  builder.setMaxFeePerGas(180000000000); // Set current gas
  builder.setMaxPriorityFeePerGas(180000000000); // Set current gas
  builder.setCallGasLimit(4000000);
  builder.setVerificationGasLimit(4000000);
  builder.setPreVerificationGas(80000);

  let op = builder.getOp();
  delete (op as any).paymasterAndData;
  delete (op as any).signature;

  return op;
}

export async function isTokenDeposited(
  token: string,
  account: string,
  rpcEndpoint: string
): Promise<Boolean> {
  const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);
  const Itoken = TokenPaymaster__factory.connect(paymasterToken, provider);
  const balance = await Itoken.balances(token, account);
  console.log("balances", balance);
  return Number(balance) > 0;
}

// This function is intended to be in the server that protects the private key.
export async function getVerifierPaymasterSignatureFromServer(
  userOp: Partial<IUserOperation>,
  paymaster: PaymasterType,
  signer: Wallet,
  bundlerRPC: string,
  rpcEndpoint: string
) {
  const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);

  let builder = new UserOperationBuilder();
  builder.useDefaults(userOp);

  const client = await Client.init(rpcEndpoint, {
    overrideBundlerRpc: bundlerRPC,
    entryPoint: entryPoint,
  });

  // 8. Set `paymasterAndData` field if any.
  let paymasterAndData: BytesLike;
  if (paymaster === PaymasterType.OffChainVerifier) {
    // 1. Get VerifierPaymasterAddress + validUntil + validAfter
    const paymasterData = hexConcat([
      paymasterVerifier,
      defaultAbiCoder.encode(["uint48", "uint48"], [0, 0]),
    ]);
    // 2. Create a copy of the builder.
    let builderCopy = builder;
    // 3. Set unsigned paymasterData to the builder copy.
    builderCopy.setPaymasterAndData(paymasterData);
    // 4. Build the userOp.
    const userOpPartial = await client.buildUserOperation(builderCopy);
    // 5. Get paymasterData with paymaster signature.
    paymasterAndData = await getPaymasterVerificationData(
      userOpPartial,
      0,
      0,
      signer,
      provider
    );
    // 6. Set the signed paymaster data to the orginal builder.
  } else if (paymaster === PaymasterType.TokenReceiver) {
    // TODO: Need to look into it.
    paymasterAndData = hexConcat([
      paymasterVerifier,
      defaultAbiCoder.encode(["address"], [""]),
    ]);
  } else {
    paymasterAndData = EMPTY_CALLDATA;
  }
  builder.setPaymasterAndData(paymasterAndData);

  let op = builder.getOp();
  delete (op as any).signature;

  return op;
}

export async function getTokenPaymasterSignatureFromServer(
  userOp: Partial<IUserOperation>,
  signer: Wallet,
  payingWallet: string,
  walletFor: string,
  tokenToPayIn: string,
  tokenPriceNormalized: BigNumberish,
  bundlerRPC: string,
  rpcEndpoint: string
) {
  const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);

  let builder = new UserOperationBuilder();
  builder.useDefaults(userOp);

  const ItokenPaymaster = TokenPaymaster__factory.connect(
    paymasterToken,
    provider
  );
  const hash = await ItokenPaymaster.getHash(
    tokenToPayIn,
    payingWallet,
    walletFor,
    tokenPriceNormalized
  );

  let sig = await new Wallet(signer.privateKey).signMessage(arrayify(hash));

  // builder.setPaymasterAndData(paymasterAndData);

  // console.log("paymasterAndData: ", paymasterAndData)

  let op = builder.getOp();

  return op;
}

async function getVerificationGasLimit(
  op: IUserOperation,
  rpcUrl: string
): Promise<BigNumberish> {
  const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  const ep = EntryPoint__factory.connect(entryPoint, provider);

  const res = await ep.callStatic.simulateValidation(op).catch((e) => e);
  const preOp = res.errorArgs[0].preOpGas;
  console.log("preop", Number(preOp));
  return ethers.utils.hexlify(Number(preOp));
}

/**
 * Simulates the execution of the operation handle on-chain and checks for errors.
 *
 * @param op - An object containing the details of the user operation.
 * @param calldata - An object containing the `to` address and `calldata` bytes.
 * @param provider - The Ethereum JSON-RPC provider.
 *
 * @returns A Promise that resolves to `true` if the operation handle execution succeeds, otherwise throws an error.
 *
 * @throws Will throw an error if the EntryPoint contract is invalid or some other unknown error occurs.
 *
 * @example
 * ```typescript
 * const isSuccess = await simulateHandleOps(userOperation, executeCall, provider);
 * ```
 */
export async function simulateHandleOps(
  op: IUserOperation,
  calldata: ExecuteCall,
  provider: ethers.providers.JsonRpcProvider
): Promise<boolean> {
  try {
    const err = await EntryPoint__factory.connect(entryPoint, provider)
      .callStatic.simulateHandleOp(op, calldata.to, calldata.calldata)
      .catch((e) => e);

    if (err?.errorName === "ExecutionResult") {
      return true;
    }
    console.log("err:", err);

    return false;
  } catch (error: any) {
    throw new Error(error.message || "An unknown error occurred.");
  }
}

/**
 * Simulates the validation of the operation on-chain and checks for errors.
 *
 * @param op - An object containing the details of the user operation.
 * @param provider - The Ethereum JSON-RPC provider.
 *
 * @returns A Promise that resolves to `true` if the validation of the operation succeeds, otherwise throws an error.
 *
 * @throws Will throw an error if the EntryPoint contract is invalid or some other unknown error occurs.
 *
 * @example
 * ```typescript
 * const isValid = await simulateValidation(userOperation, provider);
 * ```
 */
export async function simulateValidation(
  op: IUserOperation,
  provider: ethers.providers.JsonRpcProvider
): Promise<boolean> {
  try {
    const err = await EntryPoint__factory.connect(entryPoint, provider)
      .callStatic.simulateValidation(op)
      .catch((e) => e);

    if (err?.errorName === "ValidationResult") {
      return true;
    }
    console.log("err", err);

    return false;
  } catch (error: any) {
    throw new Error(error.message || "An unknown error occurred.");
  }
}

/**
 * Asynchronously retrieves the paymaster verification data.
 *
 * @param op - An object containing the details of the user operation.
 * @param validUntil - A timestamp (in seconds) indicating until when the operation is valid.
 * @param validAfter - A timestamp (in seconds) indicating when the operation becomes valid.
 * @param provider - The Ethereum JSON-RPC provider.
 *
 * @returns A Promise that resolves to the concatenated verification data, represented as a BytesLike.
 *
 * @throws Will throw an error if unable to retrieve the verification data.
 *
 * @example
 * ```typescript
 * const verificationData = await getPaymasterVerificationData(userOperation, 1629814918, 1629714918, provider);
 * ```
 *
 * @todo Get signature data from server instead of using a static key.
 *
 * @note There is an old way to calculate the signature without using `await`, which has been commented out in the function body.
 */
export async function getPaymasterVerificationData(
  op: IUserOperation,
  validUntil: number,
  validAfter: number,
  signer: Wallet,
  provider: ethers.providers.JsonRpcProvider
): Promise<BytesLike> {
  const paymaster = VerifyingPaymaster__factory.connect(
    paymasterVerifier,
    provider
  );
  const hash = await paymaster.getHash(op, validUntil, validAfter);

  // // TODO: Get this data from server.
  let sig = await new Wallet(signer.privateKey).signMessage(arrayify(hash));

  // NOTE: Old way to calculate without using `await` keyword
  // const msg1 = Buffer.concat([
  //     Buffer.from('\x19Ethereum Signed Message:\n32', 'ascii'),
  //     Buffer.from(arrayify(hash))
  // ])
  // const sig = ecsign(keccak256_buffer(msg1), Buffer.from(arrayify(config.PAYMASTER_VERIFIER_KEY)))
  // const signature = toRpcSig(sig.v, sig.r, sig.s)

  const paymasterAndData =
    hexConcat([
      paymasterVerifier,
      defaultAbiCoder.encode(["uint48", "uint48"], [validUntil, validAfter]),
    ]) + sig.split("0x")[1];
  return paymasterAndData;
}

/**
 * Fetches the nonce for a given counterfactual address and salt.
 *
 * @param counterFactual - The counterfactual address for which to fetch the nonce.
 * @param salt - The salt value used in the generation of the counterfactual address.
 * @param provider - The Ethereum JSON-RPC provider.
 * @returns The nonce as a BigNumberish type.
 * @throws Will throw an error if unable to fetch the nonce.
 */
export async function getNonceForBuilder(
  counterFactual: string,
  salt: number,
  provider: ethers.providers.JsonRpcProvider
): Promise<BigNumberish> {
  try {
    // Call entry point and get the sender address
    const res = await EntryPoint__factory.connect(entryPoint, provider)
      .callStatic.getNonce(counterFactual, salt)
      .catch((e) => e);

    if (typeof res === "number" || ethers.BigNumber.isBigNumber(res)) {
      return res;
    } else {
      throw new Error("Invalid response type for nonce");
    }
  } catch (error: any) {
    throw new Error(error.message || "An unknown error occurred.");
  }
}

/**
 * Asynchronously fetches and validates the counterfactual address for the given signer and salt using the specified JSON-RPC provider.
 *
 * @async
 * @param {BytesLike} initCode - The initialization code for generating the counterfactual address.
 * @param {string} signer - The address of the signer.
 * @param {number} salt - The salt used for generating the counterfactual address.
 * @param {ethers.providers.JsonRpcProvider} provider - The ethers.js JSON-RPC provider for interacting with an Ethereum node.
 * @returns {Promise<string>} A promise that resolves to the counterfactual address as a string.
 * @throws Will throw an error if unable to fetch the counterfactual address or if the factory and entry point contracts return different counterfactual addresses.
 */
//replace with sdk (nvm leave as is)
export async function getCounterFactualAddress(
  initCode: BytesLike,
  signer: string,
  salt: number,
  provider: ethers.providers.JsonRpcProvider,
  factoryAddress: string
): Promise<string> {
  try {
    // Call factory and get the address
    const factoryResponse = await Factory__factory.connect(
      factoryAddress,
      provider
    ).callStatic.getAddress(signer, salt);

    if (initCode == EMPTY_CALLDATA) {
      initCode = await getInitCodeForBundler(
        FactoryData.FactoryGetSender,
        signer,
        SALT,
        factoryAddress
      );
    }

    // Call entry point and get the sender address
    const err = await EntryPoint__factory.connect(entryPoint, provider)
      .callStatic.getSenderAddress(initCode)
      .catch((e) => e);
    if (err?.errorName !== "SenderAddressResult") {
      throw new Error(
        `Invalid entryPoint contract at ${entryPoint}. Wrong version?`
      );
    }
    const { sender } = err?.errorArgs;

    // INVARIANT: Check if factory and entry point results are the same
    if (factoryResponse.toLowerCase() !== sender.toLowerCase()) {
      throw new Error(
        "EntryPoint & Factory results in different counterfactual address."
      );
    }
    return sender;
  } catch (error: any) {
    throw new Error(error.message || "An unknown error occurred.");
  }
}

/**
 * Generates the call data based on the specified call type and execution parameters.
 *
 * @param {CallDataType} callType - The type of the call, either 'Empty' or 'Execute'.
 * @param {ExecuteCall} execute - An object containing the 'to' address, 'value' and 'calldata' to be executed.
 * @returns {BytesLike} The call data in bytes format.
 */
//use as is
//converts normal tx to userop calldata
export function getExecuteCallDataForBundler(
  callType: CallDataType,
  execute: ExecuteCall
): BytesLike {
  if (callType == CallDataType.Empty) {
    return EMPTY_CALLDATA;
  } else {
    const stash = Stash__factory.createInterface();
    const calldata = stash.encodeFunctionData("execute", [
      execute.to,
      execute.value,
      execute.calldata,
    ]);
    return calldata;
  }
}
//use as is
//convert multiple multicall tx in to a execute batch tx call data for the user op
export function getBatchDataForBundler(
  callType: CallDataType,
  calldatas: ExecuteCall[]
): BytesLike {
  if (callType == CallDataType.Empty) {
    return EMPTY_CALLDATA;
  } else {
    const tos: string[] = [];
    const values: string[] = [];
    const calls: BytesLike[] = [];

    calldatas.forEach((calldata) => {
      tos.push(calldata.to);
      values.push(calldata.value);
      calls.push(calldata.calldata);
    });

    const final = Stash__factory.createInterface().encodeFunctionData(
      "executeBatch",
      [tos, values, calls]
    );

    return final;
  }
}
//i assume this is for paymaster token logic to add token transfers after the user calldata executes
//adds to the existing calldata array
//adds logic for paying bundler with tokens
export function getBatchCalldataForBundlerWithToken(
  callType: CallDataType,
  calldatas: ExecuteCall[],
  transferData: TransferData
): BytesLike {
  if (callType == CallDataType.Empty) {
    return EMPTY_CALLDATA;
  } else {
    let toAdd: ExecuteCall;
    // Check if native is being transfered.
    if (transferData.tokenAddress == "0x") {
      toAdd = {
        to: Paymaster_Owner_Address,
        value: transferData.tokenAmount,
        calldata: EMPTY_CALLDATA,
      };
    } else {
      const compensateCalldata =
        Token__factory.createInterface().encodeFunctionData("transfer", [
          Paymaster_Owner_Address,
          transferData.tokenAmount,
        ]);
      toAdd = {
        to: transferData.tokenAddress,
        value: "0",
        calldata: compensateCalldata,
      };
    }

    calldatas.push(toAdd);

    const tos: string[] = [];
    const values: string[] = [];
    const calls: BytesLike[] = [];

    calldatas.forEach((calldata) => {
      tos.push(calldata.to);
      values.push(calldata.value);
      calls.push(calldata.calldata);
    });

    const final = Stash__factory.createInterface().encodeFunctionData(
      "executeBatch",
      [tos, values, calls]
    );

    // console.log("final calldata", final)

    return final;
  }
}

/**
 * Generates the init code for the bundler based on the call type.
 * @param callType - The type of factory data, can be either FactoryCreateSender or FactoryGetSender.
 * @param signer - The address of the signer.
 * @param salt - The salt value.
 * @returns - The init code.
 */

function getInitCodeForBundler(
  callType: FactoryData,
  signer: string,
  salt: number,
  factoryAddress?: string
): BytesLike {
  const factoryInterface = Factory__factory.createInterface();

  let init: string;
  if (callType === FactoryData.FactoryCreateSender) {
    init = factoryInterface.encodeFunctionData("createAccount", [signer, salt]);
  } else if (callType === FactoryData.FactoryGetSender) {
    init = factoryInterface.encodeFunctionData("getAddress", [signer, salt]);
  } else {
    throw new Error("Invalid callType provided");
  }

  return `${
    factoryAddress ? factoryAddress.toLowerCase() : factory.toLowerCase()
  }${init.replace("0x", "")}`;
}

export * from "./constants";
export * from "./types/ethers-contracts/index";
