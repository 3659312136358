import {
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { useState } from "react";

import SearchOptionsIcon from "assets/searchOptionsIcon.svg";
import activeFilterIcon from "assets/activeFilterIcon.svg";
import NoDataUI from "components/NoDataCard";
import { initialPendingTxValues } from "@slices/appSlice";
import { useAppSelector } from "store/store";
import NavigatorHeading from "components/NavigatorHeading";
import SearchInput from "components/SearchInput";
import CenterItem from "components/CenterItem";
import TransactionList from "./TransactionList";
import TransactionsFilterModal from "components/Modal/TransactionsFilterModal";
import { groupTimestampData } from "utils/txhistory";

const CashTransactionsView = () => {
  const [searchText, setSearchText] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [filteredTerms, setFilteredTerms] = useState<string[]>([]);
  const { activeAccount, portfolio, cashTransactions } = useAppSelector(
    (state) => state.app
  );

  const { walletTxHistoryLoading } = useAppSelector((state) => state.wallet);

  let allTransactions = cashTransactions?.length > 0 ? cashTransactions : [];
  console.log(
    "🚀 ~ file: View.tsx:21 ~ CashTransactionsView ~ allTransactions:",
    allTransactions
  );

  const allCashTxs = [...allTransactions].sort(
    (a, b) => b.timestamp - a.timestamp
  );

  const groupedByDateArray = groupTimestampData(allCashTxs);
  console.log(
    "file: View.tsx:47  CryptoTransactionsView  todayGroup, yesterdayGroup, earlierGroup:",
    groupedByDateArray
  );

  const handleFilterChange = (value) => {
    console.log("value", value);

    if (filteredTerms.includes(value)) {
      const updatedFilters = filteredTerms.filter((ft) => ft !== value);
      setFilteredTerms(updatedFilters);
    } else {
      setFilteredTerms([...filteredTerms, value]);
    }
  };

  const filterTx = () => {
    let arr = [];
    const renderTxs = Object.keys(groupedByDateArray).map((key) => (
      <>
        {groupedByDateArray[key].filter((item: any) =>
          (item.action + " " + item.assetName) // Concatenate assetName and action
            .toLowerCase()
            .includes(searchText.trim().toLocaleLowerCase())
        )?.length > 0 &&
          groupedByDateArray[key].filter((item: any) =>
            filteredTerms.length > 0
              ? filteredTerms.includes(item.action.toLowerCase())
              : item
          )?.length > 0 && (
            <>
              <Typography
                variant="h5"
                py={2}
                fontWeight={400}
                sx={{ color: "#1A1C20BF" }}
              >
                {key}
              </Typography>
              {groupedByDateArray[key]
                .filter((item: any) =>
                  (item.action + " " + item.assetName) // Concatenate assetName and action
                    .toLowerCase()
                    .includes(searchText.trim().toLocaleLowerCase())
                )
                .filter((item: any) =>
                  filteredTerms.length > 0
                    ? filteredTerms.includes(item.action.toLowerCase())
                    : item
                )
                .map((item: any, index: number) => {
                  arr.push(item);
                  return <TransactionList props={{ tx: item }} />;
                })}
            </>
          )}
      </>
    ));

    if (arr.length > 0) {
      return renderTxs;
    } else {
      return (
        <Box
          py={15}
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NoDataUI
            title="No transaction history found with your applied filters"
            description=""
          />
        </Box>
      );
    }
  };

  return (
    <Grid paddingY={5}>
      <NavigatorHeading
        title={"Transactions"}
        RightComponent={
          <Grid
            container
            spacing={1}
            width={{
              xs: "300px",
              sm: "400px",
              md: "500px",
              lg: "500px",
              xl: "500px",
            }}
          >
            <Grid item sm={11} md={11} lg={11} alignItems={"center"}>
              <SearchInput
                handleOnChange={(e: any) => {
                  setSearchText(e.target.value);
                }}
                padding="0px 20px"
                placeholder="Search Transactions"
              />
            </Grid>

            <Grid
              item
              sm={1}
              md={1}
              lg={1}
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                justifyContent: "end",
                paddingLeft: "12px",
              }}
            >
              <CenterItem>
                <img
                  src={
                    filteredTerms.length > 0
                      ? activeFilterIcon
                      : SearchOptionsIcon
                  }
                  onClick={() => setShowFilters(!showFilters)}
                  style={{ cursor: "pointer" }}
                />
              </CenterItem>
            </Grid>
          </Grid>
        }
      />
      {showFilters && (
        <TransactionsFilterModal
          modalProps={{
            open: showFilters,
            onClose: () => setShowFilters(false),
            terms: ["Sent", "Received"],
            filteredTerms,
            handleFilterChange,
          }}
        />
      )}
      <Grid py={2} display={"flex"} gap={1} flexDirection={"column"}>
        {/* <Grid container py={1}>
          <Box sx={{ color: "#8C8D8F" }}>Transactions</Box>
        </Grid> */}
        {allCashTxs?.length > 0 && (
          <>
            {walletTxHistoryLoading && (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Typography style={{ marginRight: 5 }}>
                  Fetching latest transactions
                </Typography>
                <CircularProgress color="inherit" size={"20px"} />
              </div>
            )}
            {filterTx()}
          </>
        )}
        {allCashTxs?.length === 0 && (
          <Box
            py={15}
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NoDataUI
              title="No Activity Yet!"
              description="You transaction history will be displayed here"
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default CashTransactionsView;
