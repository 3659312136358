import { Fade, Skeleton, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React, { FC } from "react";

import BasicButton from "../Button";

const SendTxComponent: FC<{
  loading: boolean;
  handleSend: () => void;
  finalOpState: any;
  error: boolean;
  txByDeposited: boolean;
  children: React.ReactNode;
  isSwap: boolean;
  isCashout?: boolean;
  isShowCancelButton?: boolean;
  onReject?: () => void;
  isApproval?: boolean;
}> = ({
  loading,
  error,
  handleSend,
  finalOpState,
  txByDeposited,
  isSwap,
  children,
  isShowCancelButton = false,
  isCashout = false,
  onReject,
  isApproval,
}) => {
  console.log("error || !finalOpState", { error, finalOpState });
  return (
    <>
      <Box width={"75%"} display={"flex"} flexDirection={"column"} gap={1}>
        <Box>
          {children}
          <Stack
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"row"}
            sx={{
              // border: "1px solid red",
              width: "100%",
              height: "90px",
            }}
          >
            {isShowCancelButton && loading && (
              <Skeleton
                variant="rectangular"
                width={110}
                height={50}
                sx={{
                  borderRadius: "10px",
                  width: "fit-content",
                  display: "flex",
                }}
              />
            )}
            {isShowCancelButton && !loading && (
              <BasicButton
                title={"Reject"}
                onClick={onReject}
                className="transfer-btn"
                style={{ marginRight: "5px", height: "15px" }}
                loading={false}
                mode={"active"}
                id="send"
              />
            )}
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={110}
                height={50}
                sx={{
                  borderRadius: "10px",
                  width: "fit-content",
                  display: "flex",
                }}
              />
            ) : (
              <BasicButton
                title={
                  txByDeposited
                    ? `${isSwap ? "Confirm" : "Send"}`
                    : `${
                        isSwap
                          ? "Confirm"
                          : isApproval
                          ? "Approve"
                          : isCashout
                          ? "Cash Out"
                          : "Send"
                      }`
                }
                onClick={
                  error || !finalOpState
                    ? () => {
                        console.log("abc");
                      }
                    : handleSend
                }
                style={{
                  cursor: error || !finalOpState ? "not-allowed" : "pointer",
                  height: "15px",
                }}
                className="transfer-btn"
                loading={loading}
                mode={"active"}
                id="send"
              />
            )}
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default SendTxComponent;
