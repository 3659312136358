import { Box, Grid, Typography, useTheme } from "@mui/material";
import { FC } from "react";

import InfoCard from "components/InfoCard";
import ChipButton from "components/ChipButton";
import DepositIcon from "assets/deposit.svg";
import SendIcon from "assets/send-icon.svg";
import NFTListTable from "./NFTListTable";
import { useAppSelector } from "../../../store/store";
import { useNavigate } from "react-router-dom";

import NFTCard from "assets/nftCard.svg";
import ExpandableChart from "components/ExpandableChart";
import { showAlert } from "utils/utils";

const NFTView: FC<{ accountName: string }> = ({ accountName }) => {
  const { activeAccount, newNFTs, gas, activeNetwork } = useAppSelector(
    (state) => state.app
  );
  const theme = useTheme();
  const navigate = useNavigate();
  const allNfts = newNFTs[activeAccount.smartAccountAddress];

  const currentUserNFTsAmount =
    newNFTs[activeAccount?.smartAccountAddress]?.length ?? 0;

  return (
    <Box width={"100%"}>
      <Box
        className="flex-row-center"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
          gap: "26px",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        {/* User Info & Balance Area */}
        <Box sx={{ paddingTop: "0px !important", flex: 1, width: "100%" }}>
          <InfoCard
            backgroundImage={`url(${NFTCard})`}
            backgroundPosition="0px"
            backgroundSize="cover"
            title={`@${activeAccount?.accountName.toLowerCase()}`}
            gas={String(gas.totalBalance.toFixed(4))}
            amount={
              <>
                <Typography
                  variant={"h5"}
                  fontWeight={700}
                  color="rgba(26, 28, 32, 1)"
                  fontSize={45}
                  style={{ cursor: "pointer" }}
                  align="center"
                >
                  {currentUserNFTsAmount}
                </Typography>
                <Typography
                  variant={"h5"}
                  fontWeight={700}
                  color="rgba(26, 28, 32, 0.5)"
                  fontSize={25}
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                  align="center"
                >
                  NFTs
                </Typography>
              </>
            }
          />
        </Box>

        {/* Receive Cash Area */}
        <Box sx={{ paddingTop: "0px !important", flex: 1, width: "100%" }}>
          <ExpandableChart
            chartData={[]}
            chartTimestamps={[]}
            title={`lorem ipsum`}
            amount={"0"}
            percentageChange="5"
            isNFTScreen={true}
          />
        </Box>
      </Box>

      <Box mt={2.5}>
        <Box
          className="flex-row-center"
          style={{
            display: "flex",
            gap: "4px",
            justifyContent: "flex-start",
            flexFlow: "wrap",
            paddingBottom: "24px",
          }}
        >
          <ChipButton
            title="Receive"
            onClick={() => {
              navigate("/nfts/receive");
            }}
            icon={DepositIcon}
            fontWeight={400}
            fontSize={14}
            fontFamily="Helvetica Neue"
          />

          <ChipButton
            title="Send"
            onClick={() => {
              allNfts?.length > 0
                ? navigate("/nfts/send")
                : showAlert("You don't have any NFTs to send", "Oops!");
            }}
            icon={SendIcon}
            fontWeight={400}
            fontSize={14}
            fontFamily="Helvetica Neue"
          />
        </Box>
        {/* <Banner /> */}

        <Grid paddingTop={2}>
          <NFTListTable />
        </Grid>
      </Box>
    </Box>
  );
};

export default NFTView;
