import { rejectEIP155Request } from "../utils/EIP155RequestHandlerUtil";

// import { styledToast } from '@/utils/HelperUtil'

import ProjectInfoCard from "../components/ProjectInfoCard";
import { useEffect, useState } from "react";
import ModalStore from "../walletConnectStore/ModalStore";
import { styledToast } from "../utils/HelperUtil";
import { signClient } from "../utils/WalletConnectUtil";
import RequestModalContainer from "../components/RequestModalContainer";
import RequestMethodCard from "../components/RequestMethodCard";
import RequestDetailsCard from "../components/RequestDetailsCard";
import RequestDataCard from "../components/RequestDataCard";
import {
  Box,
  Collapse,
  Fade,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import BasicButton from "../components/Button";
import EthIcon from "../assets/EthIcon.svg";
import AlertIcon from "../assets/alert-icon.svg";
import CustomLogo from "../assets/morflax.svg";
import {
  BoxStyled,
  Caption,
  ItemWrapper,
  MediumTitle,
  SessionSendTransactionModalWrapper,
} from "components/Styled";
import PolygonIcon from "assets/PolygonIcon.svg";
import SwitchSelector from "components/SwitchSelector";
import { formatJsonRpcResult } from "@json-rpc-tools/utils";
import { getSdkError } from "@walletconnect/utils";
import Web3 from "web3";
import { useAppSelector } from "store/store";
import { SUPPORTED_NETWORKS } from "constants/chains";
import {
  decryptMessage,
  formatAmount,
  extractTokenData,
  fetchPrice,
  showAlert,
  initalizeWeb3,
  saveTransaction,
  fetchUsdPriceFromMobula,
} from "utils/utils";
import { ethers, BigNumber as ethBigNumber } from "ethers";
import { assert } from "console";

import { fetchTransactionCostInNativeToken, gasPriceData } from "utils/gas";
import { getEthDollarValue } from "utils/portfolio";

import { getSignatureObj } from "utils/transaction";
import { getUserOpHashData } from "utils/jiffyscan";

import {
  CallDataType,
  EMPTY_CALLDATA,
  EntryPoint_Address,
  ExecuteCall,
  FactoryData,
  PaymasterType,
  TransferData,
} from "../contract-integration/constants";
import {
  getCounterFactualAddress,
  getPartialUserOpForVerifyingPaymaster,
  sendUserOp,
  simulateHandleOps,
  simulateValidation,
} from "../contract-integration";
import { signUserOp } from "../contract-integration/utils/helper";
import { txSubmissionOrderPrepaid } from "../contract-integration/prepaidGas";

import { tokensForGasObject } from "constants/topTokensConf";
import { readAccountHoldings } from "utils/holdings";
import TokenImage from "components/TokenImage";
import { txSubmissionOrderPostpaid } from "../contract-integration/postpaidGas";
import ConfirmPatternModal from "components/ConfirmPatternModal";
import { useNavigate } from "react-router-dom";
import ItemBox from "components/ItemBox";
import FeeUrgencyComponent from "components/SendTxComponent/FeeUrgencyComponent";
import TxTypeSwitch from "components/SendTxComponent/TxTypeSwitch";
import SendTxComponent from "components/SendTxComponent";
import GasTokenSelect from "components/GasTokenSelect";

import FeeUIComponent from "components/SendTxComponent/FeeUIComponent";
import useDynamicTitleAndFavicon from "hooks/useDynamicTitleAndFavIcon";
import { getChain } from "utils/chains";
import SendTransactionModalStore from "../walletConnectStore/SendTransactionModalStore";
import {
  handleBlockaidResponse,
  handleBlockaidSimulationResponse,
} from "utils/blockaid";
import { getProtocolFee, getUsdcBalance } from "utils/balance";
import BigNumber from "bignumber.js";
import { isCryptoAccountDeployed } from "utils/deployed";
// import { signUserOp } from "stash-aa-ts/utils/helper";

export default function SdkSendTransactionModal({ payload, reqId }) {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("Normal");
  const [gasPrice, setGasPrice] = useState(0);
  const [cost, setCost] = useState(0);
  const [error, setError] = useState(false);
  const [finalOpState, setFinalOpState] = useState<any>(null);

  const [depositableTokens, setDepositableTokens] = useState<Array<any>>([]);
  const [paymentToken, setPaymentToken] = useState({
    address: "",
    decimals: 18,
    tokenBalance: 0,
  });
  const [sameTokens, setSameTokens] = useState<any[]>([]);
  const [src, setSrc] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isPatternCorrect, setIsPatternCorrect] = useState(false);
  const {
    activeAccount,
    rootAccountInfo,
    activeNetwork,
    portfolio: { assets },
    accounts,
    userSpendingDetails,
    gas,
  } = useAppSelector((state) => state.app);
  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const {
    rpc,
    usdcAddress,
    usdcDecimals,
    alchemy_url,
    stackupUrl,
    block_explorer_url,
    bundlerRPC,
  } = SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const navigate = useNavigate();
  const { startBlinkingTitle, stopBlinkingTitle } = useDynamicTitleAndFavicon();
  const callData = payload?.callData;
  console.log(
    "🚀 ~ file: SessionSendTransactionModal.tsx:126 ~ SessionSendTransactionModal ~ ModalStore.state.data?:",
    payload,
    JSON.stringify(payload)
  );
  const toadd = payload?.to;
  const txValue = payload?.value;

  const handleApprove = async () => {
    if (
      userSpendingDetails.isDailyLimitExceed &&
      userSpendingDetails.isPatternSet &&
      userSpendingDetails.isSpendingLimitSaved
    ) {
      setOpenModal(true);
    } else {
      onApprove();
    }
  };
  const verifyMessageWithEthers = (message, signature, address) => {
    console.log("message, signature, address", { message, signature, address });
    const recoveredAddress = ethers.utils.verifyMessage(message, signature);
    console.log("recovered address", { recoveredAddress, address });
    return recoveredAddress.toLowerCase() == address.toLowerCase();
  };
  // Handle approve action
  async function onApprove() {
    setLoading(true);
    try {
      if (finalOpState) {
        stopBlinkingTitle();
        setLoading(true);
        const response = await sendUserOp(
          finalOpState,
          stackupUrl,
          alchemy_url
        );
        const userOPResponse: any = await response.wait();
        console.log("userOp Hash :", response.userOpHash);
        console.log("Tx Hash :", userOPResponse?.transactionHash);
        console.log("success status :", userOPResponse?.args.success);
        console.log(
          "actualGasCost  :",
          Number(userOPResponse?.args.actualGasCost)
        );

        console.log(
          "actualGasCost  :",
          Number(userOPResponse?.args.actualGasCost)
        );
        console.log(
          "actualGasUsed  :",
          Number(userOPResponse?.args.actualGasUsed)
        );

        showAlert(
          "Soon you can see your transaction in the transactions tab",
          "Transaction Submitted",
          `<a href="https://polygonscan.com/tx/${userOPResponse?.transactionHash}" target="_blank">View on Polygonscan</a>`
        );

        setFinalOpState(null);
        setLoading(false);
        SendTransactionModalStore.close(reqId);
        response.wait();
        let interval = setInterval(async () => {
          const transactionHash = await getUserOpHashData(
            response.userOpHash,
            activeNetwork
          );
          console.log(
            "🚀 ~ file: SessionSendTransactionModal.tsx:243 ~ onApprove ~ transactionHash:",
            transactionHash
          );
          let resp: any;
          console.log("resp logic above", resp);
          if (transactionHash.userOps.length > 0 && !resp?.hash) {
            console.log("resp logic below", resp);
            // const { block_explorer_url } = SUPPORTED_NETWORKS[activeNetwork];
            const web3 = initalizeWeb3(activeNetwork);

            const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);
            console.log("pkey", pkey);
            const signature = web3.eth.accounts.sign(
              `${userOPResponse?.transactionHash}-${gasFeeInUSD}`,
              pkey
            );
            console.log(
              "ye sign krrhe hain",
              `${userOPResponse?.transactionHash}-${gasFeeInUSD}`
            );
            console.log("signature", { signature });

            const respverifyMessageWithEthers = await verifyMessageWithEthers(
              `${userOPResponse?.transactionHash}-${gasFeeInUSD}`,
              signature.signature,
              rootAccountInfo.address.toLowerCase()
            );
            console.log(
              "resp of verifyMessageWithEthers",
              respverifyMessageWithEthers
            );

            if (!userSpendingDetails.isFirstTx) {
              navigate("/transaction-success");
            }
            console.log("gasFeeInUSD", gasFeeInUSD);
            resp = await saveTransaction(
              activeAccount.smartAccountAddress.toLowerCase(),
              rootAccountInfo.address.toLowerCase(),
              userOPResponse?.transactionHash,
              activeNetwork,
              Number(gasFeeInUSD),
              signature.signature,
              txnDetailsForHistory?.value || "",
              txnDetailsForHistory?.valueIn$ || "",
              txnDetailsForHistory?.value || "",
              "Crypto",
              `${block_explorer_url}/tx/${userOPResponse?.transactionHash}`,
              rootAccountInfo.name,
              activeAccount.accountName,
              rootAccountInfo.name,
              txnDetailsForHistory?.toAddress || "",
              txnDetailsForHistory?.tokenSymbol || "",
              gasFeeInUSD || "",
              txnDetailsForHistory?.icon || "",
              0,
              "Sent"
            );
            console.log("createTransaction resp", resp);
            showAlert(
              "",
              "Transaction Sucess"
              // `<a href="https://polygonscan.com/tx/${transactionHash.userOps[0].transactionHash}" target="_blank">View on Polygonscan</a>`
            );
            clearInterval(interval);
          }
        }, 4000);
      }
      // return response1
    } catch (e) {
      styledToast((e as Error).message, "error");
      return;
    }
    SendTransactionModalStore.close(reqId);
    // }
  }

  // Handle reject action
  async function onReject() {
    stopBlinkingTitle();
    SendTransactionModalStore.close(reqId);
  }

  const [risk, setRisk] = useState(false);
  const [riskData, setRiskData] = useState({ type: "", description: "" });
  const [txnDetailsForHistory, setTxnDetailsForHistory] = useState({});
  useEffect(() => {
    (async () => {
      const options = {
        to: toadd || "",
        value: txValue || "0",
        calldata: callData,
      };
      console.log("options", options);
      const blockAidSimulation = await handleBlockaidSimulationResponse(
        activeNetwork,
        activeAccount.smartAccountAddress,
        options
      );
      console.log("blockAidSimulation", blockAidSimulation);
      const simulation =
        blockAidSimulation?.simulation?.assets_diffs[
          activeAccount.smartAccountAddress
        ];
      const txnDetails = {
        toAddress: payload?.to,
        value: simulation[0]?.out[0]?.value,
        valueIn$: simulation[0]?.out[0]?.usd_price,
        tokenName: simulation[0]?.asset?.name,
        tokenSymbol: simulation[0]?.asset?.symbol,
        tokenDecimals: simulation[0]?.asset?.decimals,
        isNative: simulation[0]?.asset?.type === "NATIVE",
        icon: simulation[0]?.asset?.logo_url,
      };

      console.log("txnDetails", txnDetails);
      setTxnDetailsForHistory(txnDetails);
      // const { params } = requestEvent;
      // const { request } = params;
      const blockAidReponse = await handleBlockaidResponse(
        activeNetwork,
        activeAccount.smartAccountAddress,
        options
      );

      console.log("blockAidReponse", blockAidReponse);
      if (blockAidReponse?.validation?.result_type == "Benign") {
        setRisk(false);
      } else {
        setRiskData({
          type: blockAidReponse?.validation?.result_type,
          description: blockAidReponse?.validation?.description,
        });
        setRisk(true);
      }
    })();
  }, []);
  const [gasFeeInUSD, setGasFeeInUSD] = useState("0");
  const calculateTransactionParameters = async () => {
    setLoading(true);
    setError(false);
    const web3 = new Web3(rpc);
    const nonce = await web3.eth.getTransactionCount(
      activeAccount.smartAccountAddress,
      "latest"
    );

    console.log(
      "🚀 ~ file: SessionSendTransactionModal.tsx:262 ~ calculateTransactionParameters ~ activeAccount:",
      activeAccount,
      hashedPassword.length,
      rpc
    );
    const pkey = decryptMessage(activeAccount.secret, hashedPassword);
    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const stackupProvider = new ethers.providers.JsonRpcProvider(bundlerRPC);

    const wallet = new ethers.Wallet(pkey, provider);

    console.log("🚀 ~ file: swap.ts:821 ~ callData:", callData);
    let tokenAmountToTransfer = "1000";

    let userCallDataArray: ExecuteCall[] = [];

    userCallDataArray.push({
      to: toadd || "",
      value: txValue || "0",
      calldata: callData,
    });

    let data: any = {};

    const chain = getChain(activeNetwork);

    const cryptoAccountDeployed = await isCryptoAccountDeployed(
      activeNetwork,
      activeAccount.smartAccountAddress
    );

    data = await txSubmissionOrderPrepaid({
      userCallDataArray,
      // counterfactual: activeAccount.smartAccountAddress,
      wallet,

      isAccountDeployed: cryptoAccountDeployed,
      chain,
      smartAccountAddress: activeAccount.smartAccountAddress,
      provider: stackupProvider,
      extraTopupAmount: "0",
      approval: true,
      gasBalance: gas.totalBalance,
      rootAddress: rootAccountInfo.address.toLowerCase(),
    });

    setFinalOpState(data.finalOp);
    console.log("gasFeeInUSD going to be", data.usdcFee);
    setGasFeeInUSD(Number(data.usdcFee).toFixed(4));
    if (+data.usdcFee >= gas.totalBalance) {
      setError(true);
    }
    setLoading(false);
    let price = 0;
    const data1 = await fetchPrice([usdcAddress.toLowerCase()], activeNetwork);

    price = data1[usdcAddress.toLowerCase()]?.price || 0;

    setCost(price * +data.usdcFee);
  };

  useEffect(() => {
    if (usdcAddress) calculateTransactionParameters();
  }, [usdcAddress]);

  useEffect(() => {
    if (isPatternCorrect) {
      setOpenModal(false);
      onApprove();
    }
  }, [isPatternCorrect]);

  useEffect(() => {
    startBlinkingTitle();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     if (requestEvent) {
  //       const gasPrice = await gasPriceData(activeNetwork);

  //       // const estimatedGas = await getEstimatedGas(tokenIn, approvalCallData, "0", wallet)
  //       const transactionCostInNativeToken =
  //         await fetchTransactionCostInNativeToken(
  //           Number(request.params[0].gas),
  //           gasPrice.data.fast / Math.pow(10, 9)
  //         );

  //       console.log("🚀 ~ file: index.tsx:68 ~ gasPrice:", gasPrice);
  //       setGasPrice(gasPrice.data.fast);
  //       const price = await getEthDollarValue({ asset: "Polygon" });
  //       console.log("🚀 ~ file: index.tsx:101 ~ price:", price);
  //       const cost = price * transactionCostInNativeToken;
  //       console.log("🚀 ~ file: index.tsx:104 ~ cost:", cost);
  //       setCost(cost);
  //     }
  //   })();
  // }, [activeNetwork, requestEvent]);

  useEffect(() => {
    const { sameTokens } = extractTokenData(activeNetwork, assets);

    setSameTokens(sameTokens);
  }, [activeNetwork, assets]);

  const [open, setOpen] = useState(false);

  const toggleCollapse = () => {
    setOpen(!open);
  };

  return (
    <SessionSendTransactionModalWrapper>
      <RequestModalContainer title="Review Transaction">
        <Stack>
          <Stack
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            marginBottom={3}
          >
            <img
              src={CustomLogo}
              width={30}
              height={30}
              style={{ marginRight: 3 }}
            />
            <Typography>{"Demo Dapp"}</Typography>
          </Stack>
          {/* <Stack marginBottom={3}>
            <Typography variant="h5">Call Data</Typography>
            <ItemWrapper
              sx={{
                wordWrap: "break-word", // Breaks the words and wraps onto the next line
                overflowWrap: "break-word",
                height: "150px",
                maxHeight: "600px",
                overflowY: "auto",
              }}
            >
              <Typography variant="caption">{callData}</Typography>
            </ItemWrapper>
          </Stack> */}

          {!risk ? (
            <Box
              sx={{
                background: "#F7F7F7",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                //   padding: "25px",
                cursor: "pointer",
                padding: "10px",
                marginBottom: "15px",
              }}
            >
              <Typography
                color={"rgba(91, 194, 65, 1)"}
                fontSize={"12px"}
                fontWeight={500}
              >
                Secure and reliable. Safe to proceed.
              </Typography>
              {/* <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <SortOutlinedIcon />
          <Typography variant="h6">{title}</Typography>
        </Box> */}

              {/* <ExpandMoreIcon
          style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
        /> */}
            </Box>
          ) : (
            <Paper
              elevation={3}
              sx={{
                borderRadius: "10px",
                background: "#FFFFFF",
                // "&:hover": {
                //   backgroundColor: "lightgray",
                // },
                boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.0)",
                marginBottom: "20px",
              }}
            >
              <Box
                onClick={toggleCollapse}
                sx={{
                  background: "#F7F7F7",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  //   padding: "25px",
                  cursor: "pointer",
                  padding: "10px",
                  // marginBottom: "5px",
                }}
              >
                <Typography
                  color={"#EB4335"}
                  fontSize={"12px"}
                  fontWeight={500}
                >
                  {riskData.type || "High Risk"}
                </Typography>
                {/* <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <SortOutlinedIcon />
          <Typography variant="h6">{title}</Typography>
        </Box> */}

                {/* <ExpandMoreIcon
          style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
        /> */}
              </Box>
              <Collapse in={open}>
                <Box
                  sx={{
                    padding: "20px 20px 20px 20px",
                    background: "#F7F7F7",
                    borderRadius: "10px",
                    marginTop: "10px",

                    // border: "2px solid black",
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                >
                  <Typography
                    color={"#1A1C20"}
                    fontSize={"12px"}
                    fontWeight={500}
                  >
                    We found 1 risky signal
                  </Typography>

                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <img
                      src={AlertIcon}
                      alt="alert"
                      style={{ width: "30px", height: "30px" }}
                    />

                    <Typography
                      color={"#1A1C20"}
                      fontSize={"12px"}
                      fontWeight={500}
                    >
                      {riskData?.description || "Unknown Wallet"}
                    </Typography>
                  </Stack>

                  {/* <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <img
                    src={AlertIcon}
                    alt="alert"
                    style={{ width: "30px", height: "30px" }}
                  />

                  <Typography
                    color={"#1A1C20"}
                    fontSize={"12px"}
                    fontWeight={500}
                  >
                    Unusual Recipient
                  </Typography>
                </Stack>

                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <img
                    src={AlertIcon}
                    alt="alert"
                    style={{ width: "30px", height: "30px" }}
                  />

                  <Typography
                    color={"#1A1C20"}
                    fontSize={"12px"}
                    fontWeight={500}
                  >
                    Limited onchain history
                  </Typography>
                </Stack> */}
                </Box>
              </Collapse>
            </Paper>
          )}
          <Grid container display="flex" justifyContent="center" py={0}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* <FeeUrgencyComponent
                feeGasUrgency={selected}
                setFeeGasUrgency={setSelected}
              /> */}
              {/* <Box marginBottom={3}>
                <TxTypeSwitch
                  loading={loading}
                  txByDeposited={txByDeposited}
                  onTop20Select={onTop20Select}
                  onDepositSelect={onDepositSelect}
                />
              </Box> */}
              <SendTxComponent
                loading={loading}
                // SetTokenForPayment={SetTokenForPayment}
                gasTokenSelect={<></>}
                top20TokenSelect={<></>}
                txByDeposited={false}
                handleSend={() => handleApprove()}
                finalOpState={finalOpState}
                error={error}
                isSwap={true}
                isShowCancelButton={true}
                onReject={onReject}
              >
                <FeeUIComponent
                  gasFeeInUSD={formatAmount(cost)}
                  loading={!finalOpState && loading}
                />
              </SendTxComponent>
            </Box>
          </Grid>
        </Stack>

        <ConfirmPatternModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          setIsPatternCorrect={setIsPatternCorrect}
        />
      </RequestModalContainer>
    </SessionSendTransactionModalWrapper>
  );
}
