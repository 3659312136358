// import SettingsStore from '@/store/SettingsStore'

// import { createOrRestoreCosmosWallet } from '@/utils/CosmosWalletUtil'
// import { createOrRestoreEIP155Wallet } from '@/utils/EIP155WalletUtil'

// import { createOrRestoreSolanaWallet } from '@/utils/SolanaWalletUtil'
// import { createOrRestorePolkadotWallet } from '@/utils/PolkadotWalletUtil'
// import { createOrRestoreNearWallet } from '@/utils/NearWalletUtil'
// import { createOrRestoreMultiversxWallet } from '@/utils/MultiversxWalletUtil'
// import { createOrRestoreTronWallet } from '@/utils/TronWalletUtil'
// import { createOrRestoreTezosWallet } from '@/utils/TezosWalletUtil'
// import { createSignClient, signClient } from '@/utils/WalletConnectUtil'

// import { createOrRestoreKadenaWallet } from '@/utils/KadenaWalletUtil'
import { useCallback, useEffect, useRef, useState } from "react";
import { createOrRestoreEIP155Wallet } from "../utils/EIP155WalletUtil";
import {
  createSignClient,
  createWeb3Wallet,
  signClient,
  web3wallet,
} from "../utils/WalletConnectUtil";
import { useSnapshot } from "valtio";
import SettingsStore from "../walletConnectStore/SettingsStore";
import { useAppDispatch, useAppSelector } from "store/store";
import { decryptMessage } from "utils/utils";
import { toggleInitialized } from "@slices/appSlice";
import { SUPPORTED_NETWORKS } from "../constants/chains";

export default function useInitialization() {
  const [initialized, setInitialized] = useState(false);
  const prevRelayerURLValue = useRef<string>("");

  const { relayerRegionURL, smartAccountEnabled, kernelSmartAccountEnabled } =
    useSnapshot(SettingsStore.state);
  const { activeAccount, activeNetwork } = useAppSelector((state) => state.app);
  // const { mnemonic, accounts } = useAppSelector((state) => state.app);
  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const dispatch = useAppDispatch();

  const { alchemy_url } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  //   const dispatch = useAppDispatch();

  const onInitialize = useCallback(async () => {
    try {
      const { eip155Addresses, eip155Wallets } = createOrRestoreEIP155Wallet(
        activeAccount.secret,
        hashedPassword,
        alchemy_url
      );

      SettingsStore.setEIP155Address(eip155Addresses[0]);

      // await createSignClient(relayerRegionURL);
      await createWeb3Wallet(relayerRegionURL);
      setInitialized(true);
      dispatch(toggleInitialized());
    } catch (err: unknown) {
      // alert(err);
      console.log("err", err);
    }
  }, [relayerRegionURL, activeAccount.address, hashedPassword]);

  // restart transport if relayer region changes
  const onRelayerRegionChange = useCallback(() => {
    try {
      // signClient.core.relayer.restartTransport(relayerRegionURL);
      web3wallet?.core?.relayer.restartTransport(relayerRegionURL);
      prevRelayerURLValue.current = relayerRegionURL;
    } catch (err: unknown) {
      // alert(err);
      console.log("ERROR => onRelayerRegionChange", err);
    }
  }, [relayerRegionURL]);

  useEffect(() => {
    // if (!initialized) {
    onInitialize();
    // }
    if (prevRelayerURLValue.current !== relayerRegionURL) {
      onRelayerRegionChange();
    }
  }, [
    initialized,
    onInitialize,
    relayerRegionURL,
    onRelayerRegionChange,
    hashedPassword,
  ]);

  return initialized;
}
