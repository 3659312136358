import React, { useEffect, useState } from "react";
import { Box, Grid, Skeleton, Snackbar, Typography } from "@mui/material";
import BasicModal from "components/BasicModal";
import ModalHeader from "components/ModalHeader";
import AssetsView from "components/AssetsView";
import { Token } from "interfaces";
import { useAppDispatch, useAppSelector } from "store/store";
import { SUPPORTED_NETWORKS } from "constants/chains";
import {
  decryptMessage,
  fetchUsdPriceFromMobula,
  showAlert,
} from "utils/utils";
import { setSelectedToken } from "@slices/walletSlice";
import { ethers, BigNumber as ethBigNumber } from "ethers";
import {
  setPendingTx,
  setPendingTxDetails,
  setUserSpendingDetails,
} from "@slices/appSlice";
import { useNavigate } from "react-router-dom";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import NetworksList from "components/NetworksList";
import TokensListTable from "../TokensListTable";
import TokenInputForm from "components/TokenInputForm";
import TransactionContactList from "../../../../components/TransactionContactList";
import SendAsset from "./SendAsset";
import {
  ExecuteCall,
  Token__factory,
  sendUserOp,
} from "../../../../contract-integration";
import { txSubmissionOrderPrepaid } from "../../../../contract-integration/prepaidGas";
import ConfirmPatternModal from "components/ConfirmPatternModal";
import SendTxComponent from "../../../../components/SendTxComponent";
import FeeUIComponent from "../../../../components/SendTxComponent/FeeUIComponent";
import Simulation from "components/Simulation";
import {
  getMinimumTopupLimit,
  getProtocolFee,
  getUsdcBalance,
} from "utils/balance";
import {
  APPROVE_AMOUNT,
  NATIVE_ADDRESS,
  USDT_URL,
  ZERO_ADDRESS,
} from "constants/";

import "./index.css";
import { getChain } from "utils/chains";
import GasToken from "components/GasToken";

import { checkGasAllowance } from "utils/swap";
import { getApproveTokenCallData } from "stash-fe-script";
import BigNumber from "bignumber.js";
import BasicButton from "components/Button";
import ExtraTopupAmountPopup from "components/ExtraTopupAmountPopup";
import { isCryptoAccountDeployed } from "utils/deployed";
import { createSignatureAndCalculateFees } from "utils/signature";
import WarningModal from "components/WarningModal";

const Send = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [to, setTo] = useState("");
  const [risk, setRisk] = useState(false);
  const [gasFeeInUSD, setGasFeeInUSD] = useState("0");
  const [tag, setTag] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [step, setStep] = useState(0);

  const [error, setError] = useState("");
  const [openPatternModal, setOpenPatternModal] = useState(false);
  const [isPatternCorrect, setIsPatternCorrect] = useState(false);
  const [allowance, setAllowance] = useState(0);
  const [usdcBalance, setUsdcBalance] = useState(0);
  const [minimumTopupAmount, setMinimumTopupAmount] = useState("0");
  const [extraTopupAmount, setExtraTopupAmount] = useState("0");
  const [openExtraTopupModal, setOpenExtraTopupModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const [finalOpState, setFinalOpState] = useState<any>(null);

  const navigate = useNavigate();
  const {
    activeAccount,
    holdings,
    activeNetwork,
    accounts,
    rootAccountInfo,
    userSpendingDetails,
    gas,
    pendingTx,
  } = useAppSelector((state) => state.app);

  const { selectedToken }: { selectedToken: any } = useAppSelector(
    (state) => state.wallet
  );

  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const {
    symbol,
    icon,
    block_explorer_url,
    wethAddress,
    usdcAddress,
    usdcDecimals,
    stackupUrl,
    alchemy_url,
    factoryAddress,
  } = SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const { nativeBalance } = holdings[activeAccount.smartAccountAddress];

  const dispatch = useAppDispatch();

  useEffect(() => {
    setError("");
  }, [step]);

  useEffect(() => {
    let userTokens: Token[] =
      holdings[activeAccount.smartAccountAddress]?.tokens || [];

    setTokens(userTokens);
  }, [activeAccount, holdings]);

  useEffect(() => {
    if (isPatternCorrect) {
      setOpenPatternModal(false);
      // executeSendCrypto();
    }
  }, [isPatternCorrect]);

  const isSelectedToken = selectedToken.length > 0;
  const tokenSymbol = isSelectedToken ? selectedToken[0].tokenSymbol : symbol;
  const tokenIcon = isSelectedToken ? selectedToken[0].image : icon;

  const tokenBalance = isSelectedToken
    ? selectedToken[0].tokenBalance
    : nativeBalance;

  useEffect(() => {
    setValue("");
  }, [selectedToken]);

  useEffect(() => {
    if (step === 4 && selectedToken.length > 0 && to && value) {
      if (usdcBalance < 2) {
        setOpenWarningModal(true);
      }

      sendCryptoTransaction();
    }
  }, [selectedToken, step, minimumTopupAmount, allowance]);

  useEffect(() => {
    (async () => {
      const allowance = await checkGasAllowance(
        activeAccount.smartAccountAddress,
        usdcAddress,
        activeNetwork
      );
      const minimumTopUpLimit = await getMinimumTopupLimit(activeNetwork);
      const usdcBalance = await getUsdcBalance(
        activeAccount.smartAccountAddress,
        activeNetwork
      );
      setUsdcBalance(usdcBalance);
      setMinimumTopupAmount(minimumTopUpLimit);
      setAllowance(Number(allowance));
    })();
  }, [loading]);
  //integrate with sdk
  //tx involving token transfer or native transfers
  async function sendCryptoTransaction() {
    try {
      setLoading(true);
      setError(false);
      setFinalOpState(null);

      const chain = getChain(activeNetwork);

      const ISTRANSFER_TOKEN_NATIVE =
        selectedToken[0]?.tokenAddress == NATIVE_ADDRESS ||
        selectedToken[0]?.tokenAddress == wethAddress;

      const rpcEndpoint =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
          .alchemy_url;

      const pkey = decryptMessage(activeAccount.secret, hashedPassword);

      const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);
      const stackupProvider = new ethers.providers.JsonRpcProvider(stackupUrl);

      const wallet = new ethers.Wallet(pkey, provider);

      if (!wallet) return;

      let userCallDataArray: ExecuteCall[] = [];
      const tokenAddress = selectedToken[0].tokenAddress;

      console.log("usdcBalance", usdcBalance);

      if (!usdcBalance && !gas.totalBalance) {
        showAlert("You do not have USDC balance to top up gas tank.");
        setLoading(false);
        return;
      }

      const approveCallData = getApproveTokenCallData(
        APPROVE_AMOUNT,

        chain,
        usdcAddress
      );

      if (!allowance) {
        userCallDataArray.push(approveCallData);
      } else {
        if (ISTRANSFER_TOKEN_NATIVE) {
          const call0 = "0x";
          userCallDataArray.push({
            to: to,
            value: ethers.utils
              .parseUnits(String(value), selectedToken[0].tokenDecimal)
              .toString(),
            calldata: call0,
          });
        } else {
          const call0 = Token__factory.createInterface().encodeFunctionData(
            "transfer",
            [
              to,
              ethers.utils
                .parseUnits(String(value), selectedToken[0].tokenDecimal)
                .toString(),
            ]
          );

          userCallDataArray.push({
            to: tokenAddress,
            value: "0",
            calldata: call0,
          });
        }
      }

      console.log(activeAccount.address);

      const cryptoAccountDeployed = await isCryptoAccountDeployed(
        activeNetwork,
        activeAccount.smartAccountAddress
      );

      const { finalOp, usdcFee } = await txSubmissionOrderPrepaid({
        userCallDataArray,

        wallet,

        isAccountDeployed: cryptoAccountDeployed,
        chain,

        smartAccountAddress: activeAccount.smartAccountAddress,
        provider: stackupProvider,

        extraTopupAmount,
        gasBalance: gas.totalBalance,
        approval: allowance ? true : false,
        rootAddress: rootAccountInfo.address.toLowerCase(),
      });

      setFinalOpState(finalOp);
      setGasFeeInUSD(Number(usdcFee).toFixed(4));

      if (Number(usdcFee) > gas.totalBalance) {
        setOpenExtraTopupModal(true);
      } else {
        setLoading(false);
      }
    } catch (e: any) {
      console.log("file: index.tsx:674  newSendTransaction  e:", e);
      showAlert(e?.message ? e?.message : e);
      setLoading(false);
      navigate("/crypto");
    }
  }

  const handleSend = async () => {
    if (
      userSpendingDetails.isDailyLimitExceed &&
      userSpendingDetails.isPatternSet
    ) {
      setOpenModal(true);
    } else executeSendCrypto();
  };
  //integrate with sdk
  const executeSendCrypto = async () => {
    try {
      setLoading(true);
      setOpenExtraTopupModal(false);
      //send userOp
      if (finalOpState) {
        //replace via sendStackup or sendAlchemy  UserOp
        const response = await sendUserOp(
          finalOpState,
          stackupUrl,
          alchemy_url
        );
        const userOPResponse: any = await response.wait();
        console.log("userOp Hash :", response.userOpHash);
        console.log("Tx Hash :", userOPResponse?.transactionHash);
        console.log("success status :", userOPResponse?.args.success);

        dispatch(
          setPendingTxDetails({
            value: allowance ? value : 0,
            valueIn$: allowance
              ? String(Number(value) * Number(selectedToken[0].tokenPrice ?? 0))
              : 0,
            transferAmount: allowance ? value : 0,
            transactionMethod: "Crypto",
            scanLink: block_explorer_url,
            eoaEns: rootAccountInfo.name,
            addressEns: activeAccount.accountName,
            toAddressEns: allowance ? tag : usdcAddress,
            toAddress: allowance ? to : usdcAddress,
            assetName: allowance ? selectedToken[0].tokenSymbol : "USDC",
            networkFeesIn$: gasFeeInUSD,
            iconURL: allowance ? selectedToken[0].image : USDT_URL,
            txByDesposited: false,
            action: allowance ? "Sent" : "Approved",
          })
        );

        dispatch(setPendingTx(userOPResponse?.transactionHash));

        showAlert(
          "Soon you can see your transaction in the transactions tab",
          allowance
            ? "Transaction Submitted"
            : "Approval Transaction Submitted",
          `<a href="https://polygonscan.com/tx/${userOPResponse?.transactionHash}" target="_blank">View on Polygonscan</a>`
        );
      }
      //reset OpState
      setFinalOpState(null);

      if (userSpendingDetails.isFirstTx && userSpendingDetails.isFirstTxInApp) {
        dispatch(
          setUserSpendingDetails({
            isFirstTxInApp: false,
            isFirstTx: false,
          })
        );
        if (allowance) {
          navigate("/transaction-success");
        }
      } else {
        if (allowance) {
          navigate("/crypto");
        }
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setError("Something went wrong");
      showAlert("Something went wrong");
      setFinalOpState(null);
      navigate("/crypto");
    }
  };

  const selectTokenHandler = (tk: Token) => {
    dispatch(setSelectedToken([tk]));
    setOpenModal(false);
  };

  const isValid =
    Number(value) && to && Number(value) <= Number(tokenBalance) && !error;

  const handleAmountChange = (value) => {
    const inputValue = value;
    console.log(inputValue);

    let regex = new RegExp(
      `^\\d{0,10}(\\.\\d{0,${selectedToken[0].tokenDecimal}})?$`
    );

    console.log("redex", regex);

    if (/[^0-9.]/.test(inputValue)) {
      setError("");
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setError("");
    } else if (!regex.test(inputValue)) {
      setError(
        `Maximum of 10 digits before decimals and ${selectedToken[0].tokenDecimal} digits after decimals are allowed`
      );
    } else {
      setError("");

      setValue(inputValue);
    }
  };

  const handleTopupAmountChange = (value) => {
    const inputValue = value;
    console.log(inputValue);

    if (/[^0-9.]/.test(inputValue)) {
      setError("");
    } else {
      setError("");
      setExtraTopupAmount(inputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      setValue(newValue);
      setError("");
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (Number(newValue) > 0) {
        setValue(newValue);
      }
      setError("");
    }
  };

  const createSignature = async () => {
    try {
      const chain = getChain(activeNetwork);
      const { feesInUsdc, preFinalOp } = await createSignatureAndCalculateFees(
        finalOpState,
        rootAccountInfo.address,
        extraTopupAmount,
        activeAccount.secret,
        hashedPassword,
        alchemy_url,
        chain
      );
      setFinalOpState(preFinalOp);

      setGasFeeInUSD(Number(feesInUsdc).toFixed(4));
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };
  const addExtraTopupAmount = async () => {
    try {
      if (!extraTopupAmount || Number(extraTopupAmount) <= 0) {
        setOpenExtraTopupModal(false);
        return;
      }
      setOpenExtraTopupModal(false);
      setLoading(true);

      await createSignature();

      await executeSendCrypto();
    } catch (error) {
      setLoading(false);
      showAlert(error);
    }
  };

  // test

  return (
    <>
      <BasicModal open={openModal} onClose={() => setOpenModal(false)}>
        <>
          <ModalHeader
            title="Select asset"
            onClose={() => setOpenModal(false)}
            showBackIcon
          />
          <AssetsView tokens={tokens} selectTokenHandler={selectTokenHandler} />
        </>
      </BasicModal>
      <ExtraTopupAmountPopup
        openExtraTopupModal={openExtraTopupModal}
        addExtraTopupAmount={addExtraTopupAmount}
        extraTopupAmount={extraTopupAmount}
        gasBalance={gas.totalBalance}
        usdcBalance={usdcBalance}
        handleTopupAmountChange={handleTopupAmountChange}
        minimumTopupAmount={usdcBalance - Number(value) - Number(gasFeeInUSD)}
        closeExtraTopupModal={() => {
          setOpenExtraTopupModal(false);
          setLoading(false);
        }}
      />

      <WarningModal
        close={() => setOpenWarningModal(false)}
        open={openWarningModal}
      />
      <Box mt={6}>
        <NavigatorHeading
          title="Send Crypto"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/crypto");
              }}
            />
          }
        />
      </Box>
      <Box mt={2}>
        <CustomizedSteppers
          step={step}
          steps={["Network", "Recipient", "Asset", "Amount", "Send"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />
      </Box>
      <Box mt={2}>
        {step == 0 && (
          <Grid container display="flex" justifyContent="center">
            <Grid
              item
              lg={6}
              sm={12}
              style={{
                flexBasis: "100%",
                maxWidth: "68%",
              }}
            >
              <NetworksList
                nextStep={() => setStep(1)}
                title="Select which network you want to send crypto on"
              />
            </Grid>
          </Grid>
        )}
        {/* required contact smart wallet address from step 1*/}
        {step == 1 && (
          <Box display={"flex"} flexDirection={"column"}>
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={12}
                sm={12}
                style={{
                  flexBasis: "100%",
                  maxWidth: "68%",
                }}
              >
                <TransactionContactList
                  shouldAddContact={false}
                  isTxForm
                  isChooseRecipient={true}
                  nextStep={(address: string, tag: string) => {
                    setStep(2);
                    setTo(address);
                    setTag(tag);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {/* required token object in redux name selectedToken from step 2 */}
        {step == 2 && (
          <Grid container display="flex" justifyContent="center">
            <Grid
              item
              lg={12}
              sm={12}
              style={{
                flexBasis: "100%",
                maxWidth: "68%",
              }}
            >
              <TokensListTable
                transactionForm={true}
                nextStep={() => setStep(3)}
                chainId={activeNetwork}
                isApplyFilter={false}
                isShowTokenAmountUnderName
              />
            </Grid>
          </Grid>
        )}
        {/* required token value to transfer from step 3 */}
        {step == 3 && (
          <Grid container display="flex" justifyContent="center">
            <Grid
              item
              lg={6}
              sm={12}
              display="flex"
              justifyContent="center"
              style={{
                flexBasis: "100%",
                maxWidth: "65%",
              }}
            >
              <TokenInputForm
                placeHolder={
                  Number(selectedToken[0]?.tokenBalance) &&
                  Number(selectedToken[0]?.tokenBalance) < 0.0001
                    ? "<0.0001"
                    : selectedToken[0]?.tokenBalance
                }
                title="Crypto Tag"
                addBorder
                balance={selectedToken[0]?.tokenBalance}
                type="number"
                onChange={handleAmountChange}
                onKeydown={handleKeyDown}
                value={String(value)}
                receiverENS={tag ? tag : to}
                isDepositValid={!!isValid}
                nextStep={() => setStep(4)}
                tokenName={tokenSymbol}
                tokenIcon={tokenIcon}
                errorMessage={error}
                decimals={selectedToken[0]?.tokenDecimal}
              />
            </Grid>
          </Grid>
        )}
        {/* review tx and get gas fees option from step 4 */}
        {step == 4 && (
          <Grid container display="flex" justifyContent="center" py={0}>
            <Grid
              item
              lg={6}
              sm={12}
              style={{
                flexBasis: "100%",
                maxWidth: "70%",
              }}
            >
              <SendAsset
                nextStep={() => console.log("end")}
                value={value}
                constantValue={`${value} ${selectedToken[0]?.tokenSymbol}`}
              />

              <Typography variant="body2" textAlign={"left"} mt={5} mb={-2}>
                Pay Fees With
              </Typography>

              <GasToken
                priceInUSD={gas.totalBalance}
                tokenBalance={gas.totalBalance}
                symbol="USDC"
                name="USDC"
              />

              <FeeUIComponent
                gasFeeInUSD={gasFeeInUSD}
                loading={!finalOpState && loading}
              />
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={50}
                  sx={{
                    borderRadius: "10px",

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <BasicButton
                  title={allowance ? "Send" : "Approve Paymaster"}
                  onClick={
                    error || !finalOpState
                      ? () => {
                          console.log("abc");
                        }
                      : handleSend
                  }
                  style={{
                    cursor: error || !finalOpState ? "not-allowed" : "pointer",
                    height: "15px",
                  }}
                  className="transfer-btn"
                  loading={loading}
                  mode={"active"}
                  id="send"
                />
              )}
            </Grid>
          </Grid>
        )}
      </Box>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Funds are not enough to use as gas fees"
      />
      <ConfirmPatternModal
        open={openPatternModal}
        onClose={() => {
          setOpenPatternModal(false);
        }}
        // error={error}
        // setError={setError}
        setIsPatternCorrect={setIsPatternCorrect}
      />
    </>
  );
};

export default Send;
