import { withRouter } from "hoc/withRouter";

import Welcome from "pages/auth/Welcome";
import { Route, Routes } from "react-router-dom";

import CreateAccount from "pages/auth/CreateFlow/CreateAccount";

import CreatePassword from "pages/auth/CreateFlow/CreatePassword";
import Pin from "pages/auth/CreateFlow/Pin";
import DownloadRecoveryKey from "pages/auth/CreateFlow/DownloadRecoveryKey";
import ReferralCode from "pages/auth/CreateFlow/ReferralCode";
import AccountCreated from "pages/auth/CreateFlow/AccountCreated";
import CashStashedTag from "pages/auth/CreateFlow/CashStashedTag";

import Signin from "pages/auth/SigninFlow/SignIn";
import SignInWithPassword from "pages/auth/SigninFlow/SigninWithPassword";
import SignInWithPin from "pages/auth/SigninFlow/SignInWithPin";
import SignInDownloadRecoveryKey from "pages/auth/SigninFlow/DownloadRecoveryKey";
import RecoverDownloadRecoveryKey from "pages/auth/RecoverFlow/DownloadRecoveryKey";

import Recover from "pages/auth/RecoverFlow/Recover";
import RecoverStashedTag from "pages/auth/RecoverFlow/UpdateSigninMethodFlow/RecoverStashedTag";
import UpdateSigninMethodFlowRecoverPassword from "pages/auth/RecoverFlow/UpdateSigninMethodFlow/RecoverPassword";
import UpdateSigninMethodFlowRecoverPin from "pages/auth/RecoverFlow/UpdateSigninMethodFlow/RecoverPin";
import UpdateSigninMethodFlowUploadRecoveryKey from "pages/auth/RecoverFlow/UpdateSigninMethodFlow/UploadRecoveryKey";
import UpdateSignInMethod from "pages/auth/RecoverFlow/UpdateSigninMethodFlow/UpdateSignInMethod";

import RecoverChooseMethod from "pages/auth/RecoverFlow/ForgotPasswordFlow/ChooseMethod";
import RecoverCreatePassword from "pages/auth/RecoverFlow/ForgotPasswordFlow/CreatePassword";
import ForgotPasswordFlowRecoverPin from "pages/auth/RecoverFlow/ForgotPasswordFlow/RecoverPin";
import ForgotPasswordFlowUploadRecoveryKey from "pages/auth/RecoverFlow/ForgotPasswordFlow/UploadRecoveryKey";

import ForgotPinFlowChooseMethod from "pages/auth/RecoverFlow/ForgotPinFlow/ChooseMethod";
import ForgotPinFlowPassword from "pages/auth/RecoverFlow/ForgotPinFlow/RecoverPassword";
import ForgotPinFlowRecoverCreatePin from "pages/auth/RecoverFlow/ForgotPinFlow/Pin";
import ForgotPinFlowUploadRecoveryKey from "pages/auth/RecoverFlow/ForgotPinFlow/UploadRecoveryKey";

import AccountRecovered from "pages/auth/RecoverFlow/AccountRecovered";
import ImportWallet from "pages/auth/CreateFlow/ImportWallet";
import TransactionSuccess from "components/TransactionFlow/TransactionSuccess";

const AuthFlowRoutes = () => {
  return (
    <div className="auth-container">
      <Routes>
        <Route path={"/"} element={<Welcome />} />
        <Route path={"/import-wallet"} element={<ImportWallet />} />

        <Route path={"/create-account"} element={<CreateAccount />} />

        <Route path={"/create-password"} element={<CreatePassword />} />
        <Route path={"/pin"} element={<Pin />} />
        <Route
          path={"/download-recovery-key"}
          element={<DownloadRecoveryKey />}
        />
        <Route path={"/referral-code"} element={<ReferralCode />} />
        <Route path={"/account-created"} element={<AccountCreated />} />

        <Route path={"/recover"} element={<Recover />} />
        <Route
          path={"/recover-download-recovery-key"}
          element={<RecoverDownloadRecoveryKey />}
        />

        <Route path={"/stashed-tag"} element={<CashStashedTag />} />

        <Route path={"/recover-stashed-tag"} element={<RecoverStashedTag />} />
        <Route path={"/account-recovered"} element={<AccountRecovered />} />

        {/* Sign in ->  Sign In Routes */}
        <Route path={"/signin"} element={<Signin />} />
        <Route
          path={"/signin-with-password"}
          element={<SignInWithPassword />}
        />
        <Route path={"/signin-with-pin"} element={<SignInWithPin />} />
        <Route
          path={"/signin-download-recovery-key"}
          element={<SignInDownloadRecoveryKey />}
        />

        {/* Recover -> Update Sign In Method Routes */}

        <Route
          path={"/update-signin-method/recover-password"}
          element={<UpdateSigninMethodFlowRecoverPassword />}
        />
        <Route
          path={"/update-signin-method/recover-pin"}
          element={<UpdateSigninMethodFlowRecoverPin />}
        />

        <Route
          path={"/update-signin-method/upload-recovery-key"}
          element={<UpdateSigninMethodFlowUploadRecoveryKey />}
        />
        <Route
          path={"/update-signin-method"}
          element={<UpdateSignInMethod />}
        />

        {/* Recover -> Forgot Password Routes */}

        <Route
          path={"/forgot-password/recover-choose-method"}
          element={<RecoverChooseMethod />}
        />
        <Route
          path={"/forgot-password/recover-create-password"}
          element={<RecoverCreatePassword />}
        />
        <Route
          path={"/forgot-password/recover-pin"}
          element={<ForgotPasswordFlowRecoverPin />}
        />
        <Route
          path={"/forgot-password/upload-recovery-key"}
          element={<ForgotPasswordFlowUploadRecoveryKey />}
        />

        {/* Recover -> Forgot Pin Routes */}

        <Route
          path={"/forgot-pin/recover-choose-method"}
          element={<ForgotPinFlowChooseMethod />}
        />
        <Route
          path={"/forgot-pin/password"}
          element={<ForgotPinFlowPassword />}
        />
        <Route
          path={"/forgot-pin/create-pin"}
          element={<ForgotPinFlowRecoverCreatePin />}
        />
        <Route
          path={"/forgot-pin/upload-recovery-key"}
          element={<ForgotPinFlowUploadRecoveryKey />}
        />

        <Route path={"*"} element={<Welcome />} />

        {/* Transactions*/}
      </Routes>
    </div>
  );
};

export default withRouter(AuthFlowRoutes);
