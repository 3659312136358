import { Box, Stack, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import AddApps from "assets/AddApps.svg";
import CustomLogo from "assets/morflax.svg";
import CustomDapp from "assets/CustomDapp.svg";
import NoImg from "assets/noImg.svg";
import DappConnectionModal from "./DappConnectionModal";
import TabChip from "./TabChip";
import { Caption, ConnectedDappBoxWrapper, MediumTitle } from "./Styled";
import { useAppDispatch, useAppSelector } from "store/store";
import { setOpenConnectionModal } from "@slices/walletSlice";

interface ConnectedDappBoxProps {
  name?: string;
  image?: string;
  description?: string;
  topic?: string;
  onDisconnect?: () => void;
}

const ConnectedDappBox: FC<ConnectedDappBoxProps> = (props) => {
  //   const { image, name, description } = props;

  console.log("props is ko check", props);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useAppDispatch();
  const openConnectionModal = useAppSelector(
    (state) => state.wallet.openConnectionModal
  );
  const setModalStates = (open) => {
    setOpenModal(open);
    dispatch(setOpenConnectionModal(open));
  };

  const ConnectionBox = () => (
    <>
      <Stack
        gap={1}
        direction="column"
        alignItems={"center"}
        justifyContent={"flex-start"}
        // paddingLeft={"25px"}
      >
        <img src={CustomLogo} style={{ width: "128px", height: "110px" }} />

        {/* <Stack
          gap={1}
          direction="column"
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ border: "1px solid red" }}
          // paddingLeft={"25px"}
        > */}
        <Typography
          color={"#1A1C20"}
          fontSize={"17px"}
          fontWeight={500}
          // height={"50px"}
        >
          {/* <MediumTitle variant={"body2"} color="#1A1C20"> */}
          Connect to Custom App
          {/* </MediumTitle> */}
        </Typography>
        <Box>
          <Typography
            color={"rgba(26, 28, 32, 0.5)"}
            fontSize={"14px"}
            fontWeight={400}
            // height={"80px"}
            textAlign={"center"}
          >
            Connect to any application
          </Typography>
          <Typography
            color={"rgba(26, 28, 32, 0.5)"}
            fontSize={"14px"}
            fontWeight={400}
            // height={"80px"}
            textAlign={"center"}
          >
            via wallet connect
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          width="100%"
          marginTop="20px"
        >
          <TabChip
            title=" + ADD APP "
            onClick={() => setModalStates(true)}
            mode={"chip"}
          />
        </Box>
      </Stack>
      <DappConnectionModal
        open={openModal}
        onClose={() => setModalStates(false)}
      />
    </>
  );
  return (
    <ConnectedDappBoxWrapper>
      {props?.topic ? (
        <Stack
          width={"100%"}
          height="100%"
          gap={2}
          direction="column"
          alignItems={"flex-start"}
          justifyContent={"center"}
          paddingLeft={"25px"}
          paddingRight={"25px"}
        >
          <img
            src={props?.image}
            style={{ width: "40px", height: "40px", borderRadius: "40px" }}
            onError={(e) => {
              e.currentTarget.src = NoImg;
            }}
          />

          <Typography
            color={"#1A1C20"}
            fontSize={"15px"}
            fontWeight={500}
            height={"50px"}
          >
            {/* <MediumTitle variant={"body2"} color="#1A1C20"> */}
            {props?.name || "Unnamed Dapp"}
            {/* </MediumTitle> */}
          </Typography>
          <Typography
            color={"rgba(26, 28, 32, 0.5)"}
            fontSize={"14px"}
            fontWeight={400}
            height={"80px"}
          >
            {props?.description || ""}
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"center"}
            width="100%"
          >
            <TabChip
              title="Disconnect"
              onClick={() => {
                if (props?.onDisconnect) props.onDisconnect();
              }}
              mode={"chip"}
              isShowStartIcon={false}
            />
          </Box>
          {/* <img
  src={AddApps}
  style={{ marginTop: "20px", marginBottom: "20px" }}
  onClick={() => setOpenModal(true)}
/> */}
        </Stack>
      ) : (
        <ConnectionBox />
      )}
    </ConnectedDappBoxWrapper>
  );
};

export default ConnectedDappBox;
