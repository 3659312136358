import Button from "components/NewButton";
import {
  Box,
  FormControl,
  Grid,
  Input,
  Snackbar,
  Typography,
} from "@mui/material";
import {
  CASH_SUPPORTED_NETWORK,
  SUPPORTED_NETWORKS,
  SupportedChainId,
} from "constants/chains";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { fetchBaseChainGasPrice } from "utils/gas";
import Web3 from "web3";

import abi from "abis/erc20abi.json";
import { APPROVE_AMOUNT, BASE_URL, USDT_URL } from "constants/";
import axios from "axios";
import {
  decryptMessage,
  fetchCashAccountNativeBalance,
  fetchUsdPriceFromMobula,
  getWallet,
  showAlert,
} from "utils/utils";

import { bridgeAbi } from "abis/bridgeABI";
import { Wallet, ethers, providers, utils } from "ethers";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import { useNavigate } from "react-router-dom";
import CustomizedSteppers from "components/Stepper";
import CashIcon from "assets/cash-token.svg";
import UsdcIcon from "assets/usdc.svg";
import MidArrow from "assets/midArrow.svg";

import NetworksList from "components/NetworksList";
import {
  setCashExchangeTxs,
  setCashPendingTx,
  setPendingTxDetails,
} from "@slices/appSlice";
import { getChain } from "../../../../utils/chains";
import {
  getMinimumTopupLimit,
  getUsdcBalance,
} from "../../../../utils/balance";
import { getApproveTokenCallData } from "stash-fe-script";
import { checkGasAllowance } from "../../../../utils/swap";
import { ExecuteCall } from "stash-fe-script/constants";
import { depositCalldata2 } from "../../../../utils/bridge";
import { isCashAccountDeployed } from "../../../../utils/deployed";
import { txSubmissionOrderPrepaid } from "../../../../contract-integration/prepaidGas";
import { sendUserOp } from "../../../../contract-integration";

const CashExchange = () => {
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState("");

  const [step, setStep] = useState(0);

  const [txCost, setTxCost] = useState("0");
  const [error, setError] = useState("");
  const [generalError, setGeneralError] = useState(false);

  const [allowance, setAllowance] = useState(0);
  const [minimumTopupAmount, setMinimumTopupAmount] = useState("0");
  const [extraTopupAmount, setExtraTopupAmount] = useState("0");
  const [openExtraTopupModal, setOpenExtraTopupModal] = useState(false);
  const [finalOpState, setFinalOpState] = useState<any>(null);
  const [gasFeeInUSD, setGasFeeInUSD] = useState("0");
  const [usdcBalance, setUsdcBalance] = useState(0);

  const [selectedAccount, setSelectedAccount] = useState({});

  const {
    rootAccountInfo,
    accounts,
    balances,
    activeNetwork,
    activeAccount,
    gas,
  } = useAppSelector((state) => state.app);

  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    accrossAddress,
    squidUsdcSupportedAddress,
    alchemy_url,
    usdcAddress,
    usdcDecimals,
    bundlerRPC,
    block_explorer_url,
    rpcUrl,
    factoryAddress,
  } = CASH_SUPPORTED_NETWORK[SupportedChainId.BASE];

  //change with sdk
  useEffect(() => {
    (async () => {
      const allowance = await checkGasAllowance(
        rootAccountInfo.smartAccountAddress,
        usdcAddress,
        SupportedChainId.BASE
      );
      const minimumTopUpLimit = await getMinimumTopupLimit(activeNetwork);
      const usdcBalance = await getUsdcBalance(
        rootAccountInfo.smartAccountAddress,
        SupportedChainId.BASE
      );
      console.log("usdcBalance", usdcBalance);
      setUsdcBalance(usdcBalance);
      setMinimumTopupAmount(minimumTopUpLimit);
      setAllowance(Number(allowance));
    })();
  }, [loading, allowance]);
  async function sendCryptoTransaction() {
    try {
      console.log("sendCryptoTransaction STARTED");
      const chain = getChain(SupportedChainId.BASE);
      const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);
      const provider = new ethers.providers.JsonRpcProvider(bundlerRPC);
      const wallet = new ethers.Wallet(pkey, provider);
      setLoading(true);

      if (!wallet) return;

      const web3 = new Web3(bundlerRPC);
      const nonce = await web3.eth.getTransactionCount(
        rootAccountInfo.smartAccountAddress,
        "latest"
      );

      let userCallDataArray: ExecuteCall[] = [];

      //remove
      const gasLimits = {
        approve: "59103",
        swapExactInput: "135300",
        swapExactOutput: "141660",
        deposit: "92452",
      };

      // if (ISNATIVE) {
      //   decimals = 18;
      // } else {

      // const contract = new web3.eth.Contract(
      //   //@ts-ignore
      //   abi.abi,
      //   squidUsdcSupportedAddress
      // );
      // let decimals = await contract.methods.decimals().call();
      // console.log("decimals OF BASE", decimals);

      // }

      const usdcBalance = await getUsdcBalance(
        rootAccountInfo.smartAccountAddress,
        SupportedChainId.BASE
      );
      console.log("usdcBalance OF BASE", usdcBalance);

      if (!usdcBalance && !gas.totalBalance) {
        setLoading(false);
        showAlert("You do not have USDC balance to top up gas tank.");
        return;
      }

      const approveCallData = getApproveTokenCallData(
        APPROVE_AMOUNT,

        chain,
        usdcAddress
      );

      console.log("allowance ====>", allowance);
      if (!allowance) {
        userCallDataArray.push(approveCallData);
      } else {
        console.log(
          "value",
          value,
          utils.parseUnits(value.toString(), usdcDecimals).toString()
        );
        const data = {
          recipient: activeAccount.smartAccountAddress,
          sender: rootAccountInfo.smartAccountAddress,
          originToken: usdcAddress,
          originTokenDecimals: Number(usdcDecimals),
          amount: utils.parseUnits(value.toString(), usdcDecimals).toString(), // incase of exactInput send the originToken value
          //why is it hardcoded
          destinationChainId: SupportedChainId.POLYGON,
          nonce,
        };
        const transactionOpts = {
          toAddress: squidUsdcSupportedAddress,
          gasPrice: 0,
        };

        console.log("depositCalldata", data, activeNetwork);

        const txns = await depositCalldata2(
          data,
          SupportedChainId.BASE,
          transactionOpts,
          gasLimits,
          usdcBalance * 10 ** usdcDecimals
        );
        // setLoading(false);
        // return;
        console.log("txns", txns);

        txns.map((tx) => {
          userCallDataArray.push({
            calldata: tx.data,
            to: tx.to,
            value: tx?.value,
          });
        });

        console.log(
          "----------userCallDataArray BEFORE ------------",
          userCallDataArray
        );

        console.log(
          "----------userCallDataArray IN BETWEEN ------------",
          userCallDataArray
        );
      }

      console.log("usdcDecimals", usdcDecimals);
      console.log("usdcBalance*usdcDecimals", usdcBalance * 10 ** usdcDecimals);
      console.log(
        "----------userCallDataArray AFTER ------------",
        userCallDataArray
      );

      const cashAccountDeployed = await isCashAccountDeployed(
        rootAccountInfo.smartAccountAddress
      );

      console.log("cashAccountDeployed", cashAccountDeployed);

      const { finalOp, usdcFee } = await txSubmissionOrderPrepaid({
        userCallDataArray,

        wallet,
        isAccountDeployed: cashAccountDeployed,
        chain,
        smartAccountAddress: rootAccountInfo.smartAccountAddress,
        provider,
        approval: allowance ? true : false,
        extraTopupAmount: "0",
        gasBalance: gas.totalBalance,
        rootAddress: rootAccountInfo.address.toLowerCase(),
      });

      console.log("Clicked!");

      console.log(
        "file: index.tsx:1058  sendCryptoTransactionfinal  finalOp:",
        finalOp
      );

      setFinalOpState(finalOp);
      setGasFeeInUSD(Number(usdcFee).toFixed(4));

      console.log("usdcFee", usdcFee, "gas.totalBalance", gas.totalBalance);
      setLoading(false);
      // if (Number(usdcFee) > gas.totalBalance) {
      //   setOpenExtraTopupModal(true);
      // } else {
      //   setLoading(false);
      // }
    } catch (error) {
      console.log("error in sendCryptoTx()", error?.message);

      setLoading(false);
      showAlert(error?.message || "Something went wrong");
    }
  }
  useEffect(() => {
    console.log("ye chala", step);
    if (step === 2) {
      sendCryptoTransaction();
    }
  }, [step]);
  const executeBridgeCrypto = async () => {
    try {
      setLoading(true);
      //send userOp
      if (finalOpState) {
        //replace via sendStackup or sendAlchemy  UserOp
        const response = await sendUserOp(finalOpState, bundlerRPC, bundlerRPC);
        const userOPResponse: any = await response.wait();
        console.log("userOp Hash :", response.userOpHash);
        console.log("Tx Hash :", userOPResponse?.transactionHash);
        console.log("success status :", userOPResponse?.args.success);

        dispatch(
          setPendingTxDetails({
            value: value,
            valueIn$: String(Number(value) * 1),
            transferAmount: value,
            transactionMethod: "Cash",
            scanLink: block_explorer_url,
            eoaEns: rootAccountInfo.name,
            addressEns: rootAccountInfo.name,
            toAddressEns: activeAccount.accountName,
            toAddress: activeAccount.smartAccountAddress,
            assetName: "USDC",
            networkFeesIn$: gasFeeInUSD,
            iconURL: USDT_URL,
            txByDesposited: false,
            action: allowance ? "Sent" : "Approved",
          })
        );
        dispatch(setCashPendingTx(userOPResponse?.transactionHash));

        showAlert(
          "Soon you can see your transaction in the transactions tab",
          allowance
            ? "Transaction Submitted"
            : "Approval Transaction Submitted",
          `<a href="https://basescan.org/tx/${userOPResponse?.transactionHash}" target="_blank">View on Basescan</a>`
        );
      }
      //reset OpState
      setFinalOpState(null);

      if (allowance) {
        navigate("/cash");
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      showAlert("Something went wrong");
    }
  };

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;

    if (/[^0-9.]/.test(inputValue)) {
      setError("Special characters are not allowed");
      setGeneralError(true);
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setError("Only one decimal point is allowed");
      setGeneralError(false);
    } else if (!/^\d{0,10}(\.\d{0,2})?$/.test(inputValue)) {
      setError(
        "Maximum of 10 digits before decimals and 2 digits after decimals are allowed"
      );
      setGeneralError(true);
    } else if (value && Number(value) > rootAccountInfo.usdTokenBalance) {
      setError("You do not have enough balance");
      setGeneralError(false);
      setValue(inputValue);
    } else {
      setError("");
      setGeneralError(false);
      setValue(inputValue);
    }
    // else if (parseFloat(inputValue) < 2.01) {
    //   setError("Value should not be less than $2.01");
    //   setGeneralError(false);
    //   setValue(inputValue);
    // }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      if (/[^0-9.]/.test(newValue)) {
        setError("Special characters are not allowed");
        setGeneralError(true);
      } else if (value && value > rootAccountInfo.usdTokenBalance) {
        setError("You do not have enough balance");
        setGeneralError(false);
        setValue(newValue);
      } else {
        setValue(newValue);
        setError("");
      }
      // else if (parseFloat(newValue) < 2.01) {
      //   setError("Value should not be less than $2.01");
      //   setGeneralError(false);
      //   setValue(newValue);
      // }
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (/[^0-9.]/.test(newValue)) {
        setError("Special characters are not allowed");
        setGeneralError(true);
      } else if (value && value > rootAccountInfo.usdTokenBalance) {
        setError("You do not have enough balance");
        setGeneralError(false);
        setValue(newValue);
      } else {
        if (Number(newValue) > 0) {
          setValue(newValue);
        }
        setError("");
      }
      // else if (parseFloat(newValue) < 2.01) {
      //   setError("Value should not be less than $2.01");
      //   setGeneralError(false);
      //   setValue(newValue);
      // }
    }
  };

  console.log(accounts, "accounts");
  return (
    <>
      {/* <Button title="Send" onClick={sendUsdc} loading={loading} /> */}

      <Box mt={6}>
        <NavigatorHeading
          title="Exchange Cash"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/cash");
              }}
            />
          }
        />
      </Box>
      <Box mt={6}>
        <CustomizedSteppers
          step={step}
          steps={["Network", "Amount", "Exchange"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />
        <Box mt={5}>
          {step == 0 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <NetworksList
                  nextStep={() => setStep(1)}
                  title="Select which network you want to receive crypto on"
                />
              </Grid>
            </Grid>
          )}

          {step == 1 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                display="flex"
                justifyContent="center"
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "rgba(26, 28, 32, 0.50)",
                        fontFamily: "Helvetica Neue",
                        fontsize: 17,
                        fontWeight: 500,
                      }}
                    >
                      Amount to receive USDC to {activeAccount.accountName}
                    </Typography>
                    <Box mt={2.5}>
                      <div className="input-container">
                        <FormControl sx={{ flex: 1 }}>
                          <Box
                            sx={{
                              color: "#1A1C20",
                              width: "100%",
                              // height: "80px",
                              borderRadius: "10px",
                              fontSize: "25px",
                              fontFamily: "Space Grotesk",
                              fontWeight: "700",
                              border: "0.5px solid rgba(26, 28, 32, 0.50)",
                            }}
                          >
                            <Input
                              onChange={handleAmountChange}
                              onKeyDown={handleKeyDown}
                              placeholder="0.00"
                              style={{
                                padding: "0px 15px 0px 15px",
                              }}
                              endAdornment={
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box mr={1.25}>
                                    <img
                                      src={UsdcIcon}
                                      style={{
                                        marginTop: "10px",
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      color: "#1A1C20",
                                      fontFamily: "Space Grotesk",
                                      fontSize: "18px",
                                      fontStyle: "normal",
                                      fontWeight: "600",
                                      lineHeight: "normal",
                                      marginTop: 0,
                                      marginLeft: -1.5,
                                    }}
                                  >
                                    USDC
                                  </Box>
                                </Box>
                              }
                              value={value}
                              sx={{ width: "100%", padding: "20px" }}
                            />
                          </Box>

                          {error && (
                            <Typography
                              style={{
                                fontSize: 12,
                                color: "red",
                                marginTop: 5,
                              }}
                            >
                              {error}
                            </Typography>
                          )}
                        </FormControl>
                      </div>
                    </Box>
                    <Box mt={5}>
                      <Button
                        title="Continue"
                        onClick={() => setStep(2)}
                        style={{
                          padding: "15px 0px",
                        }}
                        disabled={
                          !value ||
                          Number(value) > rootAccountInfo.usdTokenBalance ||
                          (!generalError && error)
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}

          {step == 2 && (
            <Grid container display="flex" justifyContent="center" py={4}>
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <Box mt={2.5} position={"relative"}>
                  <div className="input-container" style={{ marginBottom: 10 }}>
                    <FormControl sx={{ flex: 1 }}>
                      <Box
                        sx={{
                          backgroundColor: "#EDEEF2",
                          color: "#1A1C20",
                          width: "100%",
                          // height: "80px",
                          borderRadius: "10px",
                          fontSize: "25px",
                          fontFamily: "Space Grotesk",
                          fontWeight: "700",
                          border: "0.5px solid rgba(26, 28, 32, 0.50)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              paddingTop: 15,
                            }}
                          >
                            <Box mr={1.25}>
                              <img src={CashIcon} />
                            </Box>
                            <Box
                              sx={{
                                color: "#1A1C20",
                                fontFamily: "Space Grotesk",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "normal",
                                marginTop: -2,
                                marginLeft: -1.5,
                              }}
                            >
                              Cash
                            </Box>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginRight: 10,
                            }}
                          >
                            <Box
                              mr={1.25}
                              sx={{
                                color: "#1A1C20",
                                fontFamily: "Space Grotesk",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "normal",
                              }}
                            >
                              ${value}
                            </Box>
                            <Box
                              sx={{
                                color: "#8C8D8F",
                                fontFamily: "Space Grotesk",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }}
                            >
                              {value} USDbC
                            </Box>
                          </div>
                        </Box>
                      </Box>
                    </FormControl>
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: "40%",
                      left: "50%",
                      zIndex: 2,
                    }}
                  >
                    <img src={MidArrow} />
                  </div>

                  <div className="input-container" style={{ marginBottom: 10 }}>
                    <FormControl sx={{ flex: 1 }}>
                      <Box
                        sx={{
                          backgroundColor: "#EDEEF2",
                          color: "#1A1C20",
                          width: "100%",
                          // height: "80px",
                          borderRadius: "10px",
                          fontSize: "25px",
                          fontFamily: "Space Grotesk",
                          fontWeight: "700",
                          border: "0.5px solid rgba(26, 28, 32, 0.50)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              paddingTop: 15,
                            }}
                          >
                            <Box mr={1.25}>
                              <img src={UsdcIcon} />
                            </Box>
                            <Box
                              sx={{
                                color: "#1A1C20",
                                fontFamily: "Space Grotesk",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "normal",
                                marginTop: -2,
                                marginLeft: -1.5,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              USDC
                              <Typography
                                style={{ fontSize: 12, marginLeft: 5 }}
                              >
                                (
                                {
                                  SUPPORTED_NETWORKS[
                                    activeNetwork as keyof typeof SUPPORTED_NETWORKS
                                  ].name
                                }
                                )
                              </Typography>
                            </Box>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginRight: 10,
                            }}
                          >
                            <Box
                              mr={1.25}
                              sx={{
                                color: "#1A1C20",
                                fontFamily: "Space Grotesk",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "normal",
                              }}
                            >
                              ${value}
                            </Box>
                            <Box
                              sx={{
                                color: "#8C8D8F",
                                fontFamily: "Space Grotesk",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }}
                            >
                              {value} USDC
                            </Box>
                          </div>
                        </Box>
                      </Box>
                    </FormControl>
                  </div>
                </Box>
                <Typography
                  sx={{
                    color: "rgba(26, 28, 32, 0.50)",
                    fontFamily: "Helvetica Neue",
                    fontsize: 17,
                    fontWeight: 500,
                    textAlign: "left",
                    marginBottom: 4,
                    marginTop: 6,
                    textTransform: "uppercase",
                  }}
                >
                  Pay Fees with
                </Typography>
                <div className="input-container" style={{ marginBottom: 10 }}>
                  <FormControl sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        backgroundColor: "#EDEEF2",
                        color: "#1A1C20",
                        width: "100%",
                        // height: "80px",
                        borderRadius: "10px",
                        fontSize: "25px",
                        fontFamily: "Space Grotesk",
                        fontWeight: "700",
                        border: "0.5px solid rgba(26, 28, 32, 0.50)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: 15,
                          }}
                        >
                          <Box mr={1.25}>
                            <img src={CashIcon} />
                          </Box>
                          <Box
                            sx={{
                              color: "#1A1C20",
                              fontFamily: "Space Grotesk",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              marginTop: -2,
                              marginLeft: -1.5,
                            }}
                          >
                            Cash
                          </Box>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginRight: 10,
                          }}
                        >
                          <Box
                            mr={1.25}
                            sx={{
                              color: "#1A1C20",
                              fontFamily: "Space Grotesk",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                            }}
                          >
                            ${txCost}
                          </Box>
                        </div>
                      </Box>
                    </Box>
                  </FormControl>
                </div>
                <Typography
                  sx={{
                    color: "rgba(26, 28, 32, 0.50)",
                    fontFamily: "Helvetica Neue",
                    fontsize: 17,
                    fontWeight: 500,
                    textAlign: "center",
                    marginBottom: 4,
                    marginTop: 6,
                  }}
                >
                  Your crypto will show in your account within 9 minutes
                </Typography>
                <Button
                  title={!allowance ? "Approve" : "Send"}
                  onClick={executeBridgeCrypto}
                  loading={loading}
                  disabled={
                    Number(value) + Number(txCost) >
                    rootAccountInfo.usdTokenBalance
                  }
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CashExchange;
