import { useSnapshot } from "valtio";
import { useCallback, useEffect, useState } from "react";
import { Box, Modal as CustomModal } from "@mui/material";
import SessionSignTypedDataModal from "../views/SessionSignTypedDataModal";
import SessionUnsuportedMethodModal from "../views/SessionUnsupportedMethodModal";
import SessionRequestModal from "../views/SessionSignModal";
import SessionSendTransactionModal from "../views/SessionSendTransactionModal";
import SessionProposalModal from "../views/SessionProposalModal";
import ModalStore from "../walletConnectStore/ModalStore";
import WalletConnectPage from "./Walletconnect";
import { signClient, web3wallet } from "../utils/WalletConnectUtil";
import { styledToast } from "../utils/HelperUtil";
import { useAppSelector } from "store/store";
import { parseUri } from "@walletconnect/utils";
import CancelIcon from "assets/cancel.svg";

const DappConnectionModal = ({ open, onClose }) => {
  //   const { open, view } = useSnapshot(ModalStore.state);
  // handle the modal being closed by click outside
  const [uri, setUri] = useState("");
  const [loading, setLoading] = useState(false);

  const { connectedDapps } = useAppSelector((state) => state.app);

  async function onConnect(uri: string) {
    try {
      if (!web3wallet) {
        throw new Error("Wallet Connect is not initialized !");
      }
      const { topic: pairingTopic } = parseUri(uri);
      // if for some reason, the proposal is not received, we need to close the modal when the pairing expires (5mins)
      const pairingExpiredListener = ({ topic }: { topic: string }) => {
        if (pairingTopic === topic) {
          styledToast(
            "Pairing expired. Please try again with new Connection URI",
            "error"
          );
          ModalStore.close();
          web3wallet.core.pairing.events.removeListener(
            "pairing_expire",
            pairingExpiredListener
          );
        }
      };
      web3wallet.once("session_proposal", () => {
        web3wallet.core.pairing.events.removeListener(
          "pairing_expire",
          pairingExpiredListener
        );
      });

      setLoading(true);
      web3wallet.core.pairing.events.on(
        "pairing_expire",
        pairingExpiredListener
      );
      await web3wallet.pair({ uri });
    } catch (error) {
      styledToast("Invalid connection URI", "error");
      console.log("error in connection", error);
      ModalStore.close();
    } finally {
      setLoading(false);
      setUri("");
      onClose();
    }
  }

  // async function onConnect(uri: string) {
  //   try {
  //     setLoading(true);
  //     console.log("try started!!!!");
  //     const data = await signClient.core.pairing.pair({ uri });
  //     console.log(
  //       "🚀 ~ file: DappConnectionModal.tsx:24 ~ onConnect ~ data:",
  //       data
  //     );
  //     console.log("connectedDapps", connectedDapps);
  //     onClose();
  //   } catch (error) {
  //     console.log("catch started!!!!", error);
  //     styledToast((error as Error).message, "error");
  //   } finally {
  //     console.log("finally started!!!!");
  //     setUri("");
  //     setLoading(false);
  //     // styledToast("Invalid WC URL.", "error");
  //   }
  // }

  return (
    <CustomModal
      onClose={onClose}
      open={open}
      style={{
        border: "1px solid rgba(139, 139, 139, 0.4)",
        // backgroundColor: "red",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "390px",
          height: "250px",
          borderRadius: "4px",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          paddingTop: "30px",
        }}
      >
        <Box
          sx={{
            // border: "1px solid red",
            width: "100%",
            display: "flex",

            justifyContent: "flex-end",
          }}
        >
          <img
            src={CancelIcon}
            alt=""
            height={20}
            style={{ cursor: "pointer", marginRight: "30px" }}
            onClick={() => onClose()}
          />
        </Box>
        <WalletConnectPage
          {...{ uri, setUri, loading, setLoading, onConnect, onClose }}
        />
      </Box>
    </CustomModal>
  );
};

export default DappConnectionModal;
