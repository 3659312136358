import { Typography } from "@mui/material";
import { width } from "@mui/system";
import BasicModal from "components/BasicModal";
import Button from "components/NewButton";
import TextInput from "components/TextInput";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

interface ExtraTopupAmountPopupProps {
  open: boolean;

  close: () => void;
  setStep?: () => void;
}

const WarningModal: FC<ExtraTopupAmountPopupProps> = ({
  open,
  close,
  setStep,
}) => {
  const navigate = useNavigate();

  return (
    <BasicModal open={open} onClose={() => close()}>
      <>
        <Typography variant="h3" textAlign={"center"}>
          Warning
        </Typography>

        <Typography variant="h6" textAlign={"center"}>
          You are running low on USDC
        </Typography>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => close()}
            title="Ignore"
            style={{ width: "90%" }}
          />
          <div style={{ width: 20 }}></div>
          <Button
            onClick={() => navigate("/crypto/receive")}
            title="Receive"
            style={{ width: "90%" }}
          />
          <div style={{ width: 20 }}></div>

          <Button
            onClick={() => (setStep ? setStep() : navigate("/crypto/buy"))}
            title="Buy USDC"
            style={{ width: "90%" }}
          />
        </div>
      </>
    </BasicModal>
  );
};

export default WarningModal;
