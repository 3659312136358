import React, { useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";
import pbkdf2 from "pbkdf2";

import TextInput from "components/TextInput";
import Button from "components/NewButton";
import { useLocation, useNavigate } from "react-router-dom";
import AuthHeader from "components/AuthHeader";
import { useAppDispatch, useAppSelector } from "store/store";
import { setHashedPassword } from "@slices/walletSlice";
import { decryptMessage } from "utils/utils";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../../src/constants";
import StepperForPages from "components/Stepper/StepperForPages";

const RecoverPassword = () => {
  const [loading, setLoading] = useState(false);
  const [securePassword, setSecurePassword] = useState(true);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  const {
    fetchedKeys: { key2 },
    user: { email },
  } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (!email) {
      navigate("/recover");
    }
  }, []);

  const handleContinue = async () => {
    try {
      setLoading(true);

      const hashedPassword = pbkdf2
        .pbkdf2Sync(password, "salt", 1, 32, "sha512")
        .toString("hex");
      dispatch(setHashedPassword(hashedPassword));

      const decryptedKey = decryptMessage(key2, hashedPassword);

      if (decryptedKey) {
        setStep(2);
        navigate("/forgot-pin/upload-recovery-key", {
          state: {
            currentStep: step + 1,
            pathName: pathName,
          },
        });
      } else {
        setLoading(false);
        setError("Password is incorrect");
      }
    } catch (error) {
      setLoading(false);
      setError("Password is incorrect");

      console.log("err", error);
    }
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <StepperForPages
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={(selectedStep: number) => {
                //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
                if (selectedStep < step) {
                  setStep(selectedStep);
                  navigate(-1);
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "30px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Enter your password
        </Typography>
        <Typography
          variant="body2"
          sx={{
            margin: "0px 0px 10px 0px",
            color: "rgba(26, 28, 32, 0.5)",

            fontWeight: "500",
            textAlign: "center",
            width: "50%",
          }}
        >
          Your password is a combination of upper and lowercase letters, numbers
          and special characters.
        </Typography>

        <div className="welcome-box-container">
          <TextInput
            title="Password"
            value={password}
            onChange={setPassword}
            showSecure={() => setSecurePassword(!securePassword)}
            showEndIcon
            secure={securePassword}
            type={securePassword ? "password" : "text"}
            style={{
              width: "350px",
              fontfamily: "Helvetica Neue",
            }}
          />

          {error && (
            <div className="password-validator-box">
              <Typography
                style={{ color: "red", fontSize: 14, marginTop: -20 }}
              >
                {error}
              </Typography>
            </div>
          )}

          <Button
            onClick={handleContinue}
            title="Continue"
            loading={loading}
            style={{
              width: "380px",
              padding: "15px 0px",
              marginTop: "10px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
