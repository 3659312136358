import React, { useEffect, useState } from "react";
import WelcomeBox from "components/WelcomeBox";
import pbkdf2 from "pbkdf2";
import sss from "shamirs-secret-sharing";
import Google from "assets/google.svg";
import Discord from "assets/discord.svg";
import UpdateSignInImg from "assets/update-signin.png";

import { CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import AuthHeader from "components/AuthHeader";
import { useAppDispatch, useAppSelector } from "store/store";

import { init, loginWithGoogle } from "utils/google";
import {
  createEVMAccount,
  createEVMSmartAccount,
  decryptMessage,
  encryptMessage,
  fetchEncryptedKeys,
  getEnsFromAddress,
  initalizeWeb3,
  isUserExists,
  showAlert,
  updateEncryptedKeys,
  updateEncryptedKeysAndEmailBySignature,
} from "utils/utils";
import {
  removeAccounts,
  setAccesToken,
  setAccounts,
  setActiveAccount,
  setBlockNumbers,
  setEncryptedSeedPhraseWithPassword,
  setExpirationTime,
  setHoldings,
  setIsAccountCreated,
  setMode,
  setRootAccountInfo,
  setUserInfo,
} from "@slices/appSlice";
import { setPopupLoading, setTorusKey } from "@slices/walletSlice";
import { initalizeBlockNumbers } from "utils/blocknumber";
import { Accounts } from "interfaces";
import {
  LOGIN_EXPIRY,
  SHARES,
  THRESHOLD,
  stepsCount,
} from "../../../../../../src/constants";
import Swal from "sweetalert2";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import StepperForPages from "components/Stepper/StepperForPages";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import RPC from "constants/webRPC";

const UpdateSignInMethod = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [path, setPath] = useState("/update-signin-method");
  const [web3auth, setWeb3auth] = useState<Web3AuthNoModal | null>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  const {
    activeNetwork,
    user: { email: prevEmail },
    fetchedKeys: { key1, key2, key3, key4, rootAddress },
  } = useAppSelector((state) => state.app);

  const { hashedPin, recoveryCode, hashedPassword } = useAppSelector(
    (state) => state.wallet
  );

  useEffect(() => {
    if (!prevEmail) {
      navigate("/recover");
    }
    return () => {
      setError("");
    };
  }, []);

  useEffect(() => {
    (async () => {
      const web3auth = await init(path, "popup");
      if (web3auth) {
        web3auth.logout();
        setWeb3auth(web3auth);
      }
    })();
  }, []);

  const fromStringToBuffer = (val) => Buffer.from(val, "hex");

  const bufferToReadable = (buffer) => {
    return buffer.toString("hex");
  };

  const fetchAndSaveData = async (
    address: string,
    privateKey: string,
    newAccount: Accounts,
    counterfactual: string
  ) => {
    dispatch(setExpirationTime(new Date().getTime() + LOGIN_EXPIRY));

    dispatch(setAccounts(newAccount));
    dispatch(
      setActiveAccount({
        address,
        secret: privateKey,
        smartAccountAddress: counterfactual,
        accountName: newAccount[address]?.name,
      })
    );

    navigate("/recover-download-recovery-key", {
      state: { currentStep: step + 1, pathName: pathName, type: "Email" },
    });
  };

  const signinWithGoogle = async () => {
    try {
      if (web3auth) {
        setError("");
        setLoading(true);

        setLoading(true);
        await loginWithGoogle(web3auth);
        const res = await web3auth.getUserInfo();
        const rpc = new RPC(web3auth.provider!);
        console.log("res0000000000000000", res);
        const torusKey = await rpc.getAccounts();

        const { name, email, profileImage, idToken } = res;

        dispatch(setIsAccountCreated(false));
        dispatch(removeAccounts({}));
        dispatch(
          setActiveAccount({
            address: "",
            secret: "",
            smartAccountAddress: "",
          })
        );

        if (email) {
          if (email !== prevEmail) {
            const data = await fetchEncryptedKeys(idToken);
            if (data) {
              showAlert("Email already connected with different account");

              setLoading(false);
              dispatch(setPopupLoading(false));
              await web3auth.logout();
              return;
            }
          }
          dispatch(
            setUserInfo({
              email,
              name,
            })
          );

          dispatch(setAccesToken(idToken));

          const hexTorus = torusKey[0].toString("hex");

          const hashedTorus = pbkdf2
            .pbkdf2Sync(hexTorus, "salt", 1, 32, "sha512")
            .toString("hex");

          dispatch(setTorusKey(hashedTorus));

          const decryptedKey1 = decryptMessage(key2, hashedPassword);
          const decryptedKey2 = decryptMessage(key4, recoveryCode);
          const decryptedKey3 = decryptMessage(key3, hashedPin);

          console.log(decryptedKey1, decryptedKey2, decryptedKey3);
          const decryptMnemonic = sss
            .combine([
              fromStringToBuffer(decryptedKey1),
              fromStringToBuffer(decryptedKey2),
              fromStringToBuffer(decryptedKey3),
            ])
            .toString();

          console.log(decryptMnemonic);

          const secretBuffer = Buffer.from(decryptMnemonic);
          const generatedShares = sss.split(secretBuffer, {
            shares: SHARES,
            threshold: THRESHOLD,
          });

          let s = generatedShares.map((s) => bufferToReadable(s));

          const encryptedShare1 = encryptMessage(s[0], hashedTorus);
          const encryptedShare2 = encryptMessage(s[1], hashedPassword);
          const encryptedShare3 = encryptMessage(s[2], hashedPin);

          const updatedRecoveryCode = hashedTorus + hashedPassword + hashedPin;
          const encryptedShare4 = encryptMessage(s[3], updatedRecoveryCode);

          const encryptedSeedPhraseWithPassword = encryptMessage(
            decryptMnemonic,
            hashedPassword
          );

          const ens = await getEnsFromAddress(rootAddress?.toLowerCase());

          const { newAccount, address, secret, counterfactual } =
            await createEVMSmartAccount(
              decryptMnemonic,
              1,
              "",
              hashedPassword,
              activeNetwork
            );

          const web3 = initalizeWeb3(activeNetwork);

          const {
            address: derivedRootAddress,
            secret: rootSecret,
            counterfactual: rootSmartAccountAddress,
          } = await createEVMSmartAccount(
            decryptMnemonic,
            0,
            ens,
            hashedPassword,
            activeNetwork
          );

          const pkey = decryptMessage(rootSecret, hashedPassword);

          const signature = await web3.eth.accounts.sign(
            `${name}${email}`,
            pkey
          );

          await updateEncryptedKeysAndEmailBySignature(
            email,
            name,
            encryptedShare1,
            encryptedShare2,
            encryptedShare3,
            encryptedShare4,
            signature.signature,
            derivedRootAddress.toLowerCase(),
            idToken
          );

          dispatch(
            setEncryptedSeedPhraseWithPassword(encryptedSeedPhraseWithPassword)
          );

          dispatch(setMode("google"));
          if (rootAddress.toLowerCase() === derivedRootAddress.toLowerCase()) {
            dispatch(
              setRootAccountInfo({
                address: rootAddress,
                name: ens,
                secret: rootSecret,
                smartAccountAddress: rootSmartAccountAddress,
              })
            );
          }

          dispatch(setMode("google"));

          await fetchAndSaveData(address, secret, newAccount, counterfactual);

          setLoading(false);
          dispatch(setPopupLoading(false));
        }
      }
    } catch (error) {
      setLoading(false);
      dispatch(setPopupLoading(false));
    }
  };

  const signupWithDiscord = () => {
    showAlert("Coming Soon");
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <StepperForPages
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={(selectedStep: number) => {
                //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
                if (selectedStep < step) {
                  setStep(selectedStep);
                  navigate(-1);
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "0px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Update sign in method
        </Typography>

        <div className="welcome-box-container">
          {loading || !web3auth ? (
            <div
              style={{ width: "100%", textAlign: "center", margin: "20px 0px" }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <WelcomeBox
                title="Continue with Google"
                description=""
                onClick={signinWithGoogle}
                icon={<img src={Google} style={{ marginRight: 10 }} />}
              />
              {/* <WelcomeBox
                title="Continue with Discord"
                description=""
                onClick={signupWithDiscord}
                icon={<img src={Discord} style={{ marginRight: 10 }} />}
              /> */}
            </>
          )}
          {error && (
            <Typography style={{ color: "red", marginTop: "5%" }}>
              {error}
            </Typography>
          )}
        </div>
        {/* <div className="stashed-update-signin">
          <img src={UpdateSignInImg} />
        </div> */}
      </div>
    </div>
  );
};

export default UpdateSignInMethod;
