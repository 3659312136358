import { useSnapshot } from "valtio";
import { useCallback, useEffect, useState } from "react";
import { Modal as CustomModal } from "@mui/material";
import SessionSignTypedDataModal from "../views/SessionSignTypedDataModal";
import SessionUnsuportedMethodModal from "../views/SessionUnsupportedMethodModal";
import SessionRequestModal from "../views/SessionSignModal";
import SessionSendTransactionModal from "../views/SessionSendTransactionModal";
import SessionProposalModal from "../views/SessionProposalModal";
import ModalStore from "../walletConnectStore/ModalStore";
import SdkSendTransactionModal from "../views/SdkSendTransactionModal";
import SessionDisconnectModal from "../views/SessionDisconnectModal";
import { useAppSelector } from "../store/store";
import SendTransactionModalStore from "../walletConnectStore/SendTransactionModalStore";
import AuthRequestModal from "../views/AuthRequestModal";
import { rejectEIP155Request } from "utils/EIP155RequestHandlerUtil";
import useDynamicTitleAndFavicon from "hooks/useDynamicTitleAndFavIcon";
import { web3wallet } from "utils/WalletConnectUtil";
import { styledToast } from "utils/HelperUtil";
import { SUPPORTED_NETWORKS } from "constants/chains";
import { checkGasAllowance } from "utils/swap";
import { getApproveTokenCallData } from "stash-fe-script";
import { APPROVE_AMOUNT } from "constants";
import { getChain } from "utils/chains";
import {
  handleBlockaidResponse,
  handleBlockaidSimulationResponse,
} from "utils/blockaid";
import {
  decodeCalldataWithBlockaidSimuation,
  getDetailsForApprovalFromBlockaidSimulationResponse,
  showAlert,
} from "utils/utils";

enum TxType {
  PAYMASTER_APPROVAL = "Paymaster Approval",
  APPROVAL = "Approve",
  CONFIRM = "Confirm",
}

export default function TxModal() {
  const {
    activeAccount,
    rootAccountInfo,
    activeNetwork,
    userSpendingDetails,
    gas,
  } = useAppSelector((state) => state.app);

  const [loading, setLoading] = useState(false);
  const [allowance, setAllowance] = useState(0);
  const [signData, setSignData] = useState({});
  const [txType, setTxType] = useState("");
  const [error, setError] = useState(false);
  const [finalOpState, setFinalOpState] = useState<any>(null);
  const [risk, setRisk] = useState(false);
  const [riskData, setRiskData] = useState({ type: "", description: "" });
  const [decodedCalldata, setDecodedCalldata] = useState();
  const [gasFeeInUSD, setGasFeeInUSD] = useState("0");

  const {
    rpc,
    usdcAddress,
    usdcDecimals,
    alchemy_url,
    stackupUrl,
    block_explorer_url,
    wethAddress,
  } = SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const allValtioOdModalState = useSnapshot(ModalStore.state);
  const allValtioOdSendTransactionModalStore = useSnapshot(
    SendTransactionModalStore.state
  );
  const { txnQueue, viewST } = useSnapshot(SendTransactionModalStore.state);

  const [txnModalStepper, setTxnModalStepper] = useState(
    txnQueue.map((txn, i) => {
      if (i === 0) {
        return {
          ...txn,
          current: true,
        };
      } else {
        return {
          ...txn,
          current: false,
        };
      }
    })
  );

  useEffect(() => {
    console.log("men chala to update stepper");
    // if (txnQueue.length > 0) {
    setTxnModalStepper(
      txnQueue.map((txn, i) => {
        if (i === 0) {
          return {
            ...txn,
            current: true,
          };
        } else {
          return {
            ...txn,
            current: false,
          };
        }
      })
    );
    // }
  }, [viewST, txnQueue, txnQueue.length]);

  console.log("allValtio", {
    allValtioOdModalState,
    allValtioOdSendTransactionModalStore,
    txnModalStepper,
  });
  const { hashedPassword } = useAppSelector((state) => state.wallet);
  const { stopBlinkingTitle } = useDynamicTitleAndFavicon();

  async function onReject(requestEvent) {
    if (requestEvent) {
      const response = rejectEIP155Request(requestEvent);
      try {
        stopBlinkingTitle();
        await web3wallet.respondSessionRequest({
          topic: requestEvent?.topic,
          response,
        });
        setFinalOpState(null);
        setLoading(false);
        setDecodedCalldata(null);
        setTxType("");

        setAllowance(0);
      } catch (e) {
        styledToast((e as Error).message, "error");
        return;
      }
      SendTransactionModalStore.close(requestEvent.id);
    }
  }

  // handle the modal being closed by click outside
  const onClose = useCallback(() => {
    if (viewST) {
      console.log("looking in this", txnModalStepper);
      const reqIdToClose = txnModalStepper.filter((tx) => tx.current);
      console.log("reqIdToClose", reqIdToClose);
      if (reqIdToClose.length > 0) {
        // SendTransactionModalStore.close(reqIdToClose[0]?.reqId);
        onReject(reqIdToClose[0]?.data?.requestEvent);
      } else {
        console.log("CLOSE ALL EXECUTED!!!!!!!!");
        SendTransactionModalStore.closeAll();
      }
    }
  }, [viewST]);
  useEffect(() => {
    console.log("{ viewST }", { viewST });
  }, [viewST]);

  useEffect(() => {
    (async () => {
      try {
        const currTxn = txnModalStepper.filter((tx) => tx.current);
        if (currTxn.length) {
          const currRequestEvent = currTxn[0]?.data?.requestEvent;
          const chain = getChain(activeNetwork);

          if (finalOpState || !currRequestEvent) return;
          setLoading(true);

          const { params } = currRequestEvent;
          const { request } = params;
          const allowance = await checkGasAllowance(
            activeAccount.smartAccountAddress,
            usdcAddress,
            activeNetwork
          );
          setAllowance(Number(allowance));
          console.log("allowance -------------->", allowance);
          if (!Number(allowance)) {
            // if (true) {
            console.log("A PAYMASTER TXN", { allowance });

            const approveCallData = getApproveTokenCallData(
              APPROVE_AMOUNT,

              chain,
              usdcAddress
            );

            const blockAidSimulation = await handleBlockaidSimulationResponse(
              activeNetwork,
              activeAccount.smartAccountAddress,
              approveCallData
            );
            console.log("blockAidSimulation for PAYMASTER", blockAidSimulation);

            let approvalTxDetails =
              getDetailsForApprovalFromBlockaidSimulationResponse(
                blockAidSimulation
              );

            console.log("approvalTxDetails", approvalTxDetails);

            setDecodedCalldata(approvalTxDetails);
            setSignData(approvalTxDetails);
            setTxType(TxType.PAYMASTER_APPROVAL);
            setLoading(false);
            // setError(true);
            return;
          } else {
            console.log("NOT A PAYMASTER TXN", { allowance });
            const respD = await decodeCalldataWithBlockaidSimuation(
              currRequestEvent,
              activeNetwork,
              activeAccount.smartAccountAddress,
              wethAddress
            );

            console.log("respD", respD);

            if (respD?.srcToken || respD?.spender) {
              setDecodedCalldata(respD);
              setTxType(TxType.CONFIRM);
              if (respD?.spender) {
                setSignData(respD);
                setTxType(TxType.APPROVAL);
              }
            } else {
              setDecodedCalldata(false);
            }
          }
          const blockAidReponse = await handleBlockaidResponse(
            activeNetwork,
            activeAccount.smartAccountAddress,
            {
              to: request.params[0].to,
              value: request.params[0]?.value || "0",
              calldata: request.params[0].data,
            }
          );

          console.log("blockAidReponse", blockAidReponse);
          if (blockAidReponse?.validation?.result_type == "Benign") {
            setRisk(false);
          } else {
            setRiskData({
              type: blockAidReponse?.validation?.result_type,
              description: blockAidReponse?.validation?.description,
            });
            setRisk(true);
          }
        } else {
          console.log("No transaction to process!!");
        }
      } catch (e) {
        console.log("error in blockaid", e);
        setLoading(false);
        showAlert(e);
      }
    })();
  }, [txnModalStepper, allowance, txType]);

  const ModalBody = () => {
    if (
      viewST === "SessionSendTransactionModal" ||
      viewST === "SdkSendTransactionModal"
    ) {
      return (
        <>
          {txnModalStepper.filter((tx) => !tx.fromSdk).length > 0 &&
            txnModalStepper
              .filter((tx) => !tx.fromSdk)
              .filter((tx) => tx.current).length > 0 &&
            txType && (
              <SessionSendTransactionModal
                requestEvent={
                  txnModalStepper.filter((tx) => tx.current)[0].data
                    .requestEvent
                }
                requestSession={
                  txnModalStepper.filter((tx) => tx.current)[0].data
                    .requestSession
                }
                rest={{
                  loading,
                  setLoading,
                  allowance,
                  setAllowance,
                  signData,
                  setSignData,
                  txType,
                  setTxType,
                  error,
                  setError,
                  finalOpState,
                  setFinalOpState,
                  risk,
                  setRisk,
                  riskData,
                  setRiskData,
                  decodedCalldata,
                  setDecodedCalldata,
                  gasFeeInUSD,
                  setGasFeeInUSD,
                }}
              />
            )}
          {txnModalStepper.filter((tx) => tx.fromSdk).length > 0 &&
            txnModalStepper
              .filter((tx) => tx.fromSdk)
              .filter((tx) => tx.current).length > 0 && (
              <SdkSendTransactionModal
                payload={txnModalStepper.filter((tx) => tx.current)[0].data}
                reqId={txnModalStepper.filter((tx) => tx.current)[0].reqId}
              />
            )}
        </>
      );
    }
  };

  return (
    <CustomModal
      onClose={onClose}
      open={
        (viewST && hashedPassword.length > 0) ||
        (txnQueue.length > 0 && hashedPassword.length > 0)
      }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <ModalBody />
    </CustomModal>
  );
}
