import {
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Web3Wallet } from "@walletconnect/web3wallet";

import ConnectedDappBox from "components/ConnectedDappBox";
import { SearchBox } from "components/Styled";
import { useAppDispatch, useAppSelector } from "store/store";
import SearchIcon from "assets/SearchInputIcon.svg";
import { Core } from "@walletconnect/core";
import { getSdkError } from "@walletconnect/utils";
import SettingsStore from "../../../walletConnectStore/SettingsStore";
import { useSnapshot } from "valtio";
import ModalStore from "../../../walletConnectStore/ModalStore";
import { setConnectedDapps2, setFilteredDappsList } from "@slices/appSlice";
import { SocketContext } from "../../../socketContext";
import { ethers } from "ethers";
import { web3wallet } from "utils/WalletConnectUtil";

const DappConnection = () => {
  const { open, view } = useSnapshot(ModalStore.state);
  const { activeAccount, connectedDapps, connectedDapp2, dappsList } =
    useAppSelector((state) => state.app);
  const { sessions } = useSnapshot(SettingsStore.state);
  console.log("sessions ==================>", sessions);

  const dispatch = useAppDispatch();
  const [pairings, setPairings] = useState<any[]>([]);

  const [value, setValue] = useState("");
  // const [toggle, setToggle] = useState(false);

  const [filteredDapps, setFilteredDapps] = useState<any[]>([]);
  const { socket } = useContext(SocketContext);

  console.log("connectedDapp2", connectedDapp2);

  const updateDappStateForDisplay = async () => {
    // const core = new Core({
    let dappSessions = [...Object.values(sessions)];
    dappSessions = dappSessions.map((session) => {
      const { name, icons, url, description } = session.peer.metadata;
      return {
        name,
        icons: [icons[0]],
        description,
        url,
        topic: session?.topic,
      };
    });

    console.log("dappSessions", dappSessions);

    setFilteredDapps([...dappSessions, ...connectedDapp2]);
  };

  // useEffect(() => {
  //   // dispatch(setConnectedDapps2([]));
  //   dispatch(setFilteredDappsList([]));
  //   console.log("emptied!!!!");
  // }, []);
  useEffect(() => {
    console.log("connectedDapp2 changed!", connectedDapp2);
    updateDappStateForDisplay();
  }, [connectedDapp2, sessions]);

  // useEffect(() => {
  //   updateDappStateForDisplay();
  // }, [view, toggle, dappsList, connectedDapp2]);

  async function onDelete(dapp: any, topic: any) {
    // onDeleteSession(topic);
    // Assuming 'pairings' is an array of pairing objects available in your scope
    // and 'getSdkError' is a function that returns error messages
    ModalStore.open("SessionDisconnectModal", {
      dappDetails: {
        dappName: dapp?.name,
        dappIcon: dapp?.icons[0],
      },
    });

    console.log(
      "Object.values(web3wallet.getActiveSessions())",
      Object.values(web3wallet.getActiveSessions())
    );
    let allSessions = Object.values(web3wallet.getActiveSessions());
    if (topic) {
      allSessions = allSessions.filter((session) => session.topic !== topic);
    }
    console.log("allSessions", allSessions);
    SettingsStore.setSessions(allSessions);
    // Use the topic from the matching session in the disconnectSession call
    await web3wallet.disconnectSession({
      topic: topic,
      reason: getSdkError("USER_DISCONNECTED"),
    });

    setTimeout(() => {
      ModalStore.close();
    }, 2000);
    // Assuming you have a toggle state to trigger a re-render or update
    // setToggle((toggle) => !toggle);

    // console.log("filteredDapps1 just above above in delete", filteredDapps);
    // const filteredDapps1 = dappsList.filter((dapp) => dapp.topic !== topic);
    // console.log("filteredDapps1 just above in delete in comp", {
    //   filteredDapps1,
    //   topic,
    // });
    // dispatch(setFilteredDappsList(filteredDapps1));
  }

  // // Handle deletion of a session
  // const onDeleteSession = async (topic) => {
  //   // setDeleteLoading(true)
  //   try {
  //     await web3wallet.disconnectSession({
  //       topic,
  //       reason: getSdkError("USER_DISCONNECTED"),
  //     });
  //   } catch (e) {
  //     // styledToast((e as Error).message, 'error')
  //     console.log("eeror in deleting", e);
  //   }
  //   // setDeleteLoading(false)
  // };

  useEffect(() => {
    let dappSessions = [...Object.values(sessions)];
    dappSessions = dappSessions.map((session) => {
      const { name, icons, url, description } = session.peer.metadata;
      return {
        name,
        icons: [icons[0]],
        description,
        url,
        topic: session?.topic,
      };
    });
    // Filter dapps list based on value
    const searchResults = [...dappSessions, ...connectedDapp2].filter((dapp) =>
      dapp?.name?.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDapps(searchResults);
  }, [value, pairings]);
  console.log("filteredDapps", { filteredDapps, connectedDapp2, dappsList });
  return (
    <div className="main-view2">
      <Box
        sx={{
          // paddingTop: "5px",
          paddingBottom: "0px",
          paddingLeft: "60px",
          // paddingRight: "150px",
          display: "flex",
          justifyContent: "space-between",

          alignItems: "center",
          // border: "1px solid red",
        }}
      >
        <Typography
          variant="h5"
          color="#1A1C20"
          fontSize="25px"
          fontWeight={700}
          fontFamily={"Space Grotesk"}
        >
          Applications
        </Typography>
        <SearchBox
          sx={{ backgroundColor: "rgba(239, 243, 244, 1)" }}
          placeholder="Search by name or category"
          onChange={(e: { target: { value: string } }) => {
            setValue(e.target.value);
          }}
          value={value}
          startAdornment={
            <Grid
              sx={{
                minHeight: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={SearchIcon}
                style={{
                  // background: "red",
                  height: "18px",
                  width: "18px",
                }}
              />
            </Grid>
          }
        />
      </Box>

      <Grid container spacing={2} sx={{ padding: 5 }}>
        <Grid item xs={12} md={6} lg={4}>
          <ConnectedDappBox />
        </Grid>

        {filteredDapps.length > 0 &&
          filteredDapps.map((dapp) => (
            <Grid item xs={12} md={6} lg={4}>
              <ConnectedDappBox
                name={dapp?.name}
                image={dapp?.icons[0]}
                description={dapp?.description}
                topic={dapp?.topic}
                onDisconnect={() => {
                  console.log("abc", dapp);
                  if (dapp._id) {
                    console.log("delete this dapp =>", dapp);
                    socket?.emit("disconnect-from-wallet", {
                      dappId: dapp._id,
                      address: activeAccount.smartAccountAddress,
                    });
                  } else {
                    onDelete(dapp, dapp?.topic);
                  }
                }}
              />
            </Grid>
          ))}
      </Grid>
      {filteredDapps.length === 0 &&
        (Object.values(sessions).length !== 0 ||
          connectedDapp2.length !== 0) && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>No Result Found !</Typography>
          </Box>
        )}
    </div>
  );
};

export default DappConnection;
