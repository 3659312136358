import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../store/store";
import { numFormatter } from "../../../../../../utils/utils";
import { Grid } from "@mui/material";
import TokenImage from "../../../../../../components/TokenImage";
import DarkGas from "../../../../../../assets/darkGas.svg";

const ReviewAssetListComponent: FC<{
  //   asset: Asset;
  nextStep?: () => void;
  value: number;
  isGasComponent?: boolean;
  transactionForm?: boolean;
}> = ({ value, isGasComponent = false }) => {
  //   const navigate = useNavigate();

  const { activeNetwork, gas } = useAppSelector((state) => state.app);
  const { selectedToken } = useAppSelector((state) => state.wallet);
  console.log("file: index.tsx:89  Send  selectedToken:", selectedToken);
  console.log("file: index.tsx:17  activeNetwork:", activeNetwork);

  const { amount: usdBalance, symbol: usdSymbol } = numFormatter(
    value * selectedToken[0]?.tokenPrice,
    4
  );

  return (
    <Grid
      sx={{
        padding: "10px",
        display: "flex",
        width: "100%",
        alignSelf: "center",
        justifyContent: "space-between",
        borderRadius: "10px",
        backgroundColor: "#F7F7F7",
        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        {isGasComponent ? (
          <img
            src={DarkGas}
            alt="DarkGas"
            style={{
              width: "50px",
              height: "36px",
              objectFit: "contain",
            }}
          />
        ) : (
          <TokenImage logo={selectedToken[0]?.image} />
        )}

        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            textAlign: "left",
            alignItems: "center",
          }}
        >
          <div>{isGasComponent ? "Gas" : selectedToken[0]?.tokenName}</div>
          <div
            style={{
              fontFamily: "Space Grotesk",
              fontSize: "9px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
              marginTop: "2px",
              display: isGasComponent ? "none" : "block",
            }}
          >
            <span
              style={{
                color: "rgba(26, 28, 32, 0.50)",
              }}
            >
              {/* (Ethereum) */}
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Space Grotesk",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "normal",
            textAlign: "right",
          }}
        >
          <div>
            ${isGasComponent ? gas.totalBalance || 0 : usdBalance + usdSymbol}
          </div>
          <div
            style={{
              fontFamily: "Helvetica Neue",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              display: isGasComponent ? "none" : "block",
            }}
          >
            <span
              style={{
                color: "rgba(26, 28, 32, 0.50)",
              }}
            >
              {value} {selectedToken[0].tokenSymbol}
            </span>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ReviewAssetListComponent;
