import { useSnapshot } from "valtio";
import { useCallback, useEffect, useState } from "react";
import { Modal as CustomModal } from "@mui/material";
import SessionSignTypedDataModal from "../views/SessionSignTypedDataModal";
import SessionUnsuportedMethodModal from "../views/SessionUnsupportedMethodModal";
import SessionRequestModal from "../views/SessionSignModal";
import SessionSendTransactionModal from "../views/SessionSendTransactionModal";
import SessionProposalModal from "../views/SessionProposalModal";
import ModalStore from "../walletConnectStore/ModalStore";
import SdkSendTransactionModal from "../views/SdkSendTransactionModal";
import SessionDisconnectModal from "../views/SessionDisconnectModal";
import { useAppSelector } from "../store/store";
import SendTransactionModalStore from "../walletConnectStore/SendTransactionModalStore";
import AuthRequestModal from "../views/AuthRequestModal";

export default function Modal() {
  const { open, view } = useSnapshot(ModalStore.state);

  const { hashedPassword } = useAppSelector((state) => state.wallet);
  // handle the modal being closed by click outside
  const onClose = useCallback(() => {
    if (open) {
      ModalStore.close();
    }
  }, [open]);
  useEffect(() => {
    console.log("{ open, view }", { open, view });
  }, [open, view]);

  const ModalBody = () => {
    if (view === "SessionProposalModal") {
      return <SessionProposalModal />;
    } else if (view === "SessionSignModal") {
      return <SessionRequestModal />;
    } else if (view === "SessionSignTypedDataModal") {
      return <SessionSignTypedDataModal />;
    } else if (view === "AuthRequestModal") {
      return <AuthRequestModal />;
    } else if (view === "SessionUnsuportedMethodModal") {
      return <SessionUnsuportedMethodModal />;
    } else if (view === "SessionDisconnectModal") {
      return <SessionDisconnectModal />;
    }
  };

  return (
    <CustomModal
      onClose={onClose}
      open={open && hashedPassword.length > 0}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <ModalBody />
    </CustomModal>
  );
}
