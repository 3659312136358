import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import CenterItem from "../../../../../components/CenterItem";
import { getEnsFromAddress } from "utils/utils";

const ReferredFriendList = ({
  name,
  address,
  nextStep = (address: string, tag: string) => {},
  isChooseRecipient = false,
}: {
  name: string;
  address: string;
  nextStep?: (address: string, tag: string) => void;
  isChooseRecipient?: boolean;
  isCashMode?: boolean;
}) => {
  const [ens, setEns] = useState("");

  useEffect(() => {
    (async () => {
      const ens = await getEnsFromAddress(address.toLowerCase());
      setEns(ens);
    })();
  }, [address]);
  function getFirstLetters(input: string): string {
    const words = input.split(" "); // Split the input string into words
    const firstTwoWords = words.slice(0, 2); // Take the first two words
    const initials = firstTwoWords.map((word) => word.charAt(0)); // Get the first letter of each word
    return initials.join(""); // Join the first letters together
  }

  console.log(name, address);

  return (
    <Box>
      <Grid
        sx={{
          padding: "10px",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          borderRadius: "10px",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          backgroundColor: !isChooseRecipient ? "transparent" : "white",
          "&:hover": {
            backgroundColor: "lightgray",
          },
        }}
        onClick={() => {
          nextStep(address, name);
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <CenterItem>
            <Typography
              width={30}
              padding={1}
              textAlign="center"
              alignContent="center"
              color={"#8C8D8F"}
              style={{
                borderRadius: "100%",
                objectFit: "cover",
                background: "#8C8D8F33",
              }}
            >
              {getFirstLetters(name)}
            </Typography>
          </CenterItem>
          <Grid
            sx={{
              color: "#1A1C20",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              fontFamily: "Space Grotesk",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            <div
              style={{
                // color: "rgba(26, 28, 32, 0.50)",
                fontFamily: "Helvetica Neue",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              <span
                style={{
                  marginLeft: "5px",
                  color: "#1A1C20",
                  fontFamily: " Space Grotesk",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: " 700",
                  lineHeight: " normal",
                }}
              >
                {ens}
              </span>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReferredFriendList;
