// import { COSMOS_SIGNING_METHODS } from '@/data/COSMOSData'
// import { EIP155_SIGNING_METHODS } from '@/data/EIP155Data'
import { EIP155_SIGNING_METHODS } from "../data/EIP155Data";

// import { SOLANA_SIGNING_METHODS } from '@/data/SolanaData'
// import { POLKADOT_SIGNING_METHODS } from '@/data/PolkadotData'
// import { MULTIVERSX_SIGNING_METHODS } from '@/data/MultiversxData'
// import { TRON_SIGNING_METHODS } from '@/data/TronData'
// import ModalStore from '@/store/ModalStore'
import ModalStore from "../walletConnectStore/ModalStore";

// import SettingsStore from '@/store/SettingsStore'
import SettingsStore from "../walletConnectStore/SettingsStore";

import { useSnapshot } from "valtio";
// import { signClient } from '@/utils/WalletConnectUtil'
import { signClient, web3wallet } from "../utils/WalletConnectUtil";

import { Web3Wallet, Web3WalletTypes } from "@walletconnect/web3wallet";
import { getSdkError } from "@walletconnect/utils";
import { SignClientTypes } from "@walletconnect/types";
import { Core } from "@walletconnect/core";
import { SetStateAction, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/store";
import { setFilteredDappsList } from "@slices/appSlice";
import SendTransactionModalStore from "../walletConnectStore/SendTransactionModalStore";
import SignModalStore from "../walletConnectStore/SignModalStore";
// import { NEAR_SIGNING_METHODS } from '@/data/NEARData'
// import { approveNearRequest } from '@/utils/NearRequestHandlerUtil'
// import { TEZOS_SIGNING_METHODS } from '@/data/TezosData'
// import { KADENA_SIGNING_METHODS } from '@/data/KadenaData'

export default function useWalletConnectEventsManager(initialized: boolean) {
  const { hashedPassword } = useAppSelector((state) => state.wallet);
  const { isInitialized } = useAppSelector((state) => state.app);
  // const { txnQueue } = useSnapshot(SendTransactionModalStore.state);
  /******************************************************************************
   * 1. Open session proposal modal for confirmation / rejection
   *****************************************************************************/
  const onSessionProposal = useCallback(
    (proposal: SignClientTypes.EventArguments["session_proposal"]) => {
      console.log("onSessionProposal --> ", "SessionProposalModal", {
        proposal,
      });
      // set the verify context so it can be displayed in the projectInfoCard
      SettingsStore.setCurrentRequestVerifyContext(proposal.verifyContext);
      ModalStore.open("SessionProposalModal", { proposal });
    },
    []
  );

  /******************************************************************************
   * 2. Open Auth modal for confirmation / rejection
   *****************************************************************************/
  const onAuthRequest = useCallback((request: Web3WalletTypes.AuthRequest) => {
    ModalStore.open("AuthRequestModal", { request });
  }, []);

  /******************************************************************************
   * 3. Open request handling modal based on method that was used
   *****************************************************************************/
  const onSessionRequest = useCallback(
    async (requestEvent: SignClientTypes.EventArguments["session_request"]) => {
      console.log("session_request --->", requestEvent);

      const { topic, params, verifyContext } = requestEvent;
      console.log("mark1");
      const { request } = params;
      console.log("mark2");
      // const requestSession = signClient.session.get(topic);
      const requestSession = web3wallet.engine.signClient.session.get(topic);
      console.log("mark3");

      // set the verify context so it can be displayed in the projectInfoCard
      SettingsStore.setCurrentRequestVerifyContext(verifyContext);

      console.log("is se lg kr set horha hai allvaltio", request.method);
      switch (request.method) {
        case EIP155_SIGNING_METHODS.ETH_SIGN:
        case EIP155_SIGNING_METHODS.PERSONAL_SIGN:
          return SignModalStore.open("SessionSignModal", {
            requestEvent,
            requestSession,
          });

        case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA:
        case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V3:
        case EIP155_SIGNING_METHODS.ETH_SIGN_TYPED_DATA_V4:
          return ModalStore.open("SessionSignTypedDataModal", {
            requestEvent,
            requestSession,
          });

        case EIP155_SIGNING_METHODS.ETH_SEND_TRANSACTION:
        case EIP155_SIGNING_METHODS.ETH_SIGN_TRANSACTION:
          return SendTransactionModalStore.open("SessionSendTransactionModal", {
            requestEvent,
            requestSession,
          });

        default:
          return ModalStore.open("SessionUnsuportedMethodModal", {
            requestEvent,
            requestSession,
          });
      }
    },
    []
  );
  // const { open, view } = useSnapshot(ModalStore.state);
  // const { dappsList } = useAppSelector((state) => state.app);
  // const dispatch = useAppDispatch();
  // const [pairings, setPairings] = useState<any[]>([]);
  // const [toggle, setToggle] = useState(false);
  // const [filteredDapps, setFilteredDapps] = useState<any[]>([]);

  // useEffect(() => {
  //   (async () => {
  //     // const core = new Core({
  //     const dapps = [...dappsList];
  //     const sortedDappsList = dapps.sort((a, b) => b.timeStamp - a.timeStamp);

  //     const uniqueDapps: SetStateAction<any[]> = [];
  //     const seenNames = new Set();

  //     sortedDappsList.forEach((dapp) => {
  //       if (!seenNames.has(dapp.name)) {
  //         uniqueDapps.push(dapp);
  //         seenNames.add(dapp.name);
  //       }
  //     });

  //     setFilteredDapps(uniqueDapps);
  //   })();
  // }, [view, toggle, dappsList]);

  // async function onDelete(topic: any) {
  //   // Assuming 'pairings' is an array of pairing objects available in your scope
  //   // and 'getSdkError' is a function that returns error messages
  //   ModalStore.open("SessionDisconnectModal", {
  //     payload: {},
  //   });
  //   const core = new Core({
  //     projectId: "812f6740ea51715efe85aa7929220db1",
  //     // projectId: "02bca0404e214292df5b3fd124b82bca",
  //   });

  //   const web3wallet = await Web3Wallet.init({
  //     core, // pass the shared `core` instance
  //     metadata: {
  //       name: "Demo app",
  //       description: "Demo Client as Wallet/Peer",
  //       url: "www.walletconnect.com",
  //       icons: [],
  //     },
  //   });

  //   // Find the matching pairing
  //   const matchingPairing = pairings.find((pair) => pair.topic === topic);
  //   // if (!matchingPairing) {
  //   //   console.error("No matching pairing found");
  //   //   return;
  //   // }

  //   const nameToMatch = matchingPairing?.peerMetadata.name;

  //   // Filter out all pairings with the same name
  //   const pairingsToDelete = pairings.filter(
  //     (pair) => pair.peerMetadata?.name === nameToMatch
  //   );

  //   // Iterate over each pairing to disconnect and remove
  //   for (const pairing of pairingsToDelete) {
  //     await web3wallet.disconnectSession({
  //       topic: pairing.topic,
  //       reason: getSdkError("USER_DISCONNECTED"),
  //     });

  //     // Assuming setPairings is a function to update the pairings state
  //     setPairings((prevPairings) =>
  //       prevPairings.filter((p) => p.topic !== pairing.topic)
  //     );
  //   }

  //   // Optionally, log the remaining pairings
  //   const updatedPairings = await web3wallet.core.pairing.getPairings();
  //   console.log("Remaining pairings:", updatedPairings);
  //   const sessions = web3wallet.engine.signClient.session.values;
  //   const matchingSession = sessions.find(
  //     (session) => session.pairingTopic === topic
  //   );
  //   if (!matchingSession) {
  //     console.error("No matching session found for the provided pairingTopic");
  //     return;
  //   }

  //   // Use the topic from the matching session in the disconnectSession call
  //   await web3wallet.disconnectSession({
  //     topic: matchingSession.topic,
  //     reason: getSdkError("USER_DISCONNECTED"),
  //   });
  //   // Assuming you have a toggle state to trigger a re-render or update
  //   setToggle((toggle) => !toggle);
  //   console.log("filteredDapps1 just above above in delete", filteredDapps);
  //   const filteredDapps1 = filteredDapps.filter((dapp) => dapp.topic !== topic);
  //   console.log("filteredDapps1 just above in delete", {
  //     filteredDapps1,
  //     topic,
  //   });
  //   dispatch(setFilteredDappsList(filteredDapps1));
  // }

  /******************************************************************************
   * Set up WalletConnect event listeners
   *****************************************************************************/
  useEffect(() => {
    console.log(
      "🚀 ~ file: useWalletConnectEventsManager.ts:142 ~ useEffect ~ initialized:",
      initialized
    );
    if (initialized && hashedPassword.length > 0 && web3wallet) {
      // signClient.on("session_proposal", onSessionProposal);
      // signClient.on("session_request", onSessionRequest);
      //sign
      web3wallet.on("session_proposal", onSessionProposal);
      web3wallet.on("session_request", onSessionRequest);
      // auth
      web3wallet.on("auth_request", onAuthRequest);
      // TODOs
      // signClient.on("session_ping", (data) => console.log("ping", data));
      // signClient.on("session_event", (data) => console.log("event", data));
      // signClient.on("session_update", (data) => console.log("update", data));
      // signClient.on("session_delete", (data) => {
      //   console.log("delete", data);
      //   onDelete(data?.topic);
      // });
      web3wallet.engine.signClient.events.on("session_ping", (data) =>
        console.log("ping", data)
      );
      web3wallet.on("session_delete", async (data) => {
        console.log("session_delete event received", data);
        console.log(
          "Object.values(web3wallet.getActiveSessions())",
          Object.values(web3wallet.getActiveSessions())
        );
        let allSessions = Object.values(web3wallet.getActiveSessions());
        if (data?.topic) {
          allSessions = allSessions.filter(
            (session) => session.topic !== data.topic
          );
        }
        console.log("allSessions", allSessions);
        SettingsStore.setSessions(allSessions);
        await web3wallet.disconnectSession({
          topic: data?.topic,
          reason: getSdkError("USER_DISCONNECTED"),
        });
      });
      // load sessions on init
      SettingsStore.setSessions(Object.values(web3wallet.getActiveSessions()));
    }
  }, [
    initialized,
    onAuthRequest,
    onSessionProposal,
    onSessionRequest,
    hashedPassword,
    isInitialized,
  ]);
}
