import { CurrencyAmount, Percent, Token, TradeType } from "@uniswap/sdk-core";
import {
  AlphaRouter,
  SwapType,
  nativeOnChain,
} from "@uniswap/smart-order-router";
import {
  CASH_SUPPORTED_NETWORK,
  SUPPORTED_NETWORKS,
  SupportedChainId,
} from "constants/chains";
import {
  Contract,
  providers,
  Wallet,
  utils,
  BigNumberish,
  BytesLike,
  BigNumber,
} from "ethers";
import Web3 from "web3";
import abi from "abis/erc20abi.json";
import axios, { AxiosError } from "axios";
import { BASE_URL, NATIVE_ADDRESS, ZERO_ADDRESS } from "constants/";
import { bridgeAbi } from "abis/bridgeABI";
import { getAssetsDollarWorth } from "./portfolio";
import { fetchCashAccountNativeBalance } from "./utils";

let CURRENT_GAS_PRICE: string;
const SQUID_V1_BASE_URL: string = "https://api.0xsquid.com/v1";

export async function depositCalldata1(
  data: any,
  chainId: number,
  transactionOpts,
  gasLimits,
  currUsdcBalance: number
): Promise<any[]> {
  try {
    const {
      accrossUsdcSupportedAddress,
      rpc,
      squidUsdcSupportedAddress,
      wethAddress,
      usdcDecimals,
    } = SUPPORTED_NETWORKS[chainId as keyof typeof SUPPORTED_NETWORKS];

    let txns = [];

    const ISTRANSFER_TOKEN_NATIVE =
      data.originToken == ZERO_ADDRESS ||
      data.originToken == wethAddress ||
      data.originToken == NATIVE_ADDRESS;

    if (ISTRANSFER_TOKEN_NATIVE) data.originToken = NATIVE_ADDRESS;

    // console.log("originToken", data.originToken, accrossUsdcSupportedAddress);

    // if origintoken != usdc swap
    // if (
    //   data.originToken.toLowerCase() !==
    //   accrossUsdcSupportedAddress.toLowerCase()
    // ) {
    //   const output = await AcrossExactInputCalldata(
    //     data,
    //     transactionOpts,
    //     chainId,
    //     gasLimits
    //   );

    //   console.log("output", output);

    //   txns = output.txns;
    //   //update data.amount
    //   data.amount = output.amountOut;
    //   // update origin token
    //   data.originToken = accrossUsdcSupportedAddress;
    //   console.log("men chala naya");
    // }

    // if bridge does not have enough usdc allowance get it

    // console.log("UNISWAP KA APPPPPPPPPPPPPPPPPPPP", ISTRANSFER_TOKEN_NATIVE);
    console.log("data values", data);
    if (!ISTRANSFER_TOKEN_NATIVE) {
      const web3 = new Web3(rpc);
      //@ts-ignore
      const originToken = new web3.eth.Contract(abi.abi, data.originToken);

      const allowance = await originToken.methods
        .allowance(data.sender, "0xce16F69375520ab01377ce7B88f5BA8C48F8D666")
        .call();

      console.log("allowance", allowance);

      console.log("ye pehle approval k params hain", {
        origintoken: data.originToken,
        spender: "0xce16F69375520ab01377ce7B88f5BA8C48F8D666",
        // "0xce16F69375520ab01377ce7B88f5BA8C48F8D666",
        amount: data.amount,
        gasprice: transactionOpts.gasPrice,
        gaslimit: gasLimits.approve,
        nonce: data.nonce,
      });
      if (BigInt(allowance.toString()) < BigInt(data.amount)) {
        const txn = await getSwapRouter02ApprovalTransaction(
          data.originToken,
          // squidUsdcSupportedAddress,
          "0xce16F69375520ab01377ce7B88f5BA8C48F8D666",
          data.amount,
          transactionOpts.gasPrice,
          gasLimits.approve,
          data.nonce
        );
        console.log("txn for approval", txn);
        txns.push(txn);

        // const transactionResponse1 = await wallet.sendTransaction(txn);

        // console.log('Bridge Approval Transaction Sent:', await transactionResponse1.wait());
      }
    }

    // const { data: bridgeData } = await axios.post(
    //   BASE_URL + "/transaction/getBridgeData",
    //   {
    //     originToken: data.originToken,
    //     // originToken: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    //     chainId: chainId,
    //     destinationChainId: data.destinationChainId,
    //     amount: +data.amount,
    //   }
    // );

    // console.log(result.data?.relayFeePct, result.data?.timestamp)

    const calldata = await prepareSquidCalldata(
      chainId,
      data?.destinationChainId,
      data.originToken,
      "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
      data?.amount,
      data?.sender,
      data?.recipient,
      "1"
    );
    console.log("calldata from prepareSquid method", calldata);

    // txns.push(calldata);

    console.log("final txns value", txns);
    const rawTransaction = {
      to: calldata.to,
      value: calldata.value,
      data: calldata.data,
      gasPrice: transactionOpts.gasPrice,
      gasLimit: gasLimits.deposit,
      nonce: data.nonce,
    };

    txns.push(rawTransaction);

    let requiredMatic = Number(calldata.value) / 10 ** 18;
    const tokenData = await getAssetsDollarWorth([wethAddress]);
    const maticPriceInDollar = tokenData[wethAddress.toLowerCase()]?.price;
    let requiredMaticInUsd = requiredMatic * maticPriceInDollar;
    let usdcAmountToSwapForGasFees = Math.round(
      requiredMaticInUsd * 10 ** usdcDecimals
    );
    let usdcAmountToSwapForGasFeesIncreased =
      usdcAmountToSwapForGasFees +
      (usdcAmountToSwapForGasFees -
        Math.round(usdcAmountToSwapForGasFees * 0.9));
    console.log("conversion", {
      requiredMatic,
      tokenData,
      maticPriceInDollar,
      requiredMaticInUsd,
      usdcAmountToSwapForGasFees,
      usdcAmountToSwapForGasFeesIncreased,
    });

    if (currUsdcBalance < usdcAmountToSwapForGasFeesIncreased) {
      throw new Error("Not Enough Usdc Holding to pay for gas fees");
    }
    // let requireMaticInUsd = requiredMatic*

    const dataForGasFeesSwap = {
      originToken: wethAddress,
      originTokenDecimals: 18,
      sender: data.sender,
      amount: usdcAmountToSwapForGasFeesIncreased,
      nonce: data.nonce,
      payable: false,
    };

    const response = await GetCalldataForSwap(
      dataForGasFeesSwap,
      transactionOpts,
      chainId,
      gasLimits
    );

    console.log("response of GetCalldataForSwap", response);

    txns.unshift(...response?.txns);

    console.log("Transactions final array", txns);
    // console.log(txns);
    // throw "stopped!";
    return txns;
  } catch (error) {
    console.log("error in deposit calldata", error);
    // @ts-ignore
    throw error;
  }
}

export async function depositCalldata2(
  data: any,
  chainId: number,
  transactionOpts,
  gasLimits,
  currUsdcBalance: number
): Promise<any[]> {
  try {
    const { rpc, squidUsdcSupportedAddress, usdcDecimals, alchemy_url } =
      CASH_SUPPORTED_NETWORK[SupportedChainId.BASE];

    let txns = [];

    // const ISTRANSFER_TOKEN_NATIVE =
    //   data.originToken == ZERO_ADDRESS || data.originToken == wethAddress;

    // if (ISTRANSFER_TOKEN_NATIVE) data.originToken = NATIVE_ADDRESS;

    // console.log("originToken", data.originToken, accrossUsdcSupportedAddress);

    // if origintoken != usdc swap
    // if (
    //   data.originToken.toLowerCase() !==
    //   accrossUsdcSupportedAddress.toLowerCase()
    // ) {
    //   const output = await AcrossExactInputCalldata(
    //     data,
    //     transactionOpts,
    //     chainId,
    //     gasLimits
    //   );

    //   console.log("output", output);

    //   txns = output.txns;
    //   //update data.amount
    //   data.amount = output.amountOut;
    //   // update origin token
    //   data.originToken = accrossUsdcSupportedAddress;
    //   console.log("men chala naya");
    // }

    // if bridge does not have enough usdc allowance get it

    // console.log("UNISWAP KA APPPPPPPPPPPPPPPPPPPP", ISTRANSFER_TOKEN_NATIVE);
    console.log("data values", data);
    // if (data.originToken != ISTRANSFER_TOKEN_NATIVE) {
    const web3 = new Web3(alchemy_url);
    //@ts-ignore
    const originToken = new web3.eth.Contract(abi.abi, data.originToken);

    const allowance = await originToken.methods
      .allowance(data.sender, squidUsdcSupportedAddress)
      .call();

    console.log("allowance", allowance, "data.amount", data.amount);

    if (BigInt(allowance.toString()) < BigInt(data.amount)) {
      const txn = await getSwapRouter02ApprovalTransaction(
        data.originToken,
        squidUsdcSupportedAddress,
        // "0xce16F69375520ab01377ce7B88f5BA8C48F8D666",
        data.amount,
        transactionOpts.gasPrice,
        gasLimits.approve,
        data.nonce
      );
      console.log("txn for approval", txn);
      txns.push(txn);
    }
    // }

    const calldata = await prepareSquidCalldata(
      chainId,
      data?.destinationChainId,
      data.originToken,
      "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
      data?.amount,
      data?.sender,
      data?.recipient,
      "1"
    );
    console.log("calldata from prepareSquid method", calldata);

    // txns.push(calldata);

    console.log("final txns value", txns);
    const rawTransaction = {
      to: calldata.to,
      value: calldata.value,
      data: calldata.data,
      gasPrice: transactionOpts.gasPrice,
      gasLimit: gasLimits.deposit,
      nonce: data.nonce,
    };

    txns.push(rawTransaction);

    let requiredEth = Number(calldata.value) / 10 ** 18;
    // const tokenData = await getAssetsDollarWorth([NATIVE_ADDRESS]);
    const natBal = await fetchCashAccountNativeBalance(data.sender, chainId);
    console.log("natBal", natBal);
    const ethPriceInDollar = natBal?.nativeTokenPrice;
    let requiredEthInUsd = requiredEth * ethPriceInDollar;
    let usdcAmountToSwapForGasFees = Math.round(
      requiredEthInUsd * 10 ** usdcDecimals
    );
    let usdcAmountToSwapForGasFeesIncreased =
      usdcAmountToSwapForGasFees +
      (usdcAmountToSwapForGasFees -
        Math.round(usdcAmountToSwapForGasFees * 0.9));
    console.log("conversion", {
      requiredEth,
      // tokenData,
      ethPriceInDollar,
      requiredEthInUsd,
      usdcAmountToSwapForGasFees,
      usdcAmountToSwapForGasFeesIncreased,
    });

    if (currUsdcBalance < usdcAmountToSwapForGasFeesIncreased) {
      throw new Error("Not Enough Usdc Holding to pay for gas fees");
    }
    // let requireMaticInUsd = requiredMatic*

    // const dataForGasFeesSwap = {
    //   originToken: NATIVE_ADDRESS,
    //   originTokenDecimals: 18,
    //   sender: data.sender,
    //   amount: usdcAmountToSwapForGasFeesIncreased,
    //   nonce: data.nonce,
    //   payable: false,
    // };

    // const response = await GetCalldataForSwap(
    //   dataForGasFeesSwap,
    //   transactionOpts,
    //   chainId,
    //   gasLimits
    // );

    // console.log("response of GetCalldataForSwap", response);

    // txns.unshift(...response?.txns);

    // console.log("Transactions final array", txns);

    return txns;
  } catch (error) {
    console.log("error in deposit calldata", error);
    // @ts-ignore
    throw error;
  }
}

export async function depositCalldata(
  data,
  transactionOpts,
  chainId: number,
  gasLimits
): Promise<any[]> {
  try {
    const { accrossUsdcSupportedAddress, rpc, accrossAddress, wethAddress } =
      SUPPORTED_NETWORKS[chainId as keyof typeof SUPPORTED_NETWORKS];

    let txns = [];

    const ISTRANSFER_TOKEN_NATIVE =
      data.originToken == ZERO_ADDRESS || data.originToken == wethAddress;

    console.log("originToken", data.originToken, accrossUsdcSupportedAddress);

    // if origintoken != usdc swap
    if (
      data.originToken.toLowerCase() !==
      accrossUsdcSupportedAddress.toLowerCase()
    ) {
      const output = await AcrossExactInputCalldata(
        data,
        transactionOpts,
        chainId,
        gasLimits
      );

      console.log("output", output);

      txns = output.txns;
      //update data.amount
      data.amount = output.amountOut;
      // update origin token
      data.originToken = accrossUsdcSupportedAddress;
      console.log("men chala naya");
    }

    // if bridge does not have enough usdc allowance get it

    // console.log("UNISWAP KA APPPPPPPPPPPPPPPPPPPP", ISTRANSFER_TOKEN_NATIVE);

    if (data.originToken != ISTRANSFER_TOKEN_NATIVE) {
      const web3 = new Web3(rpc);
      //@ts-ignore
      const originToken = new web3.eth.Contract(abi.abi, data.originToken);

      const allowance = await originToken.methods
        .allowance(data.sender, accrossAddress)
        .call();

      console.log("allowance", allowance);

      if (BigInt(allowance.toString()) < BigInt(data.amount)) {
        const txn = await getSwapRouter02ApprovalTransaction(
          data.originToken,
          accrossAddress,
          data.amount,
          transactionOpts.gasPrice,
          gasLimits.approve,
          data.nonce
        );

        txns.push(txn);

        // const transactionResponse1 = await wallet.sendTransaction(txn);

        // console.log('Bridge Approval Transaction Sent:', await transactionResponse1.wait());
      }
    }

    const { data: bridgeData } = await axios.post(
      BASE_URL + "/transaction/getBridgeData",
      {
        originToken: data.originToken,
        // originToken: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
        chainId: chainId,
        destinationChainId: data.destinationChainId,
        amount: +data.amount,
      }
    );

    // console.log(result.data?.relayFeePct, result.data?.timestamp)

    const calldata = prepareAccrossCalldata(
      data.recipient,
      data.originToken,
      data.amount,
      data.destinationChainId,
      bridgeData?.relayFeePct,
      bridgeData?.timestamp,
      data.message,
      data.maxCount
    );

    const rawTransaction = {
      to: transactionOpts.toAddress,
      value: (0).toString(),
      data: calldata,
      gasPrice: transactionOpts.gasPrice,
      gasLimit: gasLimits.deposit,
      nonce: data.nonce,
    };

    txns.push(rawTransaction);

    console.log(txns);

    return txns;
  } catch (error) {
    console.log("error in deposit calldata", error);
    // @ts-ignore
    throw error;
  }
}

async function getAlphaRouterResponse(
  inputTokenAddress: string,
  inputTokenDecimals: number,
  outputTokenAddress: string,
  outputTokenDecimals: number,
  recipient: string,
  amount: string,
  tradeType: TradeType,
  swapType: SwapType,
  chainId: number
) {
  console.log("params of alpharouter in bridge", {
    inputTokenAddress,
    inputTokenDecimals,
    outputTokenAddress,
    outputTokenDecimals,
    recipient,
    amount,
    tradeType,
    swapType,
    chainId,
  });
  // TODO: change it to env rpc
  const isBase = chainId === SupportedChainId.BASE;
  const { alchemy_url, wethAddress, bundlerRPC } = isBase
    ? CASH_SUPPORTED_NETWORK[SupportedChainId.BASE]
    : SUPPORTED_NETWORKS[chainId as keyof typeof SUPPORTED_NETWORKS];
  const provider = new providers.JsonRpcProvider(
    isBase ? bundlerRPC : alchemy_url
  );
  const alphaRouter = new AlphaRouter({ chainId: chainId, provider: provider });

  console.log("inputTokenAddress", inputTokenAddress, inputTokenDecimals);
  const inputToken = new Token(chainId, inputTokenAddress, inputTokenDecimals);
  console.log("inputToken", inputToken);
  const outputToken =
    outputTokenAddress.toLowerCase() === wethAddress.toLowerCase()
      ? nativeOnChain(chainId)
      : new Token(chainId, outputTokenAddress, outputTokenDecimals);

  console.log("outputToken", outputToken);

  console.log("outputToken", outputTokenAddress, outputTokenDecimals);

  const inputAmountCurrency = CurrencyAmount.fromRawAmount(inputToken, amount);
  console.log("router method params", {
    inputAmountCurrency,
    outputToken,
    tradeType,

    recipient, // address of the wallet to receive the output token
    slippageTolerance: new Percent(40, 100),
    deadline: Math.floor(Date.now() / 1000 + 31560000), // fail transaction if it can't be mined in respective time
    type: swapType, // use Uniswap V3 Router 2 to match expected calldata format
  });
  const response = await alphaRouter.route(
    inputAmountCurrency,
    outputToken,
    tradeType,
    {
      recipient, // address of the wallet to receive the output token
      slippageTolerance: new Percent(10, 100),
      deadline: Math.floor(Date.now() / 1000 + 31560000), // fail transaction if it can't be mined in respective time
      type: swapType, // use Uniswap V3 Router 2 to match expected calldata format
    }
  );
  console.info("got alpharouter response for profit case:", response);
  if (!response || !response.methodParameters) {
    throw new Error(
      "Uniswap alpha router could not find valid route for profit case"
    );
  }
  return response;
}

export async function GetCalldataForSwap(
  data,
  transactionOpts,
  chain: number,
  gasLimits
): Promise<{ amountOut: string; txns: [] }> {
  console.log("params of GetCalldataForSwap", {
    data,
    transactionOpts,
    chain,
    gasLimits,
  });
  let txns: [] = [];

  const { squidUsdcSupportedAddress, usdcDecimals, alchemy_url, swapRouter02 } =
    chain === SupportedChainId.BASE
      ? CASH_SUPPORTED_NETWORK[SupportedChainId.BASE]
      : SUPPORTED_NETWORKS[chain as keyof typeof SUPPORTED_NETWORKS];

  // const { rpc, squidUsdcSupportedAddress } =
  // CASH_SUPPORTED_NETWORK[SupportedChainId.BASE];

  const response = await getAlphaRouterResponse(
    squidUsdcSupportedAddress,
    usdcDecimals,
    data.originToken,
    data.originTokenDecimals,
    data.sender,
    data.amount,
    TradeType.EXACT_INPUT,
    SwapType.SWAP_ROUTER_02,
    chain
  );

  const provider = new providers.JsonRpcProvider(alchemy_url);
  const web3 = new Web3(alchemy_url);

  CURRENT_GAS_PRICE = (await provider.getGasPrice()).toString();
  // console.log(CURRENT_GAS_PRICE)
  // const amountOut = response.trade.outputAmount.numerator.toString();

  const amountOut = response.trade.outputAmount.numerator.toString();
  console.log("amountOut :: ", {
    amountOut,
    amountOutActual: response.trade.outputAmount.numerator.toString(),
    response,
  });

  const ISTRANSFER_TOKEN_NATIVE =
    data.originToken == "0x0000000000000000000000000000000000000000" ||
    data.originToken == "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";

  // if(chain === SupportedChainId.BASE)
  // if (data.originToken != ISTRANSFER_TOKEN_NATIVE) {
  //@ts-ignore
  // const originToken = new web3.eth.Contract(abi.abi, data.originToken);

  // console.log(
  //   "AcrossExactInputCalldata",
  //   !data.payable,
  //   BigInt(
  //     (
  //       await originToken.methods.allowance(data.sender, swapRouter02).call()
  //     ).toString()
  //   ),
  //   BigInt(data.amount)
  // );
  // console.log(
  //   "cndition for approval tx",
  //   !data.payable &&
  //     BigInt(
  //       (
  //         await originToken.methods.allowance(data.sender, swapRouter02).call()
  //       ).toString()
  //     ) < BigInt(data.amount)
  // );
  // if (
  //   !data.payable &&
  //   BigInt(
  //     (
  //       await originToken.methods.allowance(data.sender, swapRouter02).call()
  //     ).toString()
  //   ) < BigInt(data.amount)
  // ) {
  // if swapRouter02 has enough allowance don't bother getting approval

  console.log("ye dusre approval k params hain", {
    squidUsdcSupportedAddress,
    swapRouter02,
    amount: data.amount,
    gasprice: transactionOpts.gasPrice,
    gaslimit: gasLimits.approve,
    nonce: data.nonce,
  });

  const txn = await getSwapRouter02ApprovalTransaction(
    // data.originToken,
    squidUsdcSupportedAddress,
    swapRouter02,
    data.amount,
    transactionOpts.gasPrice,
    gasLimits.approve,
    data.nonce
  );

  console.log("ye swap ki txn bani inside if", txn);
  txns.push(txn);
  // }
  // }

  const rawTransaction = {
    to: swapRouter02,
    value: data.payable ? data.amount : (0).toString(),
    data: response.methodParameters?.calldata!,
    gasPrice: (transactionOpts.gasPrice == 0
      ? CURRENT_GAS_PRICE
      : transactionOpts.gasPrice
    ).toString(),
    gasLimit: gasLimits.swapExactInput,
    nonce: data.nonce,
  };
  console.log("ye swap ki txn bani rawTransaction", rawTransaction);
  txns.push(rawTransaction);

  return { amountOut, txns };
}

export async function AcrossExactInputCalldata(
  data,
  transactionOpts,
  chain: number,
  gasLimits
): Promise<{ amountOut: string; txns: [] }> {
  let txns: [] = [];

  const { accrossUsdcSupportedAddress, usdcDecimals, rpc, swapRouter02 } =
    SUPPORTED_NETWORKS[chain as keyof typeof SUPPORTED_NETWORKS];

  const response = await getAlphaRouterResponse(
    data.originToken,
    data.originTokenDecimals,
    accrossUsdcSupportedAddress,
    usdcDecimals,
    data.sender,
    data.amount,
    TradeType.EXACT_INPUT,
    SwapType.SWAP_ROUTER_02,
    chain
  );

  const provider = new providers.JsonRpcProvider(rpc);
  const web3 = new Web3(rpc);

  CURRENT_GAS_PRICE = (await provider.getGasPrice()).toString();
  // console.log(CURRENT_GAS_PRICE)
  // const amountOut = response.trade.outputAmount.numerator.toString();

  const amountOut = response.trade.outputAmount.numerator.toString();
  console.log("amountOut :: ", {
    amountOut,
    amountOutActual: response.trade.outputAmount.numerator.toString(),
    response,
  });

  const ISTRANSFER_TOKEN_NATIVE =
    data.originToken == "0x0000000000000000000000000000000000000000" ||
    data.originToken == "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270";

  // if (data.originToken != ISTRANSFER_TOKEN_NATIVE) {
  //@ts-ignore
  const originToken = new web3.eth.Contract(abi.abi, data.originToken);

  console.log(
    "AcrossExactInputCalldata",
    !data.payable,
    BigInt(
      (
        await originToken.methods.allowance(data.sender, swapRouter02).call()
      ).toString()
    ),
    BigInt(data.amount)
  );

  if (
    !data.payable &&
    BigInt(
      (
        await originToken.methods.allowance(data.sender, swapRouter02).call()
      ).toString()
    ) < BigInt(data.amount)
  ) {
    // if swapRouter02 has enough allowance don't bother getting approval
    const txn = await getSwapRouter02ApprovalTransaction(
      data.originToken,
      swapRouter02,
      data.amount,
      transactionOpts.gasPrice,
      gasLimits.approve,
      data.nonce
    );

    console.log("ye swap ki txn bani inside if", txn);
    txns.push(txn);
    // }
  }

  const rawTransaction = {
    to: swapRouter02,
    value: data.payable ? data.amount : (0).toString(),
    data: response.methodParameters?.calldata!,
    gasPrice: (transactionOpts.gasPrice == 0
      ? CURRENT_GAS_PRICE
      : transactionOpts.gasPrice
    ).toString(),
    gasLimit: gasLimits.swapExactInput,
    nonce: data.nonce,
  };
  console.log("ye swap ki txn bani rawTransaction", rawTransaction);
  txns.push(rawTransaction);

  return { amountOut, txns };
}

export async function getSwapRouter02ApprovalTransaction(
  tokenIn: string,
  spender: string,
  amount: string,
  gasPrice: number | BigNumberish,
  gasLimit: number | BigNumberish,
  nonce: number
) {
  const _erc20Abi = new utils.Interface(abi.abi);
  const approvalCallData = _erc20Abi.encodeFunctionData("approve", [
    spender,
    amount,
  ]);

  console.log("gasPrice", gasPrice, CURRENT_GAS_PRICE);

  const rawTransaction = {
    to: tokenIn,
    value: (0).toString(),
    data: approvalCallData,
    // gasPrice: (gasPrice == 0 ? CURRENT_GAS_PRICE : gasPrice).toString(),
    gasLimit,
    nonce,
  };

  return rawTransaction;
}

export function prepareAccrossCalldata(
  recipient: string,
  originToken: string,
  amount: string,
  destinationChainId: string,
  relayerFeePct: string,
  quoteTimestamp: string,
  message: string,
  maxCount: string
) {
  //FUNCTION TYPE: deposit (address recipient, address originToken, uint256 amount, uint256 destinationChainId, int64 relayerFeePct, uint32 quoteTimestamp, bytes message, uint256, maxCount)
  const bridge = new utils.Interface(bridgeAbi);
  const encodedData = bridge.encodeFunctionData("deposit", [
    recipient,
    originToken,
    amount,
    destinationChainId,
    relayerFeePct,
    quoteTimestamp,
    message,
    maxCount,
  ]);
  return encodedData;
}

export async function prepareSquidCalldata(
  fromChainId: number,
  toChainId: number,
  fromTokenAddress: string,
  toTokenAddress: string,
  fromAmount: string,
  sender: string,
  recipient: string,
  slippage: string
) {
  try {
    const { data: route } = await axios.get(
      SQUID_V1_BASE_URL +
        `/route?fromChain=${fromChainId}&toChain=${toChainId}&fromToken=${fromTokenAddress}&toToken=${toTokenAddress}&fromAmount=${fromAmount}&fromAddress=${sender}&toAddress=${recipient}&slippage=${slippage}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-integrator-id": "stashed-api",
        },
      }
    );

    console.log("DATA of SQUID API", route);
    console.log(
      "BigNumber.from(route?.route?.transactionRequest?.value)",
      route?.route?.transactionRequest?.value
    );

    return {
      data: route?.route?.transactionRequest?.data,
      to: route?.route?.transactionRequest?.targetAddress,
      value: BigNumber.from(route?.route?.transactionRequest?.value),
      rawValue: route?.route?.transactionRequest?.value,
      gasLimit: route?.route?.transactionRequest?.gasLimit,
      maxFeePerGas: route?.route?.transactionRequest?.maxFeePerGas,
      maxPriorityFeePerGas:
        route?.route?.transactionRequest?.maxPriorityFeePerGas,
    };
    // return route?.route?.transactionRequest?.data;
  } catch (e) {
    console.log("error in prepareSquidData", e);
    if (
      e?.response?.data?.errors.length &&
      e?.response?.data?.errors[0]?.message
    ) {
      throw new Error(`${e?.response?.data?.errors[0]?.message}`);
    }

    throw "Unable to fetch swap route";
  }
}
