import React, { useEffect, useState } from "react";
import "./index.css";
import {
  Box,
  FormControl,
  Grid,
  Input,
  Skeleton,
  Typography,
} from "@mui/material";
import BasicButton from "components/Button";
import { useAppDispatch, useAppSelector } from "store/store";
import { CASH_SUPPORTED_NETWORK, SupportedChainId } from "constants/chains";
import { decryptMessage, showAlert } from "utils/utils";
import { APPROVE_AMOUNT, USDT_URL } from "constants/";
import { useNavigate } from "react-router-dom";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import Button from "components/NewButton";
import CashIcon from "assets/cash-token.svg";
import MidArrow from "assets/midArrow.svg";
import TransactionContactList from "components/TransactionContactList";
import { checkGasAllowance } from "utils/swap";
import { getMinimumTopupLimit, getUsdcBalance } from "utils/balance";
import { getChain } from "utils/chains";
import { ethers } from "ethers";
import {
  ExecuteCall,
  Token__factory,
  sendUserOp,
} from "../../../../contract-integration";
import { getApproveTokenCallData } from "stash-fe-script";
import { txSubmissionOrderPrepaid } from "../../../../contract-integration/prepaidGas";
import { setCashPendingTx, setPendingTxDetails } from "@slices/appSlice";
import GasToken from "components/GasToken";
import FeeUIComponent from "components/SendTxComponent/FeeUIComponent";
import ExtraTopupAmountPopup from "components/ExtraTopupAmountPopup";
import { isCashAccountDeployed } from "utils/deployed";
import { createSignatureAndCalculateFees } from "utils/signature";

const CashSend = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [to, setTo] = useState("");
  const [error, setError] = useState("");
  const [tag, setTag] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [step, setStep] = useState(0);
  const [allowance, setAllowance] = useState(0);
  const [usdcBalance, setUsdcBalance] = useState(0);
  const [minimumTopupAmount, setMinimumTopupAmount] = useState("0");
  const [extraTopupAmount, setExtraTopupAmount] = useState("0");
  const [openExtraTopupModal, setOpenExtraTopupModal] = useState(false);
  const [finalOpState, setFinalOpState] = useState<any>(null);
  const [gasFeeInUSD, setGasFeeInUSD] = useState("0");

  const navigate = useNavigate();
  const { activeAccount, activeNetwork, rootAccountInfo, gas } = useAppSelector(
    (state) => state.app
  );
  console.log(
    "file: index.tsx:104  Send  activeAccount:",
    activeAccount,
    rootAccountInfo
  );

  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const {
    block_explorer_url,
    usdcAddress,
    stackupUrl,
    bundlerRPC,
    usdcDecimals,
    rpcUrl,
  } = CASH_SUPPORTED_NETWORK[SupportedChainId.BASE];

  useEffect(() => {
    if (step === 2 && to && value) {
      sendCashTransaction();
    }
  }, [step, minimumTopupAmount, allowance]);

  useEffect(() => {
    (async () => {
      const allowance = await checkGasAllowance(
        rootAccountInfo.smartAccountAddress,
        usdcAddress,
        SupportedChainId.BASE
      );
      const minimumTopUpLimit = await getMinimumTopupLimit(activeNetwork);
      const usdcBalance = await getUsdcBalance(
        rootAccountInfo.smartAccountAddress,
        SupportedChainId.BASE
      );
      console.log("usdcBalance", usdcBalance);
      setUsdcBalance(usdcBalance);
      setMinimumTopupAmount(minimumTopUpLimit);
      setAllowance(Number(allowance));
    })();
  }, [loading]);

  const dispatch = useAppDispatch();

  async function sendCashTransaction() {
    try {
      setLoading(true);
      setError(false);
      setFinalOpState(null);

      const chain = getChain(SupportedChainId.BASE);

      const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);

      const provider = new ethers.providers.JsonRpcProvider(bundlerRPC);
      const wallet = new ethers.Wallet(pkey, provider);

      if (!wallet) return;

      let userCallDataArray: ExecuteCall[] = [];

      console.log("usdcBalance", usdcBalance);

      if (!usdcBalance && !gas.totalBalance) {
        showAlert("You do not have USDC balance to top up gas tank.");
        setLoading(false);
        return;
      }

      const approveCallData = getApproveTokenCallData(
        APPROVE_AMOUNT,

        chain,
        usdcAddress
      );

      if (!allowance) {
        console.log("ALLLOWANCE ME");
        userCallDataArray.push(approveCallData);
      } else {
        const call0 = Token__factory.createInterface().encodeFunctionData(
          "transfer",
          [to, ethers.utils.parseUnits(String(value), usdcDecimals).toString()]
        );

        userCallDataArray.push({
          to: usdcAddress,
          value: "0",
          calldata: call0,
        });
      }

      console.log(activeAccount.address);

      const cashAccountDeployed = await isCashAccountDeployed(
        rootAccountInfo.smartAccountAddress
      );

      console.log("cashAccountDeployed", cashAccountDeployed);

      const { finalOp, usdcFee } = await txSubmissionOrderPrepaid({
        userCallDataArray,

        wallet,

        isAccountDeployed: cashAccountDeployed,
        chain,

        smartAccountAddress: rootAccountInfo.smartAccountAddress,
        provider,

        extraTopupAmount,
        gasBalance: gas.totalBalance,
        approval: allowance ? true : false,
        rootAddress: rootAccountInfo.address.toLowerCase(),
      });

      setFinalOpState(finalOp);
      setGasFeeInUSD(Number(usdcFee).toFixed(4));

      console.log("usdcFee", usdcFee);

      if (Number(usdcFee) > gas.totalBalance) {
        setOpenExtraTopupModal(true);
      } else {
        setLoading(false);
      }
    } catch (e: any) {
      console.log("file: index.tsx:674  newSendTransaction  e:", e);

      showAlert(e?.message ? e?.message : e);
      setLoading(false);
      navigate("/cash");
    }
  }

  const createSignature = async () => {
    try {
      const chain = getChain(SupportedChainId.BASE);
      const { feesInUsdc, preFinalOp } = await createSignatureAndCalculateFees(
        finalOpState,
        rootAccountInfo.address,
        extraTopupAmount,
        rootAccountInfo.secret,
        hashedPassword,
        bundlerRPC,
        chain
      );
      setFinalOpState(preFinalOp);

      setGasFeeInUSD(Number(feesInUsdc).toFixed(4));
    } catch (error) {
      setLoading(false);
      showAlert(error);
    }
  };

  const executeSendCash = async () => {
    try {
      setLoading(true);
      //send userOp
      if (finalOpState) {
        setOpenExtraTopupModal(false);
        //replace via sendStackup or sendAlchemy  UserOp
        const response = await sendUserOp(finalOpState, bundlerRPC, rpcUrl);
        const userOPResponse: any = await response.wait();
        console.log("userOp Hash :", response.userOpHash);
        console.log("Tx Hash :", userOPResponse?.transactionHash);
        console.log("success status :", userOPResponse?.args.success);

        dispatch(
          setPendingTxDetails({
            value: allowance ? value : 0,
            valueIn$: allowance ? String(Number(value) * 1) : 0,
            transferAmount: allowance ? value : 0,
            transactionMethod: "Cash",
            scanLink: block_explorer_url,
            eoaEns: rootAccountInfo.name,
            addressEns: rootAccountInfo.name,
            toAddressEns: allowance ? tag || to : usdcAddress,
            toAddress: allowance ? to : usdcAddress,
            assetName: "Cash",
            networkFeesIn$: gasFeeInUSD,
            iconURL: USDT_URL,
            txByDesposited: false,
            action: allowance ? "Sent" : "Approved",
          })
        );

        dispatch(setCashPendingTx(userOPResponse?.transactionHash));

        showAlert(
          "Soon you can see your transaction in the transactions tab",
          allowance
            ? "Transaction Submitted"
            : "Approval Transaction Submitted",
          `<a href="https://basescan.org/tx/${userOPResponse?.transactionHash}" target="_blank">View on Basescan</a>`
        );
      }
      //reset OpState
      setFinalOpState(null);

      if (allowance) {
        navigate("/cash");
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      showAlert("Something went wrong");
      navigate("/cash");
    }
  };

  const handleAmountChange = (e) => {
    const inputValue = e.target.value;

    if (/[^0-9.]/.test(inputValue)) {
      setError("");
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setError("");
    } else if (!/^\d{0,10}(\.\d{0,6})?$/.test(inputValue)) {
      setError(
        "Maximum of 10 digits before decimals and 6 digits after decimals are allowed"
      );
    } else if (value && Number(value) > rootAccountInfo.usdTokenBalance) {
      setError("You do not have enough balance");
      setValue(inputValue);
    } else {
      setError("");
      setValue(inputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      setValue(newValue);
      setError("");
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (Number(newValue) > 0) {
        setValue(newValue);
      }
      setError("");
    }
  };

  const handleTopupAmountChange = (value) => {
    const inputValue = value;
    console.log(inputValue);

    if (/[^0-9.]/.test(inputValue)) {
      setError("");
    } else {
      setError("");
      setExtraTopupAmount(inputValue);
    }
  };

  const addExtraTopupAmount = async () => {
    try {
      setOpenExtraTopupModal(false);
      setLoading(true);

      await createSignature();

      await executeSendCash();
    } catch (error) {
      showAlert(error);
      setLoading(false);
    }
  };

  console.log(
    "TOOO",
    Number(value) && to && Number(value) <= Number(usdcBalance) && !error
  );

  const isValid =
    Number(value) && to && Number(value) <= Number(usdcBalance) && !error;

  return (
    <>
      <ExtraTopupAmountPopup
        openExtraTopupModal={openExtraTopupModal}
        addExtraTopupAmount={addExtraTopupAmount}
        extraTopupAmount={extraTopupAmount}
        gasBalance={gas.totalBalance}
        usdcBalance={usdcBalance}
        handleTopupAmountChange={handleTopupAmountChange}
        minimumTopupAmount={usdcBalance - Number(value) - Number(gasFeeInUSD)}
        closeExtraTopupModal={() => {
          setOpenExtraTopupModal(false);
          setLoading(false);
        }}
      />
      <Box mt={6}>
        <NavigatorHeading
          title="Send Cash"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/cash");
              }}
            />
          }
        />
      </Box>
      <Box mt={6}>
        <CustomizedSteppers
          step={step}
          steps={["Recipient", "Amount", "Send"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />
        <Box mt={2}>
          {step == 0 && (
            <Box display={"flex"} flexDirection={"column"}>
              <Grid container display="flex" justifyContent="center">
                <Grid
                  item
                  lg={12}
                  sm={12}
                  style={{
                    flexBasis: "100%",
                    maxWidth: "58%",
                  }}
                >
                  <TransactionContactList
                    shouldAddContact={false}
                    isTxForm
                    isChooseRecipient={true}
                    nextStep={(address: string, tag: string) => {
                      setStep(1);
                      setTo(address);
                      setTag(tag);
                    }}
                    isCashMode={true}
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {step == 1 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                display="flex"
                justifyContent="center"
                style={{
                  flexBasis: "55%",
                  maxWidth: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "rgba(26, 28, 32, 0.50)",
                        fontFamily: "Helvetica Neue",
                        fontsize: 17,
                        fontWeight: 500,
                      }}
                    >
                      Send to {tag ? tag : to}
                    </Typography>
                    <Box mt={2.5}>
                      <div className="input-container">
                        <FormControl sx={{ flex: 1 }}>
                          <Box
                            sx={{
                              color: "#1A1C20",
                              width: "100%",
                              // height: "80px",
                              borderRadius: "10px",
                              fontSize: "25px",
                              fontFamily: "Space Grotesk",
                              fontWeight: "700",
                              border: "0.5px solid rgba(26, 28, 32, 0.50)",
                            }}
                          >
                            <Input
                              style={{
                                padding: "0px 20px 0px 20px",
                              }}
                              onChange={handleAmountChange}
                              onKeyDown={handleKeyDown}
                              endAdornment={
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box
                                    mr={1.25}
                                    style={{
                                      marginTop: "10px",
                                    }}
                                  >
                                    <img src={CashIcon} />
                                  </Box>
                                  <Box
                                    sx={{
                                      color: "#1A1C20",
                                      fontFamily: "Space Grotesk",
                                      fontSize: "18px",
                                      fontStyle: "normal",
                                      fontWeight: "600",
                                      lineHeight: "normal",
                                      marginTop: "0px",
                                      marginLeft: -1.5,
                                    }}
                                  >
                                    Cash
                                  </Box>
                                </Box>
                              }
                              value={value}
                              sx={{ width: "100%", padding: "20px" }}
                              placeholder="0.00"
                            />
                          </Box>
                          {error && (
                            <Typography
                              style={{
                                fontSize: 12,
                                color: "red",
                                marginTop: 5,
                              }}
                            >
                              {error}
                            </Typography>
                          )}
                        </FormControl>
                      </div>
                    </Box>
                    <Box mt={5}>
                      <Button
                        title="Continue"
                        onClick={() => setStep(2)}
                        disabled={!isValid}
                        style={{
                          borderRadius: "10px",
                          padding: "15px 0px",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}

          {step == 2 && (
            <Grid container display="flex" justifyContent="center" py={4}>
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "55%",
                  maxWidth: "100%",
                }}
              >
                <Box mt={2.5} position={"relative"}>
                  <div className="input-container" style={{ marginBottom: 10 }}>
                    <FormControl sx={{ flex: 1 }}>
                      <Box
                        sx={{
                          backgroundColor: "#EDEEF2",
                          color: "#1A1C20",
                          width: "100%",
                          // height: "80px",
                          borderRadius: "10px",
                          fontSize: "25px",
                          fontFamily: "Space Grotesk",
                          fontWeight: "700",
                          border: "0.5px solid rgba(26, 28, 32, 0.50)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              paddingTop: 15,
                            }}
                          >
                            <Box mr={1.25}>
                              <img src={CashIcon} />
                            </Box>
                            <Box
                              sx={{
                                color: "#1A1C20",
                                fontFamily: "Space Grotesk",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "normal",
                                marginTop: -2,
                                marginLeft: -1.5,
                              }}
                            >
                              Cash
                            </Box>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginRight: 10,
                            }}
                          >
                            <Box
                              mr={1.25}
                              sx={{
                                color: "#1A1C20",
                                fontFamily: "Space Grotesk",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "normal",
                              }}
                            >
                              ${value}
                            </Box>
                            <Box
                              sx={{
                                color: "#8C8D8F",
                                fontFamily: "Space Grotesk",
                                fontSize: "12px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }}
                            >
                              {value} USDbC
                            </Box>
                          </div>
                        </Box>
                      </Box>
                    </FormControl>
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      top: "40%",
                      left: "50%",
                      zIndex: 2,
                    }}
                  >
                    <img src={MidArrow} />
                  </div>

                  <div className="input-container">
                    <FormControl sx={{ flex: 1 }}>
                      <Box
                        sx={{
                          color: "#1A1C20",
                          width: "100%",
                          // height: "80px",
                          borderRadius: "10px",
                          fontSize: "25px",
                          fontFamily: "Space Grotesk",
                          fontWeight: "700",
                          border: "0.5px solid rgba(26, 28, 32, 0.50)",
                          backgroundColor: "#EDEEF2",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              paddingTop: 20,
                              paddingBottom: 10,
                            }}
                          >
                            <Box mr={1.25}>
                              <div
                                style={{
                                  height: 25,
                                  width: 25,
                                  borderRadius: 50,
                                  padding: 10,
                                  backgroundColor: "#0038FF26",
                                  margin: 10,
                                  marginTop: 0,
                                }}
                              >
                                <div
                                  style={{
                                    color: "#0038FF",
                                    fontFamily: "Space Grotesk",
                                    fontSize: "13px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "normal",
                                    marginTop: 4,
                                    textAlign: "center",
                                  }}
                                >
                                  {tag ? tag.slice(0, 2).toUpperCase() : "0x"}
                                </div>
                              </div>
                            </Box>
                            <Box
                              sx={{
                                color: "#1A1C20",
                                fontFamily: "Space Grotesk",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "normal",
                                marginTop: -2,
                                marginLeft: -1.5,
                              }}
                            >
                              {tag || to}
                            </Box>
                          </div>
                        </Box>
                      </Box>
                    </FormControl>
                  </div>
                </Box>
                <Typography variant="body2" textAlign={"left"} mt={5} mb={-2}>
                  Pay Fees With
                </Typography>

                <GasToken
                  priceInUSD={gas.totalBalance}
                  tokenBalance={gas.totalBalance}
                  symbol="USDC"
                  name="USDC"
                />

                <FeeUIComponent
                  gasFeeInUSD={gasFeeInUSD}
                  loading={!finalOpState && loading}
                />
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={50}
                    sx={{
                      borderRadius: "10px",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  />
                ) : (
                  <BasicButton
                    title={allowance ? "Send" : "Approve Paymaster"}
                    onClick={
                      error || !finalOpState
                        ? () => {
                            console.log("abc");
                          }
                        : executeSendCash
                    }
                    style={{
                      cursor:
                        error || !finalOpState ? "not-allowed" : "pointer",
                      height: "15px",
                    }}
                    className="transfer-btn"
                    loading={loading}
                    mode={"active"}
                    id="send"
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default CashSend;
