import EIP155Lib from "../lib/EIP155Lib";
import { decryptMessage } from "./utils";
import { ethers } from "ethers";
// import EIP155Lib from '@/lib/EIP155Lib'

export let wallet1: ethers.Wallet;
export let wallet2: ethers.Wallet;
export let eip155Wallets: Record<string, ethers.Wallet>;
export let eip155Addresses: string[];

let address1: string;
let address2: string;

/**
 * Utilities
 */
export function createOrRestoreEIP155Wallet(
  accSecret,
  hashedPassword,
  alchemy_url
) {
  // console.log("inside eip155", generatedMnemonic);
  // const mnemonic1 = localStorage.getItem("EIP155_MNEMONIC_1");

  const pkey = decryptMessage(accSecret, hashedPassword);
  console.log("file: index.tsx:448  newSendTransaction  pkey:", pkey);
  const provider = new ethers.providers.JsonRpcProvider(alchemy_url);
  const wallet = new ethers.Wallet(pkey, provider);
  //   const mnemonic2 = localStorage.getItem("EIP155_MNEMONIC_2");

  // if (mnemonic1) {
  //   wallet1 = EIP155Lib.init({ mnemonic: mnemonic1 });
  //   // wallet2 = EIP155Lib.init({ mnemonic: mnemonic2 });
  // } else {
  //   wallet1 = EIP155Lib.init({ mnemonic: generatedMnemonic });
  //   // wallet2 = EIP155Lib.init({});

  //   // Don't store mnemonic in local storage in a production project!
  //   localStorage.setItem("EIP155_MNEMONIC_1", wallet1.getMnemonic());
  //   // localStorage.setItem("EIP155_MNEMONIC_2", wallet2.getMnemonic());
  // }

  address1 = wallet.address;
  // address2 = wallet2.getAddress();

  eip155Wallets = {
    [address1]: wallet,
    // [address2]: wallet2,
  };
  eip155Addresses = Object.keys(eip155Wallets);

  return {
    eip155Wallets,
    eip155Addresses,
  };
}
