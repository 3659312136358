import BasicButton from "components/Button";
import EthIcon from "assets/eth-logo.svg";
import CheckIcon from "@mui/icons-material/Check";

import React, { FC, useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "store/store";
import { setActiveNetwork } from "@slices/appSlice";
import { showNetworksList } from "utils/networks";
import { Typography, Grid, Box } from "@mui/material";
import TokenImage from "components/TokenImage";
import { supportedMainnetChainIds } from "../../constants/chains";
import SettingsStore from "../../walletConnectStore/SettingsStore";
import { updateSignClientChainId } from "../../utils/WalletConnectUtil";

const NetworksList: FC<{
  nextStep?: (chainId?: number) => void;
  title: string;
}> = ({ nextStep, title }) => {
  const { activeNetwork, mainnetNetworks, testnetNetworks, activeAccount } =
    useAppSelector((state) => state.app);
  console.log(
    "file: index.tsx:15  activeNetwork:",
    activeNetwork,
    mainnetNetworks,
    testnetNetworks
  );

  //Todo: Mainnet Testnet changes
  // const [activeTab, setActiveTab] = useState(
  //   testnetNetworks.includes(activeNetwork) ? "testnet" : "mainnet"
  // );

  const [activeTab, setActiveTab] = useState("mainnet");
  const [mNetworks, setMNetworks] = useState([]);
  console.log("file: index.tsx:28  mNetworks:", mNetworks);
  const [tNetworks, setTNetworks] = useState([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // const { mainNetworks, testnetworks } = showNetworksList(
    //   mainnetNetworks,
    //   testnetNetworks
    // );

    const { mainNetworks, testnetworks } = showNetworksList(
      supportedMainnetChainIds,
      testnetNetworks
    );
    console.log(
      "file: index.tsx:34  useEffect  mainNetworks:",
      mainNetworks,
      testnetNetworks
    );
    setMNetworks(mainNetworks);

    setTNetworks(testnetworks);
  }, []);

  async function onChainChanged(chainId: any, address: string) {
    SettingsStore.setActiveChainId(chainId);
    await updateSignClientChainId(`eip155:${chainId.toString()}`, address);
  }

  const switchNetwork = async (net) => {
    console.log(net);
    if (net.chainId === activeNetwork) {
      console.log("same");
    } else {
      dispatch(setActiveNetwork(net.chainId));
      onChainChanged(net.chainId, activeAccount.smartAccountAddress);
    }
  };

  return (
    <div>
      {/* <div className="network-btns">
        <p style={{ color: "#6A27EA" }}>Chain</p>
        <div style={{ display: "flex" }}>
          <BasicButton
            title="Mainnet"
            onClick={() => setActiveTab("mainnet")}
            className={`network-btn ${
              activeTab === "mainnet" && "active-network-btn"
            }`}
            id="Mainnet"
          />
          <BasicButton
            title="Testnet"
            onClick={() => setActiveTab("testnet")}
            className={`network-btn ${
              activeTab === "testnet" && "active-network-btn"
            }`}
            id="Testnet"
          />
        </div>
      </div> */}
      <Typography
        sx={{
          color: "rgba(26, 28, 32, 0.50)",
          textAlign: "center",
          fontFamily: "Helvetica Neue",
          fontsize: 17,
          fontWeight: 500,
        }}
      >
        {title}
      </Typography>

      <Box
        mt={4}
        sx={{
          maxHeight: 350,
          overflowY: "auto",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {(activeTab === "mainnet" ? mNetworks : tNetworks).map((net) => (
          <Grid
            sx={{
              padding: "10px",
              display: "flex",
              width: "100%",
              cursor: "pointer",
              borderRadius: "10px",
              marginBottom: 2.5,

              background: "#f7f7f7",
              "&:hover": {
                backgroundColor: "lightgray",
              },
            }}
            onClick={() => {
              switchNetwork(net);

              nextStep && nextStep(net?.chainId);
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <TokenImage logo={net.icon} borderWidth={0.5} />
              <Grid
                sx={{
                  color: "#1A1C20",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  fontFamily: "Helvetica Neue",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                }}
              >
                <div>{net.networkName}</div>
              </Grid>
            </Grid>
          </Grid>
          // <div
          //   className={`network-item ${
          //     activeNetwork === net.chainId && "active-account-item"
          //   }`}
          //   onClick={() => switchNetwork(net)}
          //   id="network"
          //   key={net.chainId}
          // >
          //   <img src={net.icon} height={30} />

          //   <div className="account-name">
          //     <p>{net.networkName}</p>
          //   </div>

          //   {activeNetwork === net.chainId && <CheckIcon />}
          // </div>
        ))}
      </Box>
    </div>
  );
};

export default NetworksList;
