import React, { FC, ReactElement, ReactNode, useState } from "react";

import "./styles.css";
import { Box, Fade, Typography } from "@mui/material";
import GasIcon from "assets/text-gas.svg";
import AccountDetailsBox from "components/AccountDetailsBox";

interface InfoCardProps {
  backgroundImage: string;
  backgroundPosition: string;
  backgroundSize: string;
  title?: string;
  amount?: string | ReactNode;
  number?: string;
  unit?: string;
  subtitle?: string;
  gas?: string;
  action?: ReactElement;
  isCashTab?: boolean;
  openModal?: boolean;
}

const InfoCard: FC<InfoCardProps> = ({
  backgroundImage,
  backgroundPosition,
  backgroundSize,
  title,
  amount,
  number,
  unit,
  gas,
  subtitle,
  action,
  isCashTab,
  openModal,
}) => {
  // const splittedBalance = (amount || "0.0").split(".");
  const [openAccountDetailsModal, setOpenAccountDetailsModal] = useState(false);

  return (
    <Fade in={true} timeout={500} easing={"ease-in-out"}>
      <Box
        className="card-box"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        style={{
          backgroundImage,
          backgroundPosition,
          backgroundSize,
          backgroundRepeat: "no-repeat",
          height: 205,
        }}
      >
        <Box p={2.5} height="27px">
          <AccountDetailsBox
            title={title || ""}
            isCashTab={isCashTab}
            open={openAccountDetailsModal}
            handleOpen={() => openModal && setOpenAccountDetailsModal(true)}
            handleClose={() => openModal && setOpenAccountDetailsModal(false)}
            isShowWithOutTitle={false}
          />
        </Box>

        <Box display="flex" justifyContent="center" alignItems="baseline">
          {amount}
          {number && (
            <Typography
              variant={"h5"}
              fontWeight={700}
              color="rgba(26, 28, 32, 1)"
              fontSize={45}
              style={{ cursor: "pointer" }}
              align="center"
              mr={2}
            >
              {number}
            </Typography>
          )}
          {unit && (
            <Typography
              variant={"h5"}
              fontWeight={700}
              color="rgba(26, 28, 32, 0.5)"
              fontSize={25}
              style={{ cursor: "pointer" }}
              align="center"
            >
              {unit}
            </Typography>
          )}
          {action}
        </Box>
        <Box display="flex" justifyContent="center" alignItems="baseline">
          {gas && (
            <>
              <img
                src={GasIcon}
                style={{
                  height: 13,
                  marginRight: "5px",
                  color: "rgba(26, 28, 32, 0.5)", // this color should match the text color
                }}
              />
              <Typography
                variant={"h5"}
                fontWeight={700}
                color="rgba(26, 28, 32, 0.5)"
                fontSize={15}
                style={{ cursor: "pointer" }}
                mt={1}
                align="center"
                lineHeight={0}
              >
                ${gas} GAS
              </Typography>
            </>
          )}
        </Box>
        <Box display="flex" justifyContent="center" p={2.5} height="19px">
          <Typography
            variant={"h5"}
            fontWeight={500}
            color="rgba(26, 28, 32, 0.50)"
            fontSize={15}
            style={{ cursor: "pointer" }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
};

export default InfoCard;
