import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import pbkdf2 from "pbkdf2";

import Button from "components/NewButton";
import OTPInput from "components/OtpInput";
import AuthHeader from "components/AuthHeader";
import { useAppDispatch, useAppSelector } from "store/store";
import { setHashedPin } from "@slices/walletSlice";
import { decryptMessage } from "utils/utils";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../../src/constants";
import StepperForPages from "components/Stepper/StepperForPages";

const RecoverPin = () => {
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const location = useLocation();

  const { state } = location || {};
  const { currentStep, pathName } = state || {};

  const [step, setStep] = useState(parseInt(currentStep) || 0);

  const {
    fetchedKeys: { key3 },
    user: { email },
  } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (!email) {
      navigate("/recover");
    }
  }, []);

  const handleContinue = async () => {
    try {
      setLoading(true);

      const hashedPin = pbkdf2
        .pbkdf2Sync(pin, "salt", 1, 32, "sha512")
        .toString("hex");
      dispatch(setHashedPin(hashedPin));

      const decryptedKey = decryptMessage(key3, hashedPin);

      if (decryptedKey) {
        setStep(2);
        navigate("/forgot-password/upload-recovery-key", {
          state: {
            currentStep: step + 1,
            pathName: pathName,
          },
        });
      } else {
        setLoading(false);
        setError("Pin is incorrect");
      }
    } catch (error) {
      setLoading(false);
      setError("Pin is incorrect");

      console.log("err", error);
    }
  };

  const handleKeyPress = (e: any) => {
    const { key, keyCode } = e || {};
    if (key === "Enter" || keyCode === 13 || key === "Tab" || keyCode === 9) {
      e.preventDefault(); // Prevent the default behavior of the Tab key
      handleContinue();
    }
  };

  const totalSteps = stepsCount[pathName] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            className="auth-header-steps"
            width={"50%"}
            style={{
              position: "absolute",
              top: "54px",
            }}
          >
            <StepperForPages
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={(selectedStep: number) => {
                //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
                if (selectedStep < step) {
                  setStep(selectedStep);
                  navigate(-1);
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "30px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Enter your pin
        </Typography>

        <div>
          <OTPInput otp={pin} onChange={setPin} onKeyPress={handleKeyPress} />

          {error && (
            <div>
              <Typography style={{ color: "red", fontSize: 14, marginTop: 20 }}>
                {error}
              </Typography>
            </div>
          )}
          <Button
            onClick={handleContinue}
            title="Continue"
            loading={loading}
            style={{
              padding: "15px 0px",
            }}
            disabled={!pin || pin.length < 6}
          />
        </div>
      </div>
    </div>
  );
};

export default RecoverPin;
