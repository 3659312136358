import { setTransactions } from "@slices/appSlice";
import { SUPPORTED_NETWORKS } from "constants/chains";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { styledToast } from "utils/HelperUtil";
import {
  decryptMessage,
  fetchUsdPriceFromMobula,
  initalizeWeb3,
  saveTransaction,
} from "utils/utils";

const useSaveTxnHistory = () => {
  const dispatch = useAppDispatch();
  const activeNetwork = useAppSelector((state) => state.app.activeNetwork);
  const activeAccount = useAppSelector((state) => state.app.activeAccount);
  const rootAccountInfo = useAppSelector((state) => state.app.rootAccountInfo);
  const transactions = useAppSelector((state) => state.app.transactions);
  const hashedPassword = useAppSelector((state) => state.wallet.hashedPassword);

  const dispatchAndSaveHistory = async (txn: any) => {
    let timer = setInterval(async () => {
      try {
        // const data = await getUserOpHashData(pendingTx, activeNetwork);
        const web3 = initalizeWeb3(activeNetwork);
        const res = await web3.eth.getTransactionReceipt(txn?.hash);
        const { block_explorer_url } =
          SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

        if (res) {
          if (res.status) {
            console.log("RES", res);
            const web3 = initalizeWeb3(activeNetwork);

            const gasUsed = res.gasUsed / 10 ** 9;
            console.log("gasUsed", gasUsed);
            const gasPrice = res.effectiveGasPrice / 10 ** 9;
            console.log("gasPrice", gasPrice);

            const txCost = gasUsed * gasPrice;
            console.log("txCost", txCost);

            const balanceInUsd = await fetchUsdPriceFromMobula("polygon");

            const feeInUsd = txCost * balanceInUsd;

            console.log("FEEEEEEEE", feeInUsd);

            const pkey = decryptMessage(rootAccountInfo.secret, hashedPassword);
            const {
              value,
              valueIn$,
              transferAmount,
              transactionMethod,
              scanLink,
              eoaEns,
              addressEns,
              toAddressEns,
              toAddress,
              assetName,
              networkFeesIn$,
              iconURL,
              txByDesposited,
              action,
            } = txn;
            console.log(
              "file: useCryptoHashConfirmation.tsx:73  timer=setInterval  action:",
              action
            );
            console.log("this is coming from PARAMS", txn);
            const signature = web3.eth.accounts.sign(
              `${res.transactionHash}-${feeInUsd}`,
              pkey
            );

            // if (action.toLowerCase() !== "approved") {
            console.log("we are sending this", toAddressEns ?? "");
            await saveTransaction(
              activeAccount.smartAccountAddress.toLowerCase(),
              rootAccountInfo.address.toLowerCase(),
              res.transactionHash,
              activeNetwork,
              Number(feeInUsd),
              signature.signature,
              value ?? "",
              valueIn$ ?? "",
              transferAmount ?? "",
              transactionMethod ?? "",
              `${block_explorer_url}/tx/${res.transactionHash}` ?? "",
              eoaEns ?? "",
              addressEns ?? "",
              toAddressEns ?? "",
              toAddress ?? "",
              assetName ?? "",
              String(feeInUsd) ?? "",
              iconURL ?? "",
              txByDesposited ? Number(feeInUsd) : 0,
              action,
              txn?.appName ?? ""
            );

            const newTx = {
              action,
              address: activeAccount.smartAccountAddress.toLowerCase(),
              addressEns,
              amount: value,
              assetName,
              distributionActive: false,
              eoa: rootAccountInfo.address.toLowerCase(),
              eoaEns,
              hash: res.transactionHash,
              iconURL,
              network: activeNetwork,
              networkFeesIn$: String(feeInUsd),
              paidWithGasTank: 0,
              referedByUserAddress: "",
              referedByUserReward: 0,
              referedToUserCashback: 0,
              scanLink: `${block_explorer_url}/tx/${res.transactionHash}`,
              stashedFees: 0,
              timestamp: new Date().getTime(),
              toAddress,
              toAddressEns,
              transactionMethod: "Crypto",
              transferAmount,
              userReferalActive: true,
              value,
              valueIn$,
            };

            let newTransactions: any = {};

            newTransactions[activeAccount.smartAccountAddress] = [
              newTx,
              ...transactions[activeAccount.smartAccountAddress],
            ];

            console.log("newTr", newTx);
            console.log("newTransactions 00 101112", newTransactions);
            dispatch(
              setTransactions({
                ...newTransactions,
              })
            );
            // }

            //   dispatch(setTxStatus(!txStatus));
            // dispatch(setPendingTx(""));
            // dispatch(setPendingTxDetails(initialPendingTxValues));

            // showNotification(
            //   "Transaction Successful",
            //   "Your Transaction successfully completed",
            //   `${block_explorer_url}/tx/${res.transactionHash}`
            // );
            styledToast("Approve Transaction Successful", "success");

            clearInterval(timer);
          } else {
            dispatch(setTxStatus(!txStatus));
            dispatch(setPendingTx(""));

            showNotification(
              "Transaction Failed",
              "Your Transaction Failed",
              `${block_explorer_url}/tx/${res.transactionHash}`
            );
            clearInterval(timer);
          }
        }
      } catch (err) {
        // dispatch(setTxStatus(!txStatus));
        // dispatch(setPendingTx(""));
        console.log("error in dispatchAndSaveHistory");
        clearInterval(timer);
      }
    }, 10000);
  };

  return { dispatchAndSaveHistory };
};

export default useSaveTxnHistory;
