import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import NavigatorHeading from "../../../../components/NavigatorHeading";
import CloseButton from "../../../../components/CloseButton";
import SearchInput from "../../../../components/SearchInput";
import { useAppSelector } from "store/store";
import { Asset } from "interfaces";
import { numFormatter } from "utils/utils";
import DepositIcon from "assets/deposit.svg";
import SendIcon from "assets/send-icon.svg";
import SwapIcon from "assets/swap-black.svg";
import ChipButton from "components/ChipButton";
import Chart from "components/Chart";
import { LIGHT_BLUE_COLOR } from "constants/";
import { DARK_BLUE_COLOR } from "constants/";
import TokenImage from "components/TokenImage";
import {
  fetchTokenChartData,
  fetchTokenTransactions,
  fetchChainBalancesChartData,
} from "utils/portfolio";
import millify from "millify";
import MiniCashout from "assets/mini-cashout.svg";
import MiniSwap from "assets/mini-swap.svg";
import MiniExchange from "assets/mini-exchange.svg";

export const timeInSeconds = [
  //1H, 1D, 1W, 1M,
  3600, 86400, 604800, 2629743,
];

const ListComponent: FC<{
  asset: any;
}> = ({ asset }) => {
  const { activeAccount } = useAppSelector((state) => state.app);

  return (
    <Grid
      sx={{
        paddingX: "20px",
        paddingY: "16px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        cursor: "pointer",
        borderRadius: "10px",
        backgroundColor: "rgba(163, 165, 166, 0.1)",
        "&:hover": {
          backgroundColor: "lightgray",
        },
      }}
    >
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            position: "relative",
            width: 45,
            height: 45,
          }}
        >
          <TokenImage
            logo={asset.asset.logo}
            borderColor="rgba(49, 112, 209, 1)"
            size={45}
            borderWidth={2.8}
          />
          <Box
            component="img"
            src={MiniExchange}
            style={{
              position: "absolute",
              bottom: -6,
              right: -3,
              width: 20,
              height: 20,
              zIndex: 1,
            }}
          />
        </Box>

        <Grid
          sx={{
            color: "#1A1C20",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Helvetica Neue",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            textTransform: "capitalize",
          }}
        >
          <div>
            {asset.from?.toLowerCase() ===
            activeAccount.smartAccountAddress.toLowerCase()
              ? "Sent"
              : "Received"}{" "}
            {asset.asset.name}
          </div>
          <div
            style={{
              fontFamily: "Helvetica Neue",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
            }}
          >
            <span
              style={{
                color: "rgba(26, 28, 32, 0.50)",
              }}
            >
              {
                //timestamp to 03:01 AM
                new Date(asset.timestamp).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            color: "rgba(18, 18, 18, 1)",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontFamily: "Helvetica Neue",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            textAlign: "right",
          }}
        >
          {Number(asset?.amount_usd) > 0 && (
            <div>
              $
              {millify(asset?.amount_usd, { precision: 4 }).replace(
                /(^|\s)([a-z])/g,
                function (m, p1, p2) {
                  return p1 + p2.toUpperCase();
                }
              )}
            </div>
          )}
          <div
            style={{
              fontFamily: "Helvetica Neue",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              color: "rgba(140, 141, 143, 1)",
            }}
          >
            {millify(asset?.amount, { precision: 4 }).replace(
              /(^|\s)([a-z])/g,
              function (m, p1, p2) {
                return p1 + p2.toUpperCase();
              }
            )}{" "}
            {asset?.asset?.symbol}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

const TokenDetailPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { portfolio, activeAccount, cryptoHistory } = useAppSelector(
    (state) => state.app
  );
  const tokenDetails = portfolio.assets.filter(
    (asset) => asset.asset.name == token
  )[0];

  console.log("tokenDetails", tokenDetails);

  const { amount: usdBalance, symbol: usdSymbol } = numFormatter(
    tokenDetails?.estimated_balance || 0,
    2
  );
  const { amount: tokenPrice, symbol: tokenSymbol } = numFormatter(
    tokenDetails?.price || 0,
    4
  );

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [lastThreeTransactions, setLastThreeTransactions] = React.useState<any>(
    []
  );

  const { activeNetwork, mainnetNetworks, testnetNetworks } = useAppSelector(
    (state) => state.app
  );

  const fetchAssetTransactions = async () => {
    const selectedTime = timeInSeconds[cryptoHistory.interval];
    // address, from timestamp 1M, to current timestamp and asset)
    const res = await fetchTokenTransactions(
      activeAccount.smartAccountAddress,
      Math.floor(Date.now() / 1000) - selectedTime,
      Math.floor(Date.now() / 1000),
      tokenDetails.asset.name.split("?")[0]
    );

    if (res?.length) {
      //set last three transactions
      setLastThreeTransactions(res.slice(0, 3));
    } else {
      setLastThreeTransactions([]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAssetTransactions();
  }, [cryptoHistory.interval]);

  return (
    <Grid paddingY={5}>
      <NavigatorHeading
        img={tokenDetails?.asset?.logo}
        title={`${token} - $${tokenPrice + tokenSymbol}`}
        RightComponent={
          <CloseButton
            handleOnClick={() => {
              navigate("/crypto");
            }}
          />
        }
      />
      <Box
        mt={4.5}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <div
          style={{
            height: 400,
            border: "10px solid #627eea",
            borderRadius: "50%",
            width: 400,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{
              color: "#1A1C20",
              fontSize: "31.46px",
              fontFamily: "Space Grotesk",
              fontWeight: 700,
            }}
          >
            ${usdBalance.toString()}
          </Typography>

          <Typography
            style={{
              color: "#8C8D8F",
              fontSize: "17.16px",
              fontFamily: "Space Grotesk",
              fontWeight: 400,
            }}
          >
            Total Balance
          </Typography>
        </div>
      </Box>
      <Box display="flex" mt={2.5}>
        <Box
          className="flex-row-center"
          style={{
            display: "flex",
            gap: "4px",
            justifyContent: "flex-start",
            flexFlow: "wrap",
            paddingBottom: "20px",
          }}
        >
          <ChipButton
            title="Receive"
            onClick={() => {
              navigate("/crypto/receive");
            }}
            icon={DepositIcon}
            fontWeight={400}
            fontSize={14}
            fontFamily="Helvetica Neue"
          />
          <ChipButton
            title="Send"
            onClick={() => {
              navigate("/crypto/send");
            }}
            icon={SendIcon}
            fontWeight={400}
            fontSize={14}
            fontFamily="Helvetica Neue"
          />
          <ChipButton
            title="Swap"
            onClick={() => {
              navigate("/crypto/sell");
            }}
            icon={SwapIcon}
            fontWeight={400}
            fontSize={14}
            fontFamily="Helvetica Neue"
          />
        </Box>
      </Box>
      <Typography
        variant="h4"
        sx={{ marginY: "20px" }}
        fontWeight={500}
        color="rgba(26, 28, 32, 0.75)"
        fontSize={16}
        fontFamily={"Space Grotesk"}
      >
        RECENT
      </Typography>
      {isLoading ? (
        <Skeleton variant="rectangular" height={50} />
      ) : (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          {!isLoading &&
            lastThreeTransactions.map((transaction: any, index: number) => (
              <ListComponent key={index} asset={transaction} />
            ))}
          {!isLoading && !lastThreeTransactions.length && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography
                variant="h4"
                sx={{ marginY: "20px" }}
                fontWeight={500}
                color="rgba(26, 28, 32, 0.75)"
                fontSize={16}
                fontFamily={"Space Grotesk"}
              >
                No transactions found in selected time period
              </Typography>
            </Box>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default TokenDetailPage;
