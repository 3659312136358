import useApp from "hooks/useApp";
import AuthFlowRoutes from "routes/auth";
import AppFlowRoutes from "./app";
import useCryptoHashConfirmation from "hooks/useCryptoHashConfirmation";
import useFetchPortfolioSocket from "hooks/useFetchPortfolioSocket";
import useCashHashConfirmation from "hooks/useCashHashConfirmation";

const Routes = () => {
  const { isLoggedIn } = useApp();
  useCryptoHashConfirmation();
  useCashHashConfirmation();
  useFetchPortfolioSocket();
  return isLoggedIn ? <AppFlowRoutes /> : <AuthFlowRoutes />;
};

export default Routes;
