import React, { useEffect, useState } from "react";
import Web3 from "web3";

import "./index.css";
import { Box, Grid, Snackbar, Typography } from "@mui/material";
import BasicModal from "components/BasicModal";
import ModalHeader from "components/ModalHeader";
import AssetsView from "components/AssetsView";
import { Token } from "interfaces";
import { useAppDispatch, useAppSelector } from "store/store";

import { SUPPORTED_NETWORKS } from "constants/chains";
import { extractTokenData, numFormatter, showAlert } from "utils/utils";
import abi from "abis/erc20abi.json";

import { setSelectedToken } from "@slices/walletSlice";
import { ethers } from "ethers";
import { Paymaster_Owner_Address } from "../../../../contract-integration/constants";
import { useNavigate } from "react-router-dom";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import NetworksList from "components/NetworksList";
import TokensListTable from "../TokensListTable";
import TokenInputForm from "components/TokenInputForm";
import { getEthDollarValue } from "../../../../utils/portfolio";
import CryptoFiatTabs from "components/CryptoFiatTabs";
import ReceiveTokenList from "components/ReceiveTokenList";
import SwapSummary from "components/SwapSummary";
import BuyFiat from "components/Buyfiat";
import GBPIcon from "assets/GBPIcon.svg";
import BuyFiatPreview from "components/BuyFiatPreview";
import { setSwapDetails } from "@slices/appSlice";
import { fetchUniswapSupportedTokens } from "utils/swap";
import axios from "axios";
import { NATIVE_ADDRESS, ZERO_ADDRESS, topFiat } from "constants/";
import Button from "components/NewButton";
import { getUsdcBalance } from "utils/balance";

const CryptoBuy = () => {
  const [value, setValue] = useState("");
  const [to, setTo] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [step, setStep] = useState(0);

  const [sameTokens, setSameTokens] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState("crypto");

  const [isCashSelected, setIsCashSelected] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState("GBP");
  const [activeCurrencyIcon, setActiveCurrencyIcon] = useState(GBPIcon);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [activeCryptoOnRamperId, setActiveCryptoOnRamperId] = useState("");

  const [swapTokens, setSwapTokens] = useState<any>([]);
  const [fiatList, setFiatList] = useState([{}]);
  const [cryptoList, setCryptoList] = useState([{}]);
  const [usdcBalance, setUsdcBalance] = useState(0);

  const navigate = useNavigate();
  const {
    activeAccount,
    holdings,
    accounts,
    activeNetwork,
    portfolio,
    rootAccountInfo,
    balances,
    swapDetails: { tokenB },
  } = useAppSelector((state) => state.app);

  const { selectedToken } = useAppSelector((state) => state.wallet);

  const { wethAddress, onRamperNetwork, symbol } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (activeTab == "crypto") {
        setLoading(true);

        const tokens = await fetchUniswapSupportedTokens(activeNetwork);

        const topTokens = tokens.filter(
          (tk) =>
            tk.symbol === "USDT" ||
            tk.symbol === "USDC" ||
            tk.symbol === "UNI" ||
            tk.symbol === "DAI" ||
            tk.symbol === "AAVE" ||
            tk.symbol === "GRT" ||
            tk.symbol === "LINK" ||
            tk.symbol === "MANA" ||
            tk.symbol === "MATIC" ||
            tk.symbol === "WETH" ||
            tk.symbol === "WBTC" ||
            tk.symbol === "ANKR" ||
            tk.symbol === "BNB"
        );

        // Remove top tokens from tokens array
        topTokens.forEach((token) => {
          const index = tokens.findIndex((tk) => tk.symbol === token.symbol);
          if (index !== -1) {
            tokens.splice(index, 1);
          }
        });

        console.log("topTokens", topTokens);

        // Add top tokens to the top of swapTokens array
        setSwapTokens([...topTokens, ...tokens]);

        setLoading(false);
      }
    })();
  }, [activeTab]);

  useEffect(() => {
    (async () => {
      if (activeTab == "fiat") {
        try {
          setLoading(true);
          const { data } = await axios.get(
            "https://api.onramper.com/supported",
            {
              headers: {
                Authorization: process.env.ONRAMP_API_KEY,
              },
            }
          );
          const { message } = data;

          const { crypto, fiat } = message;
          const filteredFiat = fiat.filter(
            (ft) => ft.id !== "eur" && ft.id !== "gbp" && ft.id !== "usd"
          );

          setFiatList([...topFiat, ...filteredFiat]);

          const filteredCrypto = crypto.filter(
            (cr) => cr.chainId == activeNetwork
          );
          setCryptoList(filteredCrypto);
          setLoading(false);
        } catch (error) {
          console.log("E>>>>>>>>>>>", error);
          setLoading(false);
        }
      }
    })();
  }, [activeTab]);

  useEffect(() => {
    let userTokens: Token[] =
      holdings[activeAccount.smartAccountAddress]?.tokens || [];

    setTokens(userTokens);
  }, [activeAccount, holdings]);

  const isSelectedToken = selectedToken.length > 0;

  const tokenPriceInUsd = isSelectedToken ? selectedToken[0].tokenPrice : 0;

  useEffect(() => {
    console.log("selectedtoken", tokenB);
    if (
      tokenB.tokenSymbol &&
      tokenB.tokenAddress &&
      cryptoList.length > 0 &&
      step === 3 &&
      activeTab === "fiat"
    ) {
      const ISNATIVE =
        tokenB.tokenAddress == NATIVE_ADDRESS ||
        tokenB.tokenAddress == wethAddress;

      const availableCrypto = ISNATIVE
        ? cryptoList.find(
            (cr) =>
              cr?.address?.toLowerCase() === NATIVE_ADDRESS &&
              cr.network === onRamperNetwork &&
              cr.symbol === symbol.toLowerCase()
          )
        : cryptoList.find(
            (cr) =>
              (cr?.address?.toLowerCase() ===
                tokenB.tokenAddress.toLowerCase() ||
                cr?.symbol?.toLowerCase() ===
                  tokenB.tokenSymbol.toLowerCase()) &&
              cr.network === onRamperNetwork
          );

      console.log("availableCrypto", availableCrypto);

      if (!availableCrypto) {
        showAlert(`${tokenB.tokenSymbol} currently not supported for onRamp`);
        setStep(1);
      } else {
        console.log("availableCrypto", availableCrypto);
        setActiveCryptoOnRamperId(availableCrypto);
      }
    }
  }, [tokenB, cryptoList, step, activeTab]);
  useEffect(() => {
    (async () => {
      const usdcBalance = await getUsdcBalance(
        activeAccount.smartAccountAddress,
        activeNetwork
      );

      console.log("usdcBalance", usdcBalance);
      setUsdcBalance(usdcBalance);
    })();
  }, []);

  // const { dummyToken } = SUPPORTED_NETWORKS[activeNetwork];

  useEffect(() => {
    setPaymentMethod(false);
  }, [activeTab]);

  const selectTokenHandler = (tk: Token) => {
    dispatch(setSelectedToken([tk]));
    setOpenModal(false);
  };

  const handleAmountChange = (value) => {
    const inputValue = value;

    let regex = new RegExp(
      `^\\d{0,10}(\\.\\d{0,${
        selectedToken[0]?.tokenDecimal || tokenB.tokenDecimal
      }})?$`
    );

    if (/[^0-9.]/.test(inputValue)) {
      setError("");
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setError("");
    } else if (!regex.test(inputValue)) {
      setError(
        `Maximum of 10 digits before decimals and ${
          selectedToken[0]?.tokenDecimal || tokenB.tokenDecimal
        } digits after decimals are allowed`
      );
    } else {
      setError("");

      setValue(inputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      if (/[^0-9.]/.test(newValue)) {
        setError("Special characters are not allowed");
      } else if (parseFloat(newValue) * tokenPriceInUsd < 2.01) {
        setError("Value should not be less than $2.01");

        setValue(newValue);
      } else {
        setValue(newValue);
        setError("");
      }
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (/[^0-9.]/.test(newValue)) {
        setError("Special characters are not allowed");
      } else if (parseFloat(newValue) * tokenPriceInUsd < 2.01) {
        setError("Value should not be less than $2.01");

        setValue(newValue);
      } else {
        if (Number(newValue) > 0) {
          setValue(newValue);
        }
        setError("");
      }
    }
  };

  const onAmountContinue = async () => {
    if (activeTab === "fiat") {
      setLoading(true);
      const { data } = await axios.get(
        `https://api.onramper.com/quotes/eur/${activeCryptoOnRamperId.id}?amount=${value}&paymentMethod=creditcard&type=buy&network=polygon`,
        {
          headers: {
            Authorization: process.env.ONRAMP_API_KEY,
          },
        }
      );

      setLoading(false);
      if (data.length > 0 && data[0].errors?.length > 0) {
        setError(`${data[0].errors[0].message}`);
      } else {
        setStep(4);
      }
    } else {
      setStep(4);
    }
  };

  const verifyBalances = (tokenA) => {
    // dispatch(setSwapDetails({ tokenA: TokenDetails }));
    console.log("verifyBalances", tokenA, value, tokenB);
    const tokenBAmountInUsd = Number(value) * (tokenB?.tokenPrice || 1);
    if (tokenBAmountInUsd > tokenA.tokenBalance * tokenA.tokenPrice) {
      setError(`Insufficient ${tokenA.tokenName} balance`);
      setStep(3);
    } else {
      const TokenDetails = {
        amountInUSD:
          (tokenBAmountInUsd / tokenB?.tokenPrice) * tokenA.tokenPrice,

        amount: (tokenBAmountInUsd / tokenA.tokenPrice).toFixed(3),
      };
      dispatch(setSwapDetails({ tokenA: { ...tokenA, ...TokenDetails } }));
      setStep(5);
    }
  };

  console.log("tokenB", tokenB);

  const isValid = !error && Number(value);

  return (
    <>
      <BasicModal open={openModal} onClose={() => setOpenModal(false)}>
        <>
          <ModalHeader
            title="Select asset"
            onClose={() => setOpenModal(false)}
            showBackIcon
          />
          <AssetsView tokens={tokens} selectTokenHandler={selectTokenHandler} />
        </>
      </BasicModal>
      <Box mt={6}>
        <NavigatorHeading
          title="Buy"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/crypto");
              }}
            />
          }
        />
      </Box>
      <Box mt={2}>
        <CustomizedSteppers
          step={step}
          steps={["Method", "Network", "Asset", "Amount", "Pay with", "Buy"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />

        <Box mt={5}>
          {step == 0 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    textAlign: "left",
                    color: "rgba(26, 28, 32, 0.5)",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                    width: "100%",
                    paddingBottom: "20px",
                  }}
                >
                  Select the method you want to buy crypto with
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    title="Crypto"
                    onClick={() => {
                      setActiveTab("crypto");
                      setStep(1);
                    }}
                    style={{
                      backgroundColor: "rgba(247, 247, 247, 1)",
                      width: "95%",
                      marginRight: 15,
                    }}
                    textStyle={{ color: "black" }}
                  />
                  <Button
                    title="Fiat"
                    onClick={() => {
                      setActiveTab("fiat");
                      setStep(1);
                    }}
                    style={{
                      backgroundColor: "rgba(247, 247, 247, 1)",
                      width: "95%",
                    }}
                    textStyle={{ color: "black" }}
                  />
                </div>
              </Grid>
            </Grid>
          )}
          {step == 1 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <NetworksList
                  nextStep={() => setStep(2)}
                  title="Select which network you want to receive crypto on"
                />
              </Grid>
            </Grid>
          )}
          {step == 2 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    textAlign: "left",
                    color: "rgba(26, 28, 32, 0.5)",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                    width: "100%",
                    paddingBottom: "20px",
                  }}
                >
                  Select the token you want to Buy
                </Typography>
                <ReceiveTokenList
                  nextStep={() => setStep(3)}
                  setIsCashSelected={() => setIsCashSelected(!isCashSelected)}
                  isCashSupported={false}
                  swapTokens={activeTab === "fiat" ? cryptoList : swapTokens}
                  loading={loading}
                  type="buy"
                  activeTab={activeTab}
                />
              </Grid>
            </Grid>
          )}
          {step == 3 && (
            <Grid container display="flex" justifyContent="center" py={4}>
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "17px",
                      textAlign: "left",
                      color: "rgba(26, 28, 32, 0.5)",
                      fontFamily: "Helvetica Neue",
                      fontWeight: 500,
                      width: "100%",
                    }}
                  >
                    Enter amount to buy
                  </Typography>
                  <TokenInputForm
                    placeHolder={
                      Number(tokenB.tokenBalance) &&
                      Number(tokenB.tokenBalance) < 0.0001
                        ? "<0.0001"
                        : tokenB.tokenBalance.toString()
                    }
                    title="Crypto Tag"
                    addBorder
                    type="number"
                    onChange={handleAmountChange}
                    onKeydown={handleKeyDown}
                    value={value}
                    receiverENS={to}
                    isDepositValid={!!isValid}
                    nextStep={onAmountContinue}
                    tokenName={tokenB.tokenSymbol.toUpperCase()}
                    tokenIcon={tokenB.image}
                    buttonTitle="Continue"
                    errorMessage={error}
                    loading={loading}
                    decimals={tokenB.tokenDecimal}
                  />
                </Box>
              </Grid>
            </Grid>
          )}

          {step == 4 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "17px",
                      textAlign: "left",
                      color: "rgba(26, 28, 32, 0.5)",
                      fontFamily: "Helvetica Neue",
                      fontWeight: 500,
                      width: "100%",
                      margin: "5px 0px 5px 0px",
                    }}
                  >
                    Select how you want to pay
                  </Typography>

                  {activeTab === "crypto" ? (
                    <>
                      <TokensListTable
                        transactionForm={true}
                        nextStep={verifyBalances}
                        chainId={activeNetwork}
                        isApplyFilter={false}
                        isShowTokenAmountUnderName
                      />
                    </>
                  ) : (
                    <BuyFiat
                      setPaymentMethod={setPaymentMethod}
                      setActiveCurrency={setActiveCurrency}
                      setActiveCurrencyIcon={setActiveCurrencyIcon}
                      setSelectedPaymentMethod={setSelectedPaymentMethod}
                      activeCurrency={activeCurrency}
                      paymentMethod={paymentMethod}
                      nextStep={setStep}
                      tokenAddress={tokenB.tokenAddress}
                      setActiveCryptoOnRamperId={setActiveCryptoOnRamperId}
                      tokenSymbol={tokenB.tokenSymbol}
                      activeCryptoOnRamperId={activeCryptoOnRamperId}
                      type="buy"
                      fiatList={fiatList}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          )}

          {step == 5 &&
            (activeTab === "crypto" ? (
              <SwapSummary
                usdcBalance={usdcBalance}
                setStep={() => setStep(0)}
              />
            ) : (
              <BuyFiatPreview
                selectedPaymentMethod={selectedPaymentMethod}
                currencyIcon={activeCurrencyIcon}
                currencySymbol={activeCurrency}
                tokenIcon={tokenB.image}
                tokenPriceInUsd={tokenB.tokenPrice}
                tokenSymbol={tokenB.tokenSymbol}
                value={value}
                activeCryptoOnRamperId={activeCryptoOnRamperId}
                type="buy"
                nextStep={() => setStep(3)}
                setError={setError}
              />
            ))}
        </Box>
      </Box>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Funds are not enough to use as gas fees"
      />
    </>
  );
};

export default CryptoBuy;
