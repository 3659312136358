import { SupportedChainId } from "constants/chains";
import { Chain, base, polygon } from "viem/chains";

export const getChain = (chainId: SupportedChainId) => {
  let chain: Chain;
  if (chainId == polygon.id) {
    chain = polygon;
  } else if (chainId == base.id) chain = base;
  else {
    throw new Error(`unknown chain pm not supported ${chainId}`);
  }

  return chain;
};
