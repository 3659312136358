import React, { useEffect, useState } from "react";
import Web3 from "web3";
import MidArrow from "assets/midArrow.svg";
import BlueTick from "assets/BlueTick.svg";
import "./index.css";
import { Box, Grid, Skeleton, Snackbar, Typography } from "@mui/material";
import BasicButton from "components/Button";
import { useAppDispatch, useAppSelector } from "store/store";
import { SUPPORTED_NETWORKS, SupportedChainNames } from "constants/chains";
import {
  decryptMessage,
  fetchUsdPriceFromMobula,
  showAlert,
} from "utils/utils";
import {
  setPendingTx,
  setPendingTxDetails,
  setUserSpendingDetails,
} from "@slices/appSlice";
import { ethers, BigNumber as ethBigNumber } from "ethers";
import { useNavigate } from "react-router-dom";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import NetworksList from "components/NetworksList";
import TransactionContactList from "../../../../components/TransactionContactList";
import SearchInput from "components/SearchInput";
import ReviewAssetListComponent from "./SendAsset/ReviewAssetListComponent";

import { sendUserOp } from "../../../../contract-integration";

import { Erc1155ABI } from "abis/Erc1155ABI";
import { Erc721ABI } from "abis/Erc721ABI";
import {
  ExecuteCall,
  TransferData,
} from "../../../../contract-integration/constants";
import { txSubmissionOrderPrepaid } from "../../../../contract-integration/prepaidGas";
import SendTxComponent from "../../../../components/SendTxComponent";
import FeeUIComponent from "../../../../components/SendTxComponent/FeeUIComponent";
import NFTCard from "../NFTCard";
import { getChain } from "utils/chains";
import { getProtocolFee, getUsdcBalance } from "utils/balance";
import { getApproveTokenCallData } from "stash-fe-script";
import BigNumber from "bignumber.js";
import { APPROVE_AMOUNT, USDT_URL, ZERO_ADDRESS } from "constants/";
import { checkGasAllowance } from "utils/swap";
import { isCryptoAccountDeployed } from "utils/deployed";

const SendNFT = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [to, setTo] = useState("");

  const [gasFeeInUSD, setGasFeeInUSD] = useState("0");
  const [tag, setTag] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [step, setStep] = useState(0);
  const [tokenForPayment, SetTokenForPayment] = React.useState("");
  const [txByDeposited, setTxByDeposited] = useState(false);

  const [error, setError] = useState(false);

  const [finalOpState, setFinalOpState] = useState<any>(null);
  const [allowance, setAllowance] = useState(0);

  const navigate = useNavigate();
  const {
    activeAccount,
    activeNetwork,
    rootAccountInfo,
    newNFTs,
    userSpendingDetails,
    gas,
  } = useAppSelector((state) => state.app);

  const allNfts = newNFTs[activeAccount.smartAccountAddress];

  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const { block_explorer_url, wethAddress, usdcAddress, bundlerRPC } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const dispatch = useAppDispatch();

  // read if the url is like `/nfts/${NftColName}/${chainName}/${nftId}/send`
  // if yes, then fetch the NFT from the store and setSelectedNFT
  const currentURL = window.location.href;
  const currentURLArray = currentURL.split("/");
  const nftId = currentURLArray[currentURLArray.length - 2];
  const chainName = currentURLArray[currentURLArray.length - 3];

  const currentNFT = newNFTs[activeAccount.smartAccountAddress].find(
    (item) => item.token_id == nftId && item.blockchain == chainName
  );

  useEffect(() => {
    SetTokenForPayment("");
    currentNFT && setSelectedNFT(currentNFT);
  }, []);

  useEffect(() => {
    (async () => {
      const allowance = await checkGasAllowance(
        activeAccount.smartAccountAddress,
        usdcAddress,
        activeNetwork
      );
      setAllowance(Number(allowance));
    })();
  }, []);

  const [searchText, setSearchText] = useState<string>("");

  const groupByCollection = (array: any[], key: string) => {
    return array.reduce((result: any, currentItem: any) => {
      const chainNumber = Number(
        SupportedChainNames[currentItem.blockchain as any]
      );

      if (chainNumber == activeNetwork) {
        (result[currentItem[key]] = result[currentItem[key]] || []).push(
          currentItem
        );
      }
      return result;
    }, {});
  };

  const collections = groupByCollection(allNfts, "name");
  console.log("file: index.tsx:222  SendNFT  collections:", collections);

  const [selectedNFT, setSelectedNFT] = useState<any>(null);
  console.log("🚀 ~ file: index.tsx:294 ~ SendNFT ~ selectedNFT:", selectedNFT);

  const ISNATIVE =
    tokenForPayment == ZERO_ADDRESS || tokenForPayment == wethAddress;

  const executeSendCrypto = async () => {
    try {
      setLoading(true);
      if (finalOpState) {
        const response = await sendUserOp(
          finalOpState,
          "https://api.stackup.sh/v1/node/221b5cfa6d4f5cff2e221d693b2e953d49d9797d0f18f2e6d119482223a92a37",
          "https://polygon-mainnet.g.alchemy.com/v2/HBxGEElD4fSo3gWukvZFV9YTKO4OvCnw"
        );
        const userOPResponse: any = await response.wait();
        console.log("userOp Hash :", response.userOpHash);
        console.log("Tx Hash :", userOPResponse?.transactionHash);
        console.log("success status :", userOPResponse?.args.success);
        console.log(
          "actualGasCost  :",
          Number(userOPResponse?.args.actualGasCost)
        );
        // const response = await sendUserOp(finalOp, bundlerRPC, rpcEndpoint);
        // const userOPResponse: any = await response.wait();
        // console.log("Tx Hash :", userOPResponse?.transactionHash);
        // console.log("success status :", userOPResponse?.args.success);
        console.log(
          "actualGasCost  :",
          Number(userOPResponse?.args.actualGasCost)
        );
        console.log(
          "actualGasUsed  :",
          Number(userOPResponse?.args.actualGasUsed)
        );

        dispatch(
          setPendingTxDetails({
            value: allowance ? value : 0,
            valueIn$: "",
            transferAmount: allowance ? value : 0,
            transactionMethod: "NFT",
            scanLink: block_explorer_url,
            eoaEns: rootAccountInfo.name,
            addressEns: activeAccount.accountName,
            toAddressEns: allowance ? tag || "" : usdcAddress,
            toAddress: allowance ? to : usdcAddress,
            assetName: allowance
              ? `${selectedNFT?.name} #${selectedNFT?.token_id}`
              : "USDC",
            networkFeesIn$: gasFeeInUSD,
            iconURL: allowance ? selectedNFT?.metadata?.image : USDT_URL,
            txByDesposited: txByDeposited,
            action: allowance ? "Sent" : "Approved",
          })
        );

        dispatch(setPendingTx(userOPResponse?.transactionHash));

        showAlert(
          "Soon you can see your transaction in the transactions tab",
          allowance
            ? "Transaction Submitted"
            : "Approval Transaction Submitted",
          `<a href="https://polygonscan.com/tx/${userOPResponse?.transactionHash}" target="_blank">View on Polygonscan</a>`
        );
      }
      if (userSpendingDetails.isFirstTx && userSpendingDetails.isFirstTxInApp) {
        dispatch(
          setUserSpendingDetails({
            isFirstTxInApp: false,
            isFirstTx: false,
          })
        );
        navigate("/transaction-success");
      } else {
        setFinalOpState(null);
        setLoading(false);
        navigate("/nfts");
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      showAlert("Something went wrong");
    }
  };

  useEffect(() => {
    console.log("file: index.tsx:439  useEffect  selectedNFT:", {
      selectedNFT,
      tokenForPayment,
    });
    if (selectedNFT && to && !txByDeposited) {
      newSendTransaction();
    }
  }, [selectedNFT, step]);

  //INTEGRATE WITH SDK
  async function newSendTransaction() {
    try {
      setError(false);
      setLoading(true);

      setFinalOpState(null);

      if (
        // !tokenForPayment.trim() ||
        !to.trim() ||
        !activeNetwork
        // !tokenForPaymentDecimal
      ) {
        window.alert("Please provide all details");
        setLoading(false);

        return;
      }

      const usdcBalance = await getUsdcBalance(
        activeAccount.smartAccountAddress,
        activeNetwork
      );
      console.log("usdcBalance", usdcBalance);

      if (!usdcBalance && !gas.totalBalance) {
        showAlert("You do not have USDC balance to top up gas tank.");
        setLoading(false);
        return;
      }
      const rpcEndpoint =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
          .alchemy_url;

      const pkey = decryptMessage(activeAccount.secret, hashedPassword);
      const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);
      const stackupProvider = new ethers.providers.JsonRpcProvider(bundlerRPC);

      const wallet = new ethers.Wallet(pkey, provider);

      const web3 = new Web3(rpcEndpoint);

      let userCallDataArray: ExecuteCall[] = [];

      const chain = getChain(activeNetwork);

      const approveCallData = getApproveTokenCallData(
        APPROVE_AMOUNT,

        chain,
        usdcAddress
      );

      if (!allowance) {
        userCallDataArray.push(approveCallData);
      } else {
        const tokenAddress = selectedNFT?.token_address;

        const contract = new web3.eth.Contract(
          (selectedNFT?.contract_type === "ERC1155"
            ? Erc1155ABI?.abi
            : Erc721ABI?.abi) as any,
          selectedNFT?.token_address
        );

        // For ERC-1155, safeTransferFrom has an additional parameter for the amount of tokens to transfer
        const call0 =
          selectedNFT?.contract_type === "ERC1155"
            ? contract.methods
                .safeTransferFrom(
                  activeAccount.smartAccountAddress,
                  to,
                  selectedNFT?.token_id,
                  1,
                  "0x"
                )
                .encodeABI()
            : contract.methods
                .safeTransferFrom(
                  activeAccount.smartAccountAddress,
                  to,
                  selectedNFT?.token_id
                )
                .encodeABI();

        userCallDataArray.push({
          to: tokenAddress,
          value: "0",
          calldata: call0,
        });
      }

      const cryptoAccountDeployed = await isCryptoAccountDeployed(
        activeNetwork,
        activeAccount.smartAccountAddress
      );

      const { finalOp, usdcFee } = await txSubmissionOrderPrepaid({
        userCallDataArray,

        wallet,

        isAccountDeployed: cryptoAccountDeployed,
        chain,

        smartAccountAddress: activeAccount.smartAccountAddress,
        provider: stackupProvider,
        approval: allowance ? true : false,
        extraTopupAmount: "0",
        gasBalance: gas.totalBalance,
        rootAddress: rootAccountInfo.address.toLowerCase(),
      });
      console.log(
        "file: index.tsx:483  newSendTransaction  counterfactual:",

        userCallDataArray
      );

      console.log("Clicked!");

      console.log(
        "file: index.tsx:1058  sendCryptoTransactionfinal  finalOp:",
        finalOp
      );

      setFinalOpState(finalOp);
      setLoading(false);
      setGasFeeInUSD(Number(usdcFee).toFixed(4));
    } catch (e: any) {
      console.log(
        "file: index.tsx:674  newSendTransaction  e:",
        e?.message?.includes("ERC")
      );

      if (e?.message?.includes("insufficient balance for transfer")) {
        showAlert("Cannot transfer this NFT");
      } else {
        showAlert("Transaction Failed");
      }
      setLoading(false);
    }
  }

  console.log(collections, "collectionscollections");

  console.log("setTo and setTag", { to, tag });

  return (
    <>
      <Box mt={4} sx={{ width: "95%" }}>
        <NavigatorHeading
          title="Send NFT"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/nfts");
              }}
            />
          }
        />
      </Box>
      <Box mt={2}>
        <CustomizedSteppers
          step={step}
          steps={
            currentNFT
              ? ["Recipient", "Send"]
              : ["Network", "Recipient", "Choose NFT", "Send"]
          }
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />

        <Box mt={4}>
          {!currentNFT && step == 0 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "65%",
                  maxWidth: "100%",
                }}
              >
                <NetworksList
                  nextStep={() => setStep(1)}
                  title="Select which network you want to send your NFT on"
                />
              </Grid>
            </Grid>
          )}
          {!currentNFT && step == 1 && (
            <Box display={"flex"} flexDirection={"column"}>
              <Grid container display="flex" justifyContent="center">
                <Grid
                  item
                  lg={12}
                  sm={12}
                  style={{
                    flexBasis: "100%",
                    maxWidth: "68%",
                  }}
                >
                  <TransactionContactList
                    shouldAddContact={false}
                    isTxForm
                    isChooseRecipient={true}
                    nextStep={(address: string, tag: string) => {
                      setStep(2);
                      setTo(address);
                      setTag(tag);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          {!currentNFT && step == 2 && (
            <Grid container display="flex" justifyContent="center" py={"10px"}>
              <Grid
                item
                lg={12}
                sm={12}
                style={{
                  flexBasis: "100%",
                  maxWidth: "68%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <SearchInput
                    handleOnChange={(e: any) => {
                      setSearchText(e.target.value);
                    }}
                    placeholder="Search NFT"
                  />
                </Box>
                {/* MAP THROUGH COLLECTIONS OBJECT AND RENDER NFT */}

                {Object.keys(collections).length > 0 ? (
                  Object.keys(collections).map((key, i) => (
                    <Box key={i}>
                      <Typography
                        fontFamily={"Space Grotesk"}
                        fontSize={"16px"}
                        fontWeight={"700"}
                        color={"rgba(26, 28, 32, 1)"}
                        pt={"30px"}
                        pb={"20px"}
                      >
                        {key}
                        <span>
                          <img
                            src={BlueTick}
                            style={{ marginLeft: "5px", marginBottom: "-2px" }}
                            alt={"blue tick"}
                          />
                        </span>
                      </Typography>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        flexDirection={"row"}
                        sx={{
                          gap: "8.5px",
                        }}
                      >
                        {collections[key]
                          .filter((nft: any) => {
                            const name = nft?.metadata?.name || nft?.name;
                            return name
                              ?.toLowerCase()
                              ?.includes(searchText?.toLowerCase());
                          })
                          .map((nft: any, index: any) => (
                            <Box
                              sx={{
                                backgroundColor: "rgba(255, 255, 255, 1)",
                                borderRadius: "8px",
                                boxShadow:
                                  "0px 4px 10px rgba(24, 39, 75, 0.08)",

                                cursor: "pointer",
                                borderColor:
                                  selectedNFT?.token_id === nft?.token_id
                                    ? "rgba(26, 28, 32, 1)"
                                    : "rgba(255, 255, 255, 1)",
                                borderWidth: "0.5px",
                                borderStyle: "solid",
                              }}
                              onClick={() => {
                                //if nft is already selected, clicking on it again will unselect it
                                if (selectedNFT?.token_id === nft?.token_id) {
                                  setSelectedNFT(null);
                                } else {
                                  setSelectedNFT(nft);
                                }
                              }}
                            >
                              <NFTCard
                                name={nft?.name || nft?.metadata?.name}
                                imageSrc={
                                  nft?.metadata?.image ??
                                  "https://thientu.vn/userfiles/files/nyan-cat-game%204.gif"
                                }
                                nftId={nft.token_id}
                                chainName={nft.blockchain}
                              />
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Box textAlign={"center"} py={5}>
                    <Typography variant="h3">
                      No NFTs found on selected chain
                    </Typography>
                  </Box>
                )}
              </Grid>
              {
                // Conditional rendering of the Continue button
                selectedNFT && (
                  <div
                    className={`continue-button ${selectedNFT ? "show" : ""}`}
                  >
                    <BasicButton
                      title={"Continue"}
                      onClick={() => {
                        setStep(3);
                      }}
                      loading={loading}
                      mode={"active"}
                      id="send"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>
                )
              }
            </Grid>
          )}
          {!currentNFT && step == 3 && (
            <Grid
              container
              display="flex"
              justifyContent="center"
              py={0}
              style={{
                width: "100%",
                flexBasis: "100%",
                maxWidth: "100%",
              }}
            >
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "100%",
                  maxWidth: "68%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={1}
                  alignItems={"center"}
                >
                  <Box
                    width={"100%"}
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <ReviewAssetListComponent
                      isNFTComponent={true}
                      selectedNFT={selectedNFT}
                    />
                    <Box
                      component={"img"}
                      src={MidArrow}
                      width={"33px"}
                      height={"33px"}
                      mt={"-8px"}
                      position={"relative"}
                    />
                    <ReviewAssetListComponent
                      isNFTComponent={false}
                      recipient={tag ? tag : to}
                    />
                  </Box>
                  <FeeUIComponent
                    gasFeeInUSD={gasFeeInUSD}
                    loading={!finalOpState && loading}
                  />
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={50}
                      sx={{
                        borderRadius: "10px",

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  ) : (
                    <BasicButton
                      title={allowance ? "Send" : "Approve Paymaster"}
                      onClick={
                        error || !finalOpState
                          ? () => {
                              console.log("abc");
                            }
                          : executeSendCrypto
                      }
                      style={{
                        cursor:
                          error || !finalOpState ? "not-allowed" : "pointer",
                        height: "15px",
                      }}
                      className="transfer-btn"
                      loading={loading}
                      mode={"active"}
                      id="send"
                    />
                  )}

                  {/* <SendTxComponent
                    loading={loading}
                    // SetTokenForPayment={SetTokenForPayment}

                    txByDeposited={txByDeposited}
                    handleSend={executeSendCrypto}
                    finalOpState={finalOpState}
                    error={error}
                    isSwap={false}
                  >
                    <FeeUIComponent
                      gasFeeInUSD={gasFeeInUSD}
                      loading={!finalOpState && loading}
                    />
                  </SendTxComponent> */}
                </Box>
              </Grid>
            </Grid>
          )}

          {currentNFT && step == 0 && (
            <Box display={"flex"} flexDirection={"column"}>
              <Grid container display="flex" justifyContent="center">
                <Grid
                  item
                  lg={12}
                  sm={12}
                  style={{
                    flexBasis: "100%",
                    maxWidth: "55%",
                  }}
                >
                  <TransactionContactList
                    shouldAddContact={false}
                    isTxForm
                    isChooseRecipient={true}
                    nextStep={(address: string, tag: string) => {
                      setStep(1);
                      setTo(address);
                      setTag(tag);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          {currentNFT && step == 1 && (
            <Grid container display="flex" justifyContent="center" py={4}>
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "100%",
                  maxWidth: "55%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  gap={2}
                  alignItems={"center"}
                >
                  <Box
                    width={"100%"}
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <ReviewAssetListComponent
                      isNFTComponent={true}
                      selectedNFT={selectedNFT}
                    />
                    <Box
                      component={"img"}
                      src={MidArrow}
                      width={"33px"}
                      height={"33px"}
                      mt={"-8px"}
                      position={"relative"}
                    />
                    <ReviewAssetListComponent
                      isNFTComponent={false}
                      recipient={tag ? tag : to}
                    />
                  </Box>
                  <FeeUIComponent
                    gasFeeInUSD={gasFeeInUSD}
                    loading={!finalOpState && loading}
                  />
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={50}
                      sx={{
                        borderRadius: "10px",

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  ) : (
                    <BasicButton
                      title={allowance ? "Send" : "Approve Paymaster"}
                      onClick={
                        error || !finalOpState
                          ? () => {
                              console.log("abc");
                            }
                          : executeSendCrypto
                      }
                      style={{
                        cursor:
                          error || !finalOpState ? "not-allowed" : "pointer",
                        height: "15px",
                      }}
                      className="transfer-btn"
                      loading={loading}
                      mode={"active"}
                      id="send"
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Funds are not enough to use as gas fees"
      />
    </>
  );
};

export default SendNFT;
