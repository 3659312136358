import { Box, IconButton } from "@mui/material";
import { useEffect, useState } from "react";

import "./index.css";

import TabChip from "components/TabChip";

import ChipButton from "components/ChipButton";
import SettingsIcon from "assets/setting.svg";
import ActiveSettingsIcon from "assets/active-settings.svg";

import { useLocation, useNavigate } from "react-router";

const TabBar = () => {
  const [activeTab, setActiveTab] = useState("assets");
  const [showTabs, setShowTabs] = useState(false);
  const [page, setPage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const pages = ["cash", "crypto", "tokens", "gas", "nfts"];

  useEffect(() => {
    const paths: string[] = location.pathname.split("/");

    console.log(paths[1]);
    console.log(paths[2]);

    // if current page is in the list of pages that have tabs
    if (pages.includes(paths[1])) {
      setShowTabs(true);
      setPage(paths[1]);

      // if path is already set for transactions
      if (paths[2] === "transactions") {
        setActiveTab("transactions");
      } else {
        setActiveTab("assets");
      }
    } else {
      setShowTabs(false);
    }
    return () => {};
  }, [location]);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          flexFlow: "wrap",
          marginRight: 20,
        }}
      >
        <Box display="flex">
          {showTabs && (
            <>
              <Box sx={{ mr: 1.25 }}>
                <TabChip
                  title={location.pathname.includes("cash") ? "Bank" : "Assets"}
                  onClick={() => {
                    setActiveTab("assets");
                    navigate(`/${page}`);
                  }}
                  mode={activeTab === "assets" ? "chip" : "text"}
                />
              </Box>
              <Box>
                <TabChip
                  title="Transactions"
                  onClick={() => {
                    setActiveTab("transactions");
                    navigate(`/${page}/transactions`);
                  }}
                  mode={activeTab === "transactions" ? "chip" : "text"}
                />
              </Box>
            </>
          )}
        </Box>
        <Box display="flex" gap={"10px"}>
          <Box>
            {/* STS-130 Remove Market button from the navbar */}
            {/* <ChipButton
              title="Markets"
              onClick={() => {}}
              fontFamily="Space Grotesk"
              fontSize={12}
              chipHeight={36}
            /> */}
          </Box>
          <Box
            onClick={() => {
              navigate("/settings");
            }}
          >
            <IconButton
              aria-label="settings"
              disableRipple={true}
              sx={
                {
                  // backgroundColor: !location.pathname.includes("/settings")
                  //   ? "rgba(235, 236, 238, 1)"
                  //   : "",
                  // padding: "6px",
                }
              }
            >
              <img
                src={
                  location.pathname.includes("/settings")
                    ? ActiveSettingsIcon
                    : SettingsIcon
                }
                height={38}
              />
            </IconButton>
          </Box>
          {/* <Box
            onClick={() => {
              navigate('/settings2')
            }}
          >
            <IconButton
              aria-label='settings'
              disableRipple={true}
              sx={{
                backgroundColor: 'rgba(235, 236, 238, 1)',
                padding: '6px',
              }}
            >
              <img src={SettingsIcon} height={24} width={24} />
            </IconButton>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default TabBar;
