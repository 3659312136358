import { Typography } from "@mui/material";
import TokenImage from "components/TokenImage";
import { USDT_URL } from "constants";
import millify from "millify";
import React, { FC } from "react";
import { truncateToken } from "utils/utils";

interface GasTokenProps {
  tokenBalance: number;
  priceInUSD: number;
  symbol: string;
  name: string;
}

const GasToken: FC<GasTokenProps> = ({
  priceInUSD,
  tokenBalance,
  name,
  symbol,
}) => {
  return (
    <div
      style={{
        alignItems: "center",

        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        background: "#EDEEF2",
        margin: "20px 0px 20px 0px",
        padding: 10,
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          alignItems: "center",
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <TokenImage logo={USDT_URL} />
        <div
          style={{
            color: "#000000",
            marginLeft: 8,
            lineHeight: "18px",
          }}
        >
          {truncateToken(name)}
          <br />

          <div
            style={{
              color: "#8C8D8F",
              textAlign: "left",
              fontFamily: "Helvetica Neue",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "20px",
            }}
          >
            {!tokenBalance
              ? 0
              : tokenBalance < 0.0001
              ? "< 0.0001"
              : millify(tokenBalance, {
                  precision: 4,
                })}{" "}
            {symbol}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GasToken;
