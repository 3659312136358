import {
  initialPendingTxValues,
  setCashPendingTx,
  setCashTransactions,
  setPendingTx,
  setPendingTxDetails,
  setTransactions,
} from "@slices/appSlice";
import { setTxStatus } from "@slices/walletSlice";
import {
  CASH_SUPPORTED_NETWORK,
  SUPPORTED_NETWORKS,
  SupportedChainId,
} from "constants/chains";

import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getUserOpHashData } from "utils/jiffyscan";
import { showNotification } from "utils/notification";
import {
  decryptMessage,
  fetchUsdPriceFromMobula,
  initalizeWeb3,
  saveTransaction,
} from "utils/utils";

const useCashHashConfirmation = () => {
  const {
    activeNetwork,
    cashPendingTx,
    pendingTxDetails,
    accounts,
    rootAccountInfo,
    activeAccount,
    cashTransactions,
  } = useAppSelector((state) => state.app);
  const { txStatus, hashedPassword } = useAppSelector((state) => state.wallet);

  const dispatch = useAppDispatch();

  useEffect(() => {
    let timer;
    (async () => {
      if (cashPendingTx && hashedPassword) {
        timer = setInterval(async () => {
          try {
            // const data = await getUserOpHashData(cashPendingTx, activeNetwork);
            const web3 = initalizeWeb3(SupportedChainId.BASE);
            const res = await web3.eth.getTransactionReceipt(cashPendingTx);
            const { block_explorer_url } =
              CASH_SUPPORTED_NETWORK[SupportedChainId.BASE];

            if (res) {
              if (res.status) {
                const gasUsed = res.gasUsed / 10 ** 9;
                console.log("gasUsed", gasUsed);
                const gasPrice = res.effectiveGasPrice / 10 ** 9;
                console.log("gasPrice", gasPrice);

                const txCost = gasUsed * gasPrice;
                console.log("txCost", txCost);

                const balanceInUsd = await fetchUsdPriceFromMobula("ethereum");

                const feeInUsd = txCost * balanceInUsd;

                console.log("FEEEEEEEE", feeInUsd);
                const pkey = decryptMessage(
                  rootAccountInfo.secret,
                  hashedPassword
                );
                const {
                  value,
                  valueIn$,
                  transferAmount,
                  transactionMethod,
                  scanLink,
                  eoaEns,
                  addressEns,
                  toAddressEns,
                  toAddress,
                  assetName,
                  networkFeesIn$,
                  iconURL,
                  txByDesposited,
                  action,
                } = pendingTxDetails;
                console.log(
                  "file: useCashHashConfirmation.tsx:73  timer=setInterval  action:",
                  action
                );
                const signature = web3.eth.accounts.sign(
                  `${res.transactionHash}-${feeInUsd}`,
                  pkey
                );

                await saveTransaction(
                  rootAccountInfo.smartAccountAddress.toLowerCase(),
                  rootAccountInfo.address.toLowerCase(),
                  res.transactionHash,
                  SupportedChainId.BASE,
                  Number(feeInUsd),
                  signature.signature,
                  value ?? "",
                  valueIn$ ?? "",
                  transferAmount ?? "",
                  transactionMethod ?? "",
                  `${block_explorer_url}/tx/${res.transactionHash}` ?? "",
                  eoaEns ?? "",
                  addressEns ?? "",
                  toAddressEns ?? "",
                  toAddress ?? "",
                  assetName ?? "",
                  String(feeInUsd) ?? "",
                  iconURL ?? "",
                  txByDesposited ? Number(feeInUsd) : 0,
                  action,
                  pendingTxDetails?.appName ?? ""
                );

                const newTx = {
                  action,
                  address: rootAccountInfo.smartAccountAddress.toLowerCase(),
                  addressEns,
                  amount: value,
                  assetName,
                  distributionActive: false,
                  eoa: rootAccountInfo.address.toLowerCase(),
                  eoaEns,
                  hash: res.transactionHash,
                  iconURL,
                  network: SupportedChainId.BASE,
                  networkFeesIn$: String(feeInUsd),
                  paidWithGasTank: 0,
                  referedByUserAddress: "",
                  referedByUserReward: 0,
                  referedToUserCashback: 0,
                  scanLink: `${block_explorer_url}/tx/${res.transactionHash}`,
                  stashedFees: 0,
                  timestamp: new Date().getTime(),
                  toAddress,
                  toAddressEns,
                  transactionMethod: "Crypto",
                  transferAmount,
                  userReferalActive: true,
                  value,
                  valueIn$,
                };

                let newTransactions: any = {};

                newTransactions = [newTx, ...cashTransactions];

                console.log("newTr", newTransactions);

                dispatch(setCashTransactions(newTransactions));

                dispatch(setTxStatus(!txStatus));
                dispatch(setCashPendingTx(""));
                dispatch(setPendingTxDetails(initialPendingTxValues));

                showNotification(
                  "Transaction Successful",
                  "Your Transaction successfully completed",
                  `${block_explorer_url}/tx/${res.transactionHash}`
                );

                clearInterval(timer);
              } else {
                dispatch(setTxStatus(!txStatus));
                dispatch(setCashPendingTx(""));

                showNotification(
                  "Transaction Failed",
                  "Your Transaction Failed",
                  `${block_explorer_url}/tx/${res.transactionHash}`
                );
                clearInterval(timer);
              }
            }
          } catch (err) {
            dispatch(setTxStatus(!txStatus));
            dispatch(setCashPendingTx(""));
            clearInterval(timer);
          }
        }, 10000);
      }
    })();

    return () => {
      clearInterval(timer);
    };
  }, [cashPendingTx, hashedPassword]);

  return "";
};

export default useCashHashConfirmation;
