import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";

import RPC from "constants/webRPC";

export const loginWithGoogle = async (web3auth: Web3AuthNoModal) => {
  if (web3auth.connected) {
    await web3auth.logout();
  }
  return await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
    loginProvider: "google",
  });
};

export const init = async (path: string, mode?: string) => {
  try {
    const chainConfig = {
      chainNamespace: CHAIN_NAMESPACES.EIP155,
      chainId: "0x1",
      rpcTarget: `https://eth-mainnet.g.alchemy.com/v2/${process.env.ALCHEMY_MAINNET_API_KEY}`,
      displayName: "Ethereum Goerlo",
      blockExplorer: "https://etherscan.io",
      ticker: "ETH",
      tickerName: "Ethereum",
    };
    const web3auth = new Web3AuthNoModal({
      clientId: process.env.WEB3AUTH_CLIENT_ID!,
      chainConfig,
      web3AuthNetwork: "testnet",
    });

    const privateKeyProvider = new EthereumPrivateKeyProvider({
      config: { chainConfig },
    });

    const openloginAdapter = new OpenloginAdapter({
      privateKeyProvider,
      adapterSettings: {
        uxMode: mode ? "popup" : "redirect",
        redirectUrl: window.location.origin + path,
      },
    });
    web3auth.configureAdapter(openloginAdapter);

    // adding wallet connect v2 adapter

    await web3auth.init();

    return web3auth;
  } catch (error) {
    console.error(error);
  }
};
