import { Box, FormControl, Grid, Typography } from "@mui/material";
import {
  setPendingTx,
  setPendingTxDetails,
  setSwapDetails,
  setUserSpendingDetails,
} from "@slices/appSlice";
import NetworksList from "components/NetworksList";
import CustomizedSteppers from "components/Stepper";
import TokenInputForm from "components/TokenInputForm";
import TokensListTable from "pages/app/Crypto/TokensListTable";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import MidArrow from "assets/midArrow.svg";
import CashIcon from "assets/cash-token.svg";
import SendTxComponent from "components/SendTxComponent";
import FeeUIComponent from "components/SendTxComponent/FeeUIComponent";
import { getChain } from "utils/chains";
import { SUPPORTED_NETWORKS, SupportedChainId } from "constants/chains";
import {
  decryptMessage,
  fetchUsdPriceFromMobula,
  getAccrossMinDeposit,
  getWallet,
  showAlert,
} from "utils/utils";
import { BigNumber as ethNum, ethers, utils } from "ethers";
import Web3 from "web3";
import {
  APPROVE_AMOUNT,
  BASE_URL,
  NATIVE_ADDRESS,
  ZERO_ADDRESS,
} from "constants/";
import { ExecuteCall } from "stash-fe-script/constants";
import abi from "abis/erc20abi.json";
import { depositCalldata, depositCalldata1 } from "utils/bridge";
import {
  EMPTY_CALLDATA,
  getCounterFactualAddress,
} from "../../contract-integration/";
import axios from "axios";
import { txSubmissionOrderPrepaid } from "../../contract-integration/prepaidGas";
import { StashUserOpExt, getApproveTokenCallData } from "stash-fe-script";
import { useNavigate } from "react-router";
import { getProtocolFee, getUsdcBalance } from "../../utils/balance";
import BigNumber from "bignumber.js";

import { checkGasAllowance } from "../../utils/swap";
import { isCryptoAccountDeployed } from "utils/deployed";
import { Skeleton } from "@mui/material";
import BasicButton from "components/Button";

const Cashout = () => {
  const [finalOpState, setFinalOpState] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [gasFeeInUSD, setGasFeeInUSD] = useState("0");
  const [allowance, setAllowance] = useState(0);
  const navigate = useNavigate();

  const {
    activeAccount,
    holdings,
    activeNetwork,
    portfolio,
    rootAccountInfo,
    userSpendingDetails,
    swapDetails: { tokenA },
    gas,
  } = useAppSelector((state) => state.app);

  const { selectedToken, hashedPassword } = useAppSelector(
    (state) => state.wallet
  );

  const {
    symbol,
    icon,
    block_explorer_url,
    wethAddress,
    onRamperNetwork,
    stackupUrl,
    alchemy_url,
    usdcAddress,
    bundlerRPC,
  } = SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const dispatch = useAppDispatch();
  console.log("123");
  const { nativeBalance, nativeTokenPrice } =
    holdings[activeAccount.smartAccountAddress];

  const isSelectedToken = selectedToken.length > 0;
  const tokenSymbol = isSelectedToken ? selectedToken[0].tokenSymbol : symbol;
  const tokenIcon = isSelectedToken ? selectedToken[0].image : icon;

  const tokenBalance = isSelectedToken
    ? selectedToken[0].tokenBalance
    : nativeBalance;

  const tokenPriceInUsd = isSelectedToken
    ? selectedToken[0].tokenPrice
    : nativeTokenPrice;
  const tokenAddress = isSelectedToken ? selectedToken[0].tokenAddress : "";

  const handleAmountChange = (value) => {
    const inputValue = value;
    let regex = new RegExp(
      `^\\d{0,10}(\\.\\d{0,${selectedToken[0].tokenDecimal}})?$`
    );

    if (/[^0-9.]/.test(inputValue)) {
      setError("");
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setError("");
    } else if (!regex.test(inputValue)) {
      setError(
        `Maximum of 10 digits before decimals and ${selectedToken[0].tokenDecimal} digits after decimals are allowed`
      );
    } else if (tokenBalance && Number(inputValue) > tokenBalance) {
      setError("Insufficient balance!");
      setValue(inputValue);
    } else {
      setError("");

      setValue(inputValue);
    }
    // else if (parseFloat(inputValue) * tokenPriceInUsd < minDeposit) {
    //   setError(`Value should not be less than $${minDeposit}`);

    //   setValue(inputValue);
    // }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      if (/[^0-9.]/.test(newValue)) {
        setError("Special characters are not allowed");
      } else if (tokenBalance && Number(newValue) > tokenBalance) {
        setError("Insufficient balance!");
        setValue(newValue);
      } else {
        setValue(newValue);
        setError("");
      }
      // else if (parseFloat(newValue) * tokenPriceInUsd < minDeposit) {
      //   setError(`Value should not be less than $${minDeposit}`);

      //   setValue(newValue);
      // }
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (/[^0-9.]/.test(newValue)) {
        setError("Special characters are not allowed");
      } else if (tokenBalance && Number(newValue) > tokenBalance) {
        setError("Insufficient balance!");
        setValue(newValue);
      } else {
        if (Number(newValue) > 0) {
          setValue(newValue);
        }
        setError("");
      }

      // else if (parseFloat(newValue) * tokenPriceInUsd < minDeposit) {
      //   setError(`Value should not be less than $${minDeposit}`);

      //   setValue(newValue);
      // }
    }
  };
  const provider = new ethers.providers.JsonRpcProvider(alchemy_url);
  const stackupProvider = new ethers.providers.JsonRpcProvider(bundlerRPC);

  useEffect(() => {
    if (selectedToken.length > 0 && value && step === 3) {
      sendCryptoTransaction();
    }
    if (step < 2) {
      setValue("");
    }
  }, [selectedToken, step]);
  const [minDeposit, setMinDeposit] = useState(2.01);
  // useEffect(() => {
  //   (async () => {
  //     const accrossDepositValues = await getAccrossMinDeposit();
  //     console.log("accrossDepositValues", accrossDepositValues);
  //     if (!accrossDepositValues?.minDeposit) return;
  //     setMinDeposit(accrossDepositValues?.minDeposit);
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      const allowance = await checkGasAllowance(
        activeAccount.smartAccountAddress,
        usdcAddress,
        activeNetwork
      );
      setAllowance(Number(allowance));
    })();
  }, [loading]);
  //change with sdk
  async function sendCryptoTransaction() {
    try {
      console.log("sendCryptoTransaction STARTED");
      const chain = getChain(activeNetwork);
      setLoading(true);
      const {
        accrossAddress,
        squidUsdcSupportedAddress,
        alchemy_url,
        usdcAddress,
        usdcDecimals,
        factoryAddress,
      } = SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

      const wallet = await getWallet(
        activeNetwork,
        activeAccount.secret,
        hashedPassword
      );

      if (!wallet) return;

      const web3 = new Web3(alchemy_url);
      const nonce = await web3.eth.getTransactionCount(
        activeAccount.smartAccountAddress,
        "latest"
      );

      const tokenAddress = selectedToken[0]?.tokenAddress;
      const tokenDecimal = selectedToken[0]?.tokenDecimal;
      console.log("ye from token ka data", {
        tokenAddress,
        tokenDecimal,
        selectedToken,
      });
      const ISNATIVE =
        tokenAddress.toLowerCase() == NATIVE_ADDRESS.toLowerCase() ||
        tokenAddress.toLowerCase() == wethAddress;

      let decimals;
      let userCallDataArray: ExecuteCall[] = [];

      //remove
      const gasLimits = {
        approve: "59103",
        swapExactInput: "135300",
        swapExactOutput: "141660",
        deposit: "92452",
      };

      if (ISNATIVE) {
        decimals = 18;
      } else {
        const contract = new web3.eth.Contract(
          //@ts-ignore
          abi.abi,
          tokenAddress
        );
        decimals = await contract.methods.decimals().call();
        console.log("decimals", decimals);
      }

      const usdcBalance = await getUsdcBalance(
        activeAccount.smartAccountAddress,
        activeNetwork
      );
      console.log("usdcBalance", usdcBalance);

      if (!usdcBalance && !gas.totalBalance) {
        setLoading(false);
        showAlert("You do not have USDC balance to top up gas tank.");
        return;
      }

      const approveCallData = getApproveTokenCallData(
        APPROVE_AMOUNT,

        chain,
        usdcAddress
      );
      console.log("allowance -+", allowance);
      if (!allowance) {
        userCallDataArray.push(approveCallData);
      } else {
        const { secret } = activeAccount;
        console.log(
          "value",
          value,
          utils.parseUnits(value.toString(), tokenDecimal).toString()
        );
        const data = {
          recipient: rootAccountInfo.smartAccountAddress,
          sender: activeAccount.smartAccountAddress,
          originToken: tokenAddress,
          originTokenDecimals: Number(tokenDecimal),
          amount: utils.parseUnits(value.toString(), tokenDecimal).toString(), // incase of exactInput send the originToken value
          //why is it hardcoded
          destinationChainId: SupportedChainId.BASE,
          nonce,
        };
        const transactionOpts = {
          toAddress: squidUsdcSupportedAddress,
          gasPrice: 0,
        };

        console.log("depositCalldata", data, activeNetwork);

        const txns = await depositCalldata1(
          data,
          activeNetwork,
          transactionOpts,
          gasLimits,
          usdcBalance * 10 ** usdcDecimals
        );
        // setLoading(false);
        // return;
        console.log("txns", txns);

        txns.map((tx) => {
          userCallDataArray.push({
            calldata: tx.data,
            to: tx.to,
            value: tx?.value,
          });
        });

        console.log(
          "----------userCallDataArray BEFORE ------------",
          userCallDataArray
        );

        // const allowance = await checkGasAllowance(
        //   activeAccount.address,
        //   usdcAddress,
        //   activeNetwork
        // );

        // console.log("allowance", allowance);

        // const approveCallData = getApproveTokenCallData(
        //   APPROVE_AMOUNT,
        //   chain,
        //   usdcAddress
        // );

        // console.log("approveCallData", approveCallData);

        // if (!Number(allowance)) {
        //   userCallDataArray.push(approveCallData);
        // }

        console.log(
          "----------userCallDataArray IN BETWEEN ------------",
          userCallDataArray
        );
      }

      console.log("usdcDecimals", usdcDecimals);
      console.log("usdcBalance*usdcDecimals", usdcBalance * 10 ** usdcDecimals);
      console.log(
        "BigNumber(usdcBalance*usdcDecimals)",
        ethNum.from(usdcBalance * 10 ** usdcDecimals)
      );

      // console.log("res", res.fees?.toString());

      console.log(
        "----------userCallDataArray AFTER ------------",
        userCallDataArray
      );
      console.log("ye asala", userCallDataArray[0].value);
      console.log(
        "ye converted",
        Number(userCallDataArray[0].value).toString()
      );
      console.log("simulation men ye jarha hai", {
        from: activeAccount.smartAccountAddress,
        data: userCallDataArray[0].calldata,
        value: userCallDataArray[0].value,
        to: userCallDataArray[0].to,
      });
      const { blockaid } =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

      // const { data: data1 } = await axios.post(`${BASE_URL}/api/blockaid`, {
      //   options: ["simulation"],
      //   //logic copied several times
      //   data: {
      //     from: activeAccount.smartAccountAddress,
      //     data: userCallDataArray[0].calldata,
      //     value: Number(userCallDataArray[0].value).toString(),
      //     to: userCallDataArray[0].to,
      //   },
      //   metadata: { domain: "https://app.1inch.io" },
      //   network: blockaid,
      // });

      // console.log("DATAAAAAAAAAAa", data1);

      // if (data1.simulation.error) {
      //   showAlert("This transaction might fail", "Warning");
      // }

      const cryptoAccountDeployed = await isCryptoAccountDeployed(
        activeNetwork,
        activeAccount.smartAccountAddress
      );

      const { finalOp, usdcFee } = await txSubmissionOrderPrepaid({
        userCallDataArray,

        wallet,
        isAccountDeployed: cryptoAccountDeployed,
        chain,
        smartAccountAddress: activeAccount.smartAccountAddress,
        provider: stackupProvider,
        approval: allowance ? true : false,
        extraTopupAmount: "0",
        gasBalance: gas.totalBalance,
        rootAddress: rootAccountInfo.address.toLowerCase(),
      });

      console.log("Clicked!");

      console.log(
        "file: index.tsx:1058  sendCryptoTransactionfinal  finalOp:",
        finalOp
      );

      setFinalOpState(finalOp);
      setGasFeeInUSD(Number(usdcFee).toFixed(4));
      setLoading(false);
    } catch (error) {
      console.log("error in sendCryptoTx()", error?.message);

      setLoading(false);
      showAlert(error?.message || "Something went wrong");
    }
  }
  //how is this different from executeCrypto ?
  const executeBridgeCrypto = async () => {
    try {
      setLoading(true);
      if (finalOpState) {
        const response = await StashUserOpExt.stackupSendUserOp(
          finalOpState,
          //get via helper function
          stackupUrl,
          //get via helper function
          alchemy_url
        );
        console.log("response", response);
        // const userOPResponse: any = await response.wait();
        // console.log("userOPResponse", userOPResponse);
        console.log("userOp Hash :", response.userOpHash);
        console.log("Tx Hash :", response?.txHash);
        // console.log("success status :", userOPResponse?.args.success);
        // console.log(
        //   "actualGasCost  :",
        //   Number(userOPResponse?.args.actualGasCost)
        // );
        // const response = await sendUserOp(finalOp, bundlerRPC, rpcEndpoint);
        // const userOPResponse: any = await response.wait();
        // console.log("Tx Hash :", userOPResponse?.transactionHash);
        // console.log("success status :", userOPResponse?.args.success);
        // console.log(
        //   "actualGasCost  :",
        //   Number(userOPResponse?.args.actualGasCost)
        // );
        // console.log(
        //   "actualGasUsed  :",
        //   Number(userOPResponse?.args.actualGasUsed)
        // );
        dispatch(
          setPendingTxDetails({
            value: value,
            valueIn$: String(
              Number(value) * Number(selectedToken[0].tokenPrice ?? 0)
            ),
            transferAmount: value,
            transactionMethod: "Crypto",
            scanLink: block_explorer_url,
            eoaEns: rootAccountInfo.name,
            addressEns: activeAccount.accountName,
            toAddressEns: rootAccountInfo.name,
            toAddress: rootAccountInfo.smartAccountAddress,
            assetName: selectedToken[0].tokenName,
            networkFeesIn$: gasFeeInUSD,
            iconURL: selectedToken[0].image,
            txByDesposited: false,
            action: "Exchanged",
          })
        );
        dispatch(setPendingTx(response.txHash));

        showAlert(
          "Soon you can see your transaction in the transactions tab",
          "Transaction Submitted",
          `<a href="https://polygonscan.com/tx/${response?.txHash}" target="_blank">View on Polygonscan</a>`
        );
      }
      if (userSpendingDetails.isFirstTx && userSpendingDetails.isFirstTxInApp) {
        dispatch(
          setUserSpendingDetails({
            isFirstTxInApp: false,
            isFirstTx: false,
          })
        );
        navigate("/transaction-success");
      } else {
        setFinalOpState(null);
        setLoading(false);
        navigate("/crypto");
      }
    } catch (error) {
      console.log("error in last stage", error);
      setLoading(false);
      showAlert("Transaction Failed");
    }
  };

  const isValid =
    !error && Number(value) && Number(value) <= Number(tokenBalance);
  const isDepositValid =
    !error && Number(value) && Number(value) <= Number(tokenBalance);

  return (
    <Box mt={6}>
      <CustomizedSteppers
        step={step}
        steps={["Network", "Asset", "Amount", "Transfer"]}
        changeStep={(selectedStep: number) => {
          //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
          if (selectedStep < step) {
            setStep(selectedStep);
          }
        }}
      />

      <Box mt={5}>
        {step == 0 && (
          <Grid container display="flex" justifyContent="center">
            <Grid
              item
              lg={6}
              sm={12}
              style={{
                flexBasis: "65%",
                maxWidth: "100%",
              }}
            >
              <NetworksList
                nextStep={() => setStep(1)}
                title="Select which network you want to select asset from"
              />
            </Grid>
          </Grid>
        )}
        {step == 1 && (
          <Grid container display="flex" justifyContent="center">
            <Grid
              item
              lg={6}
              sm={12}
              style={{
                flexBasis: "65%",
                maxWidth: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "17px",
                  textAlign: "left",
                  color: "rgba(26, 28, 32, 0.5)",
                  fontFamily: "Helvetica Neue",
                  fontWeight: 500,
                  width: "100%",
                  paddingBottom: "20px",
                }}
              >
                Select the token you want to transfer to cash
              </Typography>
              <TokensListTable
                transactionForm={true}
                nextStep={() => setStep(2)}
                chainId={activeNetwork}
                isApplyFilter={false}
                isShowTokenAmountUnderName
              />
            </Grid>
          </Grid>
        )}
        {step == 2 && (
          <Grid container display="flex" justifyContent="center" py={4}>
            <Grid
              item
              lg={6}
              sm={12}
              style={{
                flexBasis: "65%",
                maxWidth: "100%",
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    fontSize: "17px",
                    textAlign: "left",
                    color: "rgba(26, 28, 32, 0.5)",
                    fontFamily: "Helvetica Neue",
                    fontWeight: 500,
                    width: "100%",
                  }}
                >
                  Enter amount to transfer to cash
                </Typography>
                <TokenInputForm
                  placeHolder={
                    Number(tokenBalance) && Number(tokenBalance) < 0.0001
                      ? "<0.0001"
                      : tokenBalance
                  }
                  title="Crypto Tag"
                  addBorder
                  type="number"
                  onChange={handleAmountChange}
                  onKeydown={handleKeyDown}
                  value={value}
                  receiverENS={""}
                  isDepositValid={!!isValid || !!isDepositValid}
                  nextStep={() => {
                    dispatch(
                      setSwapDetails({
                        tokenA: {
                          ...tokenA,
                          amount: value,
                          amountInUSD: value * tokenA.tokenPrice,
                        },
                      })
                    );
                    setStep(3);
                  }}
                  tokenName={tokenSymbol}
                  tokenIcon={tokenIcon}
                  buttonTitle="Continue"
                  balance={tokenBalance}
                  errorMessage={error}
                  decimals={selectedToken[0]?.tokenDecimal}
                />
              </Box>
            </Grid>
          </Grid>
        )}

        {step == 3 && (
          <Grid container display="flex" justifyContent="center" py={4}>
            <Grid
              item
              lg={6}
              sm={12}
              style={{
                flexBasis: "65%",
                maxWidth: "100%",
              }}
            >
              <Box mt={2.5} position={"relative"}>
                <div className="input-container" style={{ marginBottom: 10 }}>
                  <FormControl sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        backgroundColor: "#EDEEF2",
                        color: "#1A1C20",
                        width: "100%",
                        // height: "80px",
                        borderRadius: "10px",
                        fontSize: "25px",
                        fontFamily: "Space Grotesk",
                        fontWeight: "700",
                        border: "0.5px solid rgba(26, 28, 32, 0.50)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: 15,
                          }}
                        >
                          <Box mr={1.25}>
                            {/* <img src={tokenIcon} height={33} width={33} /> */}
                            <img
                              src={tokenIcon}
                              height={33}
                              style={{ padding: 10 }}
                            />
                          </Box>
                          <Box
                            sx={{
                              color: "#1A1C20",
                              fontFamily: "Space Grotesk",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              marginTop: -1,
                              marginLeft: -1.5,
                            }}
                          >
                            {tokenSymbol}
                          </Box>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginRight: 10,
                          }}
                        >
                          <Box
                            mr={1.25}
                            sx={{
                              color: "#1A1C20",
                              fontFamily: "Space Grotesk",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                            }}
                          >
                            ${(value * tokenPriceInUsd).toFixed(3)}
                          </Box>
                          <Box
                            sx={{
                              color: "#8C8D8F",
                              fontFamily: "Space Grotesk",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                            }}
                          >
                            {value} {tokenSymbol}
                          </Box>
                        </div>
                      </Box>
                    </Box>
                  </FormControl>
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    zIndex: 2,
                  }}
                >
                  <img src={MidArrow} />
                </div>

                <div className="input-container" style={{ marginBottom: 10 }}>
                  <FormControl sx={{ flex: 1 }}>
                    <Box
                      sx={{
                        backgroundColor: "#EDEEF2",
                        color: "#1A1C20",
                        width: "100%",
                        // height: "80px",
                        borderRadius: "10px",
                        fontSize: "25px",
                        fontFamily: "Space Grotesk",
                        fontWeight: "700",
                        border: "0.5px solid rgba(26, 28, 32, 0.50)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            paddingTop: 15,
                          }}
                        >
                          <Box mr={1.25}>
                            <img src={CashIcon} />
                          </Box>
                          <Box
                            sx={{
                              color: "#1A1C20",
                              fontFamily: "Space Grotesk",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                              marginTop: -2,
                              marginLeft: -1.5,
                            }}
                          >
                            Cash
                          </Box>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginRight: 10,
                          }}
                        >
                          <Box
                            mr={1.25}
                            sx={{
                              color: "#1A1C20",
                              fontFamily: "Space Grotesk",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "normal",
                            }}
                          >
                            ${(value * tokenPriceInUsd).toFixed(3)}
                          </Box>
                          <Box
                            sx={{
                              color: "#8C8D8F",
                              fontFamily: "Space Grotesk",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "normal",
                            }}
                          >
                            {(value * tokenPriceInUsd).toFixed(3)} USDCB
                          </Box>
                        </div>
                      </Box>
                    </Box>
                  </FormControl>
                </div>
              </Box>

              <FeeUIComponent
                gasFeeInUSD={gasFeeInUSD}
                loading={!finalOpState && loading}
              />
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  width={"100%"}
                  height={50}
                  sx={{
                    borderRadius: "10px",

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <BasicButton
                  title={allowance ? "Cashout" : "Approve Paymaster"}
                  onClick={
                    error || !finalOpState
                      ? () => {
                          console.log("abc");
                        }
                      : executeBridgeCrypto
                  }
                  style={{
                    cursor: error || !finalOpState ? "not-allowed" : "pointer",
                    height: "15px",
                  }}
                  className="transfer-btn"
                  loading={loading}
                  mode={"active"}
                  id="send"
                />
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default Cashout;
