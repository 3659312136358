import React, { useEffect, useState } from "react";

import "./index.css";
import { Box, Grid, Snackbar, Typography } from "@mui/material";
import BasicModal from "components/BasicModal";
import ModalHeader from "components/ModalHeader";
import AssetsView from "components/AssetsView";
import { Token } from "interfaces";
import { useAppDispatch, useAppSelector } from "store/store";

import { SUPPORTED_NETWORKS } from "constants/chains";
import { showAlert } from "utils/utils";

import { setSelectedToken } from "@slices/walletSlice";
import { NATIVE_ADDRESS, ZERO_ADDRESS, topFiat } from "constants/";
import { setSwapDetails } from "@slices/appSlice";
import { useNavigate } from "react-router-dom";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import NetworksList from "components/NetworksList";
import TokensListTable from "../TokensListTable";
import TokenInputForm from "components/TokenInputForm";
import axios from "axios";

import ReceiveTokenList from "components/ReceiveTokenList";
import SwapSummary from "components/SwapSummary";
import BuyFiat from "components/Buyfiat";
import GBPIcon from "assets/GBPIcon.svg";
import BuyFiatPreview from "components/BuyFiatPreview";
import { fetchUniswapSupportedTokens } from "utils/swap";
import Button from "components/NewButton";
import Cashout from "components/Cashout";
import { getUsdcBalance } from "utils/balance";

const CryptoSell = () => {
  const [loading, setLoading] = useState(false);
  const [tokensLoading, setTokensLoading] = useState(false);

  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [step, setStep] = useState(0);

  const [activeTab, setActiveTab] = useState("crypto");

  const [isCashSelected, setIsCashSelected] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState("GBP");
  const [activeCurrencyIcon, setActiveCurrencyIcon] = useState(GBPIcon);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [activeCryptoOnRamperId, setActiveCryptoOnRamperId] = useState("");
  const [swapTokens, setSwapTokens] = useState<any>([]);
  const [fiatList, setFiatList] = useState([{}]);
  const [cryptoList, setCryptoList] = useState([{}]);
  const [usdcBalance, setUsdcBalance] = useState(0);

  const navigate = useNavigate();
  const {
    activeAccount,
    holdings,
    activeNetwork,

    swapDetails: { tokenA },
  } = useAppSelector((state) => state.app);

  const { selectedToken } = useAppSelector((state) => state.wallet);
  console.log("file: index.tsx:89 Send selectedToken:", selectedToken);

  const { symbol, icon, wethAddress, onRamperNetwork } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const { nativeBalance, nativeTokenPrice } =
    holdings[activeAccount.smartAccountAddress];

  const dispatch = useAppDispatch();

  useEffect(() => {
    let userTokens: Token[] =
      holdings[activeAccount.smartAccountAddress]?.tokens || [];

    setTokens(userTokens);
  }, [activeAccount, holdings]);

  useEffect(() => {
    (async () => {
      setTokensLoading(true);

      const tokens = await fetchUniswapSupportedTokens(activeNetwork);

      const topTokens = tokens.filter(
        (tk) =>
          tk.symbol === "USDT" ||
          tk.symbol === "USDC" ||
          tk.symbol === "UNI" ||
          tk.symbol === "DAI" ||
          tk.symbol === "AAVE" ||
          tk.symbol === "GRT" ||
          tk.symbol === "LINK" ||
          tk.symbol === "MANA" ||
          tk.symbol === "MATIC" ||
          tk.symbol === "WETH" ||
          tk.symbol === "WBTC" ||
          tk.symbol === "ANKR" ||
          tk.symbol === "BNB"
      );

      // Remove top tokens from tokens array
      topTokens.forEach((token) => {
        const index = tokens.findIndex((tk) => tk.symbol === token.symbol);
        if (index !== -1) {
          tokens.splice(index, 1);
        }
      });

      console.log("topTokens", topTokens);

      // Add top tokens to the top of swapTokens array
      setSwapTokens([...topTokens, ...tokens]);

      setTokensLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setTokensLoading(true);
        const { data } = await axios.get("https://api.onramper.com/supported", {
          headers: {
            Authorization: process.env.ONRAMP_API_KEY,
          },
        });
        const { message } = data;

        const { crypto, fiat } = message;
        console.log("fiat", fiat);
        const filteredFiat = fiat.filter(
          (ft) => ft.id !== "eur" && ft.id !== "gbp" && ft.id !== "usd"
        );

        setFiatList([...topFiat, ...filteredFiat]);
        const filteredCrypto = crypto.filter(
          (cr) => cr.chainId == activeNetwork
        );
        setCryptoList(filteredCrypto);
      } catch (error) {
        console.log("E>>>>>>>>>>>", error);
        setTokensLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const usdcBalance = await getUsdcBalance(
        activeAccount.smartAccountAddress,
        activeNetwork
      );

      console.log("usdcBalance", usdcBalance);
      setUsdcBalance(usdcBalance);
    })();
  }, []);

  const isSelectedToken = selectedToken.length > 0;
  const tokenSymbol = isSelectedToken ? selectedToken[0].tokenSymbol : symbol;
  const tokenIcon = isSelectedToken ? selectedToken[0].image : icon;

  const tokenBalance = isSelectedToken
    ? selectedToken[0].tokenBalance
    : nativeBalance;

  const tokenPriceInUsd = isSelectedToken
    ? selectedToken[0].tokenPrice
    : nativeTokenPrice;
  const tokenAddress = isSelectedToken ? selectedToken[0].tokenAddress : "";

  // const { dummyToken } = SUPPORTED_NETWORKS[activeNetwork];

  useEffect(() => {
    console.log("selectedtoken", selectedToken);
    if (
      tokenSymbol &&
      cryptoList.length > 0 &&
      step === 3 &&
      activeTab === "fiat"
    ) {
      const ISNATIVE =
        tokenAddress == NATIVE_ADDRESS || tokenAddress == wethAddress;

      const availableCrypto = ISNATIVE
        ? cryptoList.find(
            (cr) =>
              // cr?.address?.toLowerCase() === ZERO_ADDRESS &&
              cr.network === onRamperNetwork &&
              cr.symbol === symbol.toLowerCase()
          )
        : cryptoList.find(
            (cr) =>
              cr?.address?.toLowerCase() === tokenAddress.toLowerCase() &&
              cr.network === onRamperNetwork
          );

      console.log("availableCrypto", availableCrypto);

      if (!availableCrypto) {
        showAlert(`${tokenSymbol} currently not supported for onRamp`);
        setStep(2);
      } else {
        console.log("availableCrypto", availableCrypto);
        setActiveCryptoOnRamperId(availableCrypto);
      }
    }
  }, [tokenSymbol, cryptoList, step, activeTab]);

  useEffect(() => {
    setPaymentMethod(false);
  }, [activeTab]);

  const selectTokenHandler = (tk: Token) => {
    dispatch(setSelectedToken([tk]));
    setOpenModal(false);
  };

  const isValid =
    !error && Number(value) && Number(value) <= Number(tokenBalance);
  const isDepositValid =
    !error && Number(value) && Number(value) <= Number(tokenBalance);

  const handleAmountChange = (value) => {
    const inputValue = value;
    let regex = new RegExp(
      `^\\d{0,10}(\\.\\d{0,${selectedToken[0].tokenDecimal}})?$`
    );

    if (/[^0-9.]/.test(inputValue)) {
      setError("");
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setError("");
    } else if (!regex.test(inputValue)) {
      setError(
        `Maximum of 10 digits before decimals and ${selectedToken[0].tokenDecimal} digits after decimals are allowed`
      );
    } else {
      setError("");

      setValue(inputValue);
    }
  };

  const checkAmount = async () => {
    dispatch(
      setSwapDetails({
        tokenA: {
          ...tokenA,
          amount: value,
          amountInUSD: value * tokenA.tokenPrice,
        },
      })
    );
    if (activeTab === "fiat") {
      setLoading(true);
      const { data } = await axios.get(
        `https://api.onramper.com/quotes/${activeCryptoOnRamperId.id}/eur?amount=${value}&paymentMethod=creditcard&type=sell&network=polygon`,
        {
          headers: {
            Authorization: process.env.ONRAMP_API_KEY,
          },
        }
      );

      setLoading(false);
      if (data.length > 0 && data[0].errors?.length > 0) {
        setError(`${data[0].errors[0].message}`);
      } else {
        setStep(4);
      }
    } else {
      setStep(4);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      if (/[^0-9.]/.test(newValue)) {
        setError("Special characters are not allowed");
      } else if (parseFloat(newValue) * tokenPriceInUsd < 2.01) {
        setError("Value should not be less than $2.01");

        setValue(newValue);
      } else {
        setValue(newValue);
        setError("");
      }
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (/[^0-9.]/.test(newValue)) {
        setError("Special characters are not allowed");
      } else if (parseFloat(newValue) * tokenPriceInUsd < 2.01) {
        setError("Value should not be less than $2.01");

        setValue(newValue);
      } else {
        if (Number(newValue) > 0) {
          setValue(newValue);
        }
        setError("");
      }
    }
  };

  console.log("isCashSelected", isCashSelected);
  return (
    <>
      <BasicModal open={openModal} onClose={() => setOpenModal(false)}>
        <>
          <ModalHeader
            title="Select asset"
            onClose={() => setOpenModal(false)}
            showBackIcon
          />
          <AssetsView tokens={tokens} selectTokenHandler={selectTokenHandler} />
        </>
      </BasicModal>
      <Box mt={6}>
        <NavigatorHeading
          title={activeTab === "cash" ? "Transfer to Cash" : "Sell"}
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/crypto");
              }}
            />
          }
        />
      </Box>

      {activeTab === "cash" ? (
        <Cashout />
      ) : (
        <Box mt={2}>
          <CustomizedSteppers
            step={step}
            steps={["Method", "Network", "Asset", "Amount", "Receive", "Sell"]}
            changeStep={(selectedStep: number) => {
              //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
              if (selectedStep < step) {
                setStep(selectedStep);
              }
            }}
          />

          <Box mt={5}>
            {step == 0 && (
              <Grid container display="flex" justifyContent="center">
                <Grid
                  item
                  lg={6}
                  sm={12}
                  style={{
                    flexBasis: "65%",
                    maxWidth: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "17px",
                      textAlign: "left",
                      color: "rgba(26, 28, 32, 0.5)",
                      fontFamily: "Helvetica Neue",
                      fontWeight: 500,
                      width: "100%",
                      paddingBottom: "20px",
                    }}
                  >
                    Select the method you want to sell crypto with
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      title="Crypto"
                      onClick={() => {
                        setActiveTab("crypto");
                        setStep(1);
                      }}
                      style={{
                        backgroundColor: "rgba(247, 247, 247, 1)",
                        width: "95%",
                        marginRight: 15,
                      }}
                      textStyle={{ color: "black" }}
                    />
                    <Button
                      title="Fiat"
                      onClick={() => {
                        setActiveTab("fiat");
                        setStep(1);
                      }}
                      style={{
                        backgroundColor: "rgba(247, 247, 247, 1)",
                        width: "95%",
                        marginRight: 15,
                      }}
                      textStyle={{ color: "black" }}
                    />
                    <Button
                      title="Transfer to Cash"
                      onClick={() => {
                        setActiveTab("cash");
                        setStep(1);
                      }}
                      style={{
                        backgroundColor: "rgba(247, 247, 247, 1)",
                        width: "95%",
                      }}
                      textStyle={{ color: "black" }}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            {step == 1 && (
              <Grid container display="flex" justifyContent="center">
                <Grid
                  item
                  lg={6}
                  sm={12}
                  style={{
                    flexBasis: "65%",
                    maxWidth: "100%",
                  }}
                >
                  <NetworksList
                    nextStep={() => setStep(2)}
                    title="Select which network you want to receive crypto on"
                  />
                </Grid>
              </Grid>
            )}
            {step == 2 && (
              <Grid container display="flex" justifyContent="center">
                <Grid
                  item
                  lg={6}
                  sm={12}
                  style={{
                    flexBasis: "65%",
                    maxWidth: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "17px",
                      textAlign: "left",
                      color: "rgba(26, 28, 32, 0.5)",
                      fontFamily: "Helvetica Neue",
                      fontWeight: 500,
                      width: "100%",
                      paddingBottom: "20px",
                    }}
                  >
                    Select the token you want to Sell
                  </Typography>
                  <TokensListTable
                    transactionForm={true}
                    nextStep={() => setStep(3)}
                    chainId={activeNetwork}
                    isApplyFilter={false}
                    isShowTokenAmountUnderName
                  />
                </Grid>
              </Grid>
            )}
            {step == 3 && (
              <Grid container display="flex" justifyContent="center" py={4}>
                <Grid
                  item
                  lg={6}
                  sm={12}
                  style={{
                    flexBasis: "65%",
                    maxWidth: "100%",
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Typography
                      sx={{
                        fontSize: "17px",
                        textAlign: "left",
                        color: "rgba(26, 28, 32, 0.5)",
                        fontFamily: "Helvetica Neue",
                        fontWeight: 500,
                        width: "100%",
                      }}
                    >
                      Enter amount to sell
                    </Typography>
                    <TokenInputForm
                      placeHolder={
                        Number(tokenBalance) && Number(tokenBalance) < 0.0001
                          ? "<0.0001"
                          : tokenBalance
                      }
                      title="Crypto Tag"
                      addBorder
                      type="number"
                      onChange={handleAmountChange}
                      onKeydown={handleKeyDown}
                      value={value}
                      receiverENS={""}
                      isDepositValid={!!isValid || !!isDepositValid}
                      nextStep={checkAmount}
                      tokenName={tokenSymbol}
                      tokenIcon={tokenIcon}
                      buttonTitle="Continue"
                      balance={tokenBalance}
                      errorMessage={error}
                      loading={loading}
                      decimals={selectedToken[0]?.tokenDecimal}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}

            {step == 4 && (
              <Grid container display="flex" justifyContent="center">
                <Grid
                  item
                  lg={6}
                  sm={12}
                  style={{
                    flexBasis: "65%",
                    maxWidth: "100%",
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Typography
                      sx={{
                        fontSize: "17px",
                        textAlign: "left",
                        color: "rgba(26, 28, 32, 0.5)",
                        fontFamily: "Helvetica Neue",
                        fontWeight: 500,
                        width: "100%",
                        margin: "5px 0px 5px 0px",
                      }}
                    >
                      Select the token you want to receive
                    </Typography>

                    {activeTab === "crypto" ? (
                      <>
                        <ReceiveTokenList
                          nextStep={() => setStep(5)}
                          setIsCashSelected={() =>
                            setIsCashSelected(!isCashSelected)
                          }
                          swapTokens={swapTokens}
                          loading={tokensLoading}
                        />
                      </>
                    ) : (
                      <BuyFiat
                        setPaymentMethod={setPaymentMethod}
                        setActiveCurrency={setActiveCurrency}
                        setActiveCurrencyIcon={setActiveCurrencyIcon}
                        setSelectedPaymentMethod={setSelectedPaymentMethod}
                        activeCurrency={activeCurrency}
                        paymentMethod={paymentMethod}
                        nextStep={setStep}
                        tokenAddress={tokenAddress}
                        setActiveCryptoOnRamperId={setActiveCryptoOnRamperId}
                        tokenSymbol={tokenSymbol}
                        activeCryptoOnRamperId={activeCryptoOnRamperId}
                        fiatList={fiatList}
                        type="sell"
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}

            {step == 5 &&
              (activeTab === "crypto" && !isCashSelected ? (
                <SwapSummary usdcBalance={usdcBalance} />
              ) : (
                <BuyFiatPreview
                  selectedPaymentMethod={selectedPaymentMethod}
                  currencyIcon={activeCurrencyIcon}
                  currencySymbol={activeCurrency}
                  tokenIcon={tokenIcon}
                  tokenPriceInUsd={tokenPriceInUsd}
                  tokenSymbol={tokenSymbol}
                  value={value}
                  activeCryptoOnRamperId={activeCryptoOnRamperId}
                  nextStep={() => setStep(3)}
                  type="sell"
                  setError={setError}
                />
              ))}
          </Box>
        </Box>
      )}

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Funds are not enough to use as gas fees"
      />
    </>
  );
};

export default CryptoSell;
