import axios from "axios";
import { BASE_URL } from "constants/";
import { SUPPORTED_NETWORKS, SupportedChainId } from "constants/chains";
import { BytesLike } from "ethers";

export const handleBlockaidResponse = async (
  activeNetwork: SupportedChainId,
  smartAccountAddress: string,
  userCallDataArray: {
    calldata: BytesLike;
    value: string;
    to: string;
  }
) => {
  try {
    console.log("params of blockaid", {
      activeNetwork,
      smartAccountAddress,
      userCallDataArray,
    });
    const { blockaid } =
      SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

    const { data } = await axios.post(`${BASE_URL}/api/blockaid`, {
      options: ["validation"],

      data: {
        from: smartAccountAddress,
        data: userCallDataArray.calldata,
        value: userCallDataArray.value,
        to: userCallDataArray.to,
      },
      metadata: { domain: "https://app.1inch.io" },
      network: blockaid,
    });

    console.log("DATAAAAAAAAAAa", data);

    return data;
  } catch (error) {
    throw error;
  }
};

export const handleBlockaidSimulationResponse = async (
  activeNetwork: SupportedChainId,
  smartAccountAddress: string,
  userCallDataArray: {
    calldata: BytesLike;
    value: string;
    to: string;
  }
) => {
  try {
    console.log("params of blockaid", {
      activeNetwork,
      smartAccountAddress,
      userCallDataArray,
    });
    const { blockaid } =
      SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

    const { data } = await axios.post(`${BASE_URL}/api/blockaid`, {
      options: ["simulation"],

      data: {
        from: smartAccountAddress,
        data: userCallDataArray.calldata,
        value: userCallDataArray.value,
        to: userCallDataArray.to,
      },
      metadata: { domain: "https://app.1inch.io" },
      network: blockaid,
    });

    console.log("DATAAAAAAAAAAa", data);

    return data;
  } catch (error) {
    throw error;
  }
};
