import { FC } from "react";
import { Stack } from "@mui/material";
import NoImg from "../../assets/noImg.svg";

import {
  BoxStyled,
  Caption,
  ItemWrapper,
  MediumTitle,
} from "components/Styled";
import { formatAmount } from "utils/utils";

const ItemBox: FC<{
  style?: any;
  tokenName?: string;
  tokenSymbol?: string;
  tokenImage?: string;
  tokenAmount?: number;
  tokenAmoundInUsd?: number;
  amountSymbol?: string;
  loading?: boolean;
}> = ({
  style,
  tokenAmount,
  tokenImage,
  tokenName,
  tokenSymbol,
  tokenAmoundInUsd,
  amountSymbol,
  loading,
}) => {
  return (
    <ItemWrapper
      display={"flex"}
      flexDirection={"row"}
      justifyContent={"space-between"}
      style={style}
    >
      <BoxStyled display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <img
          alt="tokenImage"
          src={tokenImage}
          width={"37px"}
          height={"37px"}
          style={{ borderRadius: "50%", marginRight: "5px" }}
          onError={(e) => {
            e.currentTarget.src = NoImg;
          }}
        />
        <Stack
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
        >
          <MediumTitle>{tokenName}</MediumTitle>
          <Caption>{tokenSymbol}</Caption>
        </Stack>
      </BoxStyled>
      <BoxStyled
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-end"}
      >
        {!tokenAmoundInUsd && !tokenAmount && loading ? (
          <MediumTitle>Loading ..</MediumTitle>
        ) : (
          <>
            <MediumTitle>${formatAmount(tokenAmoundInUsd || 0)}</MediumTitle>
            <Caption>
              {formatAmount(tokenAmount || 0)} {amountSymbol}
            </Caption>
          </>
        )}
      </BoxStyled>
    </ItemWrapper>
  );
};

export default ItemBox;
