import StashedLogo from "assets/stashed.svg";

export const showNotification = (
  title: string,
  message: string,
  redirectUrl: string
) => {
  if ("Notification" in window) {
    // Check if the browser supports the Notification API
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        // Permission has been granted, show the notification
        const notification = new Notification(title, {
          body: message,
          icon: StashedLogo,
        });

        notification.onclick = () => {
          // Handle notification click event
          console.log("Notification clicked");
          window.open(redirectUrl, "_blank");
        };
      }
    });
  } else {
    console.log("Notification API not supported in this browser");
  }
};
