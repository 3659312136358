import { useAppSelector } from "store/store";
import CryptoView, { CryptoViewProps } from "./view";
import { useEffect } from "react";
import { Interval } from "../../../enums";

const Crypto = () => {
  const { activeAccount, portfolio, cryptoHistory } = useAppSelector(
    (state) => state.app
  );

  const viewProps: CryptoViewProps = {
    accountName: activeAccount.accountName,
    totalWalletBalance: portfolio.total_wallet_balance,
    chartData: cryptoHistory[Interval.WEEK].chart_data,
    chartTimestamps: cryptoHistory[Interval.WEEK].chart_timestamps,
  };

  return <CryptoView {...viewProps} />;
};

export default Crypto;
