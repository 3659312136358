// import ProjectInfoCard from '../components/ProjectInfoCard'

import { Box, Button, Divider, Typography } from "@mui/material";
import BasicButton from "../components/Button";
import ProjectInfoCard from "../components/ProjectInfoCard";
import RequestDataCard from "../components/RequestDataCard";
// import RequestDataCard from '@/../components/RequestDataCard'

import RequestDetailsCard from "../components/RequestDetailsCard";
// import RequesDetailsCard from '@/../components/RequestDetalilsCard'

import RequestMethodCard from "../components/RequestMethodCard";
// import RequestMethodCard from '@/../components/RequestMethodCard'

import RequestModalContainer from "../components/RequestModalContainer";
import EthIcon from "../assets/EthIcon.svg";
// import RequestModalContainer from '@/../components/RequestModalContainer'

// import ModalStore from '@/store/ModalStore'

// import { approveEIP155Request, rejectEIP155Request } from '@/utils/EIP155RequestHandlerUtil'

// import { getSignTypedDataParamsData, styledToast } from '@/utils/HelperUtil'

// import { signClient } from '@/utils/WalletConnectUtil'

// import { Button, Divider, Modal, Text } from '@nextui-org/react'
import { Fragment, useEffect, useState } from "react";
import {
  approveEIP155Request,
  rejectEIP155Request,
} from "../utils/EIP155RequestHandlerUtil";
import { getSignTypedDataParamsData, styledToast } from "../utils/HelperUtil";
import { signClient, web3wallet } from "../utils/WalletConnectUtil";
import ModalStore from "../walletConnectStore/ModalStore";
import { useAppSelector } from "../store/store";
import { Stack } from "@mui/system";
import { getNameFromTokenAddress } from "utils/utils";

export default function SessionSignTypedDataModal() {
  // Get request and wallet data from store
  const requestEvent = ModalStore.state.data?.requestEvent;
  const requestSession = ModalStore.state.data?.requestSession;
  const { activeAccount, activeNetwork } = useAppSelector((state) => state.app);
  const [signData, setSignData] = useState({});
  console.log("signData", signData);
  // Ensure request and wallet are defined
  if (!requestEvent || !requestSession) {
    return <p>Missing request data</p>;
  }

  // Get required request data
  const { topic, params } = requestEvent;
  const { request, chainId } = params;

  console.log("request: " + request);
  console.log("requestSession: " + requestSession);

  useEffect(() => {
    (async () => {
      // Get data

      const { message: data } = getSignTypedDataParamsData(request.params);
      console.log("data after getSignTypedDataParamsData", data);

      const tokenName = await getNameFromTokenAddress(
        data?.details?.token,
        activeNetwork
      );
      console.log("tokenName", tokenName);

      const amount = data?.details?.amount;

      const spender = data?.spender;

      setSignData({ tokenName, amount, spender });
    })();
  }, []);

  // Handle approve action (logic varies based on request method)
  async function onApprove() {
    if (requestEvent) {
      const response = await approveEIP155Request(
        requestEvent,
        activeAccount.address
      );

      console.log("response MAIN", response);
      try {
        await web3wallet.respondSessionRequest({
          topic,
          response,
        });
      } catch (e) {
        styledToast((e as Error).message, "error");
        throw e;
      } finally {
        ModalStore.close();
      }
    }
  }

  // Handle reject action
  async function onReject() {
    if (requestEvent) {
      const response = rejectEIP155Request(requestEvent);
      console.log("response", response);
      try {
        await web3wallet.respondSessionRequest({
          topic,
          response,
        });
      } catch (e) {
        styledToast((e as Error).message, "error");
        return;
      }
      ModalStore.close();
    }
  }

  return (
    <Box
      sx={{
        background: "white",
        overflowY: "scroll",
        width: "400px",
        padding: "20px  10px",
        borderRadius: "12px",
      }}
    >
      <RequestModalContainer title="Signing Request">
        {/* <ProjectInfoCard metadata={requestSession.peer.metadata} /> */}

        <Divider />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "330px",
            height: "140px",
            border: "0.5px solid #B2B2B2",
            margin: "0px auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "330px",
              height: "70px",
              alignItems: "center",
              paddingLeft: "10px",
              justifyContent: "flex-start",
            }}
            gap={2}
          >
            <Typography
              variant="caption"
              fontSize="12px"
              fontWeight={400}
              color="#67686C"
            >
              Token
            </Typography>

            {/* <img src={EthIcon} /> */}

            <Typography
              variant="body1"
              fontSize="12px"
              fontWeight={500}
              color="#121212"
            >
              {signData?.tokenName || ""}
              {/* abc */}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "300px",
              height: "70px",
              alignItems: "center",
              paddingLeft: "10px",
              justifyContent: "flex-start",
              // border: "1px solid red",
            }}
            gap={2}
          >
            <Typography
              variant="caption"
              fontSize="12px"
              fontWeight={400}
              color="#67686C"
            >
              Amount
            </Typography>

            {/* <img src={EthIcon} /> */}

            <Typography
              variant="body1"
              fontSize="12px"
              fontWeight={500}
              color="#121212"
              // flexWrap={"unset"}
              width={"100%"}
              sx={{
                // border: "1px solid green",
                whiteSpace: "pre-line",
                // display: "inline-block",
              }}
              noWrap
            >
              {signData?.amount || ""}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "330px",
              height: "70px",
              alignItems: "center",
              paddingLeft: "10px",
              justifyContent: "flex-start",
            }}
            gap={2}
          >
            <Typography
              variant="caption"
              fontSize="12px"
              fontWeight={400}
              color="#67686C"
            >
              Spender
            </Typography>

            {/* <img src={EthIcon} /> */}

            <Typography
              variant="body1"
              fontSize="12px"
              fontWeight={500}
              color="#121212"
            >
              {signData?.spender
                ? `${signData?.spender.slice(0, 4)}...${signData?.spender.slice(
                    signData?.spender.length - 4,
                    signData?.spender.length
                  )}`
                : ""}
              {/* abc */}
            </Typography>
          </Box>
        </Box>

        {/* <RequestDetailsCard
          chains={[chainId ?? ""]}
          protocol={requestSession.relay.protocol}
        />

        <Divider />

        <RequestDataCard data={data} /> */}

        {/* <Divider /> */}

        {/* <RequestMethodCard methods={[request.method]} /> */}
      </RequestModalContainer>

      <Stack
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"row"}
        sx={{
          // border: "1px solid red",
          width: "100%",
          height: "90px",
        }}
      >
        <BasicButton
          title={"Reject"}
          onClick={onReject}
          className="transfer-btn"
          style={{ marginRight: "5px", height: "15px" }}
          loading={false}
          mode={"active"}
          id="send"
        />
        <BasicButton
          title={"Sign"}
          onClick={onApprove}
          className="transfer-btn"
          style={{ marginRight: "5px", height: "15px" }}
          loading={false}
          mode={"active"}
          id="send"
        />
        {/* <BasicButton
          title="Reject"
          onClick={onReject}
          style={{ width: "120px", marginRight: "30px" }}
          id="account_box_open_new_account_modal"
        /> */}
        {/* <BasicButton
          title="Sign"
          onClick={onApprove}
          style={{ width: "120px" }}
          id="account_box_open_new_account_modal"
        /> */}
        {/* <Button
          color="error"
          onClick={onReject}
          data-testid="request-button-reject"
        >
          Reject
        </Button>
        <Button
          color="success"
          onClick={onApprove}
          data-testid="request-button-approve"
        >
          Approve
        </Button> */}
      </Stack>
    </Box>
  );
}
