import React, { useEffect, useState } from "react";
import { getCounterFactualAddress } from "../../../../contract-integration";
import Web3 from "web3";
import MidArrow from "assets/midArrow.svg";

import "./index.css";
import { Box, Grid, Skeleton, Snackbar, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/store";
import { SUPPORTED_NETWORKS } from "constants/chains";
import { decryptMessage, showAlert } from "utils/utils";

import { setSelectedToken } from "@slices/walletSlice";
import { USDT_URL } from "constants/";
import { ethers, BigNumber } from "ethers";
import { EMPTY_CALLDATA } from "../../../../contract-integration/constants";
import { setPendingTx, setPendingTxDetails } from "@slices/appSlice";
import { useNavigate } from "react-router-dom";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import NetworksList from "components/NetworksList";
import TokenInputForm from "components/TokenInputForm";
import { sendUserOp } from "../../../../contract-integration";
import { txSubmissionOrderPrepaid } from "../../../../contract-integration/prepaidGas";
import SendTxComponent from "../../../../components/SendTxComponent";
import FeeUIComponent from "../../../../components/SendTxComponent/FeeUIComponent";
import { getChain } from "utils/chains";
import { getGasWithdrawData } from "stash-fe-script";
import FillAsset from "./FillAsset";
import BasicButton from "components/Button";
import { isCryptoAccountDeployed } from "utils/deployed";

const WithdrawDepositedGas = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [gasFeeInUSD, setGasFeeInUSD] = useState("0");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const [inputError, setInputError] = useState("");
  const [finalOpState, setFinalOpState] = useState<any>(null);

  const navigate = useNavigate();
  const { activeAccount, activeNetwork, rootAccountInfo, gas } = useAppSelector(
    (state) => state.app
  );

  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const {
    block_explorer_url,
    alchemy_url,
    factoryAddress,
    usdcDecimals,
    stackupUrl,
    bundlerRPC,
  } = SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const dispatch = useAppDispatch();

  const executeSendCrypto = async () => {
    try {
      setLoading(true);
      if (finalOpState) {
        const response = await sendUserOp(
          finalOpState,
          stackupUrl,
          alchemy_url
        );
        const userOPResponse: any = await response.wait();
        console.log("userOp Hash :", response.userOpHash);
        console.log("Tx Hash :", userOPResponse?.transactionHash);
        console.log("success status :", userOPResponse?.args.success);
        console.log(
          "actualGasCost  :",
          Number(userOPResponse?.args.actualGasCost)
        );

        console.log(
          "actualGasCost  :",
          Number(userOPResponse?.args.actualGasCost)
        );
        console.log(
          "actualGasUsed  :",
          Number(userOPResponse?.args.actualGasUsed)
        );
        dispatch(
          setPendingTxDetails({
            value: value,
            valueIn$: String(Number(value) * Number(1 ?? 0)),
            transferAmount: value,
            transactionMethod: "SEND",
            scanLink: block_explorer_url,
            eoaEns: rootAccountInfo.name,
            addressEns: activeAccount.accountName,
            toAddressEns: activeAccount.smartAccountAddress,
            toAddress: activeAccount.smartAccountAddress,
            assetName: "Gas",
            networkFeesIn$: gasFeeInUSD,
            iconURL: USDT_URL,
            txByDesposited: true,
            action: "Withdrawn",
          })
        );

        dispatch(setPendingTx(userOPResponse?.transactionHash));

        showAlert(
          "Soon you can see your transaction in the transactions tab",
          "Transaction Submitted",
          `<a href="https://polygonscan.com/tx/${userOPResponse?.transactionHash}" target="_blank">View on Polygonscan</a>`
        );
      }
      setFinalOpState(null);
      setLoading(false);
      navigate("/gas");
    } catch (error) {
      setLoading(false);
      showAlert("Something went wrong");
    }
  };

  useEffect(() => {
    if (value && step === 2) {
      sendWithdrawTransaction();
    }
  }, [value, step]);
  //replace with gas withdraw
  async function sendWithdrawTransaction() {
    try {
      setError(false);
      setLoading(true);

      setFinalOpState(null);

      const rpcEndpoint =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
          .alchemy_url;

      console.log(
        "file: index.tsx:440 newSendTransaction rpcEndpoint:",
        rpcEndpoint
      );

      const pkey = decryptMessage(activeAccount.secret, hashedPassword);
      console.log("file: index.tsx:448 newSendTransaction pkey:", pkey);
      const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);
      const stackupProvider = new ethers.providers.JsonRpcProvider(bundlerRPC);

      const wallet = new ethers.Wallet(pkey, provider);
      console.log(
        "file: index.tsx:332 newSendTransaction wallet:",
        wallet.address,
        value
      );

      const enteredAmountInWei = Number(value) * 10 ** usdcDecimals;
      console.log(
        "file: index.tsx:461  sendTopUpTransaction  enteredAmountInWei:",
        enteredAmountInWei,
        enteredAmountInWei.toString()
      );

      console.log("In here [IF]");

      const chain = getChain(activeNetwork);

      const withdrawCallData = await getGasWithdrawData(
        activeAccount.smartAccountAddress,
        BigNumber.from(
          enteredAmountInWei.toString().includes(".")
            ? enteredAmountInWei.toString().split(".")[0]
            : enteredAmountInWei
        ),
        chain,
        rootAccountInfo.address.toLowerCase()
      );

      const cryptoAccountDeployed = await isCryptoAccountDeployed(
        activeNetwork,
        activeAccount.smartAccountAddress
      );
      const { finalOp, usdcFee } = await txSubmissionOrderPrepaid({
        userCallDataArray: [withdrawCallData],

        wallet,

        isAccountDeployed: cryptoAccountDeployed,
        chain,
        provider: stackupProvider,
        smartAccountAddress: activeAccount.smartAccountAddress,
        gasBalance: gas.totalBalance,
        approval: true,
        extraTopupAmount: "0",
        rootAddress: rootAccountInfo.address.toLowerCase(),
      });

      console.log("Clicked!");

      console.log(
        "file: index.tsx:1058  sendCryptoTransactionfinal  finalOp:",
        finalOp
      );

      // if (+usdcFee >= tokenForPaymentBalance) {
      //   showAlert(
      //     "You don't have enough token balance to pay fee, Please select different payment token.",
      //     "Not enough balance"
      //   );
      //   setError(true);
      // }

      setFinalOpState(finalOp);
      setLoading(false);
      setGasFeeInUSD(Number(usdcFee).toFixed(4));
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const isValid =
    Number(value) &&
    Number(value) <= Number(gas[activeNetwork]?.balance) &&
    Number(gas[activeNetwork]?.balance) - Number(value) >= 2;

  const handleAmountChange = (value) => {
    const inputValue = value;
    console.log(inputValue);
    setInputError("");

    let regex = /^\d{0,10}(\.\d{0,7})?$/;

    if (/[^0-9.]/.test(inputValue)) {
      setInputError("");
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setInputError("");
    } else if (!regex.test(inputValue)) {
      setInputError(
        `Maximum of 10 digits before decimals and 6 digits after decimals are allowed`
      );
    } else if (Number(inputValue) > gas[activeNetwork]?.balance) {
      setInputError("Not enough gas deposited");

      setValue(inputValue);
    } else if (gas[activeNetwork]?.balance - Number(inputValue) < 2) {
      setInputError("Gas tank should have atleast $2 left!");

      setValue(inputValue);
    } else {
      setInputError("");

      setValue(inputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      setValue(newValue);
      setInputError("");
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (Number(newValue) > 0) {
        setValue(newValue);
      }
      setInputError("");
    }
  };

  return (
    <>
      <Box mt={3}>
        <NavigatorHeading
          title="Withdraw"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/gas");
              }}
            />
          }
        />
      </Box>
      <Box mt={1}>
        <CustomizedSteppers
          step={step}
          steps={["Network", "Amount", "Withdraw"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />
        <Box mt={2}>
          {step == 0 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "55%",
                  maxWidth: "100%",
                }}
              >
                <NetworksList
                  nextStep={() => setStep(1)}
                  title="Select which network you want receive crypto on"
                />
              </Grid>
            </Grid>
          )}

          {step == 1 && (
            <Grid container display="flex" justifyContent="center" py={4}>
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "55%",
                  maxWidth: "100%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "17px",
                      textAlign: "left",
                      color: "rgba(26, 28, 32, 0.5)",
                      fontFamily: "Helvetica Neue",
                      fontWeight: 500,
                      width: "100%",
                    }}
                  >
                    Amount to withdraw gas
                  </Typography>
                  <TokenInputForm
                    placeHolder={
                      gas[activeNetwork]?.balance &&
                      Number(gas[activeNetwork]?.balance.toString()) < 0.0001
                        ? "<0.0001"
                        : gas[activeNetwork]?.balance.toString().toString()
                    }
                    title="Crypto Tag"
                    addBorder
                    type="number"
                    balance={gas[activeNetwork]?.balance}
                    onChange={handleAmountChange}
                    onKeydown={handleKeyDown}
                    value={String(value)}
                    receiverENS={""}
                    isDepositValid={!!isValid}
                    nextStep={() => {
                      dispatch(
                        setSelectedToken([
                          {
                            image: USDT_URL,
                            tokenName: "USDC",
                            tokenSymbol: "USDC",
                            tokenPrice: 1,
                          },
                        ])
                      );
                      setStep(2);
                    }}
                    tokenName={"USDC"}
                    tokenIcon={USDT_URL}
                    errorMessage={inputError}
                    decimals={6}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {step == 2 && (
            <Grid container display="flex" justifyContent="center" py={4}>
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "55%",
                  maxWidth: "100%",
                }}
              >
                <FillAsset
                  nextStep={() => console.log("end")}
                  value={value}
                  executeSend={sendWithdrawTransaction}
                />

                <Box mt={5}>
                  <FeeUIComponent
                    gasFeeInUSD={gasFeeInUSD}
                    loading={!finalOpState && loading}
                  />
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={50}
                      sx={{
                        borderRadius: "10px",

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  ) : (
                    <BasicButton
                      title={"Send"}
                      onClick={
                        error || !finalOpState
                          ? () => {
                              console.log("abc");
                            }
                          : executeSendCrypto
                      }
                      style={{
                        cursor:
                          error || !finalOpState ? "not-allowed" : "pointer",
                        height: "15px",
                      }}
                      className="transfer-btn"
                      loading={loading}
                      mode={"active"}
                      id="send"
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Funds are not enough to use as gas fees"
      />
    </>
  );
};

export default WithdrawDepositedGas;
