import { SessionTypes, SignClientTypes } from "@walletconnect/types";
import { Web3WalletTypes } from "@walletconnect/web3wallet";
import { proxy } from "valtio";

/**
 * Types
 */
interface ModalData {
  request?: Web3WalletTypes.AuthRequest;
  proposal?: SignClientTypes.EventArguments["session_proposal"];
  requestEvent?: SignClientTypes.EventArguments["session_request"];
  requestSession?: SessionTypes.Struct;
  payload?: {
    callData?: any;
    value?: string;
    to?: string;
  };
  dappDetails?: {
    dappName: string;
    dappIcon: string;
  };
}

interface State {
  open: boolean;
  view?:
    | "SessionProposalModal"
    | "SessionSignModal"
    | "SessionSignTypedDataModal"
    | "SessionSendTransactionModal"
    | "SessionUnsuportedMethodModal"
    | "SessionSignCosmosModal"
    | "SessionSignSolanaModal"
    | "SessionSignPolkadotModal"
    | "SessionSignNearModal"
    | "SessionSignMultiversxModal"
    | "SessionSignTronModal"
    | "SessionSignTezosModal"
    | "SessionSignKadenaModal"
    | "SdkSendTransactionModal"
    | "SessionDisconnectModal"
    | "AuthRequestModal";

  data?: ModalData;
}

/**
 * State
 */
const state = proxy<State>({
  open: false,
});

/**
 * Store / Actions
 */
const ModalStore = {
  state,

  open(view: State["view"], data: State["data"]) {
    state.view = view;
    state.data = data;
    state.open = true;
  },

  close() {
    state.open = false;
  },
};

export default ModalStore;
