// import ProjectInfoCard from '@/components/ProjectInfoCard'

import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import ProjectInfoCard from "../components/ProjectInfoCard";
import RequestDetailsCard from "../components/RequestDetailsCard";
// import RequesDetailsCard from '@/../components/RequestDetalilsCard'

import RequestMethodCard from "../components/RequestMethodCard";
// import RequestMethodCard from '@/../components/RequestMethodCard'

import RequestModalContainer from "../components/RequestModalContainer";
// import RequestModalContainer from '@/components/RequestModalContainer'

// import ModalStore from '@/store/ModalStore'

// import { approveEIP155Request, rejectEIP155Request } from '@/utils/EIP155RequestHandlerUtil'

// import { getSignParamsMessage, styledToast } from '@/utils/HelperUtil'

// import { signClient } from '@/utils/WalletConnectUtil'

// import { Button, Col, Divider, Modal, Row, Text } from '@nextui-org/react'
import { Fragment } from "react";
import {
  approveEIP155Request,
  rejectEIP155Request,
} from "../utils/EIP155RequestHandlerUtil";
import { getSignParamsMessage, styledToast } from "../utils/HelperUtil";
import { signClient, web3wallet } from "../utils/WalletConnectUtil";
import ModalStore from "../walletConnectStore/ModalStore";
import { useAppSelector } from "../store/store";
import SignModalStore from "../walletConnectStore/SignModalStore";
import BasicButton from "components/Button";

export default function SessionSignModal() {
  // Get request and wallet data from store
  const requestEvent = SignModalStore.state.data?.requestEvent;
  const requestSession = SignModalStore.state.data?.requestSession;

  // Ensure request and wallet are defined
  if (!requestEvent || !requestSession) {
    return <p>Missing request data</p>;
  }

  // Get required request data
  const { topic, params } = requestEvent;
  const { request, chainId } = params;
  const { activeAccount } = useAppSelector((state) => state.app);

  // Get message, convert it to UTF8 string if it is valid hex
  const message = getSignParamsMessage(request.params);

  // Handle approve action (logic varies based on request method)
  async function onApprove() {
    if (requestEvent) {
      const response = await approveEIP155Request(
        requestEvent,
        activeAccount.address
      );
      try {
        // await signClient.respond({
        //   topic,
        //   response,
        // });
        await web3wallet.respondSessionRequest({
          topic,
          response,
        });
      } catch (e) {
        styledToast((e as Error).message, "error");
        return;
      }
      SignModalStore.close();
    }
  }

  // Handle reject action
  async function onReject() {
    if (requestEvent) {
      const response = rejectEIP155Request(requestEvent);
      try {
        // await signClient.respond({
        //   topic,
        //   response,
        // });
        await web3wallet.respondSessionRequest({
          topic,
          response,
        });
      } catch (e) {
        styledToast((e as Error).message, "error");
        return;
      }
      SignModalStore.close();
    }
  }

  return (
    <Box
      sx={{
        // background: "white",
        // overflowY: "scroll",
        // width: "50%",
        // height: "70%",
        // transform: "translateX(50%) translateY(10%)",
        // padding: "10px",

        background: "white",
        overflowY: "scroll",
        minWidth: "400px",
        padding: "20px  10px",
        borderRadius: "12px",
        minHeight: "300px",
        maxHeight: "450px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        // border: "1px solid red",
        gap: 3,
      }}
    >
      <RequestModalContainer title="Sign Message">
        <ProjectInfoCard metadata={requestSession.peer.metadata} />

        {/* <Divider /> */}

        {/* <RequestDetailsCard
          chains={[chainId ?? ""]}
          protocol={requestSession.relay.protocol}
        /> */}

        <Divider />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            // border: "1px solid red",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h5>Message</h5>
            {/* <p data-testid="request-message-text">{message}</p> */}
            <Typography
              variant="body1"
              fontSize="12px"
              fontWeight={500}
              color="#121212"
              style={{
                width: "350px",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                wordWrap: "break-word",
                display: "inline-block",
              }}
            >
              {message}
            </Typography>
          </div>
        </div>

        <Divider />

        {/* <RequestMethodCard methods={[request.method]} /> */}
      </RequestModalContainer>

      <Stack
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"row"}
        sx={{
          // border: "1px solid red",
          width: "80%",
          height: "90px",
        }}
      >
        {/* <Button
          color="error"
          onClick={onReject}
          data-testid="request-button-reject"
        >
          Reject
        </Button> */}

        <BasicButton
          title={"Reject"}
          onClick={onReject}
          className="transfer-btn"
          style={{ marginRight: "5px", height: "15px" }}
          loading={false}
          mode={"active"}
          id="send"
        />
        <BasicButton
          title={"Sign"}
          onClick={onApprove}
          className="transfer-btn"
          style={{ marginRight: "5px", height: "15px" }}
          loading={false}
          mode={"active"}
          id="send"
        />

        {/* <Button
          color="success"
          onClick={onApprove}
          data-testid="request-button-approve"
        >
          Approve
        </Button> */}
      </Stack>
    </Box>
  );
}
