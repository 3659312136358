import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Login from "pages/app/Login";

import AppRoutes from "./AppRoutes";

import useInitialization from "hooks/useInitialization";
import useWalletConnectEventsManager from "hooks/useWalletConnectEventsManager";
import { signClient, web3wallet } from "utils/WalletConnectUtil";
import { RELAYER_EVENTS } from "@walletconnect/core";
import { Toaster } from "react-hot-toast";
import Modal from "components/Modal";
import { useAppSelector } from "store/store";
import DappConnection from "pages/app/DappConnection";

// Cash Module Routes
import Cash from "pages/app/Cash";
import CashTransactions from "pages/app/Cash/Transactions";

// Crypto Module Routes
import Crypto from "pages/app/Crypto";
import Gas from "pages/app/Gas";
import NFT from "pages/app/NFT";
import Collections from "../../pages/app/NFT/Collections";
import NFTDetailPage from "../../pages/app/NFT/NFTDetailPage";
import Referral from "../../pages/app/Referral";

import TokenDetailPage from "pages/app/Crypto/TokenDetailPage";
import Send from "pages/app/Crypto/Send";
import Receive from "pages/app/Crypto/Receive";
import CryptoTransactions from "../../pages/app/CryptoTransactions";
import NewSettings from "../../pages/app/Settings/index";
import FillUp from "pages/app/Gas/FillUp";
import SendNFT from "pages/app/NFT/Send";
import WithdrawDepositedGas from "../../pages/app/Gas/WithdrawDepositedGas";
import CashSend from "pages/app/Cash/Send";
import CashExchange from "pages/app/Cash/Exchange";
import TransactionSuccess from "components/TransactionFlow/TransactionSuccess";
import SecurityPattern from "components/TransactionFlow/SecurityPattern";
import DrawPattern from "components/TransactionFlow/DrawPattern";
import PerformTransaction from "components/TransactionFlow/PerformTransaction";
import ConfirmDrawPattern from "components/TransactionFlow/ConfirmDrawPattern";
import SuccessfullyEnabled from "components/TransactionFlow/SuccessfullyEnabled";
import SkipSettings from "components/TransactionFlow/SkipSettings";
import CashReceive from "pages/app/Cash/Receive";
import OnRamp from "pages/app/OnRamp";
import CashDeposit from "pages/app/Cash/Deposit";
import CashWithdraw from "pages/app/Cash/Withdraw";
import CryptoSell from "pages/app/Crypto/Sell";
import CryptoBuy from "pages/app/Crypto/Buy";
import TxModal from "components/TxModal";
import SignModal from "components/SignModal";

const AppFlowRoutes = () => {
  const { expirationTime } = useAppSelector((state) => state.app);

  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const navigate = useNavigate();

  useEffect(() => {
    if (expirationTime < new Date().getTime()) {
      navigate("/login");
    }
  }, []);

  // Step 1 - Initialize wallets and wallet connect client
  const initializeHooks = useInitialization();

  // Step 2 - Once initialized, set up wallet connect event manager
  useWalletConnectEventsManager(initializeHooks);
  // }

  useEffect(() => {
    if (!initializeHooks) return;
    web3wallet?.core.relayer.on(RELAYER_EVENTS.connect, () => {
      console.log("Network connection is restored!", "success");
    });

    web3wallet?.core.relayer.on(RELAYER_EVENTS.disconnect, () => {
      console.log("Network connection lost.", "error");
    });
  }, [initializeHooks]);

  // console.log("initialized in app file", initialized);

  return (
    <>
      <Routes>
        <Route path="/" element={<AppRoutes />}>
          {/* default route */}
          <Route path="/" element={<Navigate to="/cash" />} />

          {/* Cash Module */}
          <Route path="/cash" element={<Cash />} />
          <Route path="/cash/send" element={<CashSend />} />
          <Route path="/cash/exchange" element={<CashExchange />} />
          <Route path="/cash/deposit" element={<CashDeposit />} />
          <Route path="/cash/withdraw" element={<CashWithdraw />} />

          <Route path="/cash/transactions" element={<CashTransactions />} />

          {/* Crypto Module */}
          <Route path="/crypto" element={<Crypto />} />
          <Route path="/crypto/:token" element={<TokenDetailPage />} />
          <Route path="/crypto/transactions" element={<CryptoTransactions />} />
          <Route path="/crypto/send" element={<Send />} />

          <Route path="/crypto/receive" element={<Receive />} />
          <Route path="/cash/receive" element={<CashReceive />} />
          <Route path="/crypto/sell" element={<CryptoSell />} />
          <Route path="/crypto/buy" element={<CryptoBuy />} />

          {/* Gas Module */}
          <Route path="/gas" element={<Gas />} />
          <Route path="/gas/fillup" element={<FillUp />} />
          <Route path="/gas/withdraw" element={<WithdrawDepositedGas />} />
          <Route path="/gas/transactions" element={<CryptoTransactions />} />

          {/* pages */}

          <Route path={"/nfts"} element={<NFT />} />
          <Route path="/nfts/receive" element={<Receive isNftPage />} />
          <Route path={"/nfts/send"} element={<SendNFT />} />
          <Route path="/nfts/transactions" element={<CryptoTransactions />} />

          <Route
            path={"/nfts/:NftColName/:chainName/:nftId/send"}
            element={<SendNFT />}
          />

          <Route path={"/nfts/:NftColName"} element={<Collections />} />

          <Route
            path={"/nfts/:NftColName/:nftId/:chainName"}
            element={<NFTDetailPage />}
          />

          <Route path={"/referral"} element={<Referral />} />
          <Route path={"/dapp-connection"} element={<DappConnection />} />

          <Route path={"/settings"} element={<NewSettings />} />
          {/* <Route path={"/settings2"} element={<Settings />} /> */}
          <Route path={"/on-ramp"} element={<OnRamp />} />
        </Route>
        <Route path={"/login"} element={<Login />} />

        {/* Transaction Flow */}
        <Route path="/" element={<AppRoutes />}>
          <Route
            path={"/transaction-success"}
            element={<TransactionSuccess />}
          />
          <Route path={"/security-pattern"} element={<SecurityPattern />} />
          <Route path={"/draw-pattern"} element={<DrawPattern />} />
          <Route
            path={"/confirm-draw-pattern"}
            element={<ConfirmDrawPattern />}
          />
          <Route
            path={"/perform-transaction"}
            element={<PerformTransaction />}
          />
          <Route
            path={"/successfully-enabled"}
            element={<SuccessfullyEnabled />}
          />

          <Route path={"/skip-settings"} element={<SkipSettings />} />
        </Route>
      </Routes>
      <Toaster />
      <Modal />
      <TxModal />
      <SignModal />
    </>
  );
};

export default AppFlowRoutes;
