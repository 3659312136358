import React, { useEffect, useState } from "react";
import WelcomeBox from "components/WelcomeBox";

import Google from "assets/google.svg";
import Discord from "assets/discord.svg";
import TrustWallet from "assets/trust-wallet.svg";
import Metamask from "assets/metamask.svg";
import StashedGlobe from "assets/stashed-globe.svg";

import { CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import AuthHeader from "components/AuthHeader";

import { init, loginWithGoogle } from "utils/google";
import { useAppDispatch } from "store/store";
import { setAccesToken, setMode, setUserInfo } from "@slices/appSlice";
import { setTorusKey } from "@slices/walletSlice";
import { isUserExists, showAlert } from "utils/utils";

import { loginWithTwitter } from "utils/twitter";
import { Box } from "@mui/system";
import CustomizedSteppers from "components/Stepper";
import { stepsCount } from "../../../../../src/constants";
import StepperForPages from "components/Stepper/StepperForPages";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import RPC from "constants/webRPC";

const CreateAccount = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [path, setPath] = useState("/create-account");
  const [web3auth, setWeb3auth] = useState<Web3AuthNoModal | null>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [step, setStep] = useState(0);

  useEffect(() => {
    (async () => {
      const web3auth = await init(path);
      if (web3auth) {
        web3auth.logout();
        setWeb3auth(web3auth);
        console.log("web3auth", web3auth);
        if (web3auth.connected) {
          setLoading(true);
          const res = await web3auth.getUserInfo();
          const rpc = new RPC(web3auth.provider!);
          console.log("res0000000000000000", res);
          const address = await rpc.getAccounts();
          console.log(address);

          const { name, email, profileImage, idToken } = res;
          if (email) {
            const userData = await isUserExists(email, "a", "a");

            if (userData) {
              navigate("/signin");
              // showAlert("Account already exists. Please login.");

              await web3auth.logout();

              setLoading(false);
            } else {
              dispatch(setMode("google"));
              dispatch(setTorusKey(address[0].toString("hex")));
              dispatch(
                setUserInfo({ name, email, profileImage: profileImage })
              );
              dispatch(setAccesToken(idToken));
              setLoading(false);
              setPath("/create-password");
              navigate("/create-password", {
                state: { currentStep: step + 1, pathName: "/create-account" },
              });
            }
          }
        }
      }
    })();
  }, [path]);

  const signupWithGoogle = async () => {
    try {
      if (web3auth) {
        setLoading(true);
        setError("");

        await loginWithGoogle(web3auth);

        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      // setError("Signup Failed!");
    }
  };

  const totalSteps = stepsCount["/create-account"] || 3;

  return (
    <div className="create-account-container">
      <Grid container spacing={2}>
        <Grid item xs={8} className="auth-header-with-steps">
          <AuthHeader />
        </Grid>
        <Grid
          item
          xs={16}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box className="auth-header-steps" width={"50%"}>
            <StepperForPages
              step={step}
              steps={new Array(totalSteps).fill("")}
              changeStep={(selectedStep: number) => {
                //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
                if (selectedStep < step) {
                  setStep(selectedStep);
                }
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <div className="create-account-box">
        <Typography
          variant="h2"
          sx={{
            margin: "30px 0px 30px 0px",

            fontWeight: "700",
          }}
        >
          Create Account
        </Typography>

        {/* <div className='welcome-box-container'>
          {loading ? (
            <div
              style={{ width: "100%", textAlign: "center", margin: "20px 0px" }}
            >
              <CircularProgress color='inherit' />
            </div>
          ) : (
            <div className='box-main'>
              <div className='box top-box'>
                <WelcomeBox
                  title='Continue with Google'
                  description=''
                  onClick={signupWithGoogle}
                  icon={<img src={Google} style={{ marginRight: 10 }} />}
                />
              </div>

              <div className='box top-box'>
                <WelcomeBox
                  title='Continue with Discord'
                  description=''
                  onClick={signupWithDiscord}
                  icon={<img src={Discord} style={{ marginRight: 10 }} />}
                />
              </div>
            </div>
          )}
        </div> */}
        <div
          className="welcome-box-container"
          style={{
            zIndex: 999,
          }}
        >
          {loading || !web3auth ? (
            <div
              style={{ width: "100%", textAlign: "center", margin: "20px 0px" }}
            >
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <div className="box-main">
              <div className="box top-box">
                <WelcomeBox
                  title="Continue with Google"
                  description=""
                  onClick={signupWithGoogle}
                  icon={<img src={Google} style={{ marginRight: 10 }} />}
                />
              </div>
            </div>
          )}
          {error && (
            <Typography style={{ color: "red", marginTop: "5%" }}>
              {error}
            </Typography>
          )}
        </div>
      </div>
      <div className="stashed-globe">
        <img src={StashedGlobe} />
      </div>
    </div>
  );
};

export default CreateAccount;
