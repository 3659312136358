import { setHoldings, setPortfolio } from "@slices/appSlice";
import { setWalletLoading } from "@slices/walletSlice";
import { Holdings } from "interfaces";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { useSnapshot } from "valtio";
import SendTransactionModalStore from "../walletConnectStore/SendTransactionModalStore";
import { fetchUsdPriceFromMobula, initalizeWeb3 } from "utils/utils";
import { NATIVE_ADDRESS } from "constants/";
import { SUPPORTED_NETWORKS } from "constants/chains";
import abi from "abis/erc20abi.json";

const useFetchPortfolioSocket = () => {
  const { activeAccount, activeNetwork } = useAppSelector((state) => state.app);
  const { hashedPassword, txStatus, openConnectionModal } = useAppSelector(
    (state) => state.wallet
  );

  const { txnQueue, viewST } = useSnapshot(SendTransactionModalStore.state);
  const dispatch = useAppDispatch();
  const [canDispatchHoldings, setCanDispatchHoldings] = useState(true);

  useEffect(() => {
    console.log("condition vars", { hashedPassword, viewST, txnQueue });
    if (
      (viewST && hashedPassword.length > 0) ||
      (txnQueue.length > 0 && hashedPassword.length > 0) ||
      openConnectionModal
    ) {
      setCanDispatchHoldings(false);
      console.log("main condition changed to ", false);
    } else {
      setCanDispatchHoldings(true);
      console.log("main condition changed to ", true);
    }
  }, [viewST, hashedPassword, txnQueue, openConnectionModal]);

  useEffect(() => {
    if (
      activeAccount.smartAccountAddress &&
      hashedPassword &&
      canDispatchHoldings
    ) {
      dispatch(setWalletLoading(true));

      // Define the WebSocket URL
      const webSocketUrl = "wss://portfolio-api-wss-fgpupeioaa-uc.a.run.app";

      // Initialize WebSocket connection
      const socket = new WebSocket(webSocketUrl);

      // Event listener for when the connection is established
      socket.onopen = () => {
        console.log("WebSocket connection established.");
        // Send initial payload after connection is established
        const payload = {
          type: "wallet",
          authorization: process.env.REACT_APP_MOBULA_KEY,
          payload: {
            wallet: activeAccount.smartAccountAddress,
            interval: 15,
          },
        };
        socket.send(JSON.stringify(payload));
      };

      // Event listener for incoming messages
      socket.onmessage = async (event) => {
        console.log("main condition", { canDispatchHoldings });
        if (canDispatchHoldings) {
          if (!event.data.includes("no transactions")) {
            const walletPortfolio = JSON.parse(event.data);
            console.log("SOCKET DATAAAAAAAA", walletPortfolio);

            const { wethAddress } =
              SUPPORTED_NETWORKS[
                activeNetwork as keyof typeof SUPPORTED_NETWORKS
              ];

            const web3 = initalizeWeb3(activeNetwork);
            const contract = new web3.eth.Contract(abi.abi, wethAddress);
            const wethBalance = await contract.methods
              .balanceOf(activeAccount.smartAccountAddress)
              .call();

            const balance = await web3.eth.getBalance(
              activeAccount.smartAccountAddress
            );

            console.log("wethBalance", wethBalance);
            console.log("balance", balance);

            let assets: any = [];

            const balanceInUsd = await fetchUsdPriceFromMobula("polygon");
            walletPortfolio.assets.map((asset) => {
              if (asset.asset.name.toLowerCase() === "polygon") {
                assets.push(
                  {
                    ...asset,
                    token_balance: Number(balance) / 10 ** 18,
                    estimated_balance:
                      balanceInUsd * (Number(balance) / 10 ** 18),
                    asset: {
                      ...asset.asset,
                      name: "Matic",
                      symbol: "MATIC",
                    },
                    cross_chain_balances: {
                      Polygon: {
                        ...asset.cross_chain_balances.Polygon,
                        balance: Number(balance) / 10 ** 18,
                        balanceRaw: balance,
                        address: NATIVE_ADDRESS,
                      },
                    },
                  },
                  {
                    ...asset,
                    token_balance: Number(wethBalance) / 10 ** 18,
                    estimated_balance:
                      balanceInUsd * (Number(wethBalance) / 10 ** 18),
                    asset: {
                      ...asset.asset,
                      name: "Wrapped Matic",
                      symbol: "WMATIC",
                    },
                    cross_chain_balances: {
                      Polygon: {
                        ...asset.cross_chain_balances.Polygon,
                        balance: Number(wethBalance) / 10 ** 18,
                        balanceRaw: wethBalance.toString(),
                        address: wethAddress,
                      },
                    },
                  }
                );
              } else {
                assets.push(asset);
              }
            });

            dispatch(
              setPortfolio({
                ...walletPortfolio,
                assets,
              })
            );

            let holdings: Holdings = {};

            holdings[activeAccount.smartAccountAddress] = {
              nativeBalance: 0,
              nativeBalanceUsd: 0,
              nativeTokenPrice: 0,
              tokens: [],
            };

            console.log(
              "holdings[activeAccount.smartAccountAddress]",
              holdings
            );
            dispatch(
              setHoldings({
                ...holdings,
              })
            );

            if (
              walletPortfolio?.assets &&
              walletPortfolio?.assets?.length > 0
            ) {
              walletPortfolio.assets.map((token) => {
                if (
                  token?.asset?.name?.toLowerCase() === "polygon" &&
                  token?.asset?.symbol?.toLowerCase() == "matic"
                ) {
                  holdings[activeAccount.smartAccountAddress] = {
                    nativeBalance: token?.token_balance,
                    nativeBalanceUsd: token?.estimated_balance,
                    nativeTokenPrice: token?.price,
                    tokens: [],
                  };

                  dispatch(
                    setHoldings({
                      ...holdings,
                    })
                  );
                }
              });
            } else {
              holdings[activeAccount.smartAccountAddress] = {
                nativeBalance: 0,
                nativeBalanceUsd: 0,
                nativeTokenPrice: 0,
                tokens: [],
              };
              dispatch(
                setHoldings({
                  ...holdings,
                })
              );
            }

            dispatch(setWalletLoading(false));
          }
        } else {
          console.log("NHI chala", { canDispatchHoldings });
          dispatch(setWalletLoading(false));
        }
        // Handle incoming messages here
      };

      // Event listener for errors
      socket.onerror = (error) => {
        console.log("WebSocket error:", error);
      };

      // Event listener for closing the connection
      socket.onclose = () => {
        console.log("WebSocket connection closed.");
        // Optionally handle reconnection logic here
      };

      // Clean up WebSocket connection on unmount
      return () => {
        socket.close();
      };
    }
  }, [
    activeAccount,
    hashedPassword,
    txStatus,
    canDispatchHoldings,
    openConnectionModal,
  ]);

  return null;
};

export default useFetchPortfolioSocket;
