import { FC, useEffect, useState } from "react";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import ItemBox from "components/ItemBox";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  setPendingTx,
  setPendingTxDetails,
  setSwapDetails,
  setUserSpendingDetails,
} from "@slices/appSlice";
import {
  AcrossExactInputCalldata,
  checkGasAllowance,
  getAlphaRouterResponse,
} from "utils/swap";
import {
  decryptMessage,
  fetchPrice,
  formatAmount,
  initalizeWeb3,
  showAlert,
} from "utils/utils";
import { useNavigate } from "react-router-dom";
import { SUPPORTED_NETWORKS } from "constants/chains";
import { sendUserOp } from "../../contract-integration";
import { ethers } from "ethers";
import ConfirmPatternModal from "components/ConfirmPatternModal";
import { TradeType } from "@uniswap/sdk-core";
import { SwapRoute, SwapType } from "@uniswap/smart-order-router";
import SendTxComponent from "components/SendTxComponent";
import FeeUIComponent from "components/SendTxComponent/FeeUIComponent";
import MidArrow from "assets/midArrow.svg";
import Simulation from "components/Simulation";
import abi from "abis/erc20abi.json";
import BasicButton from "components/Button";
import ExtraTopupAmountPopup from "components/ExtraTopupAmountPopup";
import { isCryptoAccountDeployed } from "utils/deployed";
import { createSignatureAndCalculateFees } from "utils/signature";
import {
  USDT_URL,
  APPROVE_AMOUNT,
  NATIVE_ADDRESS,
  ZERO_ADDRESS,
  UNISWAP_ZERO_ADDRESS,
} from "constants/";
import { getChain } from "utils/chains";
import WarningModal from "components/WarningModal";

const SwapSummary: FC<{
  isError?: boolean;
  usdcBalance: number;
  setStep?: () => void;
}> = ({ isError, usdcBalance, setStep }) => {
  const {
    swapDetails: { tokenA, tokenB },
    activeAccount,
    rootAccountInfo,
    activeNetwork,

    userSpendingDetails,
    gas,
    pendingTx,
  } = useAppSelector((state) => state.app);
  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [risk, setRisk] = useState(false);
  const [cost, setCost] = useState(0);
  const [finalOpState, setFinalOpState] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [tokenLoading, setTokenLoading] = useState(false);
  const [error, setError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [onReverse, setOnReverse] = useState(false);
  const [isPatternCorrect, setIsPatternCorrect] = useState(false);
  const [allowance, setAllowance] = useState(0);
  const [extraTopupAmount, setExtraTopupAmount] = useState("0");
  const [openExtraTopupModal, setOpenExtraTopupModal] = useState(false);

  const [openWarningModal, setOpenWarningModal] = useState(false);

  const { usdcAddress, alchemy_url, stackupUrl, wethAddress } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  useEffect(() => {
    (async () => {
      const allowance = await checkGasAllowance(
        activeAccount.smartAccountAddress,
        usdcAddress,
        activeNetwork
      );

      setAllowance(Number(allowance));
    })();
  }, []);

  const handleSwap = async () => {
    if (
      userSpendingDetails.isDailyLimitExceed &&
      userSpendingDetails.isPatternSet
    ) {
      setOpenModal(true);
    } else {
      onSwap();
    }
  };

  const onSwap = async () => {
    try {
      // // console.log("🚀 ~ file: index.tsx:60 ~ onSwap ~ data:", data);
      if (finalOpState) {
        setLoading(true);
        setOpenExtraTopupModal(false);
        const response = await sendUserOp(
          finalOpState,
          stackupUrl,
          alchemy_url
        );
        console.log("🚀 ~ file: index.tsx:121 ~ onSwap ~ response:", response);
        const userOPResponse: any = await response.wait();
        // console.log("userOp Hash :", response.userOpHash);
        // console.log("Tx Hash :", userOPResponse?.transactionHash);
        // console.log("success status :", userOPResponse?.args.success);
        // console.log(
        // "actualGasCost  :",
        // Number(userOPResponse?.args.actualGasCost)
        // );
        // const response = await sendUserOp(finalOp, bundlerRPC, rpcEndpoint);
        // const userOPResponse: any = await response.wait();
        // // console.log("Tx Hash :", userOPResponse?.transactionHash);
        // // console.log("success status :", userOPResponse?.args.success);
        // console.log(
        // "actualGasCost  :",
        // Number(userOPResponse?.args.actualGasCost)
        // );
        // console.log(
        // "actualGasUsed  :",
        // Number(userOPResponse?.args.actualGasUsed)
        // );

        // dispatch(setPendingTx(response.userOpHash));

        showAlert(
          "Soon you can see your transaction in the transactions tab",
          allowance
            ? "Transaction Submitted"
            : "Approval Transaction Submitted",
          `<a href="https://polygonscan.com/tx/${userOPResponse.transactionHash}" target="_blank">View on Polygonscan</a>`
        );

        setFinalOpState(null);

        const { block_explorer_url } =
          SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

        dispatch(
          setPendingTxDetails({
            value: allowance ? tokenA.amount : 0,
            valueIn$: allowance ? tokenA.amountInUSD : 0,
            transferAmount: allowance ? tokenB.amount : 0,
            transactionMethod: "SWAP",
            scanLink: block_explorer_url,
            eoaEns: rootAccountInfo.name,
            addressEns: activeAccount.accountName,
            toAddressEns: allowance ? tokenB.tokenSymbol : usdcAddress,
            toAddress: allowance ? tokenB.tokenName : usdcAddress,
            assetName: allowance ? tokenA.tokenSymbol : "USDC",
            networkFeesIn$: cost,
            iconURL: allowance ? tokenA.image : USDT_URL,
            txByDesposited: false,
            action: allowance ? "Swapped" : "Approved",
          })
        );

        dispatch(setPendingTx(userOPResponse.transactionHash));

        // setFinalOpState(null);
        if (
          userSpendingDetails.isFirstTx &&
          userSpendingDetails.isFirstTxInApp
        ) {
          dispatch(
            setUserSpendingDetails({
              isFirstTxInApp: false,
              isFirstTx: false,
            })
          );
          if (allowance) {
            navigate("/transaction-success");
          }
        } else {
          if (allowance) {
            navigate("/crypto");
          }
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      showAlert("Something went wrong");
      navigate("/crypto");
    }
  };

  const calculateTransactionParameters = async (response: SwapRoute) => {
    try {
      const pKey = decryptMessage(activeAccount.secret, hashedPassword);
      // console.log("🚀 ~ file: index.tsx:39 ~ onSwap ~ pKey:", pKey);
      setLoading(true);
      setError(false);

      const web3 = initalizeWeb3(activeNetwork);
      //@ts-ignore

      const isTokenANative =
        tokenA.tokenAddress.toLowerCase() === NATIVE_ADDRESS.toLowerCase();

      let balance;
      if (isTokenANative) {
        balance = await web3.eth.getBalance(activeAccount.smartAccountAddress);
      } else {
        const contract = new web3.eth.Contract(abi.abi, tokenA.tokenAddress);
        balance = await contract.methods
          .balanceOf(activeAccount.smartAccountAddress)
          .call();
      }

      const formattedBalance = balance / 10 ** tokenA.tokenDecimal;

      console.log(formattedBalance, "formattedBalance", tokenA.amount, tokenA);

      if (
        tokenA.tokenAddress !== wethAddress &&
        formattedBalance <= tokenA.amount
      ) {
        showAlert("Not enough funds");
        setLoading(false);
        return;
      }

      const cryptoAccountDeployed = await isCryptoAccountDeployed(
        activeNetwork,
        activeAccount.smartAccountAddress
      );

      const { results: data, simulationData } = await AcrossExactInputCalldata(
        {
          amount: (
            tokenA.amount * Math.pow(10, tokenA.tokenDecimal)
          ).toString(),
          destinationChainId: activeNetwork.toString(),
          maxCount:
            "115792089237316195423570985008687907853269984665640564039457584007913129639935",
          message: "0",
          originToken: tokenA.tokenAddress,
          recipient: activeAccount.smartAccountAddress,
        },
        activeNetwork,
        pKey,

        activeAccount.smartAccountAddress,

        cryptoAccountDeployed,
        gas.totalBalance,
        rootAccountInfo.address.toLowerCase(),
        extraTopupAmount,
        response
      );
      if (simulationData.validation.result_type !== "Benign") {
        setRisk(true);
      }
      setFinalOpState(data.finalOp);

      setCost(+data.usdcFee);
      if (Number(+data.usdcFee) > gas.totalBalance) {
        setOpenExtraTopupModal(true);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.includes("alpha router")) {
        setError("Insufficient liquidity for this trade");
      } else {
        setError("");
        showAlert(error);
      }

      navigate("/crypto");
      throw error;
    }
  };

  const fetchTokenBData = async () => {
    try {
      setLoading(true);
      setTokenLoading(true);

      const isTokenBNative =
        tokenB.tokenAddress.toLowerCase() === ZERO_ADDRESS.toLowerCase() ||
        tokenB.tokenAddress.toLowerCase() ===
          UNISWAP_ZERO_ADDRESS.toLowerCase();

      const response = await getAlphaRouterResponse(
        tokenA.tokenAddress,
        tokenA.tokenDecimal,
        tokenB.tokenAddress,
        tokenB.tokenDecimal,
        activeAccount.smartAccountAddress,
        (tokenA.amount * Math.pow(10, tokenA.tokenDecimal)).toString(),
        TradeType.EXACT_INPUT,
        isTokenBNative ? SwapType.UNIVERSAL_ROUTER : SwapType.SWAP_ROUTER_02,
        activeNetwork
      );

      console.log("response new", response);

      const price = await fetchPrice(
        [isTokenBNative ? wethAddress : tokenB.tokenAddress.toLowerCase()],
        activeNetwork
      );
      console.log("==============price:", price);

      const tokenPrice =
        price[isTokenBNative ? wethAddress : tokenB.tokenAddress.toLowerCase()]
          ?.price || 0;
      console.log("tokenPrice", tokenPrice);
      const amountOut =
        +response.trade.outputAmount.numerator.toString() /
        Math.pow(10, tokenB.tokenDecimal);
      console.log(" amountOut:", +amountOut);
      dispatch(
        setSwapDetails({
          tokenB: {
            ...tokenB,
            amount: amountOut,
            amountInUSD: +amountOut * tokenPrice,
            tokenPrice,
          },
        })
      );

      setTokenLoading(false);

      return response;
    } catch (error) {
      console.log("error in fetchTokenBData", error);
      setLoading(false);
      setTokenLoading(false);
      // setError(error);
      // showAlert("Something went wrong");
    }
  };

  useEffect(() => {
    (async () => {
      console.log("USE EFFECT CALLED");
      if (!usdcBalance && !gas.totalBalance) {
        showAlert("You do not have USDC balance to top up gas tank.");
        setTokenLoading(false);
        setLoading(false);
      } else {
        if (usdcBalance < 2) {
          setOpenWarningModal(true);
        }

        const response = await fetchTokenBData();
        await calculateTransactionParameters(response);
      }
    })();
  }, [onReverse, usdcBalance]);

  useEffect(() => {
    if (isPatternCorrect) {
      setOpenModal(false);
      onSwap();
    }
  }, [isPatternCorrect]);

  const reverseTokens = () => {
    setOnReverse(!onReverse);
    dispatch(
      setSwapDetails({
        tokenA: tokenB,
        tokenB: tokenA,
      })
    );
  };

  const handleTopupAmountChange = (value) => {
    const inputValue = value;
    console.log(inputValue);

    if (/[^0-9.]/.test(inputValue)) {
      setError("");
    } else {
      setError("");
      setExtraTopupAmount(inputValue);
    }
  };

  const createSignature = async () => {
    try {
      const chain = getChain(activeNetwork);
      const { feesInUsdc, preFinalOp } = await createSignatureAndCalculateFees(
        finalOpState,
        rootAccountInfo.address,
        extraTopupAmount,
        activeAccount.secret,
        hashedPassword,
        alchemy_url,
        chain
      );
      setFinalOpState(preFinalOp);

      setCost(Number(Number(feesInUsdc).toFixed(4)));
    } catch (error) {
      setLoading(false);
      showAlert(error);
    }
  };
  const addExtraTopupAmount = async () => {
    try {
      setOpenExtraTopupModal(false);
      setLoading(true);

      await createSignature();

      await onSwap();
    } catch (error) {
      setLoading(false);
      showAlert(error);
    }
  };

  return (
    <>
      <ExtraTopupAmountPopup
        openExtraTopupModal={openExtraTopupModal}
        addExtraTopupAmount={addExtraTopupAmount}
        extraTopupAmount={extraTopupAmount}
        gasBalance={gas.totalBalance}
        usdcBalance={usdcBalance}
        handleTopupAmountChange={handleTopupAmountChange}
        minimumTopupAmount={usdcBalance - cost}
        closeExtraTopupModal={() => {
          setOpenExtraTopupModal(false);
          setLoading(false);
        }}
      />
      <WarningModal
        close={() => setOpenWarningModal(false)}
        open={openWarningModal}
        setStep={setStep}
      />
      <Stack
        width={"100%"}
        margin={"auto"}
        style={{
          flexBasis: "100%",
          maxWidth: "68%",
        }}
      >
        <ItemBox
          style={{
            padding: "15px 10px",
            margin: "0 5px 0 5px",
            borderRadius: "12px",
            position: "relative",
            background: "linear-gradient(0deg, #EDEEF2, #F7F7F7, #FFFFFF)",
          }}
          tokenName={tokenA.tokenName}
          tokenSymbol={""}
          tokenAmount={tokenA.amount}
          tokenImage={tokenA.image}
          tokenAmoundInUsd={tokenA.amount * tokenA.tokenPrice}
          amountSymbol={tokenA.tokenSymbol}
        />
        <div
          style={{
            position: "absolute",
            top: "52%",
            left: "50%",
            zIndex: 2,
            cursor: "pointer",
          }}
          onClick={reverseTokens}
        >
          <img src={MidArrow} />
        </div>
        <ItemBox
          style={{
            padding: "15px 10px",
            margin: "5px",
            borderRadius: "12px",
            backgroundColor: "#EDEEF2",
          }}
          tokenName={tokenB.tokenName}
          tokenSymbol={""}
          tokenAmount={tokenB.amount}
          tokenImage={tokenB.image}
          tokenAmoundInUsd={tokenB.amount * tokenB.tokenPrice}
          amountSymbol={tokenB.tokenSymbol}
          loading={tokenLoading}
        />

        {risk && <Simulation />}

        <Box mt={5}>
          <FeeUIComponent
            gasFeeInUSD={formatAmount(cost)}
            loading={!finalOpState && loading}
          />
          {loading ? (
            <Skeleton
              variant="rectangular"
              width={"100%"}
              height={50}
              sx={{
                borderRadius: "10px",

                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          ) : (
            <BasicButton
              title={allowance ? "Swap" : "Approve Paymaster"}
              onClick={
                error || !finalOpState
                  ? () => {
                      console.log("abc");
                    }
                  : handleSwap
              }
              style={{
                cursor: error || !finalOpState ? "not-allowed" : "pointer",
                height: "15px",
              }}
              className="transfer-btn"
              loading={loading}
              mode={"active"}
              id="send"
            />
          )}
        </Box>

        <Typography style={{ color: "red", fontSize: 14 }}>{error}</Typography>

        <ConfirmPatternModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          setIsPatternCorrect={setIsPatternCorrect}
        />
      </Stack>
    </>
  );
};

export default SwapSummary;
