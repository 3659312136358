import { Grid, Slide, Typography } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import { Asset } from "interfaces";
import TokenImage from "components/TokenImage";
import { numFormatter } from "utils/utils";
import CardLayout from "../../../../components/CardLayout";
import { Box } from "@mui/system";
import Button from "../../../../components/NewButton";
import Globe from "assets/emptyCryptoList.svg";
import { setSelectedToken } from "../../../../store/slices/walletSlice";
import { WRAPPED_TOKEN_ADDRESS } from "../../../../constants/topTokensConf";
import { SUPPORTED_NETWORKS } from "../../../../constants/chains";
import { setSwapDetails } from "@slices/appSlice";

const ListComponent: FC<{
  asset: Asset;
  nextStep?: () => void;
  transactionForm?: boolean;
  isShowTokenAmountUnderName?: boolean;
  selectedFilter?: number[];
}> = ({
  asset,
  nextStep = () => {},
  transactionForm = false,
  isShowTokenAmountUnderName = false,
  selectedFilter = [],
}) => {
  console.log("file: TokensList.tsx:19  asset: selectedToken", asset);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    nfts,
    activeAccount,
    activeNetwork,
    swapDetails: { tokenB },
  } = useAppSelector((state) => state.app);

  console.log("file: index.tsx:17  activeNetwork:", activeNetwork);
  const { amount: tokenBalance, symbol: tokenSymbol } = numFormatter(
    asset.token_balance,
    4
  );
  const dispatch = useAppDispatch();
  const { amount: usdBalance, symbol: usdSymbol } = numFormatter(
    asset.estimated_balance,
    2
  );

  const matchingObject = Object.values(asset.cross_chain_balances).find(
    (balanceObject) => balanceObject.chainId == activeNetwork
  );

  const { symbol } =
    SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const isDisabled =
    pathname.includes("buy") &&
    matchingObject &&
    (asset.asset.symbol.toLowerCase() === symbol.toLowerCase()
      ? tokenB.tokenAddress.toLowerCase() ===
        "0x0000000000000000000000000000000000001010"
      : matchingObject.address.toLowerCase() ===
        tokenB.tokenAddress.toLocaleLowerCase());

  return (
    !!tokenBalance && (
      <Grid
        sx={{
          padding: "10px",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          cursor: "pointer",
          borderRadius: "10px",
          "&:hover": {
            backgroundColor: "lightgray",
          },
          opacity: pathname.includes("buy") && isDisabled ? 0.5 : 1,
          pointerEvents:
            pathname.includes("buy") && isDisabled ? "none" : "all",
        }}
        onClick={async () => {
          if (!isDisabled) {
            if (transactionForm) {
              console.log(
                "file: TokensList.tsx:44 selectedToken  transactionForm:",
                transactionForm,
                asset
              );

              console.log(
                "file: TokensList.tsx:57  matchingObject: transactionForm",
                matchingObject
              );
              if (matchingObject) {
                const TokenDetails = {
                  id: asset.asset.id,
                  image: asset.asset.logo,
                  amountInUSD: 0,
                  tokenAddress: matchingObject.address,
                  // "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
                  tokenBalance: matchingObject.balance,
                  tokenDecimal:
                    (
                      SUPPORTED_NETWORKS[
                        activeNetwork as keyof typeof SUPPORTED_NETWORKS
                      ]?.topTokens as any
                    )[asset.asset.symbol]?.decimals ?? 18,
                  tokenName: asset.asset.name,
                  tokenPrice: asset.price,
                  tokenSymbol: asset.asset.symbol,
                  amount: 0,
                };
                await dispatch(
                  setSelectedToken([
                    // { asset },
                    {
                      ...TokenDetails,
                      isNative:
                        matchingObject.address ==
                        WRAPPED_TOKEN_ADDRESS[activeNetwork],
                    },
                  ])
                );
                dispatch(setSwapDetails({ tokenA: TokenDetails }));
                nextStep(TokenDetails);
              }
            } else {
              navigate(asset.asset.name);
            }
          }
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <TokenImage logo={asset.asset.logo} />
          <Grid
            sx={{
              color: "#1A1C20",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              fontFamily: "Space Grotesk",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            <div>{asset.asset.name}</div>
            <div
              style={{
                // color: "rgba(26, 28, 32, 0.50)",
                fontFamily: "Helvetica Neue",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              {isShowTokenAmountUnderName && (
                <span
                  style={{
                    color: "rgba(26, 28, 32, 0.50)",
                  }}
                >
                  {tokenBalance <= 0.0001
                    ? "< 0.0001"
                    : tokenBalance + tokenSymbol + " "}{" "}
                  {asset.asset.symbol}
                </span>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <Grid
            sx={{
              color: "#1A1C20",
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              fontFamily: "Space Grotesk",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "normal",
            }}
          >
            <div
              style={{
                textAlign: "right",
              }}
            >
              {usdBalance <= 0.0001
                ? "< $0.0001"
                : `$${usdBalance + usdSymbol}`}
            </div>
            {!isShowTokenAmountUnderName && (
              <span
                style={{
                  color: "rgba(26, 28, 32, 0.50)",
                }}
              >
                {tokenBalance <= 0.0001
                  ? "<0.0001"
                  : tokenBalance + tokenSymbol + " "}{" "}
                {asset.asset.symbol}
              </span>
            )}
            {/* <div
              style={{
                // color: "rgba(26, 28, 32, 0.50)",
                fontFamily: "Helvetica Neue",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Min Price
            </div> */}
          </Grid>
        </Grid>
      </Grid>
    )
  );
};

const TokensList: FC<{
  transactionForm?: boolean;
  nextStep?: () => void;
  filterText?: string;
  isApplyFilter: boolean;
  chainId: number;
  isShowTokenAmountUnderName?: boolean;
  setFilteredAssets?: any;
  filteredAssets?: any;
  selectedFilter?: number[];
}> = ({
  transactionForm = false,
  nextStep = () => {},
  filterText = "",
  chainId,
  isApplyFilter,
  isShowTokenAmountUnderName = false,
  setFilteredAssets,
  filteredAssets,
  selectedFilter = [],
}) => {
  const navigate = useNavigate();
  const {
    portfolio: { assets },
    activeNetwork,
    swapDetails,
  } = useAppSelector((state) => state.app);

  // const chainAssets = assets;
  // console.log("🚀 ~ file: TokensList.tsx:204 ~ chainAssets:", chainAssets);
  // const [filteredAssets, setFilteredAssets] = useState(assets);

  useEffect(() => {
    // This function will update 'filteredAssets' based on the filter conditions
    const updateFilteredAssets = () => {
      const chainAssets = assets.filter((item: any) => {
        const matchingObject = Object.values(item.cross_chain_balances).find(
          (balanceObject: any) => balanceObject.chainId == activeNetwork
        );

        if (matchingObject) {
          return true;
        }
        return false;
      }); //reverting - uncomment Muqeet's code to show tokens list

      let result = chainAssets;

      console.log(
        "🚀 ~ file: TokensList.tsx:224 ~ updateFilteredAssets ~ isApplyFilter:",
        isApplyFilter
      );
      if (isApplyFilter) {
        result = result.filter((item) => {
          let relevantBalanceObject = null;

          // First, check if cross_chain_balances is null or not an object. If it is, we want to include this item.
          // console.log(
          //   "🚀 ~ file: TokensList.tsx:236 ~ result=result.filter ~  !item.cross_chain_balances:",
          //   !item.cross_chain_balances,
          //   typeof item.cross_chain_balances
          // );
          // if (Object.keys(item.cross_chain_balances).length === 0) {
          //   console.log("lie here");
          //   return false; // This will include the asset because the cross_chain_balances is null or not an object
          // }

          // If cross_chain_balances is present and is an object, we check each balance object within it
          Object.keys(item.cross_chain_balances).forEach((key) => {
            const balanceObject = item.cross_chain_balances[key];
            console.log(
              "🚀 ~ file: TokensList.tsx:247 ~ Object.keys ~ balanceObject:",
              balanceObject
            );
            if (balanceObject.chainId == chainId) {
              // If the chainId matches, we remember this balance object
              relevantBalanceObject = balanceObject;
            }
          });

          // We will include items if a matching balance object for the current chainId is found
          // or if cross_chain_balances was null
          return relevantBalanceObject !== null;
        });

        console.log("Filtered result based on chainId:", result);
      }

      if (filterText.trim() !== "") {
        result = result.filter(
          (item) =>
            item?.asset?.name
              .toLowerCase()
              .includes(filterText.toLowerCase()) ||
            item?.asset?.symbol.toLowerCase().includes(filterText.toLowerCase())
        );
      }

      // Update the state with the filtered assets
      setFilteredAssets(result);
    };

    // Call the function to initiate filtering
    updateFilteredAssets();
  }, [
    assets,
    chainId,
    filterText,
    isApplyFilter,
    activeNetwork,
    swapDetails,
    selectedFilter,
  ]); // Dependencies for the effect

  console.log(filteredAssets, "filteredAssets");

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
        paddingTop: "20px",
      }}
    >
      {filterText && filteredAssets.length === 0 ? (
        <Box textAlign={"center"} py={5}>
          <Typography variant="h3">No results found</Typography>
        </Box>
      ) : filteredAssets && filteredAssets.length > 0 ? (
        filteredAssets.map((asset) => (
          <ListComponent
            asset={asset}
            nextStep={nextStep}
            transactionForm={transactionForm}
            isShowTokenAmountUnderName={isShowTokenAmountUnderName}
            selectedFilter={selectedFilter}
          />
        ))
      ) : (
        <>
          {transactionForm ? (
            <>
              {" "}
              <Box textAlign={"center"} py={5}>
                <Typography variant="h3">No Asset on Selected Chain</Typography>
              </Box>
            </>
          ) : (
            <>
              {" "}
              <CardLayout
                backgroundColor="white"
                style={{
                  flex: 1,
                  width: "100%",
                  marginTop: "40px",
                  marginBottom: "40px",
                  overflow: "hidden",
                }}
              >
                <Slide in={true} timeout={500} direction={"down"}>
                  <Box
                    className="flex-col-center"
                    style={{
                      padding: "20px 0px",
                      height: "275px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      variant={"h5"}
                      fontWeight={700}
                      color="rgba(26, 28, 32, 1)"
                      fontSize={25}
                      style={{ cursor: "pointer" }}
                    >
                      Start your Stash!{" "}
                    </Typography>
                    <Typography
                      variant={"h5"}
                      fontWeight={400}
                      color="rgba(26, 28, 32, 0.5)"
                      fontSize={12}
                      style={{ cursor: "pointer" }}
                    >
                      Get started!
                    </Typography>
                    <img
                      src={Globe}
                      width={"100%"}
                      height={"100%"}
                      style={{
                        overflow: "hidden",
                      }}
                    />

                    <div
                      style={{
                        width: "70%",
                        maxWidth: "305px",
                        marginTop: "-10%",
                      }}
                    >
                      <Button
                        title="Receive"
                        style={{
                          backgroundColor: "rgba(235, 236, 238, 1)",
                          margin: 0,
                        }}
                        textStyle={{ color: "rgba(26, 28, 32, 1)" }}
                        onClick={() => {
                          navigate("/crypto/receive");
                        }}
                      />
                    </div>
                  </Box>
                </Slide>
              </CardLayout>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default TokensList;
