import { initialPendingTxValues } from "@slices/appSlice";

export function groupTimestampData(
  data: Array<typeof initialPendingTxValues & { timestamp: string }>
) {
  const groupedData: any = {};
  const currentDate = new Date();
  const currentDateStr = currentDate.toDateString();

  data.forEach(
    (item: typeof initialPendingTxValues & { timestamp: string }) => {
      const timestamp = new Date(item.timestamp);
      const dateString = timestamp.toDateString();

      if (!groupedData[dateString]) {
        groupedData[dateString] = [];
      }
      groupedData[dateString].push(item);
    }
  );

  // Sort the keys (date strings) in ascending order
  const sortedKeys = Object.keys(groupedData).sort((a, b) => {
    const timestampA = new Date(a).getTime();
    const timestampB = new Date(b).getTime();
    return timestampB - timestampA;
  });

  const sortedGroupedData: any = {};
  sortedKeys.forEach((key) => {
    if (key === currentDateStr) {
      sortedGroupedData["Today"] = groupedData[key];
    } else {
      sortedGroupedData[key] = groupedData[key];
    }
  });

  return sortedGroupedData;
}
