import {
  setContacts,
  setHoldings,
  setNewNfts,
  setPortfolio,
  setTransactions,
  setBalances,
  setUserSpendingDetails,
  setConnectedDapps2,
  setGas,
  setCryptoHistory,
  setCashHistory,
  setCashGas,
  setCashTransactions,
} from "@slices/appSlice";
import CustomLogo from "../assets/morflax.svg";
import {
  setIsAccountDeployed,
  setIsCashAccountDeployed,
  setSelectedToken,
  setWalletLoading,
  setWalletTxHistoryLoading,
} from "@slices/walletSlice";
import {
  CASH_SUPPORTED_NETWORK,
  SUPPORTED_NETWORKS,
  SupportedChainId,
} from "constants/chains";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import { fetchOwnedNftsFromMobula } from "utils/nfts";
import { io } from "socket.io-client";
import {
  fetchCashAccountHistory,
  fetchCryptoAccHistory,
  fetchWalletPortfolio,
} from "utils/portfolio";
import {
  decryptMessage,
  fetchEncryptedKeysByName,
  getAllTransactions,
} from "utils/utils";

import {
  History,
  Holdings,
  Portfolio,
  newNFTHoldings,
  userContacts,
} from "../interfaces";

import { Interval } from "../enums";
import { getAllUserContacts } from "../utils/contact";
import { BASE_URL } from "constants/index";
import axios from "axios";
import { ethers } from "ethers";
import { EMPTY_CALLDATA } from "../contract-integration";
import { ClientJS } from "clientjs";
import { SocketContext } from "../socketContext";

import { getAggregateBalance } from "utils/balance";
import SendTransactionModalStore from "../walletConnectStore/SendTransactionModalStore";
import { isCashAccountDeployed, isCryptoAccountDeployed } from "utils/deployed";

const useApp = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const {
    accounts,
    connectedDapps,
    holdings,
    transactions: walletTransactions,
    pendingTx,
  } = useAppSelector((state) => state.app);
  const { socket } = useContext(SocketContext);
  const location = useLocation();
  useEffect(() => {
    console.log(
      "🚀 ~ file: useApp.tsx:58 ~ useApp ~ connectedDapps:",
      connectedDapps
    );
  }, [connectedDapps]);

  const { hashedPassword, txStatus } = useAppSelector((app) => app.wallet);

  const {
    activeNetwork,
    activeAccount,
    rootAccountInfo,
    isAccountCreated,
    transactions,
    contacts,
    cashPendingTx,
  } = useAppSelector((state) => state.app);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // fetch chart data
  useEffect(() => {
    console.log("account change, useEffect 1");
    if (
      Object.keys(accounts).length > 0 &&
      activeAccount.address &&
      hashedPassword
    ) {
      (async () => {
        console.log("FETCHING GRAPH DATA");

        if (
          Object.keys(accounts).length > 0 &&
          activeAccount.address &&
          hashedPassword
        ) {
          dispatch(setWalletLoading(true));

          // let holdings: Holdings = {};

          // let walletPortfolio: Portfolio;

          console.log(
            "file: useApp.tsx:56 asdasdsadsad Object.keys  acc:",
            activeAccount.smartAccountAddress
          );

          const [walletHistory1, walletHistory2, walletHistory3] =
            await Promise.all([
              fetchCryptoAccHistory(activeAccount.smartAccountAddress, 1),
              fetchCryptoAccHistory(activeAccount.smartAccountAddress, 2),
              fetchCryptoAccHistory(activeAccount.smartAccountAddress, 3),
            ]);

          await updateWalletHistory(
            walletHistory1,
            walletHistory2,
            walletHistory3
          );

          await dispatch(setWalletLoading(false));

          console.log("ENDED///////////////////////////////");
        }
      })();
    }
  }, [hashedPassword, pendingTx]);

  // check account deployment status
  useEffect(() => {
    (async () => {
      const cryptoAccountDeployed = await isCryptoAccountDeployed(
        activeNetwork,
        activeAccount.smartAccountAddress
      );
      const cashAccountDeployed = await isCashAccountDeployed(
        rootAccountInfo.smartAccountAddress
      );

      dispatch(setIsAccountDeployed(!!cryptoAccountDeployed));
      dispatch(setIsCashAccountDeployed(!!cashAccountDeployed));
    })();
  }, [txStatus, pendingTx, hashedPassword, cashPendingTx]);

  // fetch nfts
  useEffect(() => {
    if (location.pathname == "/nfts") {
      (async () => {
        console.log("NETWORK SWITCH HORAHA", activeNetwork);
        if (
          Object.keys(accounts).length > 0 &&
          activeAccount.address &&
          hashedPassword
        ) {
          await dispatch(setWalletLoading(true));

          let newNFTsHoldings: newNFTHoldings = {};

          const mobulaNfts = await fetchOwnedNftsFromMobula(
            activeAccount.smartAccountAddress
          );
          // accounts[acc].smartAccountAddress

          newNFTsHoldings[activeAccount.smartAccountAddress] = mobulaNfts;

          dispatch(
            setNewNfts({
              ...newNFTsHoldings,
            })
          );

          await dispatch(setSelectedToken([]));
          await dispatch(setWalletLoading(false));

          console.log("ENDED///////////////////////////////");
        }
      })();
    }
  }, [
    activeAccount.address,
    txStatus,
    activeNetwork,
    accounts,
    hashedPassword,
    location.pathname,
  ]);

  // fetch contacts
  useEffect(() => {
    if (
      location.pathname == "/settings" ||
      location.pathname.includes("send")
    ) {
      (async () => {
        console.log("NETWORK SWITCH HORAHA", activeNetwork);
        if (
          Object.keys(accounts).length > 0 &&
          activeAccount.address &&
          hashedPassword
        ) {
          await dispatch(setWalletLoading(true));

          let userContacts: userContacts = [];

          // await Promise.all(
          // Object.keys(accounts).map(async (acc) => {
          const contacts = await getAllUserContacts({
            address: rootAccountInfo.address,
          });
          console.log(
            "file: getAllUserContacts useApp.tsx:323  Object.keys  contacts:",
            contacts
          );
          // accounts[acc].smartAccountAddress

          userContacts = contacts;
          // })
          // );

          dispatch(setContacts([...userContacts]));

          console.log("ENDED///////////////////////////////");
        }
      })();
    }
  }, [
    activeAccount.address,
    txStatus,
    activeNetwork,
    accounts,
    hashedPassword,
    location.pathname,
  ]);

  // fetch tx history
  useEffect(() => {
    (async () => {
      if (
        Object.keys(accounts).length > 0 &&
        activeAccount.address &&
        hashedPassword
      ) {
        console.log("FETCHING TX HISTORY");
        await dispatch(setWalletTxHistoryLoading(true));

        let newTransactions: any = {};

        const cryptoTransactions = await getAllTransactions(
          activeAccount.smartAccountAddress
        );
        // activeAccount.smartAccountAddress

        newTransactions[activeAccount.smartAccountAddress] = cryptoTransactions;

        console.log(
          "file: useApp.tsx:257  Object.keys  newTransactions:",
          newTransactions
        );
        console.log("newTransactions 00 456", newTransactions);
        dispatch(
          setTransactions({
            ...newTransactions,
          })
        );

        dispatch(setWalletTxHistoryLoading(false));

        const cashTransactions = await fetchCashAccountHistory(
          rootAccountInfo.smartAccountAddress
        );

        console.log("XAHHHHHHHHHHH transactions", cashTransactions);

        dispatch(setCashTransactions(cashTransactions));

        console.log("ENDED///////////////////////////////");
      }
    })();
  }, [hashedPassword, activeAccount.smartAccountAddress]);

  useEffect(() => {
    console.log("account change, useEffect 3");
    if (Object.keys(accounts).length > 0 && isAccountCreated) {
      setIsLoggedIn(true);
      if (!hashedPassword) {
        navigate("/login");
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [accounts, isAccountCreated, hashedPassword]);

  useEffect(() => {
    if (hashedPassword) {
      (async () => {
        let isPatternSet = false;
        console.log("🚀 ~ file: useApp.tsx:512 ~ isPatternSet:", isPatternSet);
        const request = await axios.get(
          `${BASE_URL}/transaction/isFirstTx/${activeAccount.smartAccountAddress}`
        );
        console.log("🚀 ~ file: useApp.tsx:516 ~ request:", request);
        const isFirstTx = request.data.isFirstTx;
        console.log("🚀 ~ file: useApp.tsx:517 ~ isFirstTx:", isFirstTx);

        const request1 = await axios.get(
          `${BASE_URL}/security-question/getUser/${rootAccountInfo.address.toLocaleLowerCase()}`
        );
        const request2 = await fetchEncryptedKeysByName(rootAccountInfo.name);
        console.log("🚀 ~ file: useApp.tsx:523 ~ request2:", request2);
        let path = [];

        if (request2?.pattern) {
          isPatternSet = true; //
          let parsedPath = decryptMessage(request2.pattern, hashedPassword);
          console.log("🚀 ~ file: useApp.tsx:530 ~ path:", parsedPath);
          path = JSON.parse(parsedPath);
          console.log("🚀 ~ file: useApp.tsx:534 ~ path:", path);
        }

        const request3 = await axios.get(
          `${BASE_URL}/transaction/isSpentExceed/${rootAccountInfo.address.toLocaleLowerCase()}`
        );
        console.log(
          "🚀 ~ file: useApp.tsx:533 ~ request3:",
          request3.data.isDailyLimitExceed
        );
        const isDailyLimitExceed = request3.data.isDailyLimitExceed;

        console.log(isPatternSet, isFirstTx, "33333333333333333333");
        if (request1.data.spendingLimit) {
          dispatch(
            setUserSpendingDetails({
              isPatternSet,
              isSpendingLimitSaved: true,
              isFirstTx,
              path,
              isDailyLimitExceed,
            })
          );
        } else {
          dispatch(
            setUserSpendingDetails({
              isPatternSet,
              isFirstTx,
              path,
              isDailyLimitExceed,
            })
          );
        }
      })();
    }
  }, [hashedPassword]);

  useEffect(() => {
    if (activeAccount.smartAccountAddress) {
      (async () => {
        const transactionsRequest = await axios.get(
          `${BASE_URL}/transaction/byAction?address=${rootAccountInfo.address}&action=Swapped`
        );
        console.log(
          "🚀 ~ file: useApp.tsx:538 ~ transactionsRequest:",
          transactionsRequest.data
        );
        const apiHashes = transactionsRequest.data.transactions.map(
          (tx) => tx?.hash
        );
        const reduxHashes = walletTransactions[
          activeAccount.smartAccountAddress
        ].map((tx) => tx?.hash);

        console.log(
          "allHashes from api records",
          transactionsRequest.data.transactions.filter(
            (tx) =>
              tx?.hash ===
              "0x8d611f7c51634b70a9b735edda7446aa500bf8f9b9a499357f2bca9b37a06d6a"
          )
        );

        console.log(
          "allHashes from redux records",
          walletTransactions[activeAccount.smartAccountAddress].filter(
            (tx) =>
              tx?.hash ===
              "0x8d611f7c51634b70a9b735edda7446aa500bf8f9b9a499357f2bca9b37a06d6a"
          )
        );

        console.log(
          "common hashes",
          apiHashes.filter((value) => reduxHashes.includes(value))
        );

        const apiRecords = transactionsRequest.data.transactions;
        const reduxRecords =
          walletTransactions[activeAccount.smartAccountAddress];

        const newTransactions = walletTransactions[
          activeAccount.smartAccountAddress
        ]
          ? [
              ...reduxRecords.filter((tx) => !apiHashes.includes(tx?.hash)),
              ...apiRecords,
            ]
          : [...apiRecords];
        console.log(
          "🚀 ~ file: useApp.tsx:548 ~ newTransactions:",
          newTransactions
        );
        console.log("newTransactions 00 123", newTransactions);
        dispatch(
          setTransactions({
            [activeAccount.smartAccountAddress]: newTransactions,
          })
        );
      })();
    }
  }, [pendingTx, activeAccount]);

  useEffect(() => {
    dispatch(
      setCryptoHistory({
        interval: Interval.WEEK,
      })
    );
    dispatch(
      setCashHistory({
        interval: Interval.WEEK,
      })
    );
  }, []);

  const updateWalletHistory = async (
    walletHistory1: any,
    walletHistory2: any,
    walletHistory3: any
  ) => {
    // Extract data from the API response
    const balanceHistory1 = walletHistory1;
    const chartData1 = balanceHistory1.map((item: any[]) => item[1]);
    const chartTimestamps1 = balanceHistory1.map((item: any[]) => item[0]);
    const balanceHistory2 = walletHistory2;
    const chartData2 = balanceHistory2.map((item: any[]) => item[1]);
    const chartTimestamps2 = balanceHistory2.map((item: any[]) => item[0]);
    const balanceHistory3 = walletHistory3;
    const chartData3 = balanceHistory3.map((item: any[]) => item[1]);
    const chartTimestamps3 = balanceHistory3.map((item: any[]) => item[0]);

    dispatch(
      setCryptoHistory({
        ...walletHistory1.data,
        interval: Interval.WEEK,
        [Interval.HOUR]: {
          chart_data: [],
          chart_timestamps: [],
        },
        [Interval.DAY]: {
          chart_data: chartData1,
          chart_timestamps: chartTimestamps1,
        },
        [Interval.WEEK]: {
          chart_data: chartData2,
          chart_timestamps: chartTimestamps2,
        },
        [Interval.MONTH]: {
          chart_data: chartData3,
          chart_timestamps: chartTimestamps3,
        },
      })
    );
  };
  useEffect(() => {
    // Create a new ClientJS object
    const client = new ClientJS();

    // Get the client's fingerprint id
    const fingerprint = client.getFingerprint();
    console.log(
      "🚀 ~ file: useApp.tsx:678 ~ useEffect ~ fingerprint:",
      fingerprint
    );

    // Print the 32bit hash id to the console
    console.log(fingerprint);
    // fingerprint is your unique browser id.
    // This is well tested

    // const socket = io("http://localhost:4000");
    // // socket.emit('messageFromReactApp1', { message: 'Hello from React App 1!' })

    // socket.on("eth_requestAccounts", (data) => {
    //   // Handle the message from the server here.
    //   console.log(data, "The dat");
    //   socket.emit("connect_account", { address: activeAccount.address });
    // });

    // socket.on("onTransactionSend", (data) => {
    //   // Handle the message from the server here.
    //   console.log(data.payload, "onTransactionSend");
    //   ModalStore.open("SdkSendTransactionModal", {
    //     payload: data.payload,
    //   });
    // });
    return () => {
      // socket.disconnect();
    };
  }, []);

  useEffect(() => {
    // Create an object to keep track of cumulative balances for each token
    (async () => {
      const res = await getAggregateBalance(
        rootAccountInfo.address.toLowerCase(),
        "mainnet"
      );

      if (res.length > 0) {
        let totalSum = 0;
        let gas = {};
        res.map((r: { _id: string; totalAmount: number }) => {
          totalSum += r.totalAmount;
          gas[r._id] = { balance: r.totalAmount };
        });

        console.log("TOTAL SUM", totalSum);
        dispatch(
          setGas({
            totalBalance: totalSum < 0 ? 0 : totalSum,
            ...gas,
          })
        );
      }
    })();
  }, [pendingTx, activeAccount, txStatus, cashPendingTx]);

  useEffect(() => {
    console.log("socket in wallet", socket);
    // const socket = io("http://localhost:4000");
    // // socket.emit('messageFromReactApp1', { message: 'Hello from React App 1!' })

    socket?.on("eth-server-request-account", (data) => {
      // Handle the message from the server here.
      console.log(data, "The dat");

      console.log("we are sending this", {
        address: activeAccount.smartAccountAddress,
        dappId: data.payload.dappId,
        holding: holdings,
      });

      socket.emit("eth-wallet-response-account", {
        address: activeAccount.smartAccountAddress,
        dappId: data.payload.dappId,
        holding: holdings,
      });
    });

    socket?.on("send-dapps-ids", (data) => {
      console.log("send-dapps-ids response data", data);
      const { payload } = data;
      let updatedDapps: any[] = [];
      if (payload?.dappIds.length > 0) {
        // if (connectedDapp2.length > 0) {
        // updatedDapps = connectedDapp2.filter(dapp => payload?.dappIds.includes(dapp?.description))
        payload?.dappIds.forEach((dappId: any) => {
          updatedDapps.push({
            name: `DappXYZ`,
            description: dappId,
            url: "https://www.google.com",
            icons: [CustomLogo],
            _id: dappId,
          });
        });
        console.log("send-dapps-ids IF chala ", updatedDapps);
        // } else {
        //   payload?.dappIds.forEach(dappId => {
        //     updatedDapps.push({
        //       name: `DappXYZ-${dappId}`,
        //       description: dappId,
        //       url: "https://www.google.com",
        //       icons: [CustomLogo],
        //       _id: dappId
        //     })
        //   })
        //   console.log("send-dapps-ids ELSE chala ", updatedDapps)
        //   // const dappDetails = {
        //   //   name: "DappXYZ",
        //   //   description: data.payload.dappId,
        //   //   url: "https://www.google.com",
        //   //   icons: [CustomLogo],
        //   // };
        // }
        dispatch(setConnectedDapps2(updatedDapps));
      } else {
        dispatch(setConnectedDapps2([]));
      }
    });

    socket?.on("server-request-network-change", (data) => {
      console.log("🚀 ~ server-request-network-changeta:", data);
      socket?.emit("wallet-response-network-change", {
        dappId: data.payload.dappId,
      });
    });

    socket?.on("server-request-sign-transaction", (data) => {
      // Handle the message from the server here.
      console.log(data.payload, "onTransactionSend");
      SendTransactionModalStore.open("SdkSendTransactionModal", {
        payload: data.payload,
      });
    });
    return () => {
      // if (socket) socket.disconnect();
    };
  }, [socket]);

  return { isLoggedIn };
};

export default useApp;
