import React, { FC, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import SearchInput from "components/SearchInput";

import TokenBox from "components/TokenBox";
import { showAlert } from "utils/utils";

interface BuyFiatProps {
  setPaymentMethod: (method: {}) => void;
  setActiveCurrency: (currency: {}) => void;
  setActiveCurrencyIcon: (icon: string) => void;
  setSelectedPaymentMethod: (method: {}) => void;
  nextStep: () => void;
  activeCurrency: {};
  paymentMethod: boolean;
  setActiveCryptoOnRamperId: () => void;
  tokenAddress: string;
  tokenSymbol: string;
  activeCryptoOnRamperId: {};
  type: string;
  fiatList: [];
}

const BuyFiat: FC<BuyFiatProps> = ({
  setActiveCurrency,
  setActiveCurrencyIcon,
  setPaymentMethod,
  setSelectedPaymentMethod,
  activeCurrency,
  paymentMethod,
  nextStep,
  setActiveCryptoOnRamperId,
  tokenAddress,
  tokenSymbol,
  activeCryptoOnRamperId,
  fiatList,
  type,
}) => {
  const [tokensLoading, setTokensLoading] = useState(false);
  const [searchedFiat, setSearchedFiat] = useState("");

  const [paymentMethods, setPaymentMethods] = useState([{}]);

  useEffect(() => {
    (async () => {
      if (activeCurrency && activeCryptoOnRamperId.id && paymentMethod) {
        try {
          let url = "";
          if (type === "sell") {
            url = `https://api.onramper.com/supported/payment-types/${activeCryptoOnRamperId.id}?type=${type}&destination=${activeCurrency}`;
          } else {
            url = `https://api.onramper.com/supported/payment-types/${activeCurrency}?type=${type}&destination=${activeCryptoOnRamperId.id}`;
          }
          setTokensLoading(true);
          const { data } = await axios.get(
            url,

            {
              headers: {
                Authorization: process.env.ONRAMP_API_KEY,
              },
            }
          );
          const { message } = data;
          console.log("message", message);

          setPaymentMethods(message);
          setTokensLoading(false);
        } catch (error) {
          console.log("E>>>>>>>>>>>", error);
          if (error?.response?.data?.message) {
            showAlert(error?.response?.data?.message);
            setTokensLoading(false);
            setPaymentMethod(false);
          }
          setTokensLoading(false);
        }
      }
    })();
  }, [activeCurrency, activeCryptoOnRamperId, paymentMethod]);

  return tokensLoading ? (
    <div style={{ width: "100%", textAlign: "center", margin: "25% 0px" }}>
      <CircularProgress color="inherit" />
    </div>
  ) : (
    <>
      <SearchInput
        handleOnChange={(e) => {
          setSearchedFiat(e.target.value);
        }}
      />
      {paymentMethod ? (
        <div style={{ width: "100%" }}>
          {paymentMethods
            .filter((list) =>
              list?.name?.toLowerCase()?.includes(searchedFiat?.toLowerCase())
            )
            .map((method) => (
              <TokenBox
                tokenIcon={method.icon}
                tokenSymbol={method.name}
                backgroundColor="white"
                onClick={() => {
                  setSelectedPaymentMethod(method);
                  setPaymentMethod(false);
                  nextStep(5);
                }}
              />
            ))}
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          {fiatList
            .filter((list) =>
              list?.id?.toLowerCase()?.includes(searchedFiat?.toLowerCase())
            )
            .map((currency) => (
              <TokenBox
                tokenIcon={currency.icon}
                tokenSymbol={currency.code}
                backgroundColor="white"
                onClick={() => {
                  setActiveCurrency(currency.id);
                  setActiveCurrencyIcon(currency.icon);
                  setPaymentMethod(true);
                  setSearchedFiat("");
                }}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default BuyFiat;
