import { CircularProgress, Typography } from "@mui/material";
import pbkdf2 from "pbkdf2";

import OTPInput from "components/OtpInput";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  decryptMessage,
  fetchEncryptedKeys,
  saveRecoveryCode,
  showAlert,
} from "utils/utils";

import WelcomeBox from "components/WelcomeBox";
import Google from "assets/google.svg";
import { init, loginWithGoogle } from "utils/google";
import { setTorusKey } from "@slices/walletSlice";
import Button from "components/NewButton";
import KeyLogo from "assets/key.svg";
import { setSettingsOpenOption } from "@slices/appSlice";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import RPC from "constants/webRPC";

const SettingsViewRecoveryKey = () => {
  const [web3auth, setWeb3auth] = useState<Web3AuthNoModal | null>(null);
  const [pin, setPin] = useState("");
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [recoveryCode, setRecoveryCode] = useState("");
  const [path, setPath] = useState("/settings");
  const [googleError, setGoogleError] = useState("");
  const [pinError, setPinError] = useState("");

  const {
    user,
    fetchedKeys: { key3 },
  } = useAppSelector((state) => state.app);
  const { hashedPassword, torusKey } = useAppSelector((state) => state.wallet);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const web3auth = await init(path, "popup");
      if (web3auth) {
        setWeb3auth(web3auth);
      }
    })();
  }, []);

  const signinWithGoogle = async () => {
    try {
      if (web3auth) {
        setLoading(true);
        setGoogleError("");

        await loginWithGoogle(web3auth);

        const res = await web3auth.getUserInfo();
        const rpc = new RPC(web3auth.provider!);
        console.log("res0000000000000000", res);
        const torusKey = await rpc.getAccounts();

        const { name, email, profileImage, idToken } = res;

        if (email) {
          const data = await fetchEncryptedKeys(idToken);

          if (!data) {
            setGoogleError("Email does not exist");

            setLoading(false);
            await web3auth.logout();
          } else if (data.email != user.email) {
            setGoogleError("Please use correct email address");
            setLoading(false);
          } else {
            dispatch(setTorusKey(torusKey[0].toString("hex")));
            setLoading(false);
            setGoogleError("");

            setStep(1);
          }
        }
      }
    } catch (error) {
      setLoading(false);
      setGoogleError("View Recovery Key Failed");
    }
  };

  const handleContinue = async () => {
    try {
      setLoading(true);

      const hashedTorus = pbkdf2
        .pbkdf2Sync(torusKey, "salt", 1, 32, "sha512")
        .toString("hex");

      const hashedPin = pbkdf2
        .pbkdf2Sync(pin, "salt", 1, 32, "sha512")
        .toString("hex");

      const decryptedKey3 = decryptMessage(key3, hashedPin);

      if (decryptedKey3) {
        const recoveryCode = hashedTorus + hashedPassword + hashedPin;
        setRecoveryCode(recoveryCode);
        setStep(2);
        setLoading(false);
        setPinError("");
      } else {
        setLoading(false);
        setPinError("Pin is incorrect");
      }
    } catch (error) {
      setLoading(false);
      setPinError("Pin is incorrect");

      console.log("err", error);
    }
  };

  const generateRecoveryCode = async () => {
    try {
      setLoading(true);

      await saveRecoveryCode(recoveryCode, user.email);
      setLoading(false);
      setStep(0);
      dispatch(setSettingsOpenOption(null));
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="center-container" style={{ minHeight: "60vh" }}>
      <div className="welcome-box-container">
        {loading || !web3auth ? (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              margin: "20px 0px",
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : step === 0 ? (
          <div className="box-main">
            <div className="box top-box">
              <WelcomeBox
                title="Continue with Google"
                description=""
                onClick={signinWithGoogle}
                icon={<img src={Google} style={{ marginRight: 10 }} />}
                style={{ border: "1px solid #ddd" }}
              />
              {googleError && (
                <Typography
                  style={{
                    marginTop: 15,
                    color: "red",
                    fontSize: 14,
                    textAlign: "left",
                  }}
                >
                  {googleError}
                </Typography>
              )}
            </div>
          </div>
        ) : step == 1 ? (
          <div className="create-account-box">
            <Typography
              variant="h2"
              sx={{
                margin: "30px 0px 30px 0px",

                fontWeight: "700",
              }}
            >
              Enter your pin
            </Typography>

            <div>
              <OTPInput otp={pin} onChange={setPin} />
              {pinError && (
                <Typography
                  style={{
                    marginTop: 15,
                    color: "red",
                    fontSize: 14,
                    textAlign: "left",
                  }}
                >
                  {pinError}
                </Typography>
              )}
              <Button
                onClick={handleContinue}
                title="Continue"
                disabled={!pin || pin.length < 6}
                loading={loading}
                style={{
                  padding: "15px 0px",
                }}
              />
            </div>
          </div>
        ) : (
          <div className="create-account-box download-recovery-wrapper">
            <Typography
              variant="h2"
              sx={{
                // margin: "0px 0px 30px 0px",
                margin: "0",
                fontWeight: "700",
              }}
            >
              Download Recovery Key
            </Typography>

            <Typography
              variant="body2"
              sx={{
                margin: "10px 0px 0px 0px",
                color: "rgba(26, 28, 32, 0.5)",
                fontFamily: "Helvetica Neue",
                fontWeight: "500",
                textAlign: "center",
                fontSize: "18px",
                // width: '25%',
              }}
            >
              This key will be required to reset your <br />
              password if you lose access to all your trusted devices
            </Typography>

            <img
              src={KeyLogo}
              height={300}
              style={{
                height: "250px",
                margin: "30px 0px 30px 0px",
              }}
            />
            <Typography
              variant="body2"
              sx={{
                margin: "10px 0px 10px 0px",
                color: "rgba(26, 28, 32, 0.5)",
                paddingInline: "20px",
                fontFamily: "Helvetica Neue",
                fontWeight: "500",
                textAlign: "center",
                fontSize: "18px",
              }}
            >
              Download your recovery key and keep it in a safe place so you
              always have access to your Stashed Account!
            </Typography>

            <Button
              title="Continue"
              onClick={generateRecoveryCode}
              loading={loading}
              disabled={false}
              style={{
                padding: "15px 20px",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsViewRecoveryKey;
