import React, { useState } from "react";

import StashedCreatedLogo from "assets/stash-created.svg";

import SuccessLogo from "assets/success.svg";

import "../../pages/auth/TransactionFlow/TransactionSuccess/styles.css";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Button from "components/NewButton";
import AuthHeader from "components/AuthHeader";
import { useAppDispatch } from "store/store";
import { setIsAccountCreated } from "@slices/appSlice";

const SuccessfullyEnabled = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleNavigateForward = () => {
    navigate("/");
  };

  return (
    <div>
      <div
        className="transaction-box"
        style={{
          marginTop: "10px",
          alignItems: "center",
        }}
      >
        <img src={SuccessLogo} height={100} />
        <Typography
          variant="h2"
          sx={{
            margin: "20px 0px 20px 0px",
            fontWeight: "700",
            textAlign: "center",
            fontSize: "28px",
          }}
        >
          Congratulations!
        </Typography>

        <Typography
          variant="body2"
          sx={{
            margin: "0px 0px 20px 0px",
            color: "rgba(26, 28, 32, 0.5)",
            fontWeight: "500",
            textAlign: "center",
            width: "100%",
            fontSize: "17px",
            fontFamily: "Helvetica Neue",
          }}
        >
          You have successfully enabled additional security features for your
          Stashed Account!
        </Typography>

        <Typography
          variant="body2"
          sx={{
            margin: "0px 0px 20px 0px",
            color: "rgba(26, 28, 32, 0.5)",
            fontWeight: "500",
            textAlign: "center",

            fontSize: "17px",
            fontFamily: "Helvetica Neue",
          }}
        >
          Your funds have never been safer!
        </Typography>
        <Button
          title="Got It"
          onClick={handleNavigateForward}
          loading={loading}
          disabled={false}
          style={{
            margin: "20px 0 0 0",
            width: "260px",
            padding: "15px 0px",
          }}
        />
      </div>
    </div>
  );
};

export default SuccessfullyEnabled;
