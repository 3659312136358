import { Box, Stack, Typography, styled, Input } from "@mui/material";

export const ConnectedDappBoxWrapper = styled(Box)(() => ({
  width: "300px",
  height: "300px",
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#fff",
  boxShadow:
    "0px -1.01625px 24.39005px -4.06501px rgba(24, 39, 75, 0.08), 0px 6.09751px 12.19503px -6.09751px rgba(24, 39, 75, 0.12)",
}));

export const MediumTitle = styled(Typography)<{
  size?: number;
  weight?: number;
}>(({ size, weight }) => ({
  fontFamily: "Space Grotesk",
  fontSize: `${size || 17}px`,
  fontStyle: "normal",
  fontWeight: weight || 600,
}));

export const Caption = styled(Typography)<{
  size?: number;
  weight?: number;
  align?: string;
  fontFamily?: string;
}>(({ weight, size, align, fontFamily }) => ({
  fontSize: `${size || 14}px`,
  fontWeight: weight || "normal",
  color: "rgba(26, 28, 32, 0.5)",
  textAlign: align || "center",
  fontFamily: fontFamily || "Helvetica Neue",
}));

export const SearchBox = styled(Input)(() => ({
  padding: "5px 10px",
  gap: "8.384px",
  flexShrink: " 0",
  width: "400px",
  height: "32px",
  borderRadius: "60px",
  opacity: "1",
  background: "#EFF3F4",
  fontSize: "14px",
  "& ::placeholder": {
    //Styles go here...
    color: "#536371",
    fontSize: "14px",
  },
}));

export const RequestModalWrapper = styled(Stack)(() => ({
  width: "90%",
  margin: "auto",
}));

export const ItemWrapper = styled(Stack)(() => ({
  background: "#F7F7F7",
  borderRadius: "8px",
  height: "50px",

  padding: "5px",
}));

export const BoxStyled = styled(Stack)(() => ({
  // width: "60%",
}));

export const SessionSendTransactionModalWrapper = styled(Stack)(() => ({
  background: "white",
  overflowY: "scroll",
  width: "400px",
  padding: "20px  10px",
  borderRadius: "12px",
}));
