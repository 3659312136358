import React from "react";

import { Box, Typography, Modal as CustomModal } from "@mui/material";

import ModalHeader from "components/ModalHeader";

const TransactionsFilterModal = ({ modalProps }) => {
  const { open, onClose, terms, checked, filteredTerms, handleFilterChange } =
    modalProps || {};

  return (
    <CustomModal
      onClose={onClose}
      open={open}
      style={{
        border: "1px solid rgba(139, 139, 139, 0.4)",
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          background: "white",
          width: "360px",
          height: "auto",
          borderRadius: "10px",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          padding: "20px 20px 20px 20px",
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <ModalHeader title="Transaction Filters" />
          </Box>
          {/* <Box onClick={handleSelectAll}>
            <h5
              style={{
                fontWeight: "700",
                color: "#1A1C20",
                fontSize: "12px",
                fontFamily: "Helvetica Neue",
                cursor: "pointer",
              }}
            >
              {selectText}
            </h5>
          </Box> */}
        </Box>

        <Box
          style={{
            // border: "1px solid rgba(139, 139, 139, 0.4)",
            boxShadow:
              "0px 8px 24px 10px rgb(24 39 75 / 5%),0px 6px 12px 4px rgb(24 39 75 / 1%)",
            borderRadius: "10px",
            padding: "10px",
            margin: "20px 0 20px 0",
          }}
        >
          {terms?.map((data) => (
            <Box
              key={data}
              className="sdsdsdsd"
              style={{
                display: "flex",
                flexFlow: "row",
                placeContent: "center flex-start",
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignContent: "center",
                justifyContent: "flex-start",
                padding: "10px",
              }}
            >
              <input
                type="checkbox"
                id="filter__chains__checkbox"
                name="checkbox"
                checked={filteredTerms.includes(data.toLowerCase())}
                onChange={() => handleFilterChange(data.toLowerCase())}
                value="1"
                style={{
                  width: "1rem",
                  height: "1rem",
                  margin: "0 10px 0 0",
                }}
              />

              <Typography
                variant={"h5"}
                fontWeight={400}
                color="#1A1C20"
                fontSize={18}
                fontFamily={"Helvetica Neue"}
                margin={"0 0 0 10px"}
              >
                {data}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </CustomModal>
  );
};

export default TransactionsFilterModal;
