import { SessionTypes, Verify } from "@walletconnect/types";
import { proxy } from "valtio";

const TEST_NETS_ENABLED_KEY = "TEST_NETS";
const SMART_ACCOUNTS_ENABLED_KEY = "SMART_ACCOUNTS";
const ZERO_DEV_SMART_ACCOUNTS_ENABLED_KEY = "ZERO_DEV_SMART_ACCOUNTS";
/**
 * Types
 */
interface State {
  testNets: boolean;
  account: number;
  eip155Address: string;
  kernelSmartAccountAddress: string;
  relayerRegionURL: string;
  activeChainId: string;
  currentRequestVerifyContext?: Verify.Context;
  sessions: SessionTypes.Struct[];
  smartAccountSponsorshipEnabled: boolean;
  smartAccountEnabled: boolean;
  kernelSmartAccountEnabled: boolean;
}

/**
 * State
 */
const state = proxy<State>({
  testNets:
    typeof localStorage !== "undefined"
      ? Boolean(localStorage.getItem("TEST_NETS"))
      : true,
  account: 0,
  activeChainId: "1",
  eip155Address: "",
  kernelSmartAccountAddress: "",
  relayerRegionURL: "",

  sessions: [],
  smartAccountSponsorshipEnabled: true,
  smartAccountEnabled: true,
  // typeof localStorage !== 'undefined'
  //   ? Boolean(localStorage.getItem(SMART_ACCOUNTS_ENABLED_KEY))
  //   : false,
  kernelSmartAccountEnabled: true,
  // typeof localStorage !== 'undefined'
  //   ? Boolean(localStorage.getItem(ZERO_DEV_SMART_ACCOUNTS_ENABLED_KEY))
  //   : false
});

/**
 * Store / Actions
 */
const SettingsStore = {
  state,

  setAccount(value: number) {
    state.account = value;
  },

  setEIP155Address(eip155Address: string) {
    state.eip155Address = eip155Address;
  },

  setRelayerRegionURL(relayerRegionURL: string) {
    state.relayerRegionURL = relayerRegionURL;
  },

  setKernelSmartAccountAddress(smartAccountAddress: string) {
    state.kernelSmartAccountAddress = smartAccountAddress;
  },

  setActiveChainId(value: string) {
    state.activeChainId = value;
  },

  setCurrentRequestVerifyContext(context: Verify.Context) {
    state.currentRequestVerifyContext = context;
  },

  setSessions(sessions: SessionTypes.Struct[]) {
    state.sessions = sessions;
  },

  toggleTestNets() {
    state.testNets = !state.testNets;
    if (state.testNets) {
      localStorage.setItem("TEST_NETS", "YES");
    } else {
      localStorage.removeItem("TEST_NETS");
    }
  },
};

export default SettingsStore;
