// import { Container, Modal, Text } from '@nextui-org/react'
import { Fragment, ReactNode } from "react";
import { MediumTitle, RequestModalWrapper } from "./Styled";

/**
 * Types
 */
interface IProps {
  title: string;
  children: ReactNode | ReactNode[];
}

/**
 * Component
 */
export default function RequestModalContainer({ children, title }: IProps) {
  return (
    <RequestModalWrapper
      display={"flex"}
      justifyContent={"center"}
      sx={{ margin: "0px" }}
      gap={3}
    >
      <MediumTitle
        variant="h2"
        fontsize={25}
        weight={700}
        marginBottom={"20px"}
        textAlign={"center"}
      >
        {title}
      </MediumTitle>

      <div>
        <div style={{ padding: 0 }}>{children}</div>
      </div>
    </RequestModalWrapper>
  );
}
