import { rejectEIP155Request } from "../utils/EIP155RequestHandlerUtil";
import { useEffect, useState } from "react";
import { styledToast } from "../utils/HelperUtil";
import { web3wallet } from "../utils/WalletConnectUtil";
import RequestModalContainer from "../components/RequestModalContainer";
import { Box, Collapse, Grid, Paper, Stack, Typography } from "@mui/material";
import AlertIcon from "../assets/alert-icon.svg";
import { SessionSendTransactionModalWrapper } from "components/Styled";
import { formatJsonRpcResult } from "@json-rpc-tools/utils";
import Web3 from "web3";
import NoImg from "../assets/noImg.svg";
import { useAppDispatch, useAppSelector } from "store/store";
import { SUPPORTED_NETWORKS } from "constants/chains";
import {
  decryptMessage,
  formatAmount,
  fetchPrice,
  showAlert,
  decodeCalldataWithBlockaidSimuation,
  fetchUsdPriceFromMobula,
  floorFigure,
  getDetailsForApprovalFromBlockaidSimulationResponse,
} from "utils/utils";
import {
  BigNumber as ethBigNumber,
  ethers,
  Contract,
  Wallet,
  BytesLike,
} from "ethers";

import { fetchTransactionCostInNativeToken, gasPriceData } from "utils/gas";
import { getEthDollarValue } from "utils/portfolio";

import { ExecuteCall } from "../contract-integration/constants";
import { Token__factory, sendUserOp } from "../contract-integration";
import { txSubmissionOrderPrepaid } from "../contract-integration/prepaidGas";

import ConfirmPatternModal from "components/ConfirmPatternModal";
import { useNavigate } from "react-router-dom";
import FeeUrgencyComponent from "components/SendTxComponent/FeeUrgencyComponent";
import SendTxComponent from "components/SendTxComponent";
import FeeUIComponent from "components/SendTxComponent/FeeUIComponent";
import useDynamicTitleAndFavicon from "hooks/useDynamicTitleAndFavIcon";
import { getChain } from "utils/chains";
import SendTransactionModalStore from "../walletConnectStore/SendTransactionModalStore";
import {
  handleBlockaidResponse,
  handleBlockaidSimulationResponse,
} from "utils/blockaid";
import {
  setPendingTx,
  setPendingTxDetails,
  setUserSpendingDetails,
} from "@slices/appSlice";
import {
  getMinimumTopupLimit,
  getProtocolFee,
  getUsdcBalance,
} from "utils/balance";
import {
  checkGasAllowance,
  getAlphaRouterResponse,
  getSwapRouter02ApprovalTransaction,
} from "utils/swap";
import { APPROVE_AMOUNT } from "constants/";
import { getApproveTokenCallData } from "stash-fe-script";
import BigNumber from "bignumber.js";
import { setTxStatus } from "@slices/walletSlice";
import { TradeType } from "@uniswap/sdk-core";
import { SwapType } from "@uniswap/smart-order-router";
import { contractAddresses } from "constants/";
import MidArrow from "assets/midArrow.svg";

// const { abi } = require("abis/erc20abi.json");
import abi from "../abis/erc20abi.json";
import ItemBox from "components/ItemBox";
import ExtraTopupAmountPopup from "components/ExtraTopupAmountPopup";
import { isCryptoAccountDeployed } from "utils/deployed";
import { createSignatureAndCalculateFees } from "utils/signature";
import { USDT_URL } from "constants/";
import useSaveTxnHistory from "hooks/useSaveTxnHistory";

enum TxType {
  PAYMASTER_APPROVAL = "Paymaster Approval",
  APPROVAL = "Approve",
  CONFIRM = "Confirm",
}

export default function SessionSendTransactionModal({
  requestEvent,
  requestSession,
  rest,
}) {
  console.log("AB RENDER HUA ---------------");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { startBlinkingTitle, stopBlinkingTitle } = useDynamicTitleAndFavicon();
  const { dispatchAndSaveHistory } = useSaveTxnHistory();

  const {
    loading,
    setLoading,
    allowance,
    setAllowance,
    signData,
    setSignData,
    txType,
    setTxType,
    error,
    setError,
    finalOpState,
    setFinalOpState,
    risk,
    setRisk,
    riskData,
    setRiskData,
    decodedCalldata,
    setDecodedCalldata,
    gasFeeInUSD,
    setGasFeeInUSD,
  } = rest;

  const [selected, setSelected] = useState("Normal");
  // const [gasFeeInUSD, setGasFeeInUSD] = useState("0");
  const [openModal, setOpenModal] = useState(false);
  const [isPatternCorrect, setIsPatternCorrect] = useState(false);
  const [appName, setAppName] = useState("");
  const [minimumTopupAmount, setMinimumTopupAmount] = useState("0");
  const [extraTopupAmount, setExtraTopupAmount] = useState("0");
  const [openExtraTopupModal, setOpenExtraTopupModal] = useState(false);
  const [usdcBalance, setUsdcBalance] = useState(0);

  console.log("gasFeeInUSD", { gasFeeInUSD });

  const {
    activeAccount,
    rootAccountInfo,
    activeNetwork,
    userSpendingDetails,
    gas,
  } = useAppSelector((state) => state.app);
  const { hashedPassword, txStatus } = useAppSelector((state) => state.wallet);

  const {
    rpc,
    usdcAddress,
    usdcDecimals,
    alchemy_url,
    stackupUrl,
    block_explorer_url,
    wethAddress,
    bundlerRPC,
  } = SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const chain = getChain(activeNetwork);

  console.log(
    "🚀 ~ file: SessionSendTransactionModal.tsx:116 ~ SessionSendTransactionModal ~ requestEvent:",
    requestEvent
  );

  // Ensure request and wallet are defined
  if (!requestEvent || !requestSession) {
    return <p>Missing request data</p>;
  }

  // Get required proposal data

  const { topic, params } = requestEvent;
  const { request } = params;

  const handleApprove = async () => {
    setOpenExtraTopupModal(false);
    if (
      userSpendingDetails.isDailyLimitExceed &&
      userSpendingDetails.isPatternSet
    ) {
      setOpenModal(true);
    } else {
      onApprove();
    }
  };
  // Handle approve action
  async function onApprove() {
    if (requestEvent) {
      console.log(
        "🚀 ~ file: SessionSendTransactionModal.tsx:148 ~ onApprove ~ requestEvent:",
        requestEvent
      );

      try {
        console.log("ONAPPROVE started with this", finalOpState);
        if (finalOpState) {
          stopBlinkingTitle();
          setLoading(true);
          const response = await sendUserOp(
            finalOpState,
            stackupUrl,
            alchemy_url
          );
          const userOPResponse: any = await response.wait();
          console.log("complete resp :", response);
          console.log("userOp Hash :", response.userOpHash);
          console.log("Tx Hash :", userOPResponse?.transactionHash);
          console.log("success status :", userOPResponse?.args.success);
          console.log(
            "actualGasCost  :",
            Number(userOPResponse?.args.actualGasCost)
          );
          console.log(
            "actualGasCost  :",
            Number(userOPResponse?.args.actualGasCost)
          );
          console.log(
            "actualGasUsed  :",
            Number(userOPResponse?.args.actualGasUsed)
          );

          if (txType === TxType.PAYMASTER_APPROVAL) {
            console.log("----POST TXN case for ALLOWANCE-----");

            // dispatch(
            //   setPendingTxDetails({
            //     // value: Number(decodedCalldata?.valueIn$).toFixed(2),
            //     // valueIn$: floorFigure(Number(decodedCalldata?.valueIn$)),
            //     // transferAmount: Number(decodedCalldata?.valueIn$).toFixed(2),
            //     // toAddressEns: decodedCalldata?.spender,
            //     // toAddress: decodedCalldata?.spender,
            //     // assetName: decodedCalldata?.tokenName,
            //     // iconURL: decodedCalldata?.icon,
            //     value: "0",
            //     valueIn$: "0",
            //     transferAmount: "0",
            //     transactionMethod: "SWAP",
            //     scanLink: block_explorer_url,
            //     eoaEns: rootAccountInfo.name,
            //     addressEns: activeAccount.accountName,
            //     toAddressEns: usdcAddress,
            //     toAddress: usdcAddress,
            //     assetName: "USDC",
            //     networkFeesIn$: gasFeeInUSD,
            //     iconURL: USDT_URL,
            //     txByDesposited: false,
            //     action: "Approved",
            //   })
            // );
            // dispatch(setPendingTx(userOPResponse?.transactionHash));

            const txn = {
              hash: userOPResponse?.transactionHash,
              value: "0",
              valueIn$: "0",
              transferAmount: "0",
              transactionMethod: "SWAP",
              scanLink: block_explorer_url,
              eoaEns: rootAccountInfo.name,
              addressEns: activeAccount.accountName,
              toAddressEns: usdcAddress,
              toAddress: usdcAddress,
              assetName: "USDC",
              networkFeesIn$: gasFeeInUSD,
              iconURL: USDT_URL,
              txByDesposited: false,
              action: "Approved",
            };

            dispatchAndSaveHistory(txn);
            // showAlert(
            //   "Soon you can see your transaction in the transactions tab",
            //   "Paymaster Approval Transaction Submitted",
            //   `<a href="https://polygonscan.com/tx/${userOPResponse?.transactionHash}" target="_blank">View on Polygonscan</a>`
            // );

            setFinalOpState(null);
            setLoading(false);
            setDecodedCalldata(null);
            setTxType("");

            setAllowance(0);
            stopBlinkingTitle();
            // if (web3wallet?.respondSessionRequest) {
            // await web3wallet.respondSessionRequest({
            //   topic,
            //   response: formatJsonRpcResult(
            //     requestEvent.id,
            //     userOPResponse?.transactionHash
            //   ),
            // });
            // }
            // onReject();
            return;
          }

          if (txType === TxType.APPROVAL) {
            console.log("----POST TXN case for APPROVAL-----");

            // dispatch(
            //   setPendingTxDetails({
            //     value: Number(decodedCalldata?.value).toFixed(2),
            //     valueIn$: floorFigure(
            //       Number(decodedCalldata?.valueIn$)
            //     ),
            //     transferAmount: Number(decodedCalldata?.value).toFixed(2),
            //     transactionMethod: "SWAP",
            //     scanLink: block_explorer_url,
            //     eoaEns: rootAccountInfo.name,
            //     addressEns: activeAccount.accountName,
            //     toAddressEns: decodedCalldata?.spender,
            //     toAddress: decodedCalldata?.spender,
            //     assetName: decodedCalldata?.tokenName,
            //     networkFeesIn$: gasFeeInUSD,
            //     iconURL: decodedCalldata?.icon,
            //     txByDesposited: false,
            //     action: "Approved",
            //   })
            // );
            // dispatch(setPendingTx(userOPResponse?.transactionHash));

            // showAlert(
            //   "Soon you can see your transaction in the transactions tab",
            //   "Approval Transaction Submitted",
            //   `<a href="https://polygonscan.com/tx/${userOPResponse?.transactionHash}" target="_blank">View on Polygonscan</a>`
            // );

            const txn = {
              hash: userOPResponse?.transactionHash,
              value: "0",
              valueIn$: "0",
              transferAmount: "0",
              transactionMethod: "SWAP",
              scanLink: block_explorer_url,
              eoaEns: rootAccountInfo.name,
              addressEns: activeAccount.accountName,
              toAddressEns: decodedCalldata?.address,
              toAddress: decodedCalldata?.address,
              assetName: decodedCalldata?.tokenName,
              networkFeesIn$: gasFeeInUSD,
              iconURL: decodedCalldata?.icon,
              txByDesposited: false,
              action: "Approved",
            };

            dispatchAndSaveHistory(txn);

            setFinalOpState(null);
            setLoading(false);
            setDecodedCalldata(null);
            setTxType("");

            setAllowance(0);
            stopBlinkingTitle();

            // if (web3wallet?.respondSessionRequest) {
            await web3wallet.respondSessionRequest({
              topic,
              response: formatJsonRpcResult(
                requestEvent.id,
                userOPResponse?.transactionHash
              ),
            });
            SendTransactionModalStore.close(requestEvent.id);
            // }
            return;
          }

          console.log("this is going in ---> ", {
            value: decodedCalldata?.srcToken?.value,
            valueIn$: floorFigure(
              Number(decodedCalldata?.srcToken?.valueInUsd)
            ),
            transferAmount: decodedCalldata?.srcToken?.value,
            transactionMethod: "SWAP",
            scanLink: block_explorer_url,
            eoaEns: rootAccountInfo.name,
            addressEns: activeAccount.accountName,
            toAddressEns: decodedCalldata?.destinationToken?.name,
            toAddress: decodedCalldata?.destinationToken?.tokenAddress,
            assetName: decodedCalldata?.srcToken?.name,
            networkFeesIn$: gasFeeInUSD,
            iconURL: decodedCalldata?.srcToken?.icon,
            txByDesposited: false,
            action: "Swapped",
            appName: appName,
          });

          dispatch(
            setPendingTxDetails({
              value: decodedCalldata?.srcToken?.value,
              valueIn$: floorFigure(
                Number(decodedCalldata?.srcToken?.valueInUsd)
              ),
              transferAmount: decodedCalldata?.srcToken?.value,
              transactionMethod: "SWAP",
              scanLink: block_explorer_url,
              eoaEns: rootAccountInfo.name,
              addressEns: activeAccount.accountName,
              toAddressEns: decodedCalldata?.destinationToken?.name,
              toAddress: decodedCalldata?.destinationToken?.tokenAddress,
              assetName: decodedCalldata?.srcToken?.name,
              networkFeesIn$: gasFeeInUSD,
              iconURL: decodedCalldata?.srcToken?.icon,
              txByDesposited: false,
              action: "Swapped",
              appName: appName,
            })
          );
          dispatch(setPendingTx(userOPResponse?.transactionHash));
          SendTransactionModalStore.close(requestEvent.id);
          showAlert(
            "Soon you can see your transaction in the transactions tab",
            "Transaction Submitted",
            `<a href="https://polygonscan.com/tx/${userOPResponse?.transactionHash}" target="_blank">View on Polygonscan</a>`
          );

          // if (web3wallet?.respondSessionRequest) {
          await web3wallet.respondSessionRequest({
            topic,
            response: formatJsonRpcResult(
              requestEvent.id,
              userOPResponse?.transactionHash
            ),
          });
          // }

          setFinalOpState(null);
          setLoading(false);
          setDecodedCalldata(null);
          setTxType("");

          if (
            userSpendingDetails.isFirstTx &&
            userSpendingDetails.isFirstTxInApp
          ) {
            dispatch(
              setUserSpendingDetails({
                isFirstTxInApp: false,
                isFirstTx: false,
              })
            );
            if (allowance) {
              navigate("/transaction-success");
              setLoading(false);
            }
          } else {
            if (allowance) {
              setLoading(false);
            }
          }
        }
      } catch (e) {
        dispatch(setTxStatus(!txStatus));
        dispatch(setPendingTx(""));
        // styledToast((e as Error).message, "error");
        console.log("error in onapprove txn:", e);
        showAlert("Something went wrong");
      }
    }
  }

  // Handle reject action
  async function onReject() {
    if (requestEvent) {
      const response = rejectEIP155Request(requestEvent);
      try {
        stopBlinkingTitle();
        await web3wallet.respondSessionRequest({
          topic,
          response,
        });
        setFinalOpState(null);
        setLoading(false);
        setDecodedCalldata(null);
        setTxType("");

        setAllowance(0);
      } catch (e) {
        styledToast((e as Error).message, "error");
        return;
      }
      SendTransactionModalStore.close(requestEvent.id);
    }
  }

  const calculateTransactionParameters = async () => {
    try {
      setLoading(true);
      setError(false);
      const web3 = new Web3(rpc);
      const nonce = await web3.eth.getTransactionCount(
        activeAccount.smartAccountAddress,
        "latest"
      );

      const pkey = decryptMessage(activeAccount.secret, hashedPassword);
      console.log("pkey", pkey);
      const provider = new ethers.providers.JsonRpcProvider(rpc);
      const stackupProvider = new ethers.providers.JsonRpcProvider(bundlerRPC);
      const wallet = new ethers.Wallet(pkey, provider);
      const { params } = requestEvent;
      const { request } = params;
      const callData = request.params[0].data;
      let userCallDataArray: ExecuteCall[] = [];

      console.log("usdcBalance", usdcBalance);
      console.log("userCallDataArray INITIALLY", userCallDataArray);

      if (!usdcBalance && !gas.totalBalance) {
        setLoading(false);
        showAlert("You do not have USDC balance to top up gas tank.");
        return;
      }

      const approveCallData = getApproveTokenCallData(
        APPROVE_AMOUNT,

        chain,
        usdcAddress
      );
      console.log("value for approval", { allowance });
      if (txType === TxType.PAYMASTER_APPROVAL) {
        console.log("approveCallData", approveCallData);
        userCallDataArray.push(approveCallData);
      } else {
        console.log("userCallDataArray AFTER ALLOWANCE", userCallDataArray);
        if (txType === TxType.CONFIRM && decodedCalldata?.srcToken?.isNative) {
          console.log(
            "--------------CASE OF MAIN FOR NATIVE TOKEN---------------"
          );
          userCallDataArray.push({
            to: request.params[0].to,
            value: request.params[0]?.value || "0",
            calldata: callData,
          });
        } else {
          console.log(
            "--------------CASE OF MAIN FOR NON NATIVE TOKEN---------------"
          );
          if (txType === TxType.CONFIRM) {
            console.log("checkerrrr", request.params);
            console.log(
              "checkerr2",
              ethers.utils
                .parseUnits(
                  String(Number(decodedCalldata?.srcToken?.value || 0)),
                  decodedCalldata?.srcToken?.decimals
                )
                .toString()
            );
            // const call0 = Token__factory.createInterface().encodeFunctionData(
            //   "transfer",
            //   [
            //     request.params[0].to,
            //     ethers.utils
            //       .parseUnits(
            //         String(Number(decodedCalldata?.srcToken?.value || 0)),
            //         decodedCalldata?.srcToken?.decimals
            //       )
            //       .toString(),
            //   ]
            // );

            // userCallDataArray.push({
            //   to: decodedCalldata?.srcToken?.tokenAddress,
            //   value: request.params[0].value || "0",
            //   calldata: request.params[0].data,
            // });

            const amount = (
              decodedCalldata?.srcToken?.value *
              Math.pow(10, decodedCalldata?.srcToken?.decimals)
            ).toString();

            const response = await getAlphaRouterResponse(
              decodedCalldata?.srcToken?.tokenAddress,
              decodedCalldata?.srcToken?.decimals,
              decodedCalldata?.destinationToken?.tokenAddress,
              decodedCalldata?.destinationToken?.decimals,
              activeAccount.smartAccountAddress,
              amount,
              TradeType.EXACT_INPUT,
              SwapType.SWAP_ROUTER_02,
              activeNetwork
            );

            console.log("response of getAlphaRouterResponse", {
              response,
              amount,
            });

            const pKey = decryptMessage(activeAccount.secret, hashedPassword);

            const provider = new ethers.providers.JsonRpcProvider(rpc);
            // const provider = new providers.JsonRpcProvider(
            //   SUPPORTED_NETWORKS[chainId as keyof typeof SUPPORTED_NETWORKS].rpc
            // );

            const wallet = new Wallet(pKey as BytesLike, provider);

            const originToken = new Contract(
              decodedCalldata?.srcToken?.tokenAddress,
              abi.abi,
              wallet
            );

            let approvalCallData = "";
            // const originToken = new Contract(data.originToken, abi, wallet)
            const allowance = await originToken.allowance(
              activeAccount.smartAccountAddress,
              contractAddresses.polygon.swapRouter02
            );

            console.log(
              "CHECKKK",
              allowance,
              Number(allowance) < Number(amount)
            );
            if (Number(allowance) < Number(amount)) {
              approvalCallData = await getSwapRouter02ApprovalTransaction(
                contractAddresses.polygon.swapRouter02,
                amount
              );
              userCallDataArray.push({
                to: decodedCalldata?.srcToken?.tokenAddress,
                value: "0",
                calldata: approvalCallData,
              });
            }

            // @ts-ignore
            const callData = response.methodParameters.calldata;
            console.log("🚀 ~ file: swap.ts:821 ~ callData:", callData);

            userCallDataArray.push({
              to: contractAddresses.polygon.swapRouter02,
              value: "0",
              calldata: callData,
            });
          } else if (txType === TxType.APPROVAL) {
            console.log("nested else");
            userCallDataArray.push({
              to: request.params[0].to,
              value: "0",
              calldata: request.params[0].data,
            });
          }
        }
      }
      console.log("userCallDataArray AFTER MAIN", userCallDataArray);

      let data: any = {};

      const cryptoAccountDeployed = await isCryptoAccountDeployed(
        activeNetwork,
        activeAccount.smartAccountAddress
      );

      data = await txSubmissionOrderPrepaid({
        userCallDataArray,
        wallet,
        isAccountDeployed: cryptoAccountDeployed,
        chain,
        smartAccountAddress: activeAccount.smartAccountAddress,
        provider: stackupProvider,
        extraTopupAmount,
        approval: allowance ? true : false,
        gasBalance: gas.totalBalance,
        rootAddress: rootAccountInfo.address.toLowerCase(),
      });

      setFinalOpState(data.finalOp);
      console.log("gasFeeInUSD going to be", data.usdcFee, gas.totalBalance);

      console.log(
        "ye set orha hai Number(data.usdcFee).toFixed(4)",
        Number(data.usdcFee).toFixed(4)
      );
      setGasFeeInUSD(Number(data.usdcFee).toFixed(4));

      // setLoading(false);

      // let price = 0;
      // const data1 = await fetchPrice(
      //   [usdcAddress.toLowerCase()],
      //   activeNetwork
      // );
      // price = data1[usdcAddress.toLowerCase()]?.price || 0;

      // setCost(price * +data.usdcFee);

      if (Number(data.usdcFee) > gas.totalBalance) {
        setOpenExtraTopupModal(true);
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log("error in calculateTransactionParameters", e);
      setLoading(false);
      setError(true);
      // styledToast(e, "error");
      // console.log("ye bhej rhe", e );
      showAlert(e);
    }
  };

  useEffect(() => {
    console.log("gasFeeInUSD change hua", gasFeeInUSD);
  }, [gasFeeInUSD]);

  // getting usdc balance, allowance and minimum topup limit
  useEffect(() => {
    (async () => {
      console.log(
        "USEFFECT#1 : getting usdc balance, allowance and minimum topup limit"
      );
      if (requestSession?.peer?.metadata?.name) {
        setAppName(requestSession?.peer?.metadata?.name);
      }

      const minimumTopUpLimit = await getMinimumTopupLimit(activeNetwork);
      const usdcBalance = await getUsdcBalance(
        activeAccount.smartAccountAddress,
        activeNetwork
      );
      setUsdcBalance(usdcBalance);
      setMinimumTopupAmount(minimumTopUpLimit);
    })();
  }, [loading, txType]);

  useEffect(() => {
    console.log("USEFFECT#3 : calling calculateTransactionParameters");
    console.log(
      "checker",
      { decodedCalldata, finalOpState, txType },
      (decodedCalldata?.srcToken || decodedCalldata?.spender) &&
        !finalOpState &&
        txType
    );
    if (
      (decodedCalldata?.srcToken || decodedCalldata?.spender) &&
      !finalOpState &&
      txType
    ) {
      calculateTransactionParameters();
    }
  }, [decodedCalldata, txType, topic]);

  useEffect(() => {
    console.log("USEFFECT#4 : isPatternCorrect");
    if (isPatternCorrect) {
      setOpenModal(false);
      // onApprove();
    }
  }, [isPatternCorrect]);

  useEffect(() => {
    console.log("USEFFECT#5 : startBlinkingTitle");
    startBlinkingTitle();

    return () => {
      stopBlinkingTitle();
    };
  }, []);

  const createSignature = async () => {
    try {
      console.log("ye gaya new amount", extraTopupAmount);
      const chain = getChain(activeNetwork);
      const { feesInUsdc, preFinalOp } = await createSignatureAndCalculateFees(
        finalOpState,
        rootAccountInfo.address,
        extraTopupAmount,
        activeAccount.secret,
        hashedPassword,
        alchemy_url,
        chain
      );
      console.log("preFinalOp", preFinalOp);
      setFinalOpState(preFinalOp);

      setGasFeeInUSD(Number(feesInUsdc).toFixed(4));
    } catch (e) {
      console.log("error in createSignature", e);
      setLoading(false);
      showAlert(error);
    }
  };

  // useEffect(() => {
  //   setLoading(false);
  // }, []);

  const addExtraTopupAmount = async () => {
    if (!extraTopupAmount || Number(extraTopupAmount) <= 0) {
      setOpenExtraTopupModal(false);
      if (finalOpState) setError(false);
      return;
    }

    setOpenExtraTopupModal(false);
    // setLoading(true);
    await createSignature();

    await handleApprove();
  };

  const [open, setOpen] = useState(false);

  const toggleCollapse = () => {
    setOpen(!open);
  };

  const handleTopupAmountChange = (value) => {
    const inputValue = value;
    console.log(inputValue);

    if (/[^0-9.]/.test(inputValue)) {
      setError("");
    } else {
      setError("");
      setExtraTopupAmount(inputValue);
    }
  };

  return (
    <SessionSendTransactionModalWrapper>
      <RequestModalContainer title={`${txType} Transaction`}>
        <ExtraTopupAmountPopup
          openExtraTopupModal={openExtraTopupModal}
          addExtraTopupAmount={addExtraTopupAmount}
          extraTopupAmount={extraTopupAmount}
          gasBalance={gas.totalBalance}
          usdcBalance={usdcBalance}
          handleTopupAmountChange={handleTopupAmountChange}
          minimumTopupAmount={
            usdcBalance -
            Number(decodedCalldata?.srcToken?.valueInUsd || "0") -
            Number(gasFeeInUSD)
          }
          closeExtraTopupModal={() => {
            setOpenExtraTopupModal(false);
            setLoading(false);
          }}
        />

        <Stack>
          {txType !== TxType.PAYMASTER_APPROVAL && (
            <Stack
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
              marginBottom={3}
            >
              <img
                alt="App icon"
                src={
                  requestSession.peer.metadata.icons[0] ||
                  "https://quickswap.exchange/logo_circle.png"
                }
                width={30}
                height={30}
                style={{ marginRight: 6 }}
                onError={(e) => {
                  e.currentTarget.src = NoImg;
                }}
              />
              <Typography>{requestSession?.peer?.metadata?.name}</Typography>
            </Stack>
          )}
          {/*<Stack marginBottom={3}>
            <Typography variant="h5">Call Data</Typography>
            <ItemWrapper
              sx={{
                wordWrap: "break-word", // Breaks the words and wraps onto the next line
                overflowWrap: "break-word",
                height: "150px",
                maxHeight: "600px",
                overflowY: "auto",
              }}
            >
              <Typography variant="caption">
                {request.params[0].data}
              </Typography>
            </ItemWrapper>
            </Stack> */}
          {txType === TxType.CONFIRM && (
            <Stack
              width={"100%"}
              margin={"auto"}
              style={{
                flexBasis: "100%",
                position: "relative",
                // maxWidth: "68%",
              }}
            >
              <ItemBox
                style={{
                  padding: "15px 10px",
                  margin: "0 5px 0 5px",
                  borderRadius: "12px",
                  position: "relative",
                  background:
                    "linear-gradient(0deg, #EDEEF2, #F7F7F7, #FFFFFF)",
                }}
                tokenName={decodedCalldata?.srcToken?.name}
                tokenSymbol={decodedCalldata?.srcToken?.symbol}
                tokenAmount={decodedCalldata?.srcToken?.value}
                tokenImage={decodedCalldata?.srcToken?.icon}
                tokenAmoundInUsd={decodedCalldata?.srcToken?.valueInUsd}
                amountSymbol={decodedCalldata?.srcToken?.symbol}
              />
              <div
                style={{
                  position: "absolute",
                  top: "35%",
                  left: "45%",
                  zIndex: 2,
                  cursor: "pointer",
                }}
                // onClick={reverseTokens}
              >
                <img src={MidArrow} />
              </div>
              <ItemBox
                style={{
                  padding: "15px 10px",
                  margin: "5px",
                  borderRadius: "12px",
                  backgroundColor: "#EDEEF2",
                }}
                tokenName={decodedCalldata?.destinationToken?.name}
                tokenSymbol={decodedCalldata?.destinationToken?.symbol}
                tokenAmount={decodedCalldata?.destinationToken?.value}
                tokenImage={decodedCalldata?.destinationToken?.icon}
                tokenAmoundInUsd={decodedCalldata?.destinationToken?.valueInUsd}
                amountSymbol={decodedCalldata?.destinationToken?.symbol}
              />
            </Stack>
          )}

          {(txType === TxType.APPROVAL ||
            txType === TxType.PAYMASTER_APPROVAL) && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "330px",
                height: "160px",
                border: "0.5px solid #B2B2B2",
                margin: "10px auto",
              }}
              gap={2}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "330px",
                  height: "70px",
                  alignItems: "center",
                  paddingLeft: "10px",
                  justifyContent: "flex-start",
                }}
                gap={2}
              >
                <Box width="40px">
                  <Typography
                    variant="caption"
                    fontSize="12px"
                    fontWeight={400}
                    color="#67686C"
                  >
                    Token
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    variant="body1"
                    fontSize="12px"
                    fontWeight={500}
                    color="#121212"
                  >
                    {signData?.tokenName || ""}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "300px",
                  height: "70px",
                  alignItems: "flex-start",
                  paddingLeft: "10px",
                  justifyContent: "flex-start",
                  // border: "1px solid red",
                }}
                gap={2}
              >
                <Box width="40px">
                  <Typography
                    variant="caption"
                    fontSize="12px"
                    fontWeight={400}
                    color="#67686C"
                  >
                    Amount
                  </Typography>
                </Box>
                <Box sx={{ width: "250px" }}>
                  <Typography
                    variant="body1"
                    fontSize="12px"
                    fontWeight={500}
                    color="#121212"
                    style={{
                      width: "250px",
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                      wordWrap: "break-word",
                      display: "inline-block",
                    }}
                  >
                    {signData?.amount || ""}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "330px",
                  height: "70px",
                  alignItems: "flex-start",
                  paddingLeft: "10px",
                  justifyContent: "flex-start",
                }}
                gap={2}
              >
                <Box width="40px">
                  <Typography
                    variant="caption"
                    fontSize="12px"
                    fontWeight={400}
                    color="#67686C"
                  >
                    Spender
                  </Typography>
                </Box>

                <Box sx={{ width: "250px" }}>
                  <Typography
                    variant="body1"
                    fontSize="12px"
                    fontWeight={500}
                    color="#121212"
                    style={{
                      width: "250px",
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                      wordWrap: "break-word",
                      display: "inline-block",
                    }}
                  >
                    {signData?.spender || ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          {!risk ? (
            <Box
              sx={{
                background: "#F7F7F7",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                //   padding: "25px",
                cursor: "pointer",
                padding: "10px",
                marginBottom: "15px",
              }}
            >
              <Typography
                color={"rgba(91, 194, 65, 1)"}
                fontSize={"12px"}
                fontWeight={500}
              >
                Secure and reliable. Safe to proceed.
              </Typography>
              {/* <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <SortOutlinedIcon />
          <Typography variant="h6">{title}</Typography>
        </Box> */}

              {/* <ExpandMoreIcon
          style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
        /> */}
            </Box>
          ) : (
            <Paper
              elevation={3}
              sx={{
                borderRadius: "10px",
                background: "#FFFFFF",
                // "&:hover": {
                //   backgroundColor: "lightgray",
                // },
                boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.0)",
                marginBottom: "20px",
              }}
            >
              <Box
                onClick={toggleCollapse}
                sx={{
                  background: "#F7F7F7",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  //   padding: "25px",
                  cursor: "pointer",
                  padding: "10px",
                  // marginBottom: "5px",
                }}
              >
                <Typography
                  color={"#EB4335"}
                  fontSize={"12px"}
                  fontWeight={500}
                >
                  {riskData.type || "High Risk"}
                </Typography>
                {/* <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <SortOutlinedIcon />
          <Typography variant="h6">{title}</Typography>
        </Box> */}

                {/* <ExpandMoreIcon
          style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
        /> */}
              </Box>
              <Collapse in={open}>
                <Box
                  sx={{
                    padding: "20px 20px 20px 20px",
                    background: "#F7F7F7",
                    borderRadius: "10px",
                    marginTop: "10px",

                    // border: "2px solid black",
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                >
                  <Typography
                    color={"#1A1C20"}
                    fontSize={"12px"}
                    fontWeight={500}
                  >
                    We found 1 risky signal
                  </Typography>

                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <img
                      src={AlertIcon}
                      alt="alert"
                      style={{ width: "30px", height: "30px" }}
                    />

                    <Typography
                      color={"#1A1C20"}
                      fontSize={"12px"}
                      fontWeight={500}
                    >
                      {riskData?.description || "Unknown Wallet"}
                    </Typography>
                  </Stack>

                  {/* <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <img
                    src={AlertIcon}
                    alt="alert"
                    style={{ width: "30px", height: "30px" }}
                  />

                  <Typography
                    color={"#1A1C20"}
                    fontSize={"12px"}
                    fontWeight={500}
                  >
                    Unusual Recipient
                  </Typography>
                </Stack>

                <Stack
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <img
                    src={AlertIcon}
                    alt="alert"
                    style={{ width: "30px", height: "30px" }}
                  />

                  <Typography
                    color={"#1A1C20"}
                    fontSize={"12px"}
                    fontWeight={500}
                  >
                    Limited onchain history
                  </Typography>
                </Stack> */}
                </Box>
              </Collapse>
            </Paper>
          )}

          <Grid container display="flex" justifyContent="center" py={0}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                // border: "2px solid yellow",
                width: "100%",
              }}
            >
              {/* <FeeUrgencyComponent
                feeGasUrgency={selected}
                setFeeGasUrgency={setSelected}
              /> */}
              {/* <Box marginBottom={3}>
                <TxTypeSwitch
                  loading={loading}
                  txByDeposited={false}
                  onTop20Select={onTop20Select}
                  onDepositSelect={onDepositSelect}
                />
              </Box> */}
              <SendTxComponent
                loading={loading}
                gasTokenSelect={<></>}
                top20TokenSelect={<></>}
                txByDeposited={false}
                handleSend={() => handleApprove()}
                finalOpState={finalOpState}
                error={error}
                isSwap={txType === TxType.CONFIRM}
                isApproval={
                  txType === TxType.APPROVAL ||
                  txType === TxType.PAYMASTER_APPROVAL
                }
                isShowCancelButton={true}
                onReject={onReject}
              >
                <FeeUIComponent
                  gasFeeInUSD={gasFeeInUSD}
                  loading={!finalOpState && loading}
                />
              </SendTxComponent>
            </Box>
          </Grid>
        </Stack>

        <ConfirmPatternModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          setIsPatternCorrect={setIsPatternCorrect}
        />
      </RequestModalContainer>
    </SessionSendTransactionModalWrapper>
  );
}
