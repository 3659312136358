import React, { FC, useEffect, useState } from "react";
import { Box, Input, Typography } from "@mui/material";
import SearchIcon from "assets/SearchInputIcon.svg";

import SearchOptionsIcon from "assets/searchOptionsIcon.svg";
import CenterItem from "../../../../components/CenterItem";
import SearchInput from "../../../../components/SearchInput";
import TokensList from "./TokensList";
import { useAppSelector } from "store/store";
import FilterModal from "components/Modal/FilterModal";
import { filterData } from "constants/chains";
// import { useAppSelector } from "../../../../store/store";

const TokensListTable: FC<{
  transactionForm?: boolean;
  nextStep?: () => void;
  isApplyFilter: boolean;
  chainId: number;
  isShowTokenAmountUnderName?: boolean;
}> = ({
  transactionForm = false,
  nextStep = () => {},
  chainId = 137,
  isApplyFilter = false,
  isShowTokenAmountUnderName = false,
}) => {
  console.log("🚀 ~ file: index.tsx:23 ~ isApplyFilter:", isApplyFilter);
  const {
    portfolio: { assets },
    activeNetwork,
    swapDetails,
  } = useAppSelector((state) => state.app);

  const [searchText, setSearchText] = useState("");
  const [currentRoute, setCurrentRoute] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const [filteredAssets, setFilteredAssets] = useState(assets);

  // const profileNfts = nfts[activeAccount.smartAccountAddress].nfts;
  // console.log("file: index.tsx:13  TokensListTable  nfts:", nfts, profileNfts);

  const testFun = () => {
    console.log("testFun");
  };

  //fetch current route
  useEffect(() => {
    setCurrentRoute(window?.location?.pathname);
  }, [window?.location?.pathname]);

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState({
    ethereum: false,
    polygon: true,
    bnb: false,
    mantle: false,
  });
  const [selectText, setSelectText] = useState("Select All");
  const [selectedFilter, setSelectedFilter] = useState(
    filterData.map((item) => item?.chainId)
  ); // ["ethereum", "polygon", "bnb", "mantle"]

  const handleCheck = ({ chainName, chainId }) => {
    setChecked((prevState) => ({
      ...prevState,
      [chainName]: !prevState[chainName],
    }));

    setSelectedFilter((prevState) => {
      if (prevState.includes(chainId)) {
        return prevState.filter((item) => item !== chainId);
      } else {
        return [...prevState, chainId];
      }
    });
  };

  const handleSelectAll = (e) => {
    e.preventDefault();

    setChecked((prevState) => ({
      ...prevState,
      ethereum: selectText === "Select All" ? true : false,
      polygon: selectText === "Select All" ? true : false,
      bnb: selectText === "Select All" ? true : false,
      mantle: selectText === "Select All" ? true : false,
    }));

    if (selectText === "Select All") {
      setSelectText("Deselect All");
    } else {
      setSelectText("Select All");
    }

    setSelectedFilter((prevState) => {
      if (selectText === "Select All") {
        return filterData.map((item) => item?.chainId);
      } else {
        return [];
      }
    });
  };

  // if any is false then select all else deselect all
  useEffect(() => {
    const isAllTrue = Object.values(checked)?.every((chain) => chain === true);
    if (isAllTrue) {
      setSelectText("Deselect All");
    } else {
      setSelectText("Select All");
    }
  }, [checked]);

  const filterDataHandler = () => {
    setOpenModal(false);
    // filter assets on the basis of selectedFilter
    const filteredData = filteredAssets?.filter((item) => {
      const chainId =
        item?.cross_chain_balances?.Polygon &&
        item?.cross_chain_balances?.Polygon?.chainId;
      return selectedFilter?.includes(chainId);
    });

    setFilteredAssets(filteredData || []);
  };

  const filterModalProps = {
    open: openModal,
    onClose: () => setOpenModal(false),
    handleSelectAll,
    checked,
    selectText,
    filterData,
    handleCheck,
    filterDataHandler,
  };

  console.log("filterData", filterData);

  return (
    <div style={{ width: "100%" }}>
      <Box display="flex">
        <Box flex={1} alignItems={"center"}>
          <SearchInput
            handleOnChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </Box>

        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            justifyContent: "space-between",
            paddingLeft: "12px",
          }}
          style={{
            cursor: "pointer",
          }}
        >
          {currentRoute !== "/crypto/swap" && (
            <>
              <CenterItem>
                <img
                  src={SearchOptionsIcon}
                  onClick={() => setOpenModal(true)}
                />
              </CenterItem>

              <FilterModal modalProps={filterModalProps} />
            </>
          )}

          {!transactionForm && (
            <CenterItem>
              <div className="tab-chip-box" style={{ maxWidth: 110 }}>
                <Typography
                  variant={"h5"}
                  fontWeight={500}
                  color="white"
                  fontSize={12}
                  fontFamily={"Helvetica Neue"}
                  textAlign="center"
                >
                  + ADD TOKEN
                </Typography>
              </div>
            </CenterItem>
          )}
        </Box> */}
      </Box>
      <Box>
        <TokensList
          transactionForm={transactionForm}
          nextStep={nextStep}
          filterText={searchText}
          chainId={chainId}
          isApplyFilter={isApplyFilter}
          isShowTokenAmountUnderName={isShowTokenAmountUnderName}
          setFilteredAssets={setFilteredAssets}
          filteredAssets={filteredAssets}
          selectedFilter={selectedFilter}
        />
      </Box>
    </div>
  );
};

export default TokensListTable;
