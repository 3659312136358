import { Box, Stack, Typography } from "@mui/material";
import { useAppSelector } from "store/store";
import { truncate } from "utils/HelperUtil";
import SuccessLogo from "assets/success.svg";
import ModalStore from "../walletConnectStore/ModalStore";
import NoImg from "../assets/noImg.svg";

const SessionDisconnectModal = () => {
  const { activeAccount } = useAppSelector((state) => state.app);

  // Get proposal data and wallet address from store
  const dappDetails = ModalStore.state.data?.dappDetails;

  // Ensure dappName is defined
  if (!dappDetails) {
    return <p>Missing Dapp Name</p>;
  }

  console.log("dappDetails in discconection", dappDetails);

  return (
    <Box
      sx={{
        background: "white",
        overflowY: "scroll",
        width: "40%",
        height: "60%",
        // transform: "translateX(80%) translateY(20%)",
        padding: "10px",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // opacity: "0",
      }}
    >
      <Stack
        gap={2}
        sx={{ marginTop: "25px" }}
        direction="column"
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Typography
          variant="h4"
          color="#121212"
          fontSize="22px"
          fontWeight={600}
        >
          @{activeAccount?.accountName}
        </Typography>

        <Typography
          variant="body1"
          color="#67686C"
          fontSize="20px"
          fontWeight={400}
        >
          {truncate(activeAccount?.smartAccountAddress, 16)}
        </Typography>

        <img
          src={SuccessLogo}
          style={{ marginTop: "20px", marginBottom: "20px" }}
        />

        <Typography
          variant="h4"
          color="#121212"
          fontSize="22px"
          fontWeight={600}
        >
          Disconnection Successful
        </Typography>

        <Typography
          variant="body1"
          color="#121212"
          fontSize="20px"
          fontWeight={500}
        >
          You're now disconnected from
        </Typography>
        {dappDetails && (
          <Stack
            gap={1}
            direction="row"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src={dappDetails?.dappIcon}
              style={{ width: "40px", height: "40px", borderRadius: "40px" }}
              onError={(e) => {
                e.currentTarget.src = NoImg;
              }}
            />
            <Typography
              variant="body1"
              color="#121212"
              fontSize="20px"
              fontWeight={500}
            >
              {dappDetails?.dappName || "Unnamed Dapp"}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

export default SessionDisconnectModal;
