import React, { useEffect, useState } from "react";
import MidArrow from "assets/midArrow.svg";
import "./index.css";
import { Box, Grid, Skeleton, Snackbar, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "store/store";
import { SUPPORTED_NETWORKS } from "constants/chains";
import { decryptMessage, showAlert } from "utils/utils";
import { ethers, BigNumber } from "ethers";
import { setPendingTx, setPendingTxDetails } from "@slices/appSlice";
import { useNavigate } from "react-router-dom";
import CustomizedSteppers from "components/Stepper";
import NavigatorHeading from "components/NavigatorHeading";
import CloseButton from "components/CloseButton";
import NetworksList from "components/NetworksList";
import TokenInputForm from "components/TokenInputForm";
import { sendUserOp } from "../../../../contract-integration";
import FillAsset from "./FillAsset";
import FeeUIComponent from "../../../../components/SendTxComponent/FeeUIComponent";
import { getChain } from "utils/chains";
import { getApproveTokenCallData, getGasTopupData } from "stash-fe-script";
import { checkGasAllowance } from "utils/swap";
import { APPROVE_AMOUNT, USDT_URL } from "constants/";
import { setSelectedToken } from "@slices/walletSlice";
import { getMinimumTopupLimit, getUsdcBalance } from "utils/balance";
import BasicButton from "components/Button";
import ExtraTopupAmountPopup from "components/ExtraTopupAmountPopup";
import { txSubmissionOrderPrepaid } from "../../../../contract-integration/prepaidGas";
import { isCryptoAccountDeployed } from "utils/deployed";
import { createSignatureAndCalculateFees } from "utils/signature";

const FillUp = () => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [gasFeeInUSD, setGasFeeInUSD] = useState("0");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [step, setStep] = useState(0);
  const [finalOpState, setFinalOpState] = useState<any>(null);
  const [error, setError] = useState("");
  const [inputError, setInputError] = useState("");
  const [usdcBalance, setUsdBalance] = useState(0);
  const [allowance, setAllowance] = useState(0);
  const [minimumTopupAmount, setMinimumTopupAmount] = useState("0");
  const [extraTopupAmount, setExtraTopupAmount] = useState("0");
  const [openExtraTopupModal, setOpenExtraTopupModal] = useState(false);

  const navigate = useNavigate();
  const { activeAccount, activeNetwork, rootAccountInfo, gas, pendingTx } =
    useAppSelector((state) => state.app);

  const { selectedToken }: { selectedToken: any } = useAppSelector(
    (state) => state.wallet
  );

  const { hashedPassword } = useAppSelector((state) => state.wallet);

  const {
    block_explorer_url,
    alchemy_url,
    usdcAddress,
    usdcDecimals,
    stackupUrl,
    bundlerRPC,
  } = SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS];

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (value && step === 2) {
        sendTopUpTransaction();
      }
    })();
  }, [selectedToken, step, allowance]);

  useEffect(() => {
    (async () => {
      const usdcBalance = await getUsdcBalance(
        activeAccount.smartAccountAddress,
        activeNetwork
      );

      const allowance = await checkGasAllowance(
        activeAccount.smartAccountAddress,
        usdcAddress,
        activeNetwork
      );
      const minimumTopUpLimit = await getMinimumTopupLimit(activeNetwork);
      setMinimumTopupAmount(minimumTopUpLimit);

      setUsdBalance(usdcBalance);
      setAllowance(Number(allowance));
    })();
  }, [loading, pendingTx]);

  async function sendTopUpTransaction() {
    try {
      setError(false);
      setLoading(true);

      setFinalOpState(null);

      const rpcEndpoint =
        SUPPORTED_NETWORKS[activeNetwork as keyof typeof SUPPORTED_NETWORKS]
          .alchemy_url;

      const pkey = decryptMessage(activeAccount.secret, hashedPassword);
      const provider = new ethers.providers.JsonRpcProvider(rpcEndpoint);
      const stackupProvider = new ethers.providers.JsonRpcProvider(bundlerRPC);
      const wallet = new ethers.Wallet(pkey, provider);

      if (!usdcBalance) {
        showAlert("You do not have USDC balance to top up gas tank.");
        setLoading(false);
        return;
      }

      const enteredAmountInWei: BigNumber = ethers.utils.parseUnits(
        String(value),
        usdcDecimals
      );

      console.log("In here [IF]");

      const chain = getChain(activeNetwork);

      const approveCallData = getApproveTokenCallData(
        APPROVE_AMOUNT,

        chain,
        usdcAddress
      );

      console.log("enteredAmountInWei", enteredAmountInWei.toString());
      const topupCallData = getGasTopupData(
        enteredAmountInWei.toString(),

        chain
      );

      console.log("topupCallData", topupCallData);
      console.log("approveCallData", approveCallData);

      const cryptoAccountDeployed = await isCryptoAccountDeployed(
        activeNetwork,
        activeAccount.smartAccountAddress
      );

      const { finalOp, usdcFee } = await txSubmissionOrderPrepaid({
        userCallDataArray: allowance ? [topupCallData] : [approveCallData],

        wallet,

        isAccountDeployed: cryptoAccountDeployed,
        chain,
        provider: stackupProvider,
        smartAccountAddress: activeAccount.smartAccountAddress,
        gasBalance: gas.totalBalance,
        approval: allowance ? true : false,
        extraTopupAmount,
        rootAddress: rootAccountInfo.address.toLowerCase(),
      });

      console.log("Clicked!");

      console.log(
        "file: index.tsx:1058  sendCryptoTransactionfinal  finalOp:",
        finalOp
      );

      setFinalOpState(finalOp);
      setLoading(false);
      setGasFeeInUSD(Number(usdcFee).toFixed(4));

      // if (Number(usdcFee) > gas.totalBalance) {
      //   setOpenExtraTopupModal(true);
      // } else {
      // setLoading(false);
      // }

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const executeTopUpCrypto = async () => {
    try {
      setLoading(true);
      if (finalOpState) {
        setOpenExtraTopupModal(false);
        const response = await sendUserOp(
          finalOpState,
          stackupUrl,
          alchemy_url
        );
        const userOPResponse: any = await response.wait();
        console.log("userOp Hash :", response.userOpHash);
        console.log("Tx Hash :", userOPResponse?.transactionHash);
        console.log("success status :", userOPResponse?.args.success);
        console.log(
          "actualGasCost  :",
          Number(userOPResponse?.args.actualGasCost)
        );

        dispatch(
          setPendingTxDetails({
            value: allowance ? value : 0,
            valueIn$: allowance
              ? String(Number(value) * Number(selectedToken[0].tokenPrice ?? 0))
              : 0,
            transferAmount: allowance ? value : 0,
            transactionMethod: "SEND",
            scanLink: block_explorer_url,
            eoaEns: rootAccountInfo.name,
            addressEns: activeAccount.accountName,
            toAddressEns: allowance ? "" : usdcAddress,
            toAddress: allowance ? "" : usdcAddress,
            assetName: allowance ? "Gas" : "USDC",
            assetSymbol: allowance ? "Gas" : "USDC",
            networkFeesIn$: gasFeeInUSD,
            iconURL: USDT_URL,
            txByDesposited: true,
            action: allowance ? "Deposited" : "Approved",
          })
        );

        dispatch(setPendingTx(userOPResponse?.transactionHash));

        showAlert(
          "Soon you can see your transaction in the transactions tab",
          allowance
            ? "Transaction Submitted"
            : "Approval Transaction Submitted",
          `<a href="https://polygonscan.com/tx/${userOPResponse?.transactionHash}" target="_blank">View on Polygonscan</a>`
        );
      }
      setLoading(false);
      if (allowance) {
        navigate("/gas");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      showAlert("Something went wrong");
    }
  };

  const handleAmountChange = (value) => {
    const inputValue = value;
    console.log(inputValue);
    setInputError("");

    let regex = /^\d{0,10}(\.\d{0,7})?$/;

    if (/[^0-9.]/.test(inputValue)) {
      setInputError("Special characters are not allowed");
    } else if ((inputValue.match(/\./g) || []).length > 1) {
      setInputError("Only one decimal point is allowed");
    } else if (!regex.test(inputValue)) {
      setInputError(
        `Maximum of 10 digits before decimals and 6 digits after decimals are allowed`
      );
    }
    // else if (Number(inputValue) < 0.1) {
    //   setInputError("Value should not be less than $0.1");

    //   setValue(inputValue);
    // }
    else {
      setInputError("");

      setValue(inputValue);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Increment the value by 1
      const newValue = (numericValue + 1).toFixed(2);
      setValue(newValue);
      setInputError("");
    }

    if (event.key === "ArrowDown") {
      event.preventDefault();

      // Parse the current value to a float
      const numericValue = parseFloat(value || "0");

      // Ensure the value doesn't go below 0.01
      const newValue = (numericValue - 1).toFixed(2);
      if (Number(newValue) > 0) {
        setValue(newValue);
      }
      setInputError("");
    }
  };

  const handleTopupAmountChange = (value) => {
    const inputValue = value;
    console.log(inputValue);

    if (/[^0-9.]/.test(inputValue)) {
      setError("");
    } else {
      setError("");
      setExtraTopupAmount(inputValue);
    }
  };

  const createSignature = async () => {
    const chain = getChain(activeNetwork);
    const { feesInUsdc, preFinalOp } = await createSignatureAndCalculateFees(
      finalOpState,
      rootAccountInfo.address,
      extraTopupAmount,
      activeAccount.secret,
      hashedPassword,
      alchemy_url,
      chain
    );
    setFinalOpState(preFinalOp);

    setGasFeeInUSD(Number(feesInUsdc).toFixed(4));
  };
  const addExtraTopupAmount = async () => {
    setOpenExtraTopupModal(false);
    setLoading(true);

    await createSignature();

    await executeTopUpCrypto();
  };

  const isValid = Number(value) && Number(value) <= Number(usdcBalance);
  // &&
  // Number(value) >= 0.1;

  return (
    <>
      <ExtraTopupAmountPopup
        openExtraTopupModal={openExtraTopupModal}
        addExtraTopupAmount={addExtraTopupAmount}
        extraTopupAmount={extraTopupAmount}
        usdcBalance={usdcBalance}
        gasBalance={gas.totalBalance}
        handleTopupAmountChange={handleTopupAmountChange}
        minimumTopupAmount={usdcBalance - Number(value) - Number(gasFeeInUSD)}
        closeExtraTopupModal={() => {
          setOpenExtraTopupModal(false);
          setLoading(false);
        }}
      />
      <Box mt={3}>
        <NavigatorHeading
          title="Top Up"
          RightComponent={
            <CloseButton
              handleOnClick={() => {
                navigate("/gas");
              }}
            />
          }
        />
      </Box>
      <Box mt={1}>
        <CustomizedSteppers
          step={step}
          steps={["Network", "Amount", "Top Up"]}
          changeStep={(selectedStep: number) => {
            //eg. if user is on step 3 he should be able to move at step 1 or 2 on clicking step icon
            if (selectedStep < step) {
              setStep(selectedStep);
            }
          }}
        />

        <Box mt={2}>
          {step == 0 && (
            <Grid container display="flex" justifyContent="center">
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "100%",
                  maxWidth: "55%",
                }}
              >
                <NetworksList
                  nextStep={() => setStep(1)}
                  title="Select which network you want to top up from"
                />
              </Grid>
            </Grid>
          )}

          {step == 1 && (
            <Grid container display="flex" justifyContent="center" py={4}>
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "100%",
                  maxWidth: "55%",
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <Typography
                    sx={{
                      fontSize: "17px",
                      textAlign: "left",
                      color: "rgba(26, 28, 32, 0.5)",
                      fontFamily: "Helvetica Neue",
                      fontWeight: 500,
                      width: "100%",
                    }}
                  >
                    Amount to top up to gas
                  </Typography>

                  <TokenInputForm
                    placeHolder={
                      Number(usdcBalance) && Number(usdcBalance) < 0.0001
                        ? "<0.0001"
                        : usdcBalance.toString()
                    }
                    title="Crypto Tag"
                    addBorder
                    type="number"
                    onChange={handleAmountChange}
                    onKeydown={handleKeyDown}
                    value={String(value)}
                    receiverENS={""}
                    isDepositValid={!!isValid}
                    nextStep={() => {
                      dispatch(
                        setSelectedToken([
                          {
                            image: USDT_URL,
                            tokenName: "USDC",
                            tokenSymbol: "USDC",
                            tokenPrice: 1,
                          },
                        ])
                      );
                      setStep(2);
                    }}
                    tokenName={"USDC"}
                    tokenIcon={USDT_URL}
                    errorMessage={inputError}
                    balance={usdcBalance}
                    decimals={6}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
          {step == 2 && (
            <Grid container display="flex" justifyContent="center" py={4}>
              <Grid
                item
                lg={6}
                sm={12}
                style={{
                  flexBasis: "100%",
                  maxWidth: "55%",
                }}
              >
                <FillAsset
                  nextStep={() => console.log("end")}
                  value={value}
                  executeSend={sendTopUpTransaction}
                />
                <Box
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    component={"img"}
                    src={MidArrow}
                    width={"33px"}
                    height={"33px"}
                    mt={"-8px"}
                    position={"relative"}
                  />
                </Box>
                <FillAsset
                  nextStep={() => console.log("end")}
                  value={value}
                  executeSend={sendTopUpTransaction}
                  isGasComponent={true}
                />

                <Box mt={5}>
                  <FeeUIComponent
                    gasFeeInUSD={gasFeeInUSD}
                    loading={!finalOpState && loading}
                  />
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={50}
                      sx={{
                        borderRadius: "10px",

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  ) : (
                    <BasicButton
                      title={allowance ? "Send" : "Approve Paymaster"}
                      onClick={
                        error || !finalOpState
                          ? () => {
                              console.log("abc");
                            }
                          : executeTopUpCrypto
                      }
                      style={{
                        cursor:
                          error || !finalOpState ? "not-allowed" : "pointer",
                        height: "15px",
                      }}
                      className="transfer-btn"
                      loading={loading}
                      mode={"active"}
                      id="send"
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message="Funds are not enough to use as gas fees"
      />
    </>
  );
};

export default FillUp;
