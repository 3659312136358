import { Typography } from "@mui/material";
import { width } from "@mui/system";
import BasicModal from "components/BasicModal";
import Button from "components/NewButton";
import TextInput from "components/TextInput";
import React, { FC } from "react";

interface ExtraTopupAmountPopupProps {
  openExtraTopupModal: boolean;
  minimumTopupAmount: number;
  extraTopupAmount: string;
  handleTopupAmountChange: (value: any) => void;
  gasBalance: number;
  usdcBalance: number;
  addExtraTopupAmount: () => void;
  closeExtraTopupModal: () => void;
}

const ExtraTopupAmountPopup: FC<ExtraTopupAmountPopupProps> = ({
  openExtraTopupModal,
  extraTopupAmount,
  minimumTopupAmount,
  gasBalance,
  usdcBalance,
  addExtraTopupAmount,
  handleTopupAmountChange,
  closeExtraTopupModal,
}) => {
  console.log("extraTopupAmount", extraTopupAmount);
  console.log("gasBalance", gasBalance);
  console.log(
    "Number(extraTopupAmount) + gasBalance",
    Number(extraTopupAmount) + gasBalance
  );
  console.log("Number(minimumTopupAmount)", Number(minimumTopupAmount));
  return (
    <BasicModal
      open={openExtraTopupModal}
      onClose={() => closeExtraTopupModal()}
    >
      <>
        <Typography variant="h3" textAlign={"center"}>
          Add Extra Topup
        </Typography>

        <TextInput
          title="Enter Topup Amount"
          value={extraTopupAmount}
          onChange={handleTopupAmountChange}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={closeExtraTopupModal}
            title="Cancel"
            style={{ width: "90%" }}
          />
          <div style={{ width: 20 }}></div>

          <Button
            onClick={addExtraTopupAmount}
            title="Add"
            disabled={
              !extraTopupAmount ||
              Number(extraTopupAmount) > Number(usdcBalance) ||
              Number(extraTopupAmount) > Number(minimumTopupAmount)
            }
            style={{ width: "90%" }}
          />
        </div>
      </>
    </BasicModal>
  );
};

export default ExtraTopupAmountPopup;
