import { SupportedChainId } from "constants/chains";
import { getChain } from "./chains";
import {
  getMcaPaymasterSignatureFromServerApi,
  signUserOp,
} from "stash-fe-script/userop";
import { decryptMessage } from "./utils";
import { BigNumber, ethers } from "ethers";
import { getTokensAgainstGas } from "stash-fe-script";
import { EntryPoint_Address } from "stash-fe-script/constants";
import { Chain } from "viem";

export const createSignatureAndCalculateFees = async (
  finalOpState: any,
  rootAddress: string,
  extraTopupAmount: string,
  secret: string,
  hashedPassword: string,
  rpc: string,
  chainId: Chain
) => {
  try {
    let { userOp, exchangeRate, protocolFees } =
      await getMcaPaymasterSignatureFromServerApi(
        finalOpState,
        chainId,
        rootAddress,
        extraTopupAmount
      );

    let preFinalOp = userOp;
    const pkey = decryptMessage(secret, hashedPassword);

    const provider = new ethers.providers.JsonRpcProvider(rpc);
    const wallet = new ethers.Wallet(pkey, provider);

    let signature = signUserOp(preFinalOp, wallet, EntryPoint_Address, chainId);
    preFinalOp.signature = signature;
    const totalGasLimit = BigNumber.from(preFinalOp.callGasLimit).add(
      BigNumber.from(preFinalOp.preVerificationGas).add(
        BigNumber.from(preFinalOp.verificationGasLimit).mul(3)
      )
    );
    const feesInUsdc = getTokensAgainstGas(
      BigNumber.from(preFinalOp.maxFeePerGas),
      totalGasLimit,
      BigNumber.from(exchangeRate),
      BigNumber.from(protocolFees)
    );

    console.log("feesInUsdc", feesInUsdc);

    return {
      preFinalOp,
      feesInUsdc,
    };
  } catch (error) {
    throw error;
  }
};
